import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addConsult,
  getConsultDetail,
  getConsultList,
  updateConsult,
  updateConsultList,
  updateMemoList,
} from "../../redux/actions/MemoActions";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Tab,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { getYouthStudentDetail } from "../../redux/actions/YouthStudentActions";

const ConsultUpdateScreen = () => {
  let { id } = useParams();

  const dispatch: any = useDispatch();

  const consultId = id!.toString();

  const consultList = useSelector((state: any) => state.consultList);
  const { consults } = consultList;

  const consultDetail = useSelector((state: any) => state.consultDetail);
  const { loading, error, consult } = consultDetail;
  
  const youthStudentDetail = useSelector((state: any) => state.youthStudentDetail);
  const { youthStudent } = youthStudentDetail;

  const [consultParents, setConsultParents] = useState<string>("");
  const [consultTeacher, setConsultTeacher] = useState<string>("");
  const [checkboxState, setCheckboxState] = useState<boolean>(false);

  const dateWithDayOfWeek = (date: Date) => {
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}(${days[date.getDay()]})`;
  }

  const updateHandler = () => {
    dispatch(updateConsult(consultId, consultParents, consultTeacher, checkboxState));
  }

  useEffect(() => {
    dispatch(getYouthStudentDetail(consultId));
  }, [dispatch, consultId]);

  useEffect(() => {
    dispatch(getConsultDetail(consultId));
  }, [dispatch, consultId]);

  useEffect(() => {
    if (consult) {
      setConsultParents(consult.consultParents);
      setConsultTeacher(consult.consultTeacher);
      setCheckboxState(consult.isImportant);
    }
  }, [dispatch, consult]);

  return (
    <Box mx={{ base: "4", md: "40" }} pb={{ base: "40", md: "0" }} mb={20}>
      <Center>
        <VStack>
          <Text fontSize="4xl" fontWeight="bold">
            {youthStudent?.name} 회원 상담일지 수정
          </Text>
        </VStack>
      </Center>
      <Center mt={50}>
        <TableContainer overflowX="auto">
          <Table variant="simple" border="2px solid" borderColor="gray.400">
            <Thead bg="gray.100">
              <Tr>
                <Th
                  textAlign="center"
                  left="0"
                  borderWidth="1px"
                  borderColor="gray.400"
                  bg="gray.100"
                  fontSize={"md"}
                  w={"200px"}
                >
                  날짜 선택
                </Th>
                <Th
                  textAlign="center"
                  bg="gray.100"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                  w={"1000px"}
                >
                  상담일지 내용
                </Th>
                <Th
                  textAlign="center"
                  bg="gray.100"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                >
                  중요
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr minH="80px">
                <Td
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                >
                  {dateWithDayOfWeek(new Date(consult?.date))}
                </Td>
                <Td
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                  paddingLeft="2"
                  paddingRight="2"
                  paddingTop="2"
                  paddingBottom="2"
                >
                  <Text textAlign={"left"} mb={1}>
                    강사
                  </Text>
                  <Textarea
                    placeholder="강사 내용란"
                    resize="vertical"
                    minH="unset"
                    overflowY="auto"
                    w="100%"
                    h="70px"
                    value={consultTeacher}
                    onChange={(e) => setConsultTeacher(e.target.value)}
                  />
                  <br />
                  <Text mt={2} mb={1} textAlign={"left"}>
                    학부모
                  </Text>
                  <Textarea
                    placeholder="학부모 내용란"
                    resize="vertical"
                    minH="unset"
                    overflowY="auto"
                    w="100%"
                    h="70px"
                    value={consultParents}
                    onChange={(e) => setConsultParents(e.target.value)}
                  />
                </Td>
                <Td
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                >
                  <Checkbox
                    size="lg"
                    colorScheme="orange"
                    isChecked={checkboxState}
                    onChange={() => setCheckboxState(!checkboxState)}
                  ></Checkbox>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
      <Center mt={10}>
        <Button
          colorScheme="orange"
          w={"150px"}
          h={"50px"}
          onClick={updateHandler}
        >
          수정하기
        </Button>
      </Center>
    </Box>
  );
};

export default ConsultUpdateScreen;