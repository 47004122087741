const accessCheck = (accessList: string[]) => {
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  //if one of accesslist is in teacherInfo.accesslist, return true
  // const check = accessList.some((access) => teacherInfo.accessList.includes(access));

  const check = teacherInfo && teacherInfo.accessList ? accessList.some((access) => teacherInfo.accessList.includes(access)) : false;

  return check;
}

export default accessCheck;