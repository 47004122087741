export const BRANCH_CREATE_REQUEST = "BRANCH_CREATE_REQUEST" as const;
export const BRANCH_CREATE_SUCCESS = "BRANCH_CREATE_SUCCESS" as const;
export const BRANCH_CREATE_FAIL = "BRANCH_CREATE_FAIL" as const;

export const BRANCH_UPDATE_REQUEST = "BRANCH_UPDATE_REQUEST" as const;
export const BRANCH_UPDATE_SUCCESS = "BRANCH_UPDATE_SUCCESS" as const;
export const BRANCH_UPDATE_FAIL = "BRANCH_UPDATE_FAIL" as const;
export const BRANCH_UPDATE_RESET = "BRANCH_UPDATE_RESET" as const;

export const BRANCH_GET_REQUEST = "BRANCH_GET_REQUEST" as const;
export const BRANCH_GET_SUCCESS = "BRANCH_GET_SUCCESS" as const;
export const BRANCH_GET_FAIL = "BRANCH_GET_FAIL" as const;
export const BRANCH_GET_RESET = "BRANCH_GET_RESET" as const;

export const BRANCH_DELETE_REQUEST = "BRANCH_DELETE_REQUEST" as const;
export const BRANCH_DELETE_SUCCESS = "BRANCH_DELETE_SUCCESS" as const;
export const BRANCH_DELETE_FAIL = "BRANCH_DELETE_FAIL" as const;

export const BRANCH_INFO_REQUEST = "BRANCH_INFO_REQUEST" as const;
export const BRANCH_INFO_SUCCESS = "BRANCH_INFO_SUCCESS" as const;
export const BRANCH_INFO_FAIL = "BRANCH_INFO_FAIL" as const;
export const BRANCH_INFO_RESET = "BRANCH_INFO_RESET" as const;

export const BRANCH_GET_TEACHER_STAT_REQUEST =
  "BRANCH_GET_TEACHER_STAT_REQUEST" as const;
export const BRANCH_GET_TEACHER_STAT_SUCCESS =
  "BRANCH_GET_TEACHER_STAT_SUCCESS" as const;
export const BRANCH_GET_TEACHER_STAT_FAIL =
  "BRANCH_GET_TEACHER_STAT_FAIL" as const;
export const BRANCH_GET_TEACHER_STAT_RESET =
  "BRANCH_GET_TEACHER_STAT_RESET" as const;

  export const BRANCH_GET_GROUP_STAT_REQUEST =
  "BRANCH_GET_GROUP_STAT_REQUEST" as const;
export const BRANCH_GET_GROUP_STAT_SUCCESS =
  "BRANCH_GET_GROUP_STAT_SUCCESS" as const;
export const BRANCH_GET_GROUP_STAT_FAIL =
  "BRANCH_GET_GROUP_STAT_FAIL" as const;
export const BRANCH_GET_GROUP_STAT_RESET =
  "BRANCH_GET_GROUP_STAT_RESET" as const;

export const BRANCH_GET_TEACHER_SINGLE_STAT_REQUEST =
  "BRANCH_GET_TEACHER_SINGLE_STAT_REQUEST" as const;
export const BRANCH_GET_TEACHER_SINGLE_STAT_SUCCESS =
  "BRANCH_GET_TEACHER_SINGLE_STAT_SUCCESS" as const;
export const BRANCH_GET_TEACHER_SINGLE_STAT_FAIL =
  "BRANCH_GET_TEACHER_SINGLE_STAT_FAIL" as const;
export const BRANCH_GET_TEACHER_SINGLE_STAT_RESET =
  "BRANCH_GET_TEACHER_SINGLE_STAT_RESET" as const;

  export const BRANCH_GET_TEACHER_TEAM_STAT_REQUEST =
  "BRANCH_GET_TEACHER_TEAM_STAT_REQUEST" as const;
export const BRANCH_GET_TEACHER_TEAM_STAT_SUCCESS =
  "BRANCH_GET_TEACHER_TEAM_STAT_SUCCESS" as const;
export const BRANCH_GET_TEACHER_TEAM_STAT_FAIL =
  "BRANCH_GET_TEACHER_TEAM_STAT_FAIL" as const;
export const BRANCH_GET_TEACHER_TEAM_STAT_RESET =
  "BRANCH_GET_TEACHER_TEAM_STAT_RESET" as const;

export const BRANCH_GET_GRAPH_REQUEST = "BRANCH_GET_GRAPH_REQUEST" as const;
export const BRANCH_GET_GRAPH_SUCCESS = "BRANCH_GET_GRAPH_SUCCESS" as const;
export const BRANCH_GET_GRAPH_FAIL = "BRANCH_GET_GRAPH_FAIL" as const;
export const BRANCH_GET_GRAPH_RESET = "BRANCH_GET_GRAPH_RESET" as const;

export const BRANCH_GET_GRAPH_GROUP_REQUEST =
  "BRANCH_GET_GRAPH_GROUP_REQUEST" as const;
export const BRANCH_GET_GRAPH_GROUP_SUCCESS =
  "BRANCH_GET_GRAPH_GROUP_SUCCESS" as const;
export const BRANCH_GET_GRAPH_GROUP_FAIL =
  "BRANCH_GET_GRAPH_GROUP_FAIL" as const;
export const BRANCH_GET_GRAPH_GROUP_RESET =
  "BRANCH_GET_GRAPH_GROUP_RESET" as const;

export const GET_TEACHERS_BY_USERID_REQUEST = "GET_TEACHERS_BY_USERID_REQUEST" as const;
export const GET_TEACHERS_BY_USERID_SUCCESS = "GET_TEACHERS_BY_USERID_SUCCESS" as const;
export const GET_TEACHERS_BY_USERID_FAIL = "GET_TEACHERS_BY_USERID_FAIL" as const;
export const GET_TEACHERS_BY_USERID_RESET = "GET_TEACHERS_BY_USERID_RESET" as const;