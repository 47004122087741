import {
  Box,
  Button,
  Center,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
  VStack,
  Grid,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { getYouthTeamList } from "../../redux/actions/YouthTeamActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getYouthStudentQuitList } from "../../redux/actions/YouthStudentActions";
import { getTeachersByUserId } from "../../redux/actions/BranchActions";

const AttendListScreen = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const teacherId = userInfo._id;
  const dispatch: any = useDispatch();

  const [teacherOption, setTeacherOption] = useState<any[]>([]);
  const [team, setTeam] = useState<any[]>([]);

  const youthTeamList = useSelector((state: any) => state.youthTeamList);
  const { loading, error, youthTeams } = youthTeamList;

  const teachersByUserId = useSelector((state: any) => state.teachersByUserId);
  const { teachers } = teachersByUserId;

  useEffect(() => {
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, false));
  }, [dispatch, teacherId, teacherInfo.branch._id]);

  // useEffect(() => {
  //   if (youthTeams) {
  //     const teacherList = youthTeams.map((team: any) => ({
  //       value: team.teacherId,
  //       label: team.teacherName,
  //     }));

  //     const uniqueTeacherList = teacherList.filter(
  //       (v: any, i: any, a: any) => a.findIndex((t: any) => t.value === v.value) === i
  //     );

  //     setTeacherOption(uniqueTeacherList);

  //     const teamList = youthTeams.filter((team: any) => team.teacherId === teacherList[0].value);

  //     setTeam(teamList);
  //   }
  // }, [youthTeams]);

  useEffect(() => {
    if (teachers?.length > 0) {
      const teacherList = teachers.map((teacher: any) => ({
        value: teacher.teacherId,
        label: teacher.teacherName,
      }));

      setTeacherOption(teacherList);

      dispatch(
        getYouthTeamList(teachers[0].teacherId, teacherInfo.branch._id, false)
      );
    }
  }, [teachers]);

  const handleTeacherClick = (teacherId: string) => {
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, false));
  };

  return (
    <Box mx={{ base: "4", md: "40" }}>
      <VStack>
        <HStack mb={2}>
          <Text
            textAlign={"center"}
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight={"bold"}
          >
            {userInfo.name} 강사 출석부
          </Text>
        </HStack>
        <Grid
          columnGap={4}
          rowGap={2}
          templateColumns={{
            base: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(2, 1fr)",
            xl: "repeat(2, 1fr)",
            "2xl": "repeat(2, 1fr)",
          }}
          textAlign={"center"}
        >
          <Link to={"/youthdb/youth-student/new"}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">신규 회원 추가</Button>
          </Link>
          <Link to={"/youthdb/youth-team/new"}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">신규 팀 추가</Button>
          </Link>
          <Link to={"/youthdb/youth-student/xlsx"}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">신규 회원 추가(엑셀)</Button>
          </Link>
          <Link to={`/youthdb/youth-student/quit/1`}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">탈퇴 회원 관리</Button>
          </Link>
          <Link to={`/youthdb/youth-student/branch`}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">전체 회원</Button>
          </Link>
          <Link to={`/youthdb/youth-student/search/1`}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">회원 검색 🔍</Button>
          </Link>
          <Link to={`/youthdb/attend/supplement-list`}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">보강 관리</Button>
          </Link>
        </Grid>
        <HStack>
          <TableContainer
            maxH="2000px"
            maxW={{ base: "350px", sm: "500px", md: "700px", lg: "1500px" }}
            minW={{ base: "300px", md: "400px" }}
            overflowX="auto"
          >
            <Table variant="simple" colorScheme="orange" mt={6}>
              <Thead bg="gray.100">
                <Tr>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    팀명
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    p={1}
                  >
                    회원수
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    p={0}
                  >
                    출석부 작성
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    p={0}
                  >
                    팀 정보 수정
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <h2>Loading...</h2>
                ) : error ? (
                  <h2>{error}</h2>
                ) : (
                  youthTeams?.youthTeam?.map((team: any) => (
                    <Tr key={team._id}>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                      >
                        <Center>
                          <Text
                            cursor="pointer"
                            fontWeight={"bold"}
                            _hover={{ color: "blue.500" }}
                          >
                            <Link to={`${team._id}`}>{team.name}</Link>
                          </Text>
                        </Center>
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"light"}
                        p={1}
                      >
                        {team.members.length}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"light"}
                      >
                        <Center>
                          <Button
                            colorScheme="orange"
                            style={{ height: "30px" }}
                            fontSize={{ base: "10px", md: "12px" }}
                            p={1}
                            onClick={() => {
                              window.location.href = `/youthdb/attend/${team._id}/new`;
                            }}
                          >
                            출석부 작성
                          </Button>
                        </Center>
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"light"}
                      >
                        <Center>
                          <Button
                            colorScheme="blue"
                            style={{ height: "30px" }}
                            fontSize={{ base: "10px", md: "12px" }}
                            p={1}
                            onClick={() => {
                              window.location.href = `/youthdb/youth-student/${team._id}/edit`;
                            }}
                          >
                            팀 정보 수정
                          </Button>
                        </Center>
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </HStack>
        <Link to={"/youthdb/attend-hidden"}>
          <Button colorScheme="red" mt={10}>
            삭제된 팀 출석부
          </Button>
        </Link>
      </VStack>
    </Box>
  );
};

export default AttendListScreen;
