import React, { useState } from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";
import DatePicker2 from "./ReactDatePicker";

interface DatepickerProps {
  selectedDate: Date | undefined;
  onDateChange: (newDate: Date | null) => void;
}

const Datepicker: React.FC<DatepickerProps> = ({
  selectedDate,
  onDateChange,
}) => {
  let newDate = new Date();

  const handleDateChangeWrapper = (date: Date | null) => {
    onDateChange(date);
  };

  if (selectedDate) {
    newDate = new Date(selectedDate?.getTime() + 9 * 60 * 60 * 1000);
  } else {
    console.log("Not setted.");
  }

  return (
    <HStack>
      <VStack>
        <Text>수업일</Text>
        <DatePicker2
          selectedDate={selectedDate}
          onChange={handleDateChangeWrapper}
        />
      </VStack>
    </HStack>
  );
};

export default Datepicker;
