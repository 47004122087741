import React, { useState, useEffect } from "react";
import { getAttendTable, batchUpdateAttend } from "../../redux/actions/AttendActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Select,
  Center,
  Button,
  IconButton,
  Spinner,
  HStack,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatArrow,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import {
  getYouthTeamDetail,
  getYouthTeamList,
} from "../../redux/actions/YouthTeamActions";
import { Link, useParams } from "react-router-dom";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { format } from "date-fns";
import ko from "date-fns/locale/ko";

const AttendScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const teamId = String(id);

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const [teacherId, setTeacherId] = useState("");
  const dispatch: any = useDispatch();

  const attendTableList = useSelector((state: any) => state.attendTableList);
  const { loading, error, attendTable } = attendTableList;

  const youthTeamDetail = useSelector((state: any) => state.youthTeamDetail);
  const {
    loading: loadingDetail,
    error: errorDetail,
    youthTeam,
  } = youthTeamDetail;

  const youthTeamList = useSelector((state: any) => state.youthTeamList);
  const { youthTeams } = youthTeamList;

  const [year, setYear] = useState(new Date().getFullYear());
  const [startMonth, setStartMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );
  const [endMonth, setEndMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );

  const [teamName, setTeamName] = useState("");
  const [selectedAttends, setSelectedAttends] = useState<AttendanceData[]>([]);
  const [monthChangeLoading, setMonthChangeLoading] = useState(false);
  const [youthTeamId, setYouthTeamId] = useState(null);

  const [emptyCol, setEmptyCol] = useState(0);

  const [attendanceData, setAttendanceData] = useState<AttendanceModifyData[]>([]);
  const [isModify, setIsModify] = useState(false);

  useEffect(() => {
    if (youthTeam) {
      setYouthTeamId(youthTeam._id);
      setTeamName(youthTeam.name);
    }
  }, [youthTeam]);

  useEffect(() => {
    dispatch(getYouthTeamDetail(teamId));
  }, [dispatch, teamId]);

  useEffect(() => {
    if (attendTable) {
      setEmptyCol(
        4 - attendTable?.table?.length >= 0 ? 4 - attendTable?.table?.length : 0
      );
    }
  }, [attendTable]);

  useEffect(() => {
    if (startMonth === 13) {
      setYear(year + 1);
      setStartMonth(1);
      setEndMonth(1);
    }
    if (startMonth === 0) {
      setYear(year - 1);
      setStartMonth(12);
      setEndMonth(12);
    }
  }, [startMonth]);



  useEffect(() => {
    if (youthTeam?.teacherId) {
      dispatch(getYouthTeamList(youthTeam.teacherId, teacherInfo.branch._id, false));
      setTeacherId(youthTeam.teacherId);
    }
  }, [dispatch, youthTeam]);


  const handleTeamChange = (e: any) => {
    window.location.href = `/youthdb/attend/${e.target.value}`;
  }

  interface AttendanceData {
    youthTeamId: string;
    date: string;
    attendance: AttendanceItem[];
    rate: AttendanceRate[];
    attendId: string;
  }

  interface AttendanceModifyData {
    studentId: string;
    studentName: string;
    note: string;
    isImportant: boolean;
    status: "출석" | "결석" | "휴강" | "탈퇴" | "신규" | "복귀" | "-";
  }

  interface AttendanceItem {
    studentId: string;
    studentName: string;
    status: "출석" | "결석" | "휴강" | "탈퇴" | "신규" | "복귀";
    supplementInfo?: any;
  }

  interface AttendanceRate {
    studentId: string;
    total: number;
    present: number;
  }

  interface TableProps {
    data: AttendanceData[];
  }

  // useEffect(() => {
  //   if (attendTable && attendTable.table && attendTable.table.length > 0) {


  const attendanceFormat = (attendanceItem: AttendanceItem, attendId: string, studentId: string) => {
    if (attendanceItem.status.startsWith("신규")) {
      return (
        <>
          {attendanceItem.status.slice(0, 2)}
          <p style={{ fontSize: "10px" }}>{attendanceItem.status.slice(2)}</p>
        </>
      );
    } else if (attendanceItem.status.startsWith("결석")) {
      if (isModify) {
        return <>❌</>
      } else {
        return (
          <>
            <Button
              p={1}
              m={0}
              minW="auto"
              h="auto"
            >
              <Link to={`/youthdb/attend/supplement/${attendId}?studentId=${studentId}`}>❌</Link>
            </Button>
          </>
        )
      }
    } else if (attendanceItem.status.startsWith("보강")) {
      return (
        <>
          <Button
            p={1}
            m={0}
            minW="auto"
            h="auto"
            onClick={() => {
              const supplementDate = new Date(attendanceItem.supplementInfo.supplementDate);
              const toTeamTeacherName = attendanceItem.supplementInfo.toTeamTeacherName;
              const toTeamName = attendanceItem.supplementInfo.toTeamName;
              alert(`${supplementDate.getFullYear()}년 ${supplementDate.getMonth() + 1}월 ${supplementDate.getDate()}일 [${toTeamTeacherName}] 강사님의 [${toTeamName}]반으로 보강 완료되었습니다.`);
            }}
          >
            보강
          </Button>
        </>
      )
    } else if (attendanceItem.status.startsWith("출석")) {
      return <>⭕️</>;
    } else {
      return attendanceItem.status;
    }
  };

  const getNextStatus = (currentStatus: string) => {
    switch (currentStatus) {
      case "출석":
        return "결석";
      case "결석":
        return "휴강";
      case "휴강":
        return "탈퇴";
      case "탈퇴":
        return "신규";
      case "신규":
        return "출석";
      default:
        return "출석";
    }
  };

  const handleCellClick = (studentId: string, tableIndex: number) => {
    // setAttendanceData((prevData) =>
    //   prevData.map((item) =>
    //     item.studentId === studentId
    //       ? { ...item, status: getNextStatus(item.status) }
    //       : item
    //   )
    // );
    let copy = [...selectedAttends];

    copy[tableIndex].attendance = copy[tableIndex].attendance.map((item) =>
      item.studentId === studentId
        ? { ...item, status: getNextStatus(item.status) }
        : item
    );

    setSelectedAttends(copy);
  };

  const attendanceFontColor = (status: string) => {
    if (status === "출석") {
      return "black";
    } else if (status === "휴강") {
      return "blue";
    } else {
      return "red";
    }
  };

  const CustomTable: React.FC<TableProps> = ({ data }) => {
    const studentIds =
      data &&
      data.flatMap((item) =>
        item.attendance.map((attendance) => attendance.studentId)
      );
    const uniqueStudentIds = Array.from(new Set(studentIds));

    const Stack: React.ElementType =
      useBreakpointValue({ base: VStack, md: HStack }) || VStack;

    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          maxW={{ base: "300px", sm: "500px", md: "700px", lg: "1500px" }}
        >
          <Flex gap={10} maxW={{ base: "300px", sm: "500px", md: "700px", lg: "1500px" }}>
            <Box w="600px">
              <Center>
                <Box
                  mt={10}
                  mx={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconButton
                    aria-label="prev"
                    icon={<ArrowBackIcon />}
                    mx={4}
                    onClick={() => {
                      setStartMonth(startMonth - 1);
                      setEndMonth(endMonth - 1);
                    }}
                  />
                  <Text fontSize="2xl" fontWeight="bold">
                    {year}년 {startMonth}월
                  </Text>
                  <IconButton
                    aria-label="next"
                    icon={<ArrowForwardIcon />}
                    mx={4}
                    onClick={() => {
                      setStartMonth(startMonth + 1);
                      setEndMonth(endMonth + 1);
                    }}
                  />
                </Box>
              </Center>
              <Center>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    window.location.href = `/youthdb/attend-year/${youthTeamId}`;
                  }}
                  p={1}
                  mb={2}
                  h={4}
                  fontSize={"12px"}
                  >
                    연간 출석부 보기
                  </Button>
              </Center>
              <Center>
                <Text
                  textAlign={"center"}
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight={"bold"}
                >
                  {youthTeams?.teacherName} 강사 출석부
                </Text>
              </Center>
              <Flex
                mx={4}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box textAlign={"left"}>
                  <Select
                    placeholder={"반을 선택하세요..."}
                    onChange={handleTeamChange}
                    width={{ base: "200px" }}
                    height={{ base: "40px" }}
                    borderColor="tomato"
                    fontSize={{ base: "16px" }}
                  >
                    {teamOption.map((item: any) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value === youthTeamId}
                      >
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Button colorScheme="blue" mx={4}>
                  <Link to={"new"}>새 출석부 작성</Link>
                </Button>
                <Box textAlign={"right"}>
                  <Button
                    colorScheme="orange"
                    style={{ height: "30px" }}
                    onClick={() => {
                      window.location.href = `/youthdb/attend/${youthTeamId}/new`;
                    }}
                  >
                    이 달의 출석률 :{" "}
                    {attendTable?.totalPresent !== 0 ? (
                      (
                        (attendTable?.totalPresent /
                          (attendTable?.totalPresent +
                            attendTable?.totalAbsent)) *
                        100
                      ).toFixed(1)
                    ) : (
                      " - "
                    )}
                    %
                  </Button>
                </Box>
              </Flex>
              <Box
                mt={4}
                mx={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <TableContainer maxH="1000px" width="100%">
                  <Table
                    variant="simple"
                    border="2px solid"
                    borderColor="gray.400"
                  >
                    <Thead bg="gray.100">
                      <Tr>
                        <Th
                          textAlign="center"
                          position="sticky"
                          left="0"
                          borderWidth="1px"
                          borderColor="gray.400"
                          bg="gray.100"
                          fontSize={"md"}
                          padding={2}
                        >
                        </Th>
                        {/* <Th
                          textAlign="center"
                          position="sticky"
                          left="0"
                          borderWidth="1px"
                          borderColor="gray.400"
                          bg="gray.100"
                          fontSize={"md"}
                          padding={2}
                        >
                          순번
                        </Th> */}
                        <Th
                          textAlign="center"
                          position="sticky"
                          left="50px"
                          bg="gray.100"
                          borderWidth="1px"
                          borderColor="gray.400"
                          fontSize={"md"}
                        >
                          이름
                        </Th>
                        {data.map((item) => {
                          if (!item.date) {
                            console.warn(
                              "Undefined or null date encountered:",
                              item
                            );
                            return null;
                          }
                          const parsedDate = new Date(item.date);

                          if (isNaN(parsedDate.getTime())) {
                            console.warn(
                              "Invalid date encountered:",
                              item.date
                            );
                            return null;
                          }

                          const day = format(parsedDate, "dd일", {
                            locale: ko,
                          });
                          const weekday = format(parsedDate, "eeee", {
                            locale: ko,
                          }).charAt(0);

                          return (
                            <Th
                              key={item.date}
                              textAlign="center"
                              borderWidth="1px"
                              borderColor="gray.400"
                              fontSize={"md"}
                              paddingTop={"3"}
                              paddingBottom={"3"}
                              paddingRight={"0"}
                              paddingLeft={"0"}
                              width={"15%"}
                            >
                              <VStack>
                                <Link to={`/youthdb/attend/update/${item.attendId}`}>
                                  {`${day}(${weekday})`}
                                </Link>
                                {/* <Box
                                  height="2px"
                                  backgroundColor="gray.400"
                                  width="full"
                                />
                                <HStack>
                                  <Box
                                    as="button"
                                    onClick={() => {
                                      window.location.href = `/youthdb/consultmemo/${parsedDate.getFullYear()}${(
                                        "0" +
                                        (parsedDate.getMonth() + 1)
                                      ).slice(-2)}${("0" + parsedDate.getDate()).slice(
                                        -2
                                      )}/${youthTeamId}`;
                                    }}
                                    fontWeight={"bold"}
                                  >
                                    <p style={{ fontSize: "12px" }}>상담일지 •</p>
                                  </Box>
                                  <Box
                                    as="button"
                                    onClick={() => {
                                      window.location.href = `/youthdb/onelinememo/${parsedDate.getFullYear()}${(
                                        "0" +
                                        (parsedDate.getMonth() + 1)
                                      ).slice(-2)}${("0" + parsedDate.getDate()).slice(
                                        -2
                                      )}/${youthTeamId}`;
                                    }}
                                    fontWeight={"bold"}
                                  >
                                    <p style={{ fontSize: "12px" }}>한줄평</p>
                                  </Box>
                                </HStack> */}
                              </VStack>
                            </Th>
                          );
                        })}
                        {new Array(emptyCol).fill(0).map((_, i) => (
                          <Th
                            key={i}
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            fontSize={"md"}
                            paddingTop={"3"}
                            paddingBottom={"3"}
                            paddingRight={"0"}
                            paddingLeft={"0"}
                            width={"15%"}
                            style={{
                              color: "transparent",
                            }}
                          >
                            {"00일(월)"}
                          </Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {monthChangeLoading ? (
                        <Tr>
                          <Td
                            colSpan={4 + (data.length || 0)}
                            borderWidth="1px"
                            borderColor="gray.400"
                          >
                            {" "}
                            <Center fontSize={"2xl"}>
                              <Spinner size="xl" color="orange" />
                            </Center>
                            <Center mt={10} fontSize={"xl"} color={"orange"}>
                              데이터를 불러오는 중입니다...
                            </Center>
                          </Td>
                        </Tr>
                      ) : uniqueStudentIds.length > 0 ? (
                        uniqueStudentIds.map((studentId, index) => {
                          var studentName = data[
                            data.length - 1
                          ].attendance.find(
                            (attendance) => attendance.studentId === studentId
                          )?.studentName;
                          if (studentName === undefined) {
                            studentName = attendTable?.rate.find(
                              (s: any) => s.studentId === studentId
                            )?.studentName;
                          }
                          return (
                            <Tr key={studentId}>
                              {/* <Td
                                textAlign="center"
                                position="sticky"
                                left="0px"
                                fontWeight={"bold"}
                                bg="white"
                                borderWidth="1px"
                                borderColor="gray.400"
                                padding={2}
                              >
                                {index + 1}
                              </Td> */}
                              <Td
                                textAlign="center"
                                position="sticky"
                                left="0px"
                                fontWeight={"bold"}
                                bg="white"
                                borderWidth="1px"
                                borderColor="gray.400"
                                padding={2}
                              >
                                <Button size="sm">
                                  <Link to={`/youthdb/consultmemo/student/${studentId}`}>상담일지</Link>
                                </Button>
                              </Td>
                              <Td
                                width="100px"
                                textAlign="center"
                                position="sticky"
                                left="50px"
                                fontWeight={"bold"}
                                bg="white"
                                borderWidth="1px"
                                borderColor="gray.400"
                              >
                                <Link to={`/youthdb/youth-student/update/${studentId}`}>
                                  {studentName}
                                </Link>
                              </Td>
                              {selectedAttends.map((item, tableIndex) => {
                                const attendanceItem = item.attendance.find(
                                  (attendance) =>
                                    attendance.studentId === studentId
                                );
                                return (
                                  <>
                                    {isModify ? (
                                      <Td
                                        textAlign="center"
                                        onClick={() => handleCellClick(studentId, tableIndex)}
                                        cursor="pointer"
                                        borderWidth="1px"
                                        borderColor="gray.400"
                                        padding={2}
                                      >
                                        {attendanceItem
                                          ? attendanceFormat(attendanceItem, item.attendId, studentId)
                                          : " "}
                                      </Td>
                                    ) : (
                                      <Td
                                        key={item.date}
                                        textAlign="center"
                                        borderWidth="1px"
                                        borderColor="gray.400"
                                        padding={2}
                                        style={{ color: attendanceItem && attendanceFontColor(attendanceItem.status) }}
                                        background={attendanceItem ? "white" : "gray.100"}
                                      >
                                        {attendanceItem
                                          ? attendanceFormat(attendanceItem, item.attendId, studentId)
                                          : " "}
                                      </Td>
                                    )}
                                  </>
                                );
                              })}
                              {new Array(emptyCol).fill(0).map((_, i) => (
                                <Td
                                  key={i}
                                  textAlign="center"
                                  borderWidth="1px"
                                  borderColor="gray.400"
                                  style={{
                                    color: "transparent",
                                  }}
                                >
                                  {"출석"}
                                </Td>
                              ))}
                            </Tr>
                          );
                        })
                      ) : (
                        <Tr>
                          <Td colSpan={4 + (data.length || 0)}>
                            <Center fontSize={"2xl"} color={"red"}>
                              해당 월에는 출석부 데이터가 없습니다.
                            </Center>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
              <Flex mt={2}>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    setIsModify(!isModify);
                  }}
                  p={1}
                  mr={2}
                >
                  수정모드
                </Button>
                {isModify && (
                <Button
                  colorScheme="orange"
                  onClick={() => {
                    dispatch(batchUpdateAttend(selectedAttends));
                  }}
                  p={1}
                >
                  수정완료
                </Button>
                )}
              </Flex>
            </Box>
            <Box mt={10} textAlign={"center"} minW="100px">
              <Text fontSize="2xl" fontWeight="bold">
                {startMonth}월 현황
              </Text>
              <VStack mt={10} gap="4">
                <Stat
                  border={"1px"}
                  borderRadius="15px"
                  borderColor="gray.400"
                  padding={"3"}
                >
                  <StatLabel fontSize="l" fontWeight="bold">
                    신규
                  </StatLabel>
                  <StatNumber fontSize="2xl">
                    <StatArrow type="increase" />
                    {attendTable && attendTable.newStudent}
                  </StatNumber>
                </Stat>
                <Stat
                  border={"1px"}
                  borderRadius="15px"
                  borderColor="gray.400"
                  padding={"3"}
                >
                  <StatLabel fontSize="l" fontWeight="bold">
                    복귀
                  </StatLabel>
                  <StatNumber fontSize="2xl">
                    <StatArrow type="increase" />
                    {attendTable && attendTable.returnStudent}
                  </StatNumber>
                </Stat>
                <Stat
                  border={"1px"}
                  borderRadius="15px"
                  borderColor="gray.400"
                  padding={"3"}
                >
                  <StatLabel fontSize="l" fontWeight="bold">
                    휴강
                  </StatLabel>
                  <StatNumber fontSize="2xl">
                    <StatArrow type="decrease" />
                    {attendTable && attendTable.restStudent}
                  </StatNumber>
                </Stat>
                <Stat
                  border={"1px"}
                  borderRadius="15px"
                  borderColor="gray.400"
                  padding={"3"}
                >
                  <StatLabel fontSize="l" fontWeight="bold">
                    탈퇴
                  </StatLabel>
                  <StatNumber fontSize="2xl">
                    <StatArrow type="decrease" />
                    {attendTable && attendTable.quitStudent}
                  </StatNumber>
                </Stat>
              </VStack>
            </Box>
          </Flex>
        </Box>
      </>
    );
  };

  const teamOption: { value: string; label: string }[] = [];

  youthTeams?.youthTeam?.map((team: any) => {
    teamOption.push({ value: team._id, label: team.name });
  });

  useEffect(() => {
    setMonthChangeLoading(true);
    if (youthTeams?.youthTeam?.length > 0 && youthTeamId) {
      setSelectedAttends([]);
      dispatch(getAttendTable(year, startMonth, endMonth, teamId));

    } else if (youthTeams && youthTeams.length === 0) {
      setMonthChangeLoading(false);
    }
  }, [dispatch, youthTeams, startMonth, endMonth, youthTeamId]);

  useEffect(() => {

    if (
      attendTable &&
      attendTable.table &&
      attendTable.table.length > 0 &&
      youthTeams?.youthTeam?.length > 0
    ) {
      // setSelectedAttends(
      //   attendTable.table.filter(
      //     (item: any) =>
      //       item.youthTeamId.toString() === youthTeams[0]._id.toString()
      //   )
      // );

      console.log(attendTable.table);
      setSelectedAttends(attendTable.table);

      setMonthChangeLoading(false);
    }
    if (attendTable && attendTable.table && attendTable.table.length === 0) {
      setMonthChangeLoading(false);
    }
  }, [attendTable]);

  return (
    <Box mx={{ base: "4", md: "40" }} pb={{ base: "40", md: "0" }}>
      {loading && loadingDetail ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <CustomTable data={selectedAttends} />
        </>
      )}
    </Box>
  );
};

export default AttendScreen;