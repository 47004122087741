import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { inputYouthPost } from "../../redux/actions/HomePageActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MyCustomUploadAdapterPlugin from "../../utils/MyCustomUploadAdapterPlugin";
import { useParams } from "react-router-dom";

export default function PostWrite() {
  const { type } = useParams<{ type: string }>();
  const typeStr = String(type);
  const isNotice: boolean = typeStr === "공지사항";

  const editorConfig = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "selectAll",
        "|",
        "bold",
        "italic",
        "|",
        "accessibilityHelp",
        "|",
        "imageUpload",
      ],
      shouldNotGroupWhenFull: false,
    },
    extraPlugins: [MyCustomUploadAdapterPlugin],
  };

  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState(typeStr);

  const submitHandler = () => {
    if (category === "") {
      alert("카테고리를 선택해주세요");
      return;
    }
    if (title === "") {
      alert("제목을 입력해주세요");
      return;
    }
    if (content === "") {
      alert("내용을 입력해주세요");
      return;
    }

    dispatch(
      inputYouthPost(
        branchPage.branchId,
        title,
        content,
        category,
        userInfo.name,
        userInfo._id
      )
    );

    window.location.href = `/${branchPage?.branchUrl}/${isNotice ? "post" : "program-guide"}/${category}?p=1`;
  };

  const categoryOptions: { value: string; label: string }[] = [];

  branchPage?.programGuideList?.map((category: any) => {
    categoryOptions.push({ value: category, label: category });
  });

  useEffect(() => {
    if (
      branchPage &&
      branchPage.programGuideList &&
      branchPage.programGuideList.length > 0
    ) {
      const newCategory = isNotice
        ? "공지사항"
        : branchPage.programGuideList[0];
      setCategory(newCategory);
    }
  }, [branchPage]);

  return (
    <Box bg="white" color="black" p={10} maxW={"1300px"} mx="auto">
      <Flex>
        <Center>
          <Text fontSize="xl">
            {isNotice ? "공지사항" : "프로그램 안내"} 작성
          </Text>
        </Center>
      </Flex>
      {!isNotice && (
        <>
          <Box py={4}>
            <Select
              onChange={(e) => setCategory(e.target.value)}
            >
              {categoryOptions.map((c: { value: string; label: string }) => (
                <option key={c.value} value={c.value} selected={c.value === typeStr}>
                  {c.label}
                </option>
              ))}
            </Select>
          </Box>
        </>
      )}
      <Box py={4}>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="제목을 입력해주세요"
        />
      </Box>
      <Box
        className="editor-container"
        minH="500px"
        css={{
          ".ck-editor__editable_inline": {
            minHeight: "500px",
          },
        }}
      >
        <CKEditor
          editor={ClassicEditor}
          config={editorConfig}
          data=""
          // onReady={editor => {
          //   console.log('Editor is ready to use!', editor);
          // }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
          }}
          // onBlur={(event, editor) => {
          //   console.log('Blur.', editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log('Focus.', editor);
          // }}
        />
      </Box>
      <Center mt={8}>
        <Button onClick={submitHandler} colorScheme="orange" w="300px">
          작성하기
        </Button>
      </Center>
    </Box>
  );
}
