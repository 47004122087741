import {
  Box,
  Button,
  Center,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getYouthStudentList } from "../../redux/actions/YouthStudentActions";
import { Link, useParams } from "react-router-dom";
import formatPhoneNum from "../../utils/formatPhoneNum";
import Paginate from "../../components/Paginate";
import Searcher from "../../components/Searcher";

const StudentSearchScreen = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const teacherId = userInfo._id;

  const { keyword } = useParams<{ keyword: string }>();
  const keywordString = keyword ? keyword : "";
  const { pageNumber } = useParams<{ pageNumber: string }>() || 1;
  const year = 2024;

  const dispatch: any = useDispatch();

  const youthStudentQuitList = useSelector((state: any) => state.youthStudentQuitList);
  const { loading: loadingQuit, error: errorQuit, quitStudent } = youthStudentQuitList;

  const youthStudentList = useSelector((state: any) => state.youthStudentList);
  const { loading, error, youthStudents } = youthStudentList

  useEffect(() => {
    console.log(keywordString)
    dispatch(getYouthStudentList(teacherInfo.branch._id, Number(pageNumber), keywordString));
  }, [dispatch, teacherId, teacherInfo.branch._id, pageNumber, keyword]);

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack>
        <HStack mb={10}>
          <Text
            textAlign={"center"}
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight={"bold"}
          >
            회원 검색
          </Text>
        </HStack>
        <Center>
          <Searcher keyword={keyword} onSearch={(keyword: string) => {
            window.location.href = `/youthdb/youth-student/search/1/${keyword}`;
          }} />
        </Center>
        <HStack>
          <TableContainer
            maxH="2000px"
            minW={{ base: "240px", md: "300px" }}
            overflowX="auto"
          >
            <Table variant="simple" colorScheme="orange" border="ActiveBorder">
              <Thead bg="gray.100">
                <Tr>
                  {/* <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    p={1}
                  >
                    순번
                  </Th> */}
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    회원명
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    보호자 연락처
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    수정
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <h2>Loading...</h2>
                ) : error ? (
                  <h2>{error}</h2>
                ) : (
                  youthStudents &&
                  youthStudents.youthStudent?.map((student: any) => (
                    <Tr key={student._id}>
                      {/* <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        p={0}
                        w={2}
                      >
                        {student.indexNum}
                      </Td> */}
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        p={1}
                        w={40}
                      >
                        <Link
                          to={`/youthdb/youth-student-detail/${student._id}`}
                          style={{ textDecoration: "none" }}
                        >
                        {student.name}
                        </Link>
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        p={1}
                        w={40}
                      >
                        {student.parentPhoneNum && formatPhoneNum(student.parentPhoneNum)}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        p={1}
                        w={40}
                      >
                        {/* <Link
                          to={`/youthdb/youth-student/update/${student._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          수정
                        </Link> */}
                        <Button
                          colorScheme="blue"
                          size="sm"
                          onClick={() => {
                            window.location.href = `/youthdb/youth-student/update/${student._id}`;
                          }}
                        >
                          수정
                        </Button>
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </HStack>
        <Center>
          <Paginate
            pages={youthStudents?.pages}
            page={youthStudents?.page}
            onPageChange={(page: number) => {
              window.location.href = `/youthdb/youth-student/search/${page}`;
            }}
          />
        </Center>
      </VStack>
    </Box>
  );
};

export default StudentSearchScreen;