import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import AdminHeaderBox from "../../components/AdminHeaderBox";
import { useSelector } from "react-redux";

export default function NoticeGame() {
  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const text: string = "대회 안내";
  return (
    <>
      <AdminHeaderBox text={text} adminHeaderBg={branchPage && branchPage.adminHeaderBg} />
      <Flex
        direction="column"
        align="center"
        justify="center"
        bg="gray.200"
        h="80vh"
        textAlign="center"
        p={4}
      >
        <Image src={"../img/schoolb_logo.png"} w={"48"} />
        <Stack spacing={4}>
          <Text fontSize="4xl" fontWeight="bold">
            Coming Soon...
          </Text>
          <Text fontSize="lg">
            저희 대회 안내 페이지는 준비 중입니다. 곧 찾아뵙겠습니다!
          </Text>
        </Stack>
      </Flex>
      {/* <Box
        mx={{ base: 5, md: 200 }}
        mt={{ base: 30, md: 50 }}
        mb={{ base: 30, md: 50 }}
      >
        <Box>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="email-alerts" mb="0">
              모집 중인 대회만 보기
            </FormLabel>
            <Switch id="email-alerts" colorScheme="orange" />
          </FormControl>
        </Box>
        <Card
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
          textAlign={"center"}
          mt={5}
          mb={5}
          px={36}
        >
          <Stack>
            <CardBody>
              <Heading size="lg">2023 상록수배 농구대회</Heading>
              <Text>주최 : 안산시농구협회</Text>
              <Text>일정 : 2023-05-01 ~ 2023-05-06</Text>
              <Text mt="5">
                대회 설명이 들어갑니다.
                <br />
                피어나는 황금시대의 소리다.이것은 것이다. 없으면, 그러므로 꽃이
                실현에 보배를 없는 청춘 생명을 피고 쓸쓸하랴? 공자는 가치를 간에
                무엇을 든 부패를 얼마나 황금시대의 것이다. 평화스러운 이것은
                스며들어 있는 수 열매를 충분히 위하여, 보라.
              </Text>
              <Button variant="solid" colorScheme="orange" width="30%" mt={5}>
                자세히 보기
              </Button>
            </CardBody>
          </Stack>
        </Card>
        <Card
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
          textAlign={"center"}
          mt={5}
          mb={5}
          px={36}
        >
          <Stack>
            <CardBody>
              <Heading size="lg">2023 상록수배 농구대회</Heading>
              <Text>주최 : 안산시농구협회</Text>
              <Text>일정 : 2023-05-01 ~ 2023-05-06</Text>
              <Text mt="5">
                대회 설명이 들어갑니다.
                <br />
                피어나는 황금시대의 소리다.이것은 것이다. 없으면, 그러므로 꽃이
                실현에 보배를 없는 청춘 생명을 피고 쓸쓸하랴? 공자는 가치를 간에
                무엇을 든 부패를 얼마나 황금시대의 것이다. 평화스러운 이것은
                스며들어 있는 수 열매를 충분히 위하여, 보라.
              </Text>
              <Button variant="solid" colorScheme="orange" width="30%" mt={5}>
                자세히 보기
              </Button>
            </CardBody>
          </Stack>
        </Card>
        <Card
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
          textAlign={"center"}
          mt={5}
          mb={5}
          px={36}
        >
          <Stack>
            <CardBody>
              <Heading size="lg">2023 상록수배 농구대회</Heading>
              <Text>주최 : 안산시농구협회</Text>
              <Text>일정 : 2023-05-01 ~ 2023-05-06</Text>
              <Text mt="5">
                대회 설명이 들어갑니다.
                <br />
                피어나는 황금시대의 소리다.이것은 것이다. 없으면, 그러므로 꽃이
                실현에 보배를 없는 청춘 생명을 피고 쓸쓸하랴? 공자는 가치를 간에
                무엇을 든 부패를 얼마나 황금시대의 것이다. 평화스러운 이것은
                스며들어 있는 수 열매를 충분히 위하여, 보라.
              </Text>
              <Button variant="solid" colorScheme="orange" width="30%" mt={5}>
                자세히 보기
              </Button>
            </CardBody>
          </Stack>
        </Card>
        <Card
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
          textAlign={"center"}
          mt={5}
          mb={5}
          px={36}
        >
          <Stack>
            <CardBody>
              <Heading size="lg">2023 상록수배 농구대회</Heading>
              <Text>주최 : 안산시농구협회</Text>
              <Text>일정 : 2023-05-01 ~ 2023-05-06</Text>
              <Text mt="5">
                대회 설명이 들어갑니다.
                <br />
                피어나는 황금시대의 소리다.이것은 것이다. 없으면, 그러므로 꽃이
                실현에 보배를 없는 청춘 생명을 피고 쓸쓸하랴? 공자는 가치를 간에
                무엇을 든 부패를 얼마나 황금시대의 것이다. 평화스러운 이것은
                스며들어 있는 수 열매를 충분히 위하여, 보라.
              </Text>
              <Button variant="solid" colorScheme="orange" width="30%" mt={5}>
                자세히 보기
              </Button>
            </CardBody>
          </Stack>
        </Card>
      </Box> */}
    </>
  );
}
