import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";

import { useDispatch } from "react-redux";
import dailyLogout from "../utils/viewSetup";
import { logout } from "../redux/actions/UserAction";

export default function Header({
  headerBg,
  branchUrl,
  programGuideList,
  logo,
}: {
  headerBg: string;
  branchUrl: string;
  programGuideList: string[];
  logo: string;
}) {
  const [isOpenSub, setIsOpenSub] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onClickMenu = () => {
    onClose();
  };

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  useEffect(() => {
    dailyLogout();
  }, [dispatch]);

  return (
    <>
      <Box
        display={{
          base: "none",
          md: "block",
        }}
      >
        <Stack
          justifyContent={"center"}
          alignItems="center"
          py={5}
          px={60}
          direction={{
            sm: "column",
            md: "row",
          }}
          spacing={{
            sm: 3,
            md: 0,
          }}
          borderBottomWidth={1}
          bg={"white"}
          color={"black"}
        >
          <HStack spacing={35}>
            <Box w={"100px"}>
              <Link to={`/${branchUrl}`}>
                <Image src={logo} width={"48"} alt="(logo)" />
              </Link>
            </Box>
            <Button
              w={"150px"}
              fontSize={"20px"}
              onMouseEnter={() => setIsOpenSub(true)}
              variant={"none"}
            >
              소개
            </Button>
            <Button
              w={"150px"}
              fontSize={"20px"}
              onMouseEnter={() => setIsOpenSub(true)}
              variant={"none"}
            >
              소식
            </Button>
            <Button
              w={"150px"}
              fontSize={"20px"}
              onMouseEnter={() => setIsOpenSub(true)}
              variant={"none"}
            >
              프로그램 안내
            </Button>
            <Button
              w={"150px"}
              fontSize={"20px"}
              onMouseEnter={() => setIsOpenSub(true)}
              variant={"none"}
            >
              상담문의
            </Button>
            {userInfo && userInfo._id ? (
              <Flex w={"150px"} alignItems={"center"} justifyContent="center">
                <Button
                  fontSize={"15px"}
                  onMouseEnter={() => setIsOpenSub(true)}
                  onClick={() => {
                    dispatch(logout());
                  }}
                  variant={"none"}
                  p={0}
                >
                  로그아웃
                </Button>
              </Flex>
            ) : (
              <Flex w={"150px"} alignItems={"center"} justifyContent="center">
                <Button
                  fontSize={"15px"}
                  onMouseEnter={() => setIsOpenSub(true)}
                  onClick={() => {
                    window.location.href = `/login?branch=${branchUrl}`;
                  }}
                  variant={"none"}
                  p={0}
                >
                  로그인
                </Button>
                <Text mx={2} p={0} lineHeight={"15px"}>|</Text>
                <Button
                  isDisabled
                  fontSize={"15px"}
                  onMouseEnter={() => setIsOpenSub(true)}
                  onClick={() => {
                    window.location.href = `register`;
                  }}
                  variant={"none"}
                  p={0}
                  ml={1}
                >
                  회원가입
                </Button>
              </Flex>
            )}
          </HStack>
        </Stack>
        {isOpenSub && (
          <Stack
            position={"absolute"}
            width={"100%"}
            zIndex={3}
            onMouseLeave={() => setIsOpenSub(false)}
            justifyContent={"center"}
            alignItems="center"
            py={5}
            px={60}
            direction={{
              sm: "column",
              md: "row",
            }}
            spacing={{
              sm: 3,
              md: 0,
            }}
            bg={headerBg}
            className="submenu"
          >
            <HStack spacing={35}>
              <Box w={"100px"}></Box>
              <VStack textAlign={"center"} w={"150px"}>
                <Link to={`greeting`}>
                  <Button color="white" variant={"none"}>
                    인사말
                  </Button>
                </Link>
                <Link to={`location`}>
                  <Button color="white" variant={"none"}>
                    지점위치
                  </Button>
                </Link>
                <Link to={`instructors`}>
                  <Button color="white" variant={"none"}>
                    지도자 안내
                  </Button>
                </Link>
                <Button
                  color="white"
                  variant={"none"}
                  _hover={{ cursor: "default" }}
                ></Button>
              </VStack>
              <VStack textAlign={"center"} w={"150px"}>
                <Link to={`post/공지사항?p=1`}>
                  <Button color="white" variant={"none"}>
                    공지사항
                  </Button>
                </Link>
                <Link to={`notice-game`}>
                  <Button color="white" variant={"none"}>
                    대회 안내
                  </Button>
                </Link>
                <Button
                  color="white"
                  variant={"none"}
                  _hover={{ cursor: "default" }}
                ></Button>
                <Button
                  color="white"
                  variant={"none"}
                  _hover={{ cursor: "default" }}
                ></Button>
              </VStack>
              {/* 4개까지 등록 가능하도록 하기, 만약 4개 이하면 공간만 차지하는 버튼을 넣어서 정렬 맞춰줌 */}
              <VStack textAlign={"center"} w={"150px"}>
                {programGuideList.map((programGuide) => (
                  <Link to={`program-guide/${programGuide}?p=1`} key={programGuide}>
                    <Button
                      variant={"none"}
                      color="white"
                      onClick={() => onClickMenu()}
                    >
                      {programGuide}
                    </Button>
                  </Link>
                ))}
                {[...Array(4 - programGuideList.length)].map((_, index) => (
                  <Button
                    key={`default-${index}`}
                    color="white"
                    variant={"none"}
                    _hover={{ cursor: "default" }}
                  ></Button>
                ))}
              </VStack>
              <VStack textAlign={"center"} w={"150px"}>
                <Button
                  color="white"
                  variant={"none"}
                  as="a"
                  href={"https://open.kakao.com/o/saRpmcOg"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  상담문의
                  <Image
                    src="../img/kakao_logo.png"
                    width={"4"}
                    alt="kakao"
                    ml={1}
                  />
                </Button>
                <Button
                  color="white"
                  variant={"none"}
                  _hover={{ cursor: "default" }}
                ></Button>
                <Button
                  color="white"
                  variant={"none"}
                  _hover={{ cursor: "default" }}
                ></Button>
                <Button
                  color="white"
                  variant={"none"}
                  _hover={{ cursor: "default" }}
                ></Button>
              </VStack>
              <VStack textAlign={"center"} w={"150px"}>
                <Button
                  color="white"
                  variant={"none"}
                  _hover={{ cursor: "default" }}
                ></Button>
                <Button
                  color="white"
                  variant={"none"}
                  _hover={{ cursor: "default" }}
                ></Button>
                <Button
                  color="white"
                  variant={"none"}
                  _hover={{ cursor: "default" }}
                ></Button>
                <Button
                  color="white"
                  variant={"none"}
                  _hover={{ cursor: "default" }}
                ></Button>
              </VStack>
            </HStack>
          </Stack>
        )}
      </Box>
      <Box
        display={{
          base: "absolute",
          md: "none",
        }}
      >
        <HStack
          justifyContent={"space-around"}
          // zIndex={3}
          // position={"absolute"}
          pt={2}
          pb={2}
          bg="white"
          w="100%"
        >
          <Box w={"40px"}></Box>
          <Box>
            <Link to={`/${branchUrl}`}>
              <Image src={logo} width={"24"} alt="logo" />
            </Link>
          </Box>
          <IconButton
            aria-label="Show mobile navbar"
            icon={<FiMenu />}
            onClick={onOpen}
            variant={"ghost"}
            fontSize={"30px"}
          >
            Open
          </IconButton>
          <Drawer isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent alignItems="center" bg={"#1A202C"}>
              <DrawerCloseButton alignSelf="end" />
              <DrawerHeader>
                <Text as="p" color="white">
                  TOP 안산점
                </Text>
              </DrawerHeader>
              <DrawerBody textAlign={"center"} fontSize={18} color={"orange"}>
                안산점 소개
                <VStack mt={1} mb={6} spacing={-1}>
                  <Link to={`greeting`}>
                    <Button
                      variant={"none"}
                      color="white"
                      onClick={() => onClickMenu()}
                    >
                      인사말
                    </Button>
                  </Link>
                  <Link to={`location`}>
                    <Button
                      variant={"none"}
                      color="white"
                      onClick={() => onClickMenu()}
                    >
                      지점 위치
                    </Button>
                  </Link>
                  <Link to={`instructors`}>
                    <Button
                      variant={"none"}
                      color="white"
                      onClick={() => onClickMenu()}
                    >
                      지도자 안내
                    </Button>
                  </Link>
                </VStack>
                TOP 소식
                <VStack mt={1} mb={6} spacing={-1}>
                  <Link to={`post/공지사항?p=1`}>
                    <Button
                      variant={"none"}
                      color="white"
                      onClick={() => onClickMenu()}
                    >
                      공지사항
                    </Button>
                  </Link>
                  <Link to={`notice-game`}>
                    <Button
                      variant={"none"}
                      color="white"
                      onClick={() => onClickMenu()}
                    >
                      대회 안내
                    </Button>
                  </Link>
                </VStack>
                프로그램 안내
                <VStack mt={1} mb={6} spacing={-1}>
                  {/* <Button
                    variant={"none"}
                    color="white"
                    onClick={() => onClickMenu()}
                  >
                    수업방식
                  </Button>
                  <Button
                    variant={"none"}
                    color="white"
                    onClick={() => onClickMenu()}
                  >
                    프로그램
                  </Button>
                  <Button
                    variant={"none"}
                    color="white"
                    onClick={() => onClickMenu()}
                  >
                    스킬 트레이닝
                  </Button>
                  <Button
                    variant={"none"}
                    color="white"
                    onClick={() => onClickMenu()}
                  >
                    개인레슨
                  </Button> */}
                  {programGuideList.map((programGuide) => (
                    <Link to={`post/${programGuide}?p=1`}>
                      <Button
                        variant={"none"}
                        color="white"
                        onClick={() => onClickMenu()}
                      >
                        {programGuide}
                      </Button>
                    </Link>
                  ))}
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </HStack>
      </Box>
    </>
  );
}
