import axios from "axios";
import { Dispatch } from "redux";
import {
  YOUTHTEAM_LIST_REQUEST,
  YOUTHTEAM_LIST_SUCCESS,
  YOUTHTEAM_LIST_FAIL,
  YOUTHTEAM_LIST_RESET,
  YOUTHTEAM_HIDDEN_LIST_REQUEST,
  YOUTHTEAM_HIDDEN_LIST_SUCCESS,
  YOUTHTEAM_HIDDEN_LIST_FAIL,
  YOUTHTEAM_HIDDEN_LIST_RESET,
  YOUTHTEAM_BRANCH_LIST_REQUEST,
  YOUTHTEAM_BRANCH_LIST_SUCCESS,
  YOUTHTEAM_BRANCH_LIST_FAIL,
  YOUTHTEAM_ADD_REQUEST,
  YOUTHTEAM_ADD_SUCCESS,
  YOUTHTEAM_ADD_FAIL,
  YOUTHTEAM_ADD_RESET,
  YOUTHTEAM_DETAIL_REQUEST,
  YOUTHTEAM_DETAIL_SUCCESS,
  YOUTHTEAM_DETAIL_FAIL,
  YOUTHTEAM_DETAIL_RESET,
  YOUTHTEAM_UPDATE_REQUEST,
  YOUTHTEAM_UPDATE_SUCCESS,
  YOUTHTEAM_UPDATE_FAIL,
  YOUTHTEAM_DELETE_REQUEST,
  YOUTHTEAM_DELETE_SUCCESS,
  YOUTHTEAM_DELETE_FAIL,
} from "../constants/YouthTeamConstants";
import { API_SERVER_URL } from "./actionUrl";


export const getYouthTeamList =
  (teacherId: string, branchId: string, fetchAll: boolean): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: YOUTHTEAM_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/youth-team?teacherId=${teacherId}&branchId=${branchId}&fetchAll=${fetchAll}`
      );

      dispatch({
        type: YOUTHTEAM_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: YOUTHTEAM_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getHiddenYouthTeamList = (branchId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: YOUTHTEAM_HIDDEN_LIST_REQUEST });

    const { data } = await axios.get(`${API_SERVER_URL}/api/youth-team/hidden/${branchId}`);

    dispatch({ type: YOUTHTEAM_HIDDEN_LIST_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: YOUTHTEAM_HIDDEN_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const getYouthTeamBranchList = (branchId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: YOUTHTEAM_BRANCH_LIST_REQUEST });

    const { data } = await axios.get(`${API_SERVER_URL}/api/youth-team/branch/${branchId}`);

    dispatch({ type: YOUTHTEAM_BRANCH_LIST_SUCCESS, payload: data });
  } catch (error: any) {
    dispatch({
      type: YOUTHTEAM_BRANCH_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const getYouthTeamDetail =
  (youthTeamId: string): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: YOUTHTEAM_DETAIL_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/youth-team/${youthTeamId}`
      );

      dispatch({
        type: YOUTHTEAM_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: YOUTHTEAM_DETAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addYouthTeam =
  (
    youthTeam: any
  ): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: YOUTHTEAM_ADD_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${API_SERVER_URL}/api/youth-team`,
        youthTeam,
        config
      );

      dispatch({ type: YOUTHTEAM_ADD_SUCCESS, payload: response.data });

      return response;
    } catch (error: any) {
      dispatch({
        type: YOUTHTEAM_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      return error;
    }
  };

export const updateYouthTeam =
  (
    youthTeamId: string,
    youthTeam: any
  ): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: YOUTHTEAM_UPDATE_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${API_SERVER_URL}/api/youth-team/${youthTeamId}`,
        youthTeam,
        config
      );

      dispatch({ type: YOUTHTEAM_UPDATE_SUCCESS, payload: data });
    } catch (error: any) {
      dispatch({
        type: YOUTHTEAM_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteYouthTeam = (youthTeamId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: YOUTHTEAM_DELETE_REQUEST });

    await axios.delete(`${API_SERVER_URL}/api/youth-team/${youthTeamId}`);

    dispatch({ type: YOUTHTEAM_DELETE_SUCCESS });
  } catch (error: any) {
    dispatch({
      type: YOUTHTEAM_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}