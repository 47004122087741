import axios from "axios";
import {
  GET_DAY_MEMO_LIST_REQUEST,
  GET_DAY_MEMO_LIST_SUCCESS,
  GET_DAY_MEMO_LIST_FAIL,
  UPDATE_DAY_MEMO_LIST_REQUEST,
  UPDATE_DAY_MEMO_LIST_SUCCESS,
  UPDATE_DAY_MEMO_LIST_FAIL,
  CONSULT_LIST_REQUEST,
  CONSULT_LIST_SUCCESS,
  CONSULT_LIST_FAIL,
  UPDATE_DAY_CONSULT_LIST_REQUEST,
  UPDATE_DAY_CONSULT_LIST_SUCCESS,
  UPDATE_DAY_CONSULT_LIST_FAIL,
  CONSULT_INPUT_REQUEST,
  CONSULT_INPUT_SUCCESS,
  CONSULT_INPUT_FAIL,
  CONSULT_LIST_TEACHER_REQUEST,
  CONSULT_LIST_TEACHER_SUCCESS,
  CONSULT_LIST_TEACHER_FAIL,
  CONSULT_LIST_TEACHER_ALL_REQUEST,
  CONSULT_LIST_TEACHER_ALL_SUCCESS,
  CONSULT_LIST_TEACHER_ALL_FAIL,
  CONSULT_DETAIL_REQUEST,
  CONSULT_DETAIL_SUCCESS,
  CONSULT_DETAIL_FAIL,
  CONSULT_UPDATE_REQUEST,
  CONSULT_UPDATE_SUCCESS,
  CONSULT_UPDATE_FAIL,
} from "../constants/MemoConstants";
import { API_SERVER_URL } from "./actionUrl";

export const getMemoList =
  (id: string, date: string, type: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: GET_DAY_MEMO_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/youth-memo/team/${id}?date=${date}&memoType=${type}`
      );

      dispatch({
        type: GET_DAY_MEMO_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_DAY_MEMO_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getConsultList =
  (studentId: string, year: string, month: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: CONSULT_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/youth-consult?studentId=${studentId}&year=${year}&month=${month}`
      );

      dispatch({
        type: CONSULT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: CONSULT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateMemoList =
  (
    date: any,
    memoType: string,
    memoArray: { studentId: string; memo: string }[]
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: UPDATE_DAY_MEMO_LIST_REQUEST,
      });

      const { data } = await axios.put(
        `${API_SERVER_URL}/api/youth-memo/batch/process`,
        {
          date,
          memoType,
          memoArray,
        }
      );
      dispatch({
        type: UPDATE_DAY_MEMO_LIST_SUCCESS,
        payload: data,
      });
      alert("성공적으로 업데이트 되었습니다.");
      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: UPDATE_DAY_MEMO_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateConsultList =
  (
    date: any,
    consultArray: {
      studentId: string;
      consultParents: string;
      consultTeacher: string;
    }[]
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: UPDATE_DAY_CONSULT_LIST_REQUEST,
      });

      const { data } = await axios.put(
        `${API_SERVER_URL}/api/youth-consult/batch/process`,
        {
          date,
          consultArray,
        }
      );
      dispatch({
        type: UPDATE_DAY_CONSULT_LIST_SUCCESS,
        payload: data,
      });
      alert("성공적으로 업데이트 되었습니다.");
      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: UPDATE_DAY_CONSULT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addConsult =
  (
    date: any,
    studentId: string,
    teacherId: string,
    branchId: string,
    consultParents: string,
    consultTeacher: string,
    isImportant: boolean
  ) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: CONSULT_INPUT_REQUEST,
      });

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/youth-consult`,
        {
          date,
          studentId,
          teacherId,
          branchId,
          consultParents,
          consultTeacher,
          isImportant,
        }
      );
      dispatch({
        type: CONSULT_INPUT_SUCCESS,
        payload: data,
      });
      alert("성공적으로 추가되었습니다.");
      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: CONSULT_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }

export const getConsultListByTeacher = (teacherId: string) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    dispatch({
      type: CONSULT_LIST_TEACHER_REQUEST,
    });

    const { data } = await axios.get(
      `${API_SERVER_URL}/api/youth-consult/teacher/${teacherId}`
    );

    dispatch({
      type: CONSULT_LIST_TEACHER_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: CONSULT_LIST_TEACHER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const getConsultListByTeacherAll = (branchId: string) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    dispatch({
      type: CONSULT_LIST_TEACHER_ALL_REQUEST,
    });

    const { data } = await axios.get(
      `${API_SERVER_URL}/api/youth-consult/teacher-all/${branchId}`
    );

    dispatch({
      type: CONSULT_LIST_TEACHER_ALL_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: CONSULT_LIST_TEACHER_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const getConsultDetail = (consultId: string) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    dispatch({
      type: CONSULT_DETAIL_REQUEST,
    });

    const { data } = await axios.get(
      `${API_SERVER_URL}/api/youth-consult/${consultId}`
    );

    dispatch({
      type: CONSULT_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: CONSULT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const updateConsult = (consultId: string, consultParents: string, consultTeacher: string, isImportant: boolean) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    dispatch({
      type: CONSULT_UPDATE_REQUEST,
    });

    const { data } = await axios.put(
      `${API_SERVER_URL}/api/youth-consult/${consultId}`,
      {
        consultParents,
        consultTeacher,
        isImportant,
      }
    );

    dispatch({
      type: CONSULT_UPDATE_SUCCESS,
      payload: data,
    });
    alert("성공적으로 업데이트 되었습니다.");
    
    window.location.reload();
  } catch (error: any) {
    dispatch({
      type: CONSULT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}