export const BRANCH_PAGE_REQUEST = "BRANCH_PAGE_REQUEST" as const;
export const BRANCH_PAGE_SUCCESS = "BRANCH_PAGE_SUCCESS" as const;
export const BRANCH_PAGE_FAIL = "BRANCH_PAGE_FAIL" as const;
export const BRANCH_PAGE_RESET = "BRANCH_PAGE_RESET" as const;

export const BRANCH_PAGE_UPDATE_REQUEST = "BRANCH_PAGE_UPDATE_REQUEST" as const;
export const BRANCH_PAGE_UPDATE_SUCCESS = "BRANCH_PAGE_UPDATE_SUCCESS" as const;
export const BRANCH_PAGE_UPDATE_FAIL = "BRANCH_PAGE_UPDATE_FAIL" as const;
export const BRANCH_PAGE_UPDATE_RESET = "BRANCH_PAGE_UPDATE_RESET" as const;

export const YOUTH_POST_INPUT_REQUEST = "YOUTH_POST_INPUT_REQUEST" as const;
export const YOUTH_POST_INPUT_SUCCESS = "YOUTH_POST_INPUT_SUCCESS" as const;
export const YOUTH_POST_INPUT_FAIL = "YOUTH_POST_INPUT_FAIL" as const;
export const YOUTH_POST_INPUT_RESET = "YOUTH_POST_INPUT_RESET" as const;

export const YOUTH_POST_LIST_REQUEST = "YOUTH_POST_LIST_REQUEST" as const;
export const YOUTH_POST_LIST_SUCCESS = "YOUTH_POST_LIST_SUCCESS" as const;
export const YOUTH_POST_LIST_FAIL = "YOUTH_POST_LIST_FAIL" as const;
export const YOUTH_POST_LIST_RESET = "YOUTH_POST_LIST_RESET" as const;

export const YOUTH_POST_DETAIL_REQUEST = "YOUTH_POST_DETAIL_REQUEST" as const;
export const YOUTH_POST_DETAIL_SUCCESS = "YOUTH_POST_DETAIL_SUCCESS" as const;
export const YOUTH_POST_DETAIL_FAIL = "YOUTH_POST_DETAIL_FAIL" as const;
export const YOUTH_POST_DETAIL_RESET = "YOUTH_POST_DETAIL_RESET" as const;

export const YOUTH_POST_UPDATE_REQUEST = "YOUTH_POST_UPDATE_REQUEST" as const;
export const YOUTH_POST_UPDATE_SUCCESS = "YOUTH_POST_UPDATE_SUCCESS" as const;
export const YOUTH_POST_UPDATE_FAIL = "YOUTH_POST_UPDATE_FAIL" as const;
export const YOUTH_POST_UPDATE_RESET = "YOUTH_POST_UPDATE_RESET" as const;

export const TEACHER_INTRO_LIST_REQUEST = "TEACHER_INTRO_LIST_REQUEST" as const;
export const TEACHER_INTRO_LIST_SUCCESS = "TEACHER_INTRO_LIST_SUCCESS" as const;
export const TEACHER_INTRO_LIST_FAIL = "TEACHER_INTRO_LIST_FAIL" as const;
export const TEACHER_INTRO_LIST_RESET = "TEACHER_INTRO_LIST_RESET" as const;

export const TEACHER_INTRO_INPUT_REQUEST = "TEACHER_INTRO_INPUT_REQUEST" as const;
export const TEACHER_INTRO_INPUT_SUCCESS = "TEACHER_INTRO_INPUT_SUCCESS" as const;
export const TEACHER_INTRO_INPUT_FAIL = "TEACHER_INTRO_INPUT_FAIL" as const;
export const TEACHER_INTRO_INPUT_RESET = "TEACHER_INTRO_INPUT_RESET" as const;

export const TEACHER_INTRO_UPDATE_REQUEST = "TEACHER_INTRO_UPDATE_REQUEST" as const;
export const TEACHER_INTRO_UPDATE_SUCCESS = "TEACHER_INTRO_UPDATE_SUCCESS" as const;
export const TEACHER_INTRO_UPDATE_FAIL = "TEACHER_INTRO_UPDATE_FAIL" as const;
export const TEACHER_INTRO_UPDATE_RESET = "TEACHER_INTRO_UPDATE_RESET" as const;

export const TEACHER_INTRO_DELETE_REQUEST = "TEACHER_INTRO_DELETE_REQUEST" as const;
export const TEACHER_INTRO_DELETE_SUCCESS = "TEACHER_INTRO_DELETE_SUCCESS" as const;
export const TEACHER_INTRO_DELETE_FAIL = "TEACHER_INTRO_DELETE_FAIL" as const;
export const TEACHER_INTRO_DELETE_RESET = "TEACHER_INTRO_DELETE_RESET" as const;