import React, { useState, useEffect } from "react";
import { getAttendTable } from "../../redux/actions/AttendActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Select,
  Center,
  Button,
  IconButton,
  Spinner,
  HStack,
  VStack,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Stack,
  useBreakpointValue,
  Tag,
  Flex,
} from "@chakra-ui/react";
import {
  getHiddenYouthTeamList,
  getYouthTeamDetail,
  getYouthTeamList,
} from "../../redux/actions/YouthTeamActions";
import { Link, useParams } from "react-router-dom";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { format, set } from "date-fns";
import ko from "date-fns/locale/ko";

const AttendHiddenTeamScreen: React.FC = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const [teamId, setTeamId] = useState("");
  const dispatch: any = useDispatch();

  const attendTableList = useSelector((state: any) => state.attendTableList);
  const { loading, error, attendTable } = attendTableList;

  const youthTeamDetail = useSelector((state: any) => state.youthTeamDetail);
  const {
    loading: loadingDetail,
    error: errorDetail,
    youthTeam,
  } = youthTeamDetail;

  const youthTeamHiddenList = useSelector((state: any) => state.youthTeamHiddenList);
  const { hiddenYouthTeams } = youthTeamHiddenList;

  const [year, setYear] = useState(new Date().getFullYear());
  const [startMonth, setStartMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );
  const [endMonth, setEndMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );

  const [selectedAttends, setSelectedAttends] = useState<AttendanceData[]>([]);
  const [monthChangeLoading, setMonthChangeLoading] = useState(false);

  const [emptyCol, setEmptyCol] = useState(0);

  useEffect(() => {
    if (teamId !== "") {
      dispatch(getYouthTeamDetail(teamId));
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (attendTable) {
      setEmptyCol(
        4 - attendTable?.table?.length >= 0 ? 4 - attendTable?.table?.length : 0
      );
    }
  }, [attendTable]);

  useEffect(() => {
    if (startMonth === 13) {
      setYear(year + 1);
      setStartMonth(1);
      setEndMonth(1);
    }
    if (startMonth === 0) {
      setYear(year - 1);
      setStartMonth(12);
      setEndMonth(12);
    }
  }, [startMonth]);

  useEffect(() => {
    if (hiddenYouthTeams?.youthTeam?.length > 0) {
      setTeamId(hiddenYouthTeams.youthTeam[0]._id);

      const hiddenAt = hiddenYouthTeams.youthTeam.find((team: any) => team._id === hiddenYouthTeams.youthTeam[0]._id).hiddenAt ?
        new Date(hiddenYouthTeams.youthTeam.find((team: any) => team._id === hiddenYouthTeams.youthTeam[0]._id).hiddenAt) : new Date();

      setYear(hiddenAt.getFullYear());
      setStartMonth(hiddenAt.getMonth() + 1);
      setEndMonth(hiddenAt.getMonth() + 1);
    }
  }, [hiddenYouthTeams]);

  useEffect(() => {
    dispatch(getHiddenYouthTeamList(teacherInfo.branch._id));
  }, [dispatch, userInfo._id, teacherInfo.branch._id]);


  const handleTeamChange = (e: any) => {
    setTeamId(e.target.value);

    const hiddenAt = hiddenYouthTeams?.youthTeam.find((team: any) => team._id === e.target.value).hiddenAt ?
      new Date(hiddenYouthTeams?.youthTeam.find((team: any) => team._id === e.target.value).hiddenAt) : new Date();
      
    setYear(hiddenAt.getFullYear());
    setStartMonth(hiddenAt.getMonth() + 1);
    setEndMonth(hiddenAt.getMonth() + 1);
  }

  interface AttendanceData {
    date: string;
    attendance: AttendanceItem[];
    rate: AttendanceRate[];
    attendId: string;
  }

  // useEffect(() => {
  //   if (attendTable) {
  //     setSelectedAttends(attendTable.table)
  //   }
  // }, [dispatch, attendTable]);
  // 상태 추가하기
  interface AttendanceItem {
    studentId: string;
    studentName: string;
    status: "출석" | "결석" | "휴강" | "탈퇴" | "신규" | "복귀";
  }

  interface AttendanceRate {
    studentId: string;
    total: number;
    present: number;
  }

  interface TableProps {
    data: AttendanceData[];
  }

  const attendanceFormat = (status: string) => {
    if (status.startsWith("신규")) {
      //신규 2글자 후 줄바꿈
      return (
        <>
          {status.slice(0, 2)}
          <p style={{ fontSize: "10px" }}>{status.slice(2)}</p>
        </>
      );
    } else {
      return status;
    }
  };

  const attendanceFontColor = (status: string) => {
    if (status === "출석") {
      return "black";
    } else if (status === "휴강") {
      return "blue";
    } else {
      return "red";
    }
  };

  const CustomTable: React.FC<TableProps> = ({ data }) => {
    const studentIds =
      data &&
      data.flatMap((item) =>
        item.attendance.map((attendance) => attendance.studentId)
      );
    const uniqueStudentIds = Array.from(new Set(studentIds));

    const Stack: React.ElementType =
      useBreakpointValue({ base: VStack, md: HStack }) || VStack;

    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          maxW={{ base: "300px", sm: "500px", md: "700px", lg: "1500px" }}
        >
          <Flex gap={10} maxW={{ base: "300px", sm: "500px", md: "700px", lg: "1500px" }}>
            <Box w="600px">
              <Center>
                <Box
                  mt={10}
                  mx={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconButton
                    aria-label="prev"
                    icon={<ArrowBackIcon />}
                    mx={4}
                    onClick={() => {
                      setStartMonth(startMonth - 1);
                      setEndMonth(endMonth - 1);
                    }}
                  />
                  <Text fontSize="2xl" fontWeight="bold">
                    {year}년 {startMonth}월
                  </Text>
                  <IconButton
                    aria-label="next"
                    icon={<ArrowForwardIcon />}
                    mx={4}
                    onClick={() => {
                      setStartMonth(startMonth + 1);
                      setEndMonth(endMonth + 1);
                    }}
                  />
                </Box>
              </Center>
              <Center>
                <Text fontSize="sm" mb={3}>
                  출석부 수정은 대상 날짜를 클릭하면 가능합니다.
                </Text>
              </Center>
              <Center>
                <Text
                  textAlign={"center"}
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight={"bold"}
                >
                  삭제된 팀 출석부
                </Text>
              </Center>
              <Flex
                mx={4}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box textAlign={"left"}>
                  <Select
                    placeholder={"반을 선택하세요..."}
                    onChange={handleTeamChange}
                    width={{ base: "200px" }}
                    height={{ base: "40px" }}
                    borderColor="tomato"
                    fontSize={{ base: "16px" }}
                  >
                    {teamOption.map((item: any) => (
                      <option
                        key={item.value}
                        value={item.value}
                        selected={item.value === teamId}
                      >
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box textAlign={"right"}>
                  <Button
                    colorScheme="orange"
                    style={{ height: "30px" }}
                    disabled
                  >
                    이 달의 출석률 :{" "}
                    {attendTable?.totalPresent !== 0 ? (
                      (
                        (attendTable?.totalPresent /
                          (attendTable?.totalPresent +
                            attendTable?.totalAbsent)) *
                        100
                      ).toFixed(1)
                    ) : (
                      " - "
                    )}
                    %
                  </Button>
                </Box>
              </Flex>
              <Box
                mt={4}
                mx={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <TableContainer maxH="1000px" width="100%">
                  <Table
                    variant="simple"
                    border="2px solid"
                    borderColor="gray.400"
                  >
                    <Thead bg="gray.100">
                      <Tr>
                        <Th
                          textAlign="center"
                          position="sticky"
                          left="0"
                          borderWidth="1px"
                          borderColor="gray.400"
                          bg="gray.100"
                          fontSize={"md"}
                          padding={2}
                        >
                        </Th>
                        <Th
                          textAlign="center"
                          position="sticky"
                          left="50px"
                          bg="gray.100"
                          borderWidth="1px"
                          borderColor="gray.400"
                          fontSize={"md"}
                        >
                          이름
                        </Th>
                        {data.map((item) => {
                          if (!item.date) {
                            console.warn(
                              "Undefined or null date encountered:",
                              item
                            );
                            return null;
                          }
                          const parsedDate = new Date(item.date);

                          if (isNaN(parsedDate.getTime())) {
                            console.warn(
                              "Invalid date encountered:",
                              item.date
                            );
                            return null;
                          }

                          const day = format(parsedDate, "dd일", {
                            locale: ko,
                          });
                          const weekday = format(parsedDate, "eeee", {
                            locale: ko,
                          }).charAt(0);

                          return (
                            <Th
                              key={item.date}
                              textAlign="center"
                              borderWidth="1px"
                              borderColor="gray.400"
                              fontSize={"md"}
                              paddingTop={"3"}
                              paddingBottom={"3"}
                              paddingRight={"0"}
                              paddingLeft={"0"}
                              width={"15%"}
                            >
                              <VStack>
                                <Link to={`/youthdb/attend/update/${item.attendId}`}>
                                  {`${day}(${weekday})`}
                                </Link>
                              </VStack>
                            </Th>
                          );
                        })}
                        {new Array(emptyCol).fill(0).map((_, i) => (
                          <Th
                            key={i}
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            fontSize={"md"}
                            paddingTop={"3"}
                            paddingBottom={"3"}
                            paddingRight={"0"}
                            paddingLeft={"0"}
                            width={"15%"}
                            style={{
                              color: "transparent",
                            }}
                          >
                            {"00일(월)"}
                          </Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {monthChangeLoading ? (
                        <Tr>
                          <Td
                            colSpan={4 + (data.length || 0)}
                            borderWidth="1px"
                            borderColor="gray.400"
                          >
                            {" "}
                            <Center fontSize={"2xl"}>
                              <Spinner size="xl" color="orange" />
                            </Center>
                            <Center mt={10} fontSize={"xl"} color={"orange"}>
                              데이터를 불러오는 중입니다...
                            </Center>
                          </Td>
                        </Tr>
                      ) : uniqueStudentIds.length > 0 ? (
                        uniqueStudentIds.map((studentId, index) => {
                          var studentName = data[
                            data.length - 1
                          ].attendance.find(
                            (attendance) => attendance.studentId === studentId
                          )?.studentName;
                          if (studentName === undefined) {
                            studentName = attendTable?.rate.find(
                              (s: any) => s.studentId === studentId
                            )?.studentName;
                          }
                          return (
                            <Tr key={studentId}>
                              <Td
                                textAlign="center"
                                position="sticky"
                                left="0px"
                                fontWeight={"bold"}
                                bg="white"
                                borderWidth="1px"
                                borderColor="gray.400"
                                padding={2}
                              >
                                <Button size="sm">
                                  <Link to={`/youthdb/consultmemo/student/${studentId}`}>상담일지</Link>
                                </Button>
                              </Td>
                              <Td
                                width="100px"
                                textAlign="center"
                                position="sticky"
                                left="50px"
                                fontWeight={"bold"}
                                bg="white"
                                borderWidth="1px"
                                borderColor="gray.400"
                              >
                                <Link to={`/youthdb/youth-student/update/${studentId}`}>
                                  {studentName}
                                </Link>
                              </Td>
                              {selectedAttends.map((item) => {
                                const attendanceItem = item.attendance.find(
                                  (attendance) =>
                                    attendance.studentId === studentId
                                );
                                return (
                                  <Td
                                    key={item.date}
                                    textAlign="center"
                                    borderWidth="1px"
                                    borderColor="gray.400"
                                    style={{ color: attendanceItem && attendanceFontColor(attendanceItem.status) }}
                                  >
                                    {attendanceItem
                                      ? attendanceFormat(attendanceItem.status)
                                      : "-"}
                                  </Td>
                                );
                              })}
                              {new Array(emptyCol).fill(0).map((_, i) => (
                                <Td
                                  key={i}
                                  textAlign="center"
                                  borderWidth="1px"
                                  borderColor="gray.400"
                                  style={{
                                    color: "transparent",
                                  }}
                                >
                                  {"출석"}
                                </Td>
                              ))}
                            </Tr>
                          );
                        })
                      ) : (
                        <Tr>
                          <Td colSpan={4 + (data.length || 0)}>
                            <Center fontSize={"2xl"} color={"red"}>
                              해당 월에는 출석부 데이터가 없습니다.
                            </Center>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box mt={10} textAlign={"center"} minW="100px">
              <Text fontSize="2xl" fontWeight="bold">
                {startMonth}월 현황
              </Text>
              <VStack mt={10} gap="4">
                <Stat
                  border={"1px"}
                  borderRadius="15px"
                  borderColor="gray.400"
                  padding={"3"}
                >
                  <StatLabel fontSize="l" fontWeight="bold">
                    신규
                  </StatLabel>
                  <StatNumber fontSize="2xl">
                    <StatArrow type="increase" />
                    {attendTable && attendTable.newStudent}
                  </StatNumber>
                </Stat>
                <Stat
                  border={"1px"}
                  borderRadius="15px"
                  borderColor="gray.400"
                  padding={"3"}
                >
                  <StatLabel fontSize="l" fontWeight="bold">
                    복귀
                  </StatLabel>
                  <StatNumber fontSize="2xl">
                    <StatArrow type="increase" />
                    {attendTable && attendTable.returnStudent}
                  </StatNumber>
                </Stat>
                <Stat
                  border={"1px"}
                  borderRadius="15px"
                  borderColor="gray.400"
                  padding={"3"}
                >
                  <StatLabel fontSize="l" fontWeight="bold">
                    휴강
                  </StatLabel>
                  <StatNumber fontSize="2xl">
                    <StatArrow type="decrease" />
                    {attendTable && attendTable.restStudent}
                  </StatNumber>
                </Stat>
                <Stat
                  border={"1px"}
                  borderRadius="15px"
                  borderColor="gray.400"
                  padding={"3"}
                >
                  <StatLabel fontSize="l" fontWeight="bold">
                    탈퇴
                  </StatLabel>
                  <StatNumber fontSize="2xl">
                    <StatArrow type="decrease" />
                    {attendTable && attendTable.quitStudent}
                  </StatNumber>
                </Stat>
              </VStack>
            </Box>
          </Flex>
        </Box>
      </>
    );
  };

  const teamOption: { value: string; label: string }[] = [];

  hiddenYouthTeams?.youthTeam?.map((team: any) => {
    teamOption.push({ value: team._id, label: team.name });
  });

  useEffect(() => {
    setMonthChangeLoading(true);
    if (hiddenYouthTeams?.youthTeam?.length > 0 && teamId) {
      setSelectedAttends([]);
      dispatch(getAttendTable(year, startMonth, endMonth, teamId));
    } else if (hiddenYouthTeams && hiddenYouthTeams.length === 0) {
      setMonthChangeLoading(false);
    }
  }, [dispatch, hiddenYouthTeams, startMonth, endMonth, teamId]);

  useEffect(() => {
    setMonthChangeLoading(false);
    if (
      attendTable &&
      attendTable.table &&
      attendTable.table.length > 0 &&
      hiddenYouthTeams?.youthTeam?.length > 0
    ) {
      setSelectedAttends(attendTable.table);

      setMonthChangeLoading(false);
    }
    if (attendTable && attendTable.table && attendTable.table.length === 0) {
      setMonthChangeLoading(false);
    }
  }, [attendTable]);

  return (
    <Box mx={{ base: "4", md: "40" }} pb={{ base: "40", md: "0" }}>
      {loading && loadingDetail ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <CustomTable data={selectedAttends} />
        </>
      )}
    </Box>
  );
};

export default AttendHiddenTeamScreen;