import axios from "axios";
import {
  ATTEND_REQUEST,
  ATTEND_SUCCESS,
  ATTEND_FAIL,
  ATTEND_RESET,
  ATTEND_TABLE_REQUEST,
  ATTEND_TABLE_SUCCESS,
  ATTEND_TABLE_FAIL,
  ATTEND_TABLE_RESET,
  ATTEND_INPUT_REQUEST,
  ATTEND_INPUT_SUCCESS,
  ATTEND_INPUT_FAIL,
  ATTEND_INPUT_RESET,
  GET_ATTEND_BY_ID_REQUEST,
  GET_ATTEND_BY_ID_SUCCESS,
  GET_ATTEND_BY_ID_FAIL,
  UPDATE_ATTEND_BY_ID_REQUEST,
  UPDATE_ATTEND_BY_ID_SUCCESS,
  UPDATE_ATTEND_BY_ID_FAIL,
  BATCH_UPDATE_ATTEND_REQUEST,
  BATCH_UPDATE_ATTEND_SUCCESS,
  BATCH_UPDATE_ATTEND_FAIL,
  DELETE_ATTEND_BY_ID_REQUEST,
  DELETE_ATTEND_BY_ID_FAIL,
  DELETE_ATTEND_BY_ID_SUCCESS,
  SUPPLEMENT_INPUT_REQUEST,
  SUPPLEMENT_INPUT_SUCCESS,
  SUPPLEMENT_INPUT_FAIL,
  SUPPLEMENT_LIST_REQUEST,
  SUPPLEMENT_LIST_SUCCESS,
  SUPPLEMENT_LIST_FAIL,
  SUPPLEMENT_DELETE_REQUEST,
  SUPPLEMENT_DELETE_SUCCESS,
  SUPPLEMENT_DELETE_FAIL,
} from "../constants/AttendConstants";
import { API_SERVER_URL } from "./actionUrl";

export const getAttend =
  (year: number, month: number, teacherId: string, youthTeamId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: ATTEND_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/attend/${year}&${month}&${teacherId}&${youthTeamId}`
      );

      dispatch({
        type: ATTEND_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: ATTEND_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAttendTable =
  (
    year: number,
    startMonth: number,
    endMonth: number,
    youthTeamId: string
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: ATTEND_TABLE_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/attend/monthly/table?year=${year}&startMonth=${startMonth}&endMonth=${endMonth}&youthTeamId=${youthTeamId}`
      );

      dispatch({
        type: ATTEND_TABLE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: ATTEND_TABLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addAttend =
  (
    date: any,
    youthTeamId: string,
    attendance: { studentId: string; status: string }[],
    newStudent: any[],
    branchId: string,
    supplementDoneArray: any[]
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: ATTEND_INPUT_REQUEST,
      });

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/attend`,
        {
          date,
          youthTeamId,
          attendance,
          newStudent,
          branchId,
          supplementDoneArray
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: ATTEND_INPUT_SUCCESS,
        payload: data,
      });
      alert("출석부 입력되었습니다.");
      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: ATTEND_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      alert(
        `출석부 입력에 실패했습니다. ${error.response.data.message}`
      );
    }
  };

export const getAttendById =
  (id: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: GET_ATTEND_BY_ID_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/attend/${id}`
      );

      dispatch({
        type: GET_ATTEND_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_ATTEND_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateAttendById =
  (
    id: string,
    date: Date,
    youthTeamId: string,
    attendance: { studentId: string; status: string, note: string }[],
    newStudent: any[],
    branchId: string,
    supplementDoneArray: any[]
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: UPDATE_ATTEND_BY_ID_REQUEST,
      });

      const { data } = await axios.put(
        `${API_SERVER_URL}/api/attend/${id}`,
        {
          date,
          youthTeamId,
          attendance,
          newStudent,
          branchId,
          supplementDoneArray
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: UPDATE_ATTEND_BY_ID_SUCCESS,
        payload: data,
      });
      alert("출석부가 업데이트 되었습니다.");
      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: UPDATE_ATTEND_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      alert(
        `출석부 업데이트에 실패했습니다. ${error.response.data.message}`
      );
    }
  };

export const deleteAttendById =
  (id: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: DELETE_ATTEND_BY_ID_REQUEST,
      });
      const { data } = await axios.delete(
        `${API_SERVER_URL}/api/attend/${id}`
      );
      dispatch({
        type: DELETE_ATTEND_BY_ID_SUCCESS,
        payload: data,
      });
      alert("출석부가 정상적으로 삭제되었습니다.");
      window.location.href = "/youthdb/attend";
    } catch (error: any) {
      dispatch({
        type: DELETE_ATTEND_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      alert("출석부 삭제에 실패하였습니다. 개발자에게 문의해주세요.");
    }
  };

export const addSupplement =
  (
    fromTeamId: string,
    toTeamId: string,
    attendId: string,
    studentId: string,
    absentDate: any,
    isDone: boolean,
  ) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: SUPPLEMENT_INPUT_REQUEST,
      });

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/supplement`,
        {
          fromTeamId,
          toTeamId,
          attendId,
          studentId,
          absentDate,
          isDone,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: SUPPLEMENT_INPUT_SUCCESS,
        payload: data,
      });
      alert("보강이 신청되었습니다.");
      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: SUPPLEMENT_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      alert(
        `보강 입력에 실패했습니다. ${error.response.data.message}`
      );
    }
  };

export const listSupplement =
  (isDone: boolean | null, toTeamId: string | null, teacherId: string | null) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: SUPPLEMENT_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/supplement?isDone=${isDone}&toTeamId=${toTeamId}&teacherId=${teacherId}`
      );

      dispatch({
        type: SUPPLEMENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: SUPPLEMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteSupplement =
  (id: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: SUPPLEMENT_DELETE_REQUEST,
      });
      const { data } = await axios.delete(
        `${API_SERVER_URL}/api/supplement/${id}`
      );
      dispatch({
        type: SUPPLEMENT_DELETE_SUCCESS,
        payload: data,
      });
      alert("보강이 정상적으로 삭제되었습니다.");
      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: SUPPLEMENT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      alert("보강 삭제에 실패하였습니다. 개발자에게 문의해주세요.");
    }
  };

export const batchUpdateAttend = (attendList: any[]) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    dispatch({
      type: BATCH_UPDATE_ATTEND_REQUEST,
    });

    const { data } = await axios.put(
      `${API_SERVER_URL}/api/attend/batch/update`,
      {
        attendList,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: BATCH_UPDATE_ATTEND_SUCCESS,
      payload: data,
    });
    alert("출석부가 업데이트 되었습니다.");
    window.location.reload();
  } catch (error: any) {
    dispatch({
      type: BATCH_UPDATE_ATTEND_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    alert(
      `출석부 업데이트에 실패했습니다. ${error.response.data.message}`
    );
  }
}