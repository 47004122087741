import {
  YOUTHTEAM_LIST_REQUEST,
  YOUTHTEAM_LIST_SUCCESS,
  YOUTHTEAM_LIST_FAIL,
  YOUTHTEAM_LIST_RESET,
  YOUTHTEAM_HIDDEN_LIST_REQUEST,
  YOUTHTEAM_HIDDEN_LIST_SUCCESS,
  YOUTHTEAM_HIDDEN_LIST_FAIL,
  YOUTHTEAM_HIDDEN_LIST_RESET,
  YOUTHTEAM_BRANCH_LIST_REQUEST,
  YOUTHTEAM_BRANCH_LIST_SUCCESS,
  YOUTHTEAM_BRANCH_LIST_FAIL,
  YOUTHTEAM_BRANCH_LIST_RESET,
  YOUTHTEAM_ADD_REQUEST,
  YOUTHTEAM_ADD_SUCCESS,
  YOUTHTEAM_ADD_FAIL,
  YOUTHTEAM_ADD_RESET,
  YOUTHTEAM_DETAIL_REQUEST,
  YOUTHTEAM_DETAIL_SUCCESS,
  YOUTHTEAM_DETAIL_FAIL,
  YOUTHTEAM_DETAIL_RESET,
  YOUTHTEAM_UPDATE_REQUEST,
  YOUTHTEAM_UPDATE_SUCCESS,
  YOUTHTEAM_UPDATE_FAIL,
  YOUTHTEAM_DELETE_REQUEST,
  YOUTHTEAM_DELETE_SUCCESS,
  YOUTHTEAM_DELETE_FAIL,
  YOUTHTEAM_DELETE_RESET,
} from "../constants/YouthTeamConstants";

export const getYouthTeamListReducer = (
  state = { youthTeams: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHTEAM_LIST_REQUEST:
      return { loading: true };
    case YOUTHTEAM_LIST_SUCCESS:
      return { loading: false, youthTeams: action.payload };
    case YOUTHTEAM_LIST_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHTEAM_LIST_RESET:
      return { youthTeams: [] };
    default:
      return state;
  }
};

export const addYouthTeamReducer = (
  state = { youthTeam: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHTEAM_ADD_REQUEST:
      return { loading: true };
    case YOUTHTEAM_ADD_SUCCESS:
      return { loading: false, success: true, youthTeam: action.payload };
    case YOUTHTEAM_ADD_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHTEAM_ADD_RESET:
      return { youthTeam: {} };
    default:
      return state;
  }
};

export const getYouthTeamDetailReducer = (
  state = { youthTeam: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHTEAM_DETAIL_REQUEST:
      return { loading: true };
    case YOUTHTEAM_DETAIL_SUCCESS:
      return { loading: false, youthTeam: action.payload };
    case YOUTHTEAM_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHTEAM_DETAIL_RESET:
      return { youthTeam: {} };
    default:
      return state;
  }
};

export const updateYouthTeamReducer = (
  state = { youthTeam: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHTEAM_UPDATE_REQUEST:
      return { loading: true };
    case YOUTHTEAM_UPDATE_SUCCESS:
      return { loading: false, success: true, youthTeam: action.payload };
    case YOUTHTEAM_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteYouthTeamReducer = (
  state = { youthTeam: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHTEAM_DELETE_REQUEST:
      return { loading: true };
    case YOUTHTEAM_DELETE_SUCCESS:
      return { loading: false, success: true, youthTeam: action.payload };
    case YOUTHTEAM_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHTEAM_DELETE_RESET:
      return { youthTeam: {} };
    default:
      return state;
  }
};

export const getYouthTeamHiddenListReducer = (
  state = { hiddenYouthTeams: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHTEAM_HIDDEN_LIST_REQUEST:
      return { loading: true };
    case YOUTHTEAM_HIDDEN_LIST_SUCCESS:
      return { loading: false, hiddenYouthTeams: action.payload };
    case YOUTHTEAM_HIDDEN_LIST_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHTEAM_HIDDEN_LIST_RESET:
      return { hiddenYouthTeams: [] };
    default:
      return state;
  }
};

export const getYouthTeamBranchListReducer = (
  state = { branchYouthTeams: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHTEAM_BRANCH_LIST_REQUEST:
      return { loading: true };
    case YOUTHTEAM_BRANCH_LIST_SUCCESS:
      return { loading: false, branchYouthTeams: action.payload };
    case YOUTHTEAM_BRANCH_LIST_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHTEAM_BRANCH_LIST_RESET:
      return { branchYouthTeams: [] };
    default:
      return state;
  }
};