import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getYouthTeamDetail,
  getYouthTeamList,
} from "../../redux/actions/YouthTeamActions";
import { addYouthStudent, getBranchYouthStudentList } from "../../redux/actions/YouthStudentActions";
import formatPhoneNum from "../../utils/formatPhoneNum";

const StudentScreen = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherId = userInfo._id;

  const dispatch: any = useDispatch();

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const branchYouthStudentList = useSelector((state: any) => state.branchYouthStudentList);
  const { loading, error, branchYouthStudents } = branchYouthStudentList;

  const [selectedTeacher, setSelectedTeacher] = useState("all");
  const [selectedTable, setSelectedTable] = useState([]);

  const teacherOptions: { value: string; label: string }[] = [{ value: "all", label: "전체" }];

  branchYouthStudents?.map((teacher: Teacher) => {
    teacherOptions.push({
      value: teacher.teacherId,
      label: teacher.teacherName,
    });
  });

  interface Member {
    _id: string;
    name: string;
    birthday: string;
    parentPhoneNum: string;
    school: string;
    branchId: string;
    bankTags: string[];
    __v: number;
  }

  interface Team {
    teamId: string;
    teamName: string;
    members: Member[];
  }

  interface Teacher {
    teacherId: string;
    teacherName: string;
    teams: Team[];
  }

  interface Props {
    data: Teacher[];
  }

  useEffect(() => {
    dispatch(getBranchYouthStudentList(teacherInfo.branch._id));
  }, [dispatch, teacherInfo.branch._id]);

  useEffect(() => {
    if (branchYouthStudentList) {
      setSelectedTable(branchYouthStudents);
    }
  }, [branchYouthStudentList]);

  const teacherChangeHandler = (value: string) => {
    setSelectedTeacher(value);
    if (value === "all") {
      setSelectedTable(branchYouthStudents);
    } else {
      setSelectedTable(
        branchYouthStudents?.filter(
          (teacher: Teacher) => teacher.teacherId === value
        )
      );
    }
  };

  let lastTeacherName = "";
  let backgroundColor = "gray.300";

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflowX="auto"
      minW={{ base: "700px" }}
      maxW={{ base: "700px" }}
    >
      <VStack
        spacing={4}
        align="stretch"
        overflowX="auto"
        minW={{ base: "700px" }}
        maxW={{ base: "700px" }}
      >
        <Heading as="h3" size="lg" mb={6}>{teacherInfo.branch.name} 회원 목록</Heading>
        <FormControl>
          <FormLabel>강사 선택</FormLabel>
          <Select
            value={selectedTeacher}
            onChange={(e) => teacherChangeHandler(e.target.value)}
          >
            {teacherOptions.map((teacher) => (
              <option key={teacher.value} value={teacher.value} selected={teacher.value === selectedTeacher}>
                {teacher.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <Table variant="unstyled" size="sm">
          <Thead bg="white">
            <Tr>
              <Th border="1px solid black" style={{ whiteSpace: "nowrap", textAlign: "center" }}>강사</Th>
              <Th border="1px solid black" style={{ whiteSpace: "nowrap", textAlign: "center" }}>팀</Th>
              <Th border="1px solid black" style={{ whiteSpace: "nowrap", textAlign: "center" }}>회원</Th>
              <Th border="1px solid black" style={{ whiteSpace: "nowrap", textAlign: "center" }}>생년월일</Th>
              <Th border="1px solid black" style={{ whiteSpace: "nowrap", textAlign: "center" }}>보호자 연락처</Th>
              <Th border="1px solid black" style={{ whiteSpace: "nowrap", textAlign: "center" }}>학교</Th>
            </Tr>
          </Thead>
          <Tbody bg="white">
            {/* {selectedTable?.map((teacher: Teacher) =>
            teacher.teams.map((team) =>
              team.members.map((member, index) => (
                <Tr key={member._id}>
                  {index === 0 ? <Td border="1px solid black" style={{ whiteSpace:"nowrap", fontWeight: "bold" }} backgroundColor="gray.300" rowSpan={team.members.length}>{teacher.teacherName}</Td> : null}
                  {index === 0 ? <Td border="1px solid black" style={{ textAlign:"center" }} rowSpan={team.members.length}>{team.teamName}</Td> : null}
                  <Td border="1px solid black">{member.name}</Td>
                  <Td border="1px solid black">{new Date(member.birthday).toLocaleDateString()}</Td>
                  <Td border="1px solid black">{member.parentPhoneNum}</Td>
                  <Td border="1px solid black">{member.school}</Td>
                </Tr>
              ))
            )
          )} */}
            {selectedTable?.map((teacher: Teacher) =>
              teacher.teams.map((team) =>
                team.members.map((member, index) => {
                  if (lastTeacherName !== teacher.teacherName) {
                    backgroundColor = backgroundColor === "gray.300" ? "gray.100" : "gray.300";
                  }

                  lastTeacherName = teacher.teacherName;

                  return (
                    <Tr key={member._id}>
                      {index === 0 ? <Td border="1px solid black" style={{ whiteSpace: "nowrap", fontWeight: "bold" }} backgroundColor={backgroundColor} rowSpan={team.members.length}>{teacher.teacherName}</Td> : null}
                      {index === 0 ? <Td border="1px solid black" style={{ textAlign: "center" }} rowSpan={team.members.length}>{team.teamName}</Td> : null}
                      <Td border="1px solid black">{member.name}</Td>
                      <Td border="1px solid black">{new Date(member.birthday).toLocaleDateString()}</Td>
                      <Td border="1px solid black">{member.parentPhoneNum && formatPhoneNum(member.parentPhoneNum)}</Td>
                      <Td border="1px solid black">{member.school}</Td>
                    </Tr>
                  )
                })
              )
            )}
          </Tbody>
        </Table>
      </VStack>
    </Box>
  );
};

export default StudentScreen;