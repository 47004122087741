import React, { useEffect, useRef, useState } from "react";
import {
  getBranchGraphGroup,
  getBranchInfo,
  getTeacherSingleStat,
} from "../../redux/actions/BranchActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Text,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  VStack,
  IconButton,
  Center,
  Select,
} from "@chakra-ui/react";
import {
  getBranchGraph,
  getTeacherStats,
} from "../../redux/actions/BranchActions";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  DatasetComponent,
  TransformComponent,
  CanvasRenderer,
]);

// interface TeacherData {
//   teacherName: string;
//   amountToPayGraph: number[];
// }

interface FormattedData {
  teacherName: string;
  values: { month: string; value: number }[] | any[];
}

const BranchMyScreen = () => {
  const chartRef = useRef(null);

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const userId = userInfo._id;

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );

  const [selectedTeacher, setSelectedTeacher] = useState("");

  const branchInfoList = useSelector((state: any) => state.branchInfoList);
  const { loading, error, branchInfo } = branchInfoList;

  const branchId = branchInfo && branchInfo._id;

  const [getBranchInfoCompleted, setGetBranchInfoCompleted] = useState(false);

  useEffect(() => {
    dispatch(getBranchInfo(teacherInfo.branch._id)).then(() => {
      setGetBranchInfoCompleted(true);
    });
  }, [dispatch, userId]);

  const teacherSingleStat = useSelector((state: any) => state.teacherSingleStat);
  const {
    loading: loadingSingleStat,
    error: errorSingleStat,
    stat,
  } = teacherSingleStat;

  useEffect(() => {
    if (userId) {
      dispatch(getTeacherSingleStat(userId, year, month, teacherInfo.branch._id));
    }
  }, [dispatch, userId, year, month, teacherInfo.branch._id]);

  useEffect(() => {
    if (month === 13) {
      setYear(year + 1);
      setMonth(1);
    }
    if (month === 0) {
      setYear(year - 1);
      setMonth(12);
    }
  }, [month]);

  const branchGraph = useSelector((state: any) => state.branchGraph);
  const { loading: loadingGraph, error: errorGraph, graph } = branchGraph;

  const [combinedGraphData, setCombinedGraphData] = useState<FormattedData[]>(
    []
  );
  const [selectedChart, setSelectedChart] = useState("totalIncomeGraph");
  const [chartTitle, setChartTitle] = useState("총매출");

  const graphOptions = [
    { value: "totalIncomeGraph", label: "총매출" },
    { value: "amountGraph", label: "당월입금" },
    { value: "notPaidGraph", label: "당월미납" },
    { value: "notPaidAccGraph", label: "연체미납" },
    { value: "totalStudentGraph", label: "총원" },
    { value: "newStudentReturnStudentGraph", label: "신규/복귀" },
    { value: "quitStudentGraph", label: "탈퇴" },
    { value: "standardStudentGraph", label: "평균인원" },
  ]

  useEffect(() => {
    dispatch(getBranchGraph(year, userInfo._id, branchId));
  }, [dispatch, year, userInfo._id, branchId]);

  useEffect(() => {
    if (graph !== undefined && graph.length !== 0) {
      const formattedData: FormattedData[] = [];
      const teacherName: string = graph.teacherName;
      const values = Array.from({ length: 12 }, (_, index) => {
        const monthData = graph[selectedChart][index];
        return { month: `${index + 1}월`, value: monthData || 0 };
      });
      formattedData.push({ teacherName, values });

      const options = {
        title: {
          text: `${year}년 ${chartTitle} 통계 차트`,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: formattedData.map((data) => data.teacherName),
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: formattedData[0].values.map((entry) => entry.month),
        },
        yAxis: {
          type: "value",
        },
        series: formattedData.map((data) => ({
          name: data.teacherName,
          type: "line",
          data: data.values.map((entry) => entry.value),
        })),
      };

      const chartInstance = echarts.init(chartRef.current);
      chartInstance.setOption(options);
    }
  }, [graph, selectedChart]);
  
  const handleGraphSwitch = (e: any) => {
    const findOption = graphOptions.find((option) => option.value === e.target.value);
    setSelectedChart(e.target.value);
    setChartTitle(findOption?.label || "총매출");
  }


  return (
    <Box mx={{ base: "4", md: "40" }}>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <VStack>
          <HStack>
            <Text
              textAlign={"center"}
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={"bold"}
              mb={10}
            >
              나의 분석표
            </Text>
          </HStack>
          <HStack>
            <IconButton
              aria-label="prev"
              icon={<ArrowBackIcon />}
              mx={4}
              onClick={() => {
                setMonth(month - 1);
              }}
            />
            <Text fontSize="2xl" fontWeight="bold">
              {year}년 {month}월
            </Text>
            <IconButton
              aria-label="next"
              icon={<ArrowForwardIcon />}
              mx={4}
              onClick={() => {
                setMonth(month + 1);
              }}
            />
          </HStack>
        </VStack>
      )}
      {loadingSingleStat ? (
        <div>Loading...</div>
      ) : errorSingleStat ? (
        <div>{errorSingleStat}</div>
      ) : (
        <TableContainer maxH="2000px" overflowX="auto">
          <Table variant="simple" mt={6}>
            <Thead bg="gray.100">
              <Tr>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                >
                  지도자
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  팀수
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  총원
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  신규
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  복귀
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  휴강
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  탈퇴
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  현재원
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  평균인원
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={135}
                >
                  총매출
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={135}
                >
                  당월입금
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={135}
                >
                  당월미납
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={135}
                >
                  연체미납
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={10}
                >
                  미납률
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={135}
                >
                  1인당 교육비
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {stat ? (
                  <Tr
                    key={stat && stat.teacherId}
                  >
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.teacherName}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.teamCount && stat.teamCount.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.totalStudent && stat.totalStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.newStudent && stat.newStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.returnStudent && stat.returnStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.restStudent && stat.restStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.quitStudent && stat.quitStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.nowStudent && stat.nowStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.standardStudent && stat.standardStudent.toFixed(1).toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.totalIncome && stat.totalIncome.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.amount && stat.amount.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.notPaid && stat.notPaid.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat && stat.notPaidAcc && stat.notPaidAcc.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {(stat?.notPaidRate * 100).toFixed(1)}%
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {Math.floor(stat && stat.amountPerStudent).toLocaleString()}
                    </Td>
                  </Tr>
              ) : (
                <Tr>
                  <Td colSpan={15} textAlign={"center"} color="red">
                    데이터가 없습니다.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {loadingGraph ? (
        <div>Loading...</div>
      ) : errorGraph ? (
        <div>
          개인 통계를 보기 위해서는 조회하고자 하는 강사를 선택하여 주십시오.
        </div>
      ) : (
        <>
        <Box mt={10} display={"flex"}>
        <Select
          width={{ base: "150px", md: "150px" }}
          height={{ base: "40px" }}
          borderColor="tomato"
          fontSize={{ base: "16px" }}
          value={selectedChart}
          onChange={handleGraphSwitch}
        >
          {graphOptions.map((option) => (
            <option key={option.value} value={option.value} selected={option.value === selectedChart}>
              {option.label}
            </option>
          ))}
        </Select>
        </Box>
          <Center mt={10}>
            <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
          </Center>
        </>
      )}
    </Box>
  );
};

export default BranchMyScreen;
