import {
  getAttendReducer,
  getAttendTableReducer,
  addAttendReducer,
  getAttendByIdReducer,
  updateAttendByIdReducer,
  deleteAttendByIdReducer,
  addSupplementReducer,
  getSupplementListReducer,
  deleteSupplementReducer,
  batchUpdateAttendReducer,
} from "./redux/reducers/AttendReducer";
import {
  getYouthTeamListReducer,
  getYouthTeamDetailReducer,
  updateYouthTeamReducer,
  addYouthTeamReducer,
  deleteYouthTeamReducer,
  getYouthTeamHiddenListReducer,
  getYouthTeamBranchListReducer
} from "./redux/reducers/YouthTeamReducer";
import {
  getYouthStudentDetailReducer,
  addYouthStudentReducer,
  updateYouthStudentReducer,
  getYouthStudentQuitListReducer,
  batchAddYouthStudentReducer,
  getBranchYouthStudentListReducer,
  getYouthStudentListReducer,
  addTransferReducer,
  getTransferListReducer,
} from "./redux/reducers/YouthStudentReducer";
import {
  getPaymentByMonthReducer,
  getPaymentListReducer,
  batchUpdatePaymentReducer,
  xlsxUploadReducer,
  xlsxUpdateReducer,
  getPaymentLogListReducer,
  deletePaymentLogReducer,
  deletePaymentLogFileNameReducer,
  getPaymentDetailReducer,
  etcPaymentInputReducer,
  etcPaymentListReducer,
  etcPaymentDeleteReducer,
  etcCategoryInputReducer,
  etcCategoryListReducer,
  etcCategoryDeleteReducer,
} from "./redux/reducers/PaymentReducer";
import {
  userLoginReducer,
  userFindTeacherReducer,
} from "./redux/reducers/UserReducer";
import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  consultInputReducer,
  getConsultDetailReducer,
  getConsultListByTeacherAllReducer,
  getConsultListByTeacherReducer,
  getConsultListReducer,
  getDayMemoListReducer,
  updateConsultReducer,
  updateDayConsultListReducer,
  updateDayMemoListReducer,
} from "./redux/reducers/MemoReducer";
import {
  getTeacherStatsReducer,
  getTeacherSingleStatReducer,
  getBranchInfoReducer,
  getBranchGraphReducer,
  getBranchGraphGroupReducer,
  updateBranchReducer,
  getTeacherTeamStatReducer,
  getTeachersByUserIdReducer,
  getGroupStatsReducer
} from "./redux/reducers/BranchReducer";
import {
  getBranchPageReducer,
  teacherIntroDeleteReducer,
  teacherIntroInputReducer,
  teacherIntroListReducer,
  teacherIntroUpdateReducer,
  updateBranchPageReducer,
  youthPostDetailReducer,
  youthPostInputReducer,
  youthPostListReducer,
  youthPostUpdateReducer
} from "./redux/reducers/HomePageReducer";

const reducer = combineReducers({
  attendList: getAttendReducer,
  attendTableList: getAttendTableReducer,
  attendInfo: getAttendByIdReducer,
  attendUpdate: updateAttendByIdReducer,
  attendBatchUpdate: batchUpdateAttendReducer,
  attendDelete: deleteAttendByIdReducer,
  branchInfoList: getBranchInfoReducer,
  youthTeamList: getYouthTeamListReducer,
  youthTeamAdd: addYouthTeamReducer,
  youthTeamDetail: getYouthTeamDetailReducer,
  youthTeamUpdate: updateYouthTeamReducer,
  youthTeamDelete: deleteYouthTeamReducer,
  youthTeamHiddenList: getYouthTeamHiddenListReducer,
  youthTeamBranchList: getYouthTeamBranchListReducer,
  youthStudentList: getYouthStudentListReducer,
  youthStudentDetail: getYouthStudentDetailReducer,
  youthStudentUpdate: updateYouthStudentReducer,
  youthStudentNew: addYouthStudentReducer,
  youthStudentQuitList: getYouthStudentQuitListReducer,
  youthStudentBatchAdd: batchAddYouthStudentReducer,
  branchYouthStudentList: getBranchYouthStudentListReducer,
  paymentByMonth: getPaymentByMonthReducer,
  paymentList: getPaymentListReducer,
  paymentDetail: getPaymentDetailReducer,
  etcPaymentAdd: etcPaymentInputReducer,
  etcPaymentList: etcPaymentListReducer,
  etcPaymentDelete: etcPaymentDeleteReducer,
  etcCategoryAdd: etcCategoryInputReducer,
  etcCategoryList: etcCategoryListReducer,
  etcCategoryDelete: etcCategoryDeleteReducer,
  paymentBatchUpdate: batchUpdatePaymentReducer,
  paymentXlsxUpload: xlsxUploadReducer,
  paymentXlsxUpdate: xlsxUpdateReducer,
  paymentLogList: getPaymentLogListReducer,
  paymentLogDelete: deletePaymentLogReducer,
  paymentLogDeleteFileName: deletePaymentLogFileNameReducer,
  userLogin: userLoginReducer,
  userFindTeacher: userFindTeacherReducer,
  memoTeamList: getDayMemoListReducer,
  memoTeamUpdate: updateDayMemoListReducer,
  consultList: getConsultListReducer,
  consultTeamUpdate: updateDayConsultListReducer,
  consultInput: consultInputReducer,
  consultByTeacher: getConsultListByTeacherReducer,
  consultByTeacherAll: getConsultListByTeacherAllReducer,
  consultDetail: getConsultDetailReducer,
  consultUpdate: updateConsultReducer,
  teacherStats: getTeacherStatsReducer,
  groupStats: getGroupStatsReducer,
  teacherTeamStat: getTeacherTeamStatReducer,
  teachersByUserId: getTeachersByUserIdReducer,
  teacherSingleStat: getTeacherSingleStatReducer,
  branchGraph: getBranchGraphReducer,
  branchGraphGroup: getBranchGraphGroupReducer,
  branchUpdate: updateBranchReducer,
  branchPageInfo: getBranchPageReducer,
  branchPageUpdte: updateBranchPageReducer,
  youthPostInput: youthPostInputReducer,
  youthPostList: youthPostListReducer,
  youthPostDetail: youthPostDetailReducer,
  youthPostUpdate: youthPostUpdateReducer,
  teacherIntroList: teacherIntroListReducer,
  teacherIntroInput: teacherIntroInputReducer,
  teacherIntroUpdate: teacherIntroUpdateReducer,
  teacherIntroDelete: teacherIntroDeleteReducer,
  supplementAdd: addSupplementReducer,
  supplementList: getSupplementListReducer,
  supplementDelete: deleteSupplementReducer,
  transferAdd: addTransferReducer,
  transferList: getTransferListReducer,
});

const storedUserInfo = localStorage.getItem("userInfo");
const userInfoFromStorage = storedUserInfo ? JSON.parse(storedUserInfo) : null;

const initialState = { userLogin: { userInfo: userInfoFromStorage } };


const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
