import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <VStack bg="gray.200" justifyContent={"center"} minH="100vh">
      <Heading>정상적인 경로가 아닙니다.</Heading>
      <Text>해당 페이지를 찾을수 없습니다.</Text>
      <Link to={"/top"}>
        <Button colorScheme={"red"} variant={"link"}>
          홈으로 &rarr;
        </Button>
      </Link>
    </VStack>
  );
}
