import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Grid,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getTeacherIntroList } from "../../redux/actions/HomePageActions";
import AdminHeaderBox from "../../components/AdminHeaderBox";
import { Link, useParams } from "react-router-dom";
import accessCustomize from "../../utils/accessCustomize";

export default function Instructors() {
  const { id } = useParams<{ id: string }>();
  const branchUrl = String(id);

  const text: string = "지도자 안내";
  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const teacherIntroList = useSelector((state: any) => state.teacherIntroList);
  const { loading, error, teacherIntros } = teacherIntroList;

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const dispatch: any = useDispatch();

  useEffect(() => {

    if (branchPage) {
      dispatch(getTeacherIntroList(branchPage?.branchId));
    }
  }, [dispatch, branchPage?.branchId]);

  const teacherSpecComponent = (teacherSpec: string[]) => {
    return teacherSpec.map((spec: string) => <Text key={spec}>{spec}</Text>);
  };

  return (
    <>
      <AdminHeaderBox text={text} adminHeaderBg={branchPage && branchPage.adminHeaderBg} />
      <Grid
        columnGap={8}
        rowGap={8}
        templateColumns={{
          sm: "1fr",
          md: "1fr 1fr",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
          "2xl": "repeat(4, 1fr)",
        }}
        mx={{ base: 5, md: 200 }}
        mt={{ base: 30, md: 50 }}
        mb={{ base: 30, md: 50 }}
      >
        {loading ? (
          <Spinner />
        ) : error ? (
          <Text>{error}</Text>
        ) : (
          <>
            {teacherIntros.map((teacher: any) => (
              <Card maxW="sm" key={teacher._id}>
                <CardBody>
                  <Image src={teacher.image} alt={teacher.teacherName} />
                  <Stack mt="6" spacing="3">
                    <Heading size="md">
                      {teacher.teacherRank} {teacher.teacherName}
                    </Heading>
                    {teacher.teacherSpec.length > 0 && (<Text as="b">약력</Text>)}
                    {teacherSpecComponent(teacher.teacherSpec)}
                  </Stack>
                </CardBody>
              </Card>
            ))}
          </>
        )}
      </Grid>
      <Center>
        {accessCustomize(teacherInfo, branchPage.branchId) && (
          <Button
            onClick={() => {
              window.location.href = `/${branchUrl}/instructors-customize`;
            }}
          >
            지도자 안내 페이지 관리
          </Button>
        )}
      </Center>
    </>
  );
}
