import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { login } from "../../redux/actions/UserAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useSelector } from "react-redux";
import identifyUser from "../../utils/identifyUser";
import { useLocation, useParams } from "react-router-dom";

export default function Login() {
  const userLogin = useSelector((state: any) => state.userLogin);
  const userInfo = userLogin?.userInfo;
  const error = userLogin?.error;

  const searchParams = new URLSearchParams(useLocation().search);
  const branchUrl = searchParams.get("branch");

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const handleLogin = () => {
    dispatch(login(email, password));
  };

  useEffect(() => {
    if (userInfo) {
      identifyUser(userInfo._id, false, String(branchUrl));
    } else if (error) {
      alert("아이디 또는 비밀번호가 올바르지 않습니다.");
    }
  }, [userInfo]);
  

  return (
    <Flex justify="center" align="center" height="100vh" bg="gray.50">
      <Box
        bg="white"
        p={8}
        rounded="md"
        shadow="md"
        width="100%"
        maxWidth="400px"
      >
        <VStack spacing={4} align="stretch">
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">
            로그인
          </Text>
          <FormControl id="email">
            <FormLabel>아이디</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="아이디"
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>비밀번호</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="비밀번호"
            />
          </FormControl>
          <Flex justify="space-between" fontSize="sm">
            <Link color="blue.500" href="#">
              비밀번호 찾기
            </Link>
            <Link color="blue.500" href="#">
              회원가입
            </Link>
          </Flex>
          <Button colorScheme="blue" onClick={handleLogin} width="full" mt={4}>
            로그인
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
}