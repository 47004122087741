import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getMemoList, updateMemoList } from "../../redux/actions/MemoActions";
import {
  Box,
  Button,
  Center,
  Tab,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

const OnelineMainScreen = () => {
  let { date, id } = useParams();

  const dispatch: any = useDispatch();

  const year = date!.substring(0, 4);
  const month = date!.substring(4, 6);
  const day = date!.substring(6, 8);

  const transformedDate = `${year}-${month}-${day}`;
  const teamId = id!.toString();

  const memoTeamList = useSelector((state: any) => state.memoTeamList);
  const { loading, error, memos } = memoTeamList;

  const memoTeamUpdate = useSelector((state: any) => state.memoTeamUpdate);
  const { loading: updateLoading, error: updateError } = memoTeamUpdate;

  useEffect(() => {
    dispatch(getMemoList(teamId, transformedDate, "oneLineMemo"));
  }, [dispatch, teamId, transformedDate]);

  const memoUpdateHandler = () => {
    const memoArray = memos.memo.map((memo: any) => {
      return { studentId: memo.studentId, memo: memo.memo };
    });
    const changedDate = year + "-" + month + "-" + day;
    dispatch(updateMemoList(changedDate, "oneLineMemo", memoArray));
  };

  return (
    <Box mx={{ base: "4", md: "40" }} pb={{ base: "40", md: "0" }}>
      <Center>
        <VStack>
          <Text fontSize="4xl" fontWeight="bold">
            한줄평 작성
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            날짜 : {transformedDate}
          </Text>
        </VStack>
      </Center>
      <Center mt={50}>
        <TableContainer maxH="2000px" overflowX="auto">
          <Table variant="simple" border="2px solid" borderColor="gray.400">
            <Thead bg="gray.100">
              <Tr>
                <Th
                  textAlign="center"
                  left="0"
                  borderWidth="1px"
                  borderColor="gray.400"
                  bg="gray.100"
                  fontSize={"md"}
                >
                  순번
                </Th>
                <Th
                  textAlign="center"
                  bg="gray.100"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                >
                  이름
                </Th>
                <Th
                  textAlign="center"
                  bg="gray.100"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                  w={"1000px"}
                >
                  한줄평 내용
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Center>
                  <Text>로딩중...</Text>
                </Center>
              ) : error ? (
                <Center>
                  <Text>에러 발생! 다시 시도해주세요.</Text>
                </Center>
              ) : (
                memos &&
                memos.memo &&
                memos.memo.map((memo: any, index: number) => (
                  <Tr key={index} minH="80px">
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontSize={"md"}
                    >
                      {index + 1}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontSize={"md"}
                    >
                      {memo.studentName}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontSize={"md"}
                      paddingLeft="2"
                      paddingRight="2"
                      paddingTop="2"
                      paddingBottom="2"
                    >
                      <Textarea
                        placeholder="한줄평을 작성해주세요."
                        defaultValue={memo.memo}
                        resize="vertical"
                        minH="unset"
                        overflowY="auto"
                        w="100%"
                        h="70px"
                        onChange={(e) => {
                          memos.memo[index].memo = e.target.value;
                        }}
                      />
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
      <Center mt={10}>
        <Button
          colorScheme="orange"
          w={"150px"}
          h={"50px"}
          onClick={memoUpdateHandler}
        >
          업데이트
        </Button>
      </Center>
    </Box>
  );
};

export default OnelineMainScreen;
