import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getYouthTeamList } from "../../redux/actions/YouthTeamActions";
import { Link } from "react-router-dom";
import { deletePaymentLog, deletePaymentLogFileName, getPaymentLogList, xlsxUpdate } from "../../redux/actions/PaymentActions";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import accessCheck from "../../utils/accessCheck";

const PaymentLogScreen = ({ history }: any) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const teacherId = userInfo._id;
  const dispatch: any = useDispatch();

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );
  const [isMapped, setIsMapped] = useState("all");
  const [isCard, setIsCard] = useState("all");
  const [deleteFileName, setDeleteFileName] = useState("");

  interface Student {
    _id: string;
    name: string;
  }

  const [selectedStudentObj, setSelectedStudentObj] = useState<Student[]>([]);

  const paymentLogList = useSelector((state: any) => state.paymentLogList);
  const { paymentLogs, loading, error } = paymentLogList;

  const youthTeamList = useSelector((state: any) => state.youthTeamList);
  const { loading: loadingTeam, error: errorTeam, youthTeams } = youthTeamList;

  useEffect(() => {
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, true));
  }, [dispatch, teacherId, teacherInfo.branch._id]);

  const [studentOption, setStudentOption] = useState<any[]>([]);

  // youthTeams?.youthTeam?.map((youthTeam: any) => {
  //     youthTeam.members.map((student: any, index: number) => {
  //       studentOption.push({
  //         value: student,
  //         label:
  //           youthTeam.memberInfo[index].memberName + "(" + youthTeam.name + ")",
  //       });
  //     });
  //   });
  useEffect(() => {
    if (youthTeams) {
      const tempStudentOption: any[] = [];

      youthTeams?.youthTeam?.map((youthTeam: any) => {
        youthTeam.memberInfo.map((member: any, index: number) => {
          tempStudentOption.push({
            value: member.memberId,
            label:
              member.memberName + "(" + youthTeam.name + ")",
          });
        });
      });

      setStudentOption(tempStudentOption);
    }
  }, [youthTeams]);

  const fileNameOption: any[] = [];

  const fileNameSet = new Set(); // 중복을 제거할 Set 생성

  paymentLogs &&
    paymentLogs.forEach((log: any) => {
      // Set에 파일 이름 추가 (중복된 경우 무시됨)
      fileNameSet.add(log.fileName);
    });

  // Set의 각 원소를 배열로 변환하여 fileNameOption에 추가
  fileNameSet.forEach((fileName: any) => {
    fileNameOption.push({
      value: fileName,
      label: fileName,
    });
  });

  useEffect(() => {
    // if (!accessCheck(["원장", "부원장", "조장"])) {
    //   alert("접근 권한이 없습니다.");
    //   window.location.href = "/youthdb/my";
    // }
  }, []);

  useEffect(() => {
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, true));
  }, [dispatch, teacherId, teacherInfo.branch._id]);

  useEffect(() => {
    dispatch(getPaymentLogList(year, month, teacherInfo.branch._id, isMapped, isCard));
  }, [dispatch, teacherId, year, month, isMapped, isCard]);

  useEffect(() => {
    setSelectedStudentObj([]);
  }, [isMapped]);

  useEffect(() => {
    if (month === 13) {
      setYear(year + 1);
      setMonth(1);
    }
    if (month === 0) {
      setYear(year - 1);
      setMonth(12);
    }
  }, [month]);

  const studentChangeHandler = (selectedValue: any, index: number) => {
    const newSelectedValues = [...selectedStudentObj];



    newSelectedValues[index] = {
      _id: selectedValue.value,
      name: selectedValue.label,
    };

    console.log(newSelectedValues);

    setSelectedStudentObj(newSelectedValues);
  };

  const dateTimeFormat = (date: string) => {
    // 2021-08-01T15:00:00.000Z to 01/12 15:00
    // time color blue
    const dateArr = date.split("-");
    const month = dateArr[1];
    const day = dateArr[2].split("T")[0];
    const timeArr = dateArr[2].split("T")[1].split(":");
    const hour = timeArr[0];
    const minute = timeArr[1];

    return (
      <Text>
        {month}/{day}{" "}
        <Text as="span" color="blue.500">
          {hour}:{minute}
        </Text>
      </Text>
    );
  }

  const dateFormat = (date: string) => {
    // date:2021-08-01T15:00:00.000Z
    // to 08/01

    const dateArr = date.split("-");
    const month = dateArr[1];
    const day = dateArr[2].split("T")[0];

    return `${month}/${day}`;
  }

  const submitHandler = () => {
    const submitData: any[] = [];

    for (let i = 0; i < selectedStudentObj.length; i++) {
      let bankTag: string[] = [];

      if (selectedStudentObj[i]) {
        bankTag = [
          paymentLogs[i].bank,
          paymentLogs[i].accountHolder,
          selectedStudentObj[i].name
        ];

        submitData.push({
          _id: paymentLogs[i]._id,
          depositDate: paymentLogs[i].depositDate,
          amount: paymentLogs[i].amount,
          depositMemo: paymentLogs[i].depositMemo,
          accountNum: paymentLogs[i].accountNum,
          bank: paymentLogs[i].bank,
          accountHolder: paymentLogs[i].accountHolder,
          studentId: selectedStudentObj[i]._id,
          studentName: selectedStudentObj[i].name,
          bankTag: bankTag,
          branchId: teacherInfo.branch._id,
          fileName: paymentLogs[i].fileName,
          memo: paymentLogs[i].memo,
          uploadDate: paymentLogs[i].uploadDate,
        });
      }
    }

    dispatch(xlsxUpdate(year, month, submitData, teacherInfo.branch._id));

    window.location.reload();
  };

  const fileNameDeleteHandler = () => {
    const confirm = window.confirm(`${year}년 ${month}월 "${deleteFileName}" 파일명을 가진 입금내역을 모두 삭제하시겠습니까?`);
    if (!confirm) return;

    dispatch(deletePaymentLogFileName(deleteFileName, teacherInfo.branch._id));
  }

  const paymentLogDeleteHandler = (id: string) => {
    const confirm = window.confirm("정말 삭제하시겠습니까?");
    if (!confirm) return;

    dispatch(deletePaymentLog(id));
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: "10px", // 원하는 높이를 지정
    }),
  };


  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack gap={4}>
        <HStack>
          <IconButton
            aria-label="prev"
            icon={<ArrowBackIcon />}
            mx={4}
            onClick={() => {
              setMonth(month - 1);
            }}
          />
          <Text fontSize="2xl" fontWeight="bold">
            {year}년 {month}월
          </Text>
          <IconButton
            aria-label="next"
            icon={<ArrowForwardIcon />}
            mx={4}
            onClick={() => {
              setMonth(month + 1);
            }}
          />
        </HStack>
        <HStack w="100%" justify="space-between">
          <Box mb={4}>
            <HStack align="flex-start">
                <Text>결제 방식</Text>
                <RadioGroup onChange={(value) => setIsCard(value)} value={isCard}>
                  <Stack direction="row" spacing={6}>
                    <Radio value="all">전체</Radio>
                    <Radio value="false">계좌이체</Radio>
                    <Radio value="true">카드 및 기타</Radio>
                  </Stack>
                </RadioGroup>
              </HStack>
            <VStack align="flex-start">
              <HStack align="flex-start">
                <Text>필터 기능</Text>
                <RadioGroup onChange={(value) => setIsMapped(value)} value={isMapped}>
                  <Stack direction="row" spacing={6}>
                    <Radio value="all">전체</Radio>
                    <Radio value="true">맵핑적용</Radio>
                    <Radio value="false">맵핑미적용</Radio>
                  </Stack>
                </RadioGroup>
              </HStack>
            </VStack>
          </Box>
          <Box>
            <VStack align="flex-end">
              <Link to="/youthdb/payment-type">
                <Button colorScheme="orange">입금내역 업로드</Button>
              </Link>
              <HStack
                position="relative"
              >
                <p>파일명으로 삭제</p>
                <Select
                  styles={customStyles}
                  onChange={(e: any) => setDeleteFileName(e.value)}
                  placeholder="파일명 선택"
                  isSearchable
                  options={fileNameOption.map((fileName, index) => ({
                    value: fileName.value,
                    label: fileName.label,
                  }))}
                />
                <Button
                  colorScheme="red"
                  onClick={fileNameDeleteHandler}
                >
                  삭제
                </Button>
              </HStack>
            </VStack>
          </Box>
        </HStack>
        {loading ? (
          <h2>Loading...</h2>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          <TableContainer
            maxW="80vw"
            w="80vw"
            bg="white"
            borderRadius="lg"
            boxShadow="lg"
            fontSize={"sm"}
          >
            <Table
              variant="simple"
              size="sm"
            >
              <Thead>
                <Tr>
                  <Th>업로드 일자</Th>
                  <Th>거래 일자</Th>
                  <Th>입금</Th>
                  <Th>내용</Th>
                  <Th>거래점</Th>
                  <Th>입금자명</Th>
                  <Th>비고</Th>
                  <Th minW={200}>회원</Th>
                  <Th>파일명</Th>
                  <Th>삭제</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paymentLogs.map((log: any, index: number) => (
                  <Tr key={index} background={log.studentId === "" ? "" : "gray.100"}>
                    <Td>{dateTimeFormat(log.uploadDate)}</Td>
                    <Td>{dateFormat(log.depositDate)}</Td>
                    <Td>{log.amount}</Td>
                    <Td>{log.content}</Td>
                    <Td>{log.bank}</Td>
                    <Td>{log.accountHolder}</Td>
                    <Td>{log.memo}</Td>
                    {log.studentId === "" ? (
                      <Td
                        textAlign="center"
                      >
                        <Select
                          styles={customStyles}
                          onChange={(e: any) => studentChangeHandler(e, index)}
                          placeholder="회원 선택"
                          isSearchable
                          options={studentOption.map((student, index) => ({
                            value: student.value,
                            label: student.label,
                          }))}
                          menuPosition="fixed"
                        />
                      </Td>
                    ) : (
                      <Td>{log.studentName}</Td>
                    )}
                    <Td>{log.fileName}</Td>
                    <Td>
                      <Button
                        colorScheme="red"
                        onClick={() => paymentLogDeleteHandler(log._id)}
                      >
                        삭제
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <VStack>
          <Button
            colorScheme="orange"
            onClick={submitHandler}
          >
            업데이트
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
}

export default PaymentLogScreen;