import axios from 'axios';
import { API_SERVER_URL } from '../redux/actions/actionUrl';

export const imageUrlGenerate = async (selectedImage: any, bucket: string) => {
  if (selectedImage !== null) {
    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/upload/youthpage/${bucket}`,
        formData,
        config
      );

      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  return null;
};