import {
  ATTEND_REQUEST,
  ATTEND_SUCCESS,
  ATTEND_FAIL,
  ATTEND_RESET,
  ATTEND_TABLE_REQUEST,
  ATTEND_TABLE_SUCCESS,
  ATTEND_TABLE_FAIL,
  ATTEND_TABLE_RESET,
  ATTEND_INPUT_REQUEST,
  ATTEND_INPUT_SUCCESS,
  ATTEND_INPUT_FAIL,
  ATTEND_INPUT_RESET,
  GET_ATTEND_BY_ID_REQUEST,
  GET_ATTEND_BY_ID_SUCCESS,
  GET_ATTEND_BY_ID_FAIL,
  UPDATE_ATTEND_BY_ID_REQUEST,
  UPDATE_ATTEND_BY_ID_SUCCESS,
  UPDATE_ATTEND_BY_ID_FAIL,
  BATCH_UPDATE_ATTEND_REQUEST,
  BATCH_UPDATE_ATTEND_SUCCESS,
  BATCH_UPDATE_ATTEND_FAIL,
  DELETE_ATTEND_BY_ID_REQUEST,
  DELETE_ATTEND_BY_ID_SUCCESS,
  DELETE_ATTEND_BY_ID_FAIL,
  SUPPLEMENT_INPUT_REQUEST,
  SUPPLEMENT_INPUT_SUCCESS,
  SUPPLEMENT_INPUT_FAIL,
  SUPPLEMENT_INPUT_RESET,
  SUPPLEMENT_LIST_REQUEST,
  SUPPLEMENT_LIST_SUCCESS,
  SUPPLEMENT_LIST_FAIL,
  SUPPLEMENT_LIST_RESET,
  SUPPLEMENT_DELETE_REQUEST,
  SUPPLEMENT_DELETE_SUCCESS,
  SUPPLEMENT_DELETE_FAIL,
  SUPPLEMENT_DELETE_RESET,
} from "../constants/AttendConstants";

export const getAttendReducer = (
  state = { attend: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ATTEND_REQUEST:
      return { loading: true };
    case ATTEND_SUCCESS:
      return { loading: false, attend: action.payload };
    case ATTEND_FAIL:
      return { loading: false, error: action.payload };
    case ATTEND_RESET:
      return { attend: [] };
    default:
      return state;
  }
};

export const getAttendTableReducer = (
  state = { attendTable: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ATTEND_TABLE_REQUEST:
      return { loading: true };
    case ATTEND_TABLE_SUCCESS:
      return { loading: false, attendTable: action.payload };
    case ATTEND_TABLE_FAIL:
      return { loading: false, error: action.payload };
    case ATTEND_TABLE_RESET:
      return { attendTable: [] };
    default:
      return state;
  }
};

export const addAttendReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ATTEND_INPUT_REQUEST:
      return { loading: true };
    case ATTEND_INPUT_SUCCESS:
      return { loading: false, success: true };
    case ATTEND_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case ATTEND_INPUT_RESET:
      return {};
    default:
      return state;
  }
};

export const getAttendByIdReducer = (
  state = { attend: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ATTEND_BY_ID_REQUEST:
      return { loading: true };
    case GET_ATTEND_BY_ID_SUCCESS:
      return { loading: false, attend: action.payload };
    case GET_ATTEND_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateAttendByIdReducer = (
  state = { attend: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_ATTEND_BY_ID_REQUEST:
      return { loading: true };
    case UPDATE_ATTEND_BY_ID_SUCCESS:
      return { loading: false, attend: action.payload };
    case UPDATE_ATTEND_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteAttendByIdReducer = (
  state = { attend: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case DELETE_ATTEND_BY_ID_REQUEST:
      return { loading: true };
    case DELETE_ATTEND_BY_ID_SUCCESS:
      return { loading: false, attend: action.payload };
    case DELETE_ATTEND_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addSupplementReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case SUPPLEMENT_INPUT_REQUEST:
      return { loading: true };
    case SUPPLEMENT_INPUT_SUCCESS:
      return { loading: false, success: true };
    case SUPPLEMENT_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case SUPPLEMENT_INPUT_RESET:
      return {};
    default:
      return state;
  }
};

export const getSupplementListReducer = (
  state = { supplements: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case SUPPLEMENT_LIST_REQUEST:
      return { loading: true };
    case SUPPLEMENT_LIST_SUCCESS:
      return { loading: false, supplements: action.payload };
    case SUPPLEMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case SUPPLEMENT_LIST_RESET:
      return { supplements: [] };
    default:
      return state;
  }
}

export const deleteSupplementReducer = (
  state = { supplement: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case SUPPLEMENT_DELETE_REQUEST:
      return { loading: true };
    case SUPPLEMENT_DELETE_SUCCESS:
      return { loading: false, supplement: action.payload };
    case SUPPLEMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case SUPPLEMENT_DELETE_RESET:
      return { supplement: {} };
    default:
      return state;
  }
}

export const batchUpdateAttendReducer = (
  state = { attend: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BATCH_UPDATE_ATTEND_REQUEST:
      return { loading: true };
    case BATCH_UPDATE_ATTEND_SUCCESS:
      return { loading: false, attend: action.payload };
    case BATCH_UPDATE_ATTEND_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};