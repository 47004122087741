export const GET_DAY_MEMO_LIST_REQUEST = "GET_DAY_MEMO_LIST_REQUEST" as const;
export const GET_DAY_MEMO_LIST_SUCCESS = "GET_DAY_MEMO_LIST_SUCCESS" as const;
export const GET_DAY_MEMO_LIST_FAIL = "GET_DAY_MEMO_LIST_FAIL" as const;
export const GET_DAY_MEMO_LIST_RESET = "GET_DAY_MEMO_LIST_RESET" as const;

export const CONSULT_LIST_REQUEST = "CONSULT_LIST_REQUEST" as const;
export const CONSULT_LIST_SUCCESS = "CONSULT_LIST_SUCCESS" as const;
export const CONSULT_LIST_FAIL = "CONSULT_LIST_FAIL" as const;
export const CONSULT_LIST_RESET = "CONSULT_LIST_RESET" as const;

export const CONSULT_LIST_TEACHER_REQUEST = "CONSULT_LIST_TEACHER_REQUEST" as const;
export const CONSULT_LIST_TEACHER_SUCCESS = "CONSULT_LIST_TEACHER_SUCCESS" as const;
export const CONSULT_LIST_TEACHER_FAIL = "CONSULT_LIST_TEACHER_FAIL" as const;
export const CONSULT_LIST_TEACHER_RESET = "CONSULT_LIST_TEACHER_RESET" as const;

export const CONSULT_LIST_TEACHER_ALL_REQUEST = "CONSULT_LIST_TEACHER_ALL_REQUEST" as const;
export const CONSULT_LIST_TEACHER_ALL_SUCCESS = "CONSULT_LIST_TEACHER_ALL_SUCCESS" as const;
export const CONSULT_LIST_TEACHER_ALL_FAIL = "CONSULT_LIST_TEACHER_ALL_FAIL" as const;
export const CONSULT_LIST_TEACHER_ALL_RESET = "CONSULT_LIST_TEACHER_ALL_RESET" as const;

export const UPDATE_DAY_MEMO_LIST_REQUEST =
  "UPDATE_DAY_MEMO_LIST_REQUEST" as const;
export const UPDATE_DAY_MEMO_LIST_SUCCESS =
  "UPDATE_DAY_MEMO_LIST_SUCCESS" as const;
export const UPDATE_DAY_MEMO_LIST_FAIL = "UPDATE_DAY_MEMO_LIST_FAIL" as const;

export const UPDATE_DAY_CONSULT_LIST_REQUEST =
  "UPDATE_DAY_CONSULT_LIST_REQUEST" as const;
export const UPDATE_DAY_CONSULT_LIST_SUCCESS =
  "UPDATE_DAY_CONSULT_LIST_SUCCESS" as const;
export const UPDATE_DAY_CONSULT_LIST_FAIL =
  "UPDATE_DAY_CONSULT_LIST_FAIL" as const;

export const CONSULT_INPUT_REQUEST = "CONSULT_INPUT_REQUEST" as const;
export const CONSULT_INPUT_SUCCESS = "CONSULT_INPUT_SUCCESS" as const;
export const CONSULT_INPUT_FAIL = "CONSULT_INPUT_FAIL" as const;
export const CONSULT_INPUT_RESET = "CONSULT_INPUT_RESET" as const;

export const CONSULT_DETAIL_REQUEST = "CONSULT_DETAIL_REQUEST" as const;
export const CONSULT_DETAIL_SUCCESS = "CONSULT_DETAIL_SUCCESS" as const;
export const CONSULT_DETAIL_FAIL = "CONSULT_DETAIL_FAIL" as const;
export const CONSULT_DETAIL_RESET = "CONSULT_DETAIL_RESET" as const;

export const CONSULT_UPDATE_REQUEST = "CONSULT_UPDATE_REQUEST" as const;
export const CONSULT_UPDATE_SUCCESS = "CONSULT_UPDATE_SUCCESS" as const;
export const CONSULT_UPDATE_FAIL = "CONSULT_UPDATE_FAIL" as const;
export const CONSULT_UPDATE_RESET = "CONSULT_UPDATE_RESET" as const;