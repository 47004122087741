import { useDispatch, useSelector } from "react-redux";
import { Box, Center, Flex, Text, VStack, HStack, Input, IconButton, Spinner, Textarea, Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { updateBranchPage } from "../../redux/actions/HomePageActions";
import { Button } from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";
import { API_SERVER_URL } from "../../redux/actions/actionUrl";
import { deleteImage } from "../../utils/deleteImage";
import { imageUrlGenerate } from "../../utils/imageUrlGenerate";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyCustomUploadAdapterPlugin from '../../utils/MyCustomUploadAdapterPlugin';

export default function GreetingCustomize() {
  interface Spec {
    specName: string;
    specContent: string[];
  }
  
  interface SpecList extends Array<Spec> {}

  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedImage2, setSelectedImage2] = useState<any>(null);
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [uploading, setUploading] = useState(false);

  const [content, setContent] = useState("");
  const [scholar, setScholar] = useState<string[]>([]);
  const [newScholar, setNewScholar] = useState("");

  const [specList, setSpecList] = useState<SpecList>([]);

  useEffect(() => {
    if (branchPage && branchPage.footerInfo && branchPage.greetingInfo) {
      setContent(branchPage.greetingInfo.content ? branchPage.greetingInfo.content : "");
      setScholar(branchPage.greetingInfo.scholar);
      setImage(branchPage.greetingInfo.image ? branchPage.greetingInfo.image[0] : "");
      setImage2(branchPage.greetingInfo.image ? branchPage.greetingInfo.image[1] : "");
      setSpecList(branchPage.greetingInfo.specList || []);
    }
  }, [branchPage]);

  const updateHandler = async () => {
    setUploading(true);

    const imageUrl = await imageUrlGenerate(selectedImage, "branchpage");
    const imageUrl2 = await imageUrlGenerate(selectedImage2, "branchpage");

    console.log("imageUrl", imageUrl);

    if (imageUrl !== null) {
      await deleteImage("branchpage", image);
    }

    if (imageUrl2 !== null) {
      await deleteImage("branchpage", image2);
    }

    dispatch(updateBranchPage({
      branchId: branchPage.branchId,
      greetingInfo: {
        content: content,
        specList: specList,
        image: [imageUrl || image, imageUrl2 || image2],
      },
      _id: branchPage._id,
    }));

    alert("저장되었습니다.");
    window.location.reload();
  };

  const handleAddSpec = () => {
    setSpecList([...specList, { specName: '', specContent: [''] }]);
  };

  const handleRemoveSpec = (index: number) => {
    const newList = specList.filter((_, i) => i !== index);
    setSpecList(newList);
  };

  const handleSpecNameChange = (index: number, value: string) => {
    const newList = [...specList];
    newList[index].specName = value;
    setSpecList(newList);
  };

  const handleAddContent = (index: number) => {
    const newList = [...specList];
    newList[index].specContent.push('');
    setSpecList(newList);
  };

  const handleContentChange = (specIndex: number, contentIndex: number, value: string) => {
    const newList = [...specList];
    newList[specIndex].specContent[contentIndex] = value;
    setSpecList(newList);
  };

  const handleRemoveContent = (specIndex: number, contentIndex: number) => {
    const newList = [...specList];
    newList[specIndex].specContent = newList[specIndex].specContent.filter(
      (_, i) => i !== contentIndex
    );
    setSpecList(newList);
  };

  const editorConfig = {
    toolbar: {
      items: ['undo', 'redo', '|', 'selectAll', '|', 'bold', 'italic'],
      shouldNotGroupWhenFull: false
    },
    extraPlugins: [MyCustomUploadAdapterPlugin],
  };


  return (
    <Box bg="white" color="black" p={10}>
      <Flex justifyContent="center" mb={4}>
        <Text fontSize="2xl" fontWeight="bold">인사말 페이지 관리</Text>
      </Flex>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>페이지 상단 사진(좌측)</Text>
        <img
          src={selectedImage ? URL.createObjectURL(selectedImage) : image}
          alt="image"
          style={{ width: '400px' }}
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setSelectedImage(file);
            }
          }}
        />
      </Box>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>페이지 상단 사진(우측)</Text>
        <img
          src={selectedImage2 ? URL.createObjectURL(selectedImage2) : image2}
          alt="image"
          style={{ width: '400px' }}
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setSelectedImage2(file);
            }
          }}
        />
      </Box>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>인사말</Text>
        <Box
          className="editor-container"
          minH="500px"
          css={{
            '.ck-editor__editable_inline': {
              minHeight: '500px',
            },
          }}
        >
          <CKEditor
            editor={ClassicEditor}
            config={editorConfig}
            data={content}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContent(data);
            }}
          />
        </Box>
      </Box>
      <Box mb={6}>
        <Flex mb={2} alignItems="center">
          <Text fontSize="lg" fontWeight="bold" mr={4}>학력 사항</Text>
        </Flex>
        <VStack spacing={4} align="stretch">
      {specList.map((spec, specIndex) => (
        <Box key={specIndex} borderWidth="1px" borderRadius="md" p={4} maxW={400}>
          <HStack justify="space-between">
            <Input
              placeholder="소개 항목 이름"
              value={spec.specName}
              onChange={(e) => handleSpecNameChange(specIndex, e.target.value)}
            />
            <IconButton
              icon={<DeleteIcon />}
              colorScheme="red"
              onClick={() => handleRemoveSpec(specIndex)}
              aria-label="삭제"
            />
          </HStack>
          <Divider color={"gray.400"} mt={2} mb={2} />
          <VStack mt={4} spacing={2} align="stretch">
            {spec.specContent.map((content, contentIndex) => (
              <HStack key={contentIndex} justify="space-between">
                <Input
                  value={content}
                  onChange={(e) =>
                    handleContentChange(specIndex, contentIndex, e.target.value)
                  }
                />
                <IconButton
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  onClick={() => handleRemoveContent(specIndex, contentIndex)}
                  aria-label="삭제"
                />
              </HStack>
            ))}
            <Button
              onClick={() => handleAddContent(specIndex)}
              leftIcon={<AddIcon />}
            >
              내용 추가
            </Button>
          </VStack>
        </Box>
      ))}
      <Button onClick={handleAddSpec} colorScheme="blue" leftIcon={<AddIcon />}>
        소개 항목 추가
      </Button>
    </VStack>
      </Box>
      <Box>
        <Button colorScheme="blue" onClick={updateHandler}>저장</Button>
      </Box>
      {uploading && <Spinner />}
    </Box>
  );
}