import axios from "axios";
import {
  Button,
  Box,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { API_SERVER_URL } from "../../redux/actions/actionUrl";
import accessCheck from "../../utils/accessCheck";

const XlsxStudentAddScreen: React.FC = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const submitHandler = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile as Blob);

    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/xlsx/youth-student?branchId=${teacherInfo.branch._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert("회원 등록이 완료되었습니다.");

      window.location.href = "/youthdb/youth-student";
    } catch (error) {
      alert("Error uploading file:" + error);
    }
  }

  return (
    <div>
      <Box
        mt={10}
        mx={6}
        mb={10}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <VStack gap={10}>
          <input type="file" onChange={handleFileChange} />
          <Button colorScheme="blue" onClick={submitHandler}>
            입력
          </Button>
        </VStack>
      </Box>
    </div>
  );
};

export default XlsxStudentAddScreen;