import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { FaLock, FaUser } from "react-icons/fa";
import { useEffect, useState } from "react";
import { login } from "../../redux/actions/UserAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import identifyUser from "../../utils/identifyUser";


const AdminLoginScreen = ({ history }: any) => {
  interface IForm {
    userId: string;
    password: string;
  }

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const dispatch: any = useDispatch();

  const userLogin = useSelector((state: any) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const redirect = "youthdb/my";

  useEffect(() => {
    if (userInfo) {
      identifyUser(userInfo._id, true);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e: any) => {
    e.preventDefault();
    dispatch(login(userId, password));
  };
  
  useEffect(() => {
    if (teacherInfo?.accessList?.length > 0) {
      window.location.href = "/youthdb/my";
    }
  }, [teacherInfo]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bg="gray.100"
      >
        <HStack spacing={8}>
          <VStack spacing={8}>
            {loading && <p>Loading...</p>}
            {error && (
              <Box
                bg="red.100"
                p={4}
                borderRadius="md"
                boxShadow="md"
                width="400px"
                textAlign="center"
              >
                아이디 또는 비밀번호가 올바르지 않습니다.
              </Box>
            )}
            <h1>유소년DB 관리자 로그인</h1>
            <Box
              bg="white"
              p={8}
              borderRadius="lg"
              boxShadow="md"
              width="400px"
              textAlign="center"
            >
              <form onSubmit={submitHandler}>
                <VStack spacing={4}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FaUser color="gray.300" />}
                    />
                    <Input
                      type="text"
                      placeholder="아이디"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FaLock color="gray.300" />}
                    />
                    <Input
                      type="password"
                      placeholder="비밀번호"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                  <Button type="submit" colorScheme="blue">
                    로그인
                  </Button>
                </VStack>
              </form>
            </Box>
          </VStack>
        </HStack>
      </Box>
    </>
  );
}

export default AdminLoginScreen;