import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Text,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  VStack,
  IconButton,
  Center,
  Select,
} from "@chakra-ui/react";
import {
  getBranchInfo,
  getBranchGraph,
  getGroupStats,
} from "../../redux/actions/BranchActions";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import accessCheck from "../../utils/accessCheck";
import { BRANCH_INFO_RESET } from "../../redux/constants/BranchConstants";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  DatasetComponent,
  TransformComponent,
  CanvasRenderer,
]);

interface FormattedData {
  teacherName: string;
  values: { month: string; value: number }[] | any[];
}

const GroupMainScreen = () => {
  const chartRef = useRef(null);

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const userId = userInfo._id;

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );

  const [selectedTeacher, setSelectedTeacher] = useState("");

  const branchInfoList = useSelector((state: any) => state.branchInfoList);
  const { loading, error, branchInfo } = branchInfoList;

  const branchId = branchInfo && branchInfo._id;

  const [getBranchInfoCompleted, setGetBranchInfoCompleted] = useState(false);

  const graphOptions = [
    { value: "totalIncomeGraph", label: "총매출" },
    { value: "amountGraph", label: "당월입금" },
    { value: "notPaidGraph", label: "당월미납" },
    { value: "notPaidAccGraph", label: "연체미납" },
    { value: "totalStudentGraph", label: "총원" },
    { value: "newStudentReturnStudentGraph", label: "신규/복귀" },
    { value: "quitStudentGraph", label: "탈퇴" },
    { value: "standardStudentGraph", label: "평균인원" },
  ]

  useEffect(() => {
    // if (!accessCheck(["원장", "부원장", "조장"])) {
    //   alert("접근 권한이 없습니다.");
    //   window.location.href = "/youthdb/my";
    // }
  }, []);

  useEffect(() => {
    dispatch({ type: BRANCH_INFO_RESET });

    dispatch(getBranchInfo(teacherInfo.branch._id)).then(() => {
      setGetBranchInfoCompleted(true);
    });
  }, [dispatch, userId]);

  useEffect(() => {
    if (branchInfo && branchInfo.teacherInfo && branchInfo.teacherInfo.length > 0) {
      setSelectedTeacher(branchInfo.teacherInfo[0].teacherId);
    }
  }, [branchInfo]);

  const groupStats = useSelector((state: any) => state.groupStats);
  const { loading: loadingGroupStats, error: errorGroupStats, statsGroup } = groupStats;

  useEffect(() => {
    if (getBranchInfoCompleted && branchInfo) {
      dispatch(getGroupStats(branchId, year, month, userInfo._id));
    }
  }, [dispatch, getBranchInfoCompleted, branchInfo, branchId, year, month]);

  useEffect(() => {
    if (month === 13) {
      setYear(year + 1);
      setMonth(1);
    }
    if (month === 0) {
      setYear(year - 1);
      setMonth(12);
    }
  }, [month]);

  const branchGraph = useSelector((state: any) => state.branchGraph);
  const { loading: loadingGraph, error: errorGraph, graph } = branchGraph;

  const [selectedChart, setSelectedChart] = useState("totalIncomeGraph");
  const [chartTitle, setChartTitle] = useState("총매출");

  useEffect(() => {
    dispatch(getBranchGraph(year, selectedTeacher, branchId));
  }, [dispatch, year, selectedTeacher, branchId]);

  useEffect(() => {
    if (graph !== undefined && graph.length !== 0) {
      const formattedData: FormattedData[] = [];
      const teacherName: string = graph.teacherName;
      const values = Array.from({ length: 12 }, (_, index) => {
        const monthData = graph[selectedChart][index];
        return { month: `${index + 1}월`, value: monthData || 0 };
      });
      formattedData.push({ teacherName, values });

      const options = {
        title: {
          text: `${year}년 ${chartTitle} 통계 차트`,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: formattedData.map((data) => data.teacherName),
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: formattedData[0].values.map((entry) => entry.month),
        },
        yAxis: {
          type: "value",
        },
        series: formattedData.map((data) => ({
          name: data.teacherName,
          type: "line",
          data: data.values.map((entry) => entry.value),
        })),
      };

      const chartInstance = echarts.init(chartRef.current);
      chartInstance.setOption(options);
    }
  }, [graph, selectedChart]);

  const [teacherOption, setTeacherOption] = useState<any[]>([]);

  useEffect(() => {
    if (branchInfo && branchInfo.teacherGroup2) {
      const tempGroup = branchInfo.teacherGroup2.find((group: any) => group.masterId === userId);
      
      if (tempGroup) {
        const tempTeacherOption: { value: string; label: string }[] = [];

        const tempGroupTeacher = tempGroup.groupTeacher;
        if (!tempGroupTeacher.includes(userId)) {
          tempGroupTeacher.push(userId);
        }

        for (let i = 0; i < tempGroupTeacher.length; i++) {
          const findTeacher = branchInfo.teacherInfo.find((teacher: any) => teacher.teacherId === tempGroupTeacher[i]);
          if (findTeacher) {
            tempTeacherOption.push({ value: findTeacher.teacherId, label: findTeacher.teacherName });
          }
        }
        
        setTeacherOption(tempTeacherOption);
      } else {
        setTeacherOption([]);
      }
    }
  }, [branchInfo]);

  const handleGraphSwitch = (e: any) => {
    const findOption = graphOptions.find((option) => option.value === e.target.value);
    setSelectedChart(e.target.value);
    setChartTitle(findOption?.label || "총매출");
  }

  return (
    <Box mx={{ base: "4", md: "40" }}>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <VStack>
          <HStack>
            <Text
              textAlign={"center"}
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={"bold"}
              mb={10}
            >
              {userInfo.name} 조 분석표
            </Text>
          </HStack>
          <HStack>
            <IconButton
              aria-label="prev"
              icon={<ArrowBackIcon />}
              mx={4}
              onClick={() => {
                setMonth(month - 1);
              }}
            />
            <Text fontSize="2xl" fontWeight="bold">
              {year}년 {month}월
            </Text>
            <IconButton
              aria-label="next"
              icon={<ArrowForwardIcon />}
              mx={4}
              onClick={() => {
                setMonth(month + 1);
              }}
            />
          </HStack>
        </VStack>
      )}
      <VStack mt={10}>
      {loadingGroupStats ? (
        <div>Loading...</div>
      ) : errorGroupStats ? (
        <div>{errorGroupStats}</div>
      ) : (
        <TableContainer maxH="2000px" overflowX="auto">
          <Table variant="simple" mt={6}>
            <Thead bg="gray.100">
              <Tr>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                >
                  지도자
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  팀수
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  총원
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  신규
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  복귀
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  휴강
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  탈퇴
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  현재원
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={50}
                >
                  평균인원
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={135}
                >
                  총매출
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={135}
                >
                  당월입금
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={135}
                >
                  당월미납
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={135}
                >
                  연체미납
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={10}
                >
                  미납률
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                  width={135}
                >
                  1인당 교육비
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                >
                  출석률
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                >
                  출석상세
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                >
                  회원 관리
                </Th>
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  pt={2}
                  pb={2}
                  px={1}
                >
                  보고현황
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {statsGroup && statsGroup.length > 0 ? (
                statsGroup.map((stat: any, index: number) => (
                  <Tr
                    key={stat.teacherId}
                    style={
                      index === statsGroup.length - 1
                        ? { backgroundColor: "red", color: "white" }
                        : {}
                    }
                  >
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.teacherName}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.teamCount.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.totalStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.newStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.returnStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.restStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.quitStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.nowStudent.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.standardStudent.toFixed(1).toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.totalIncome.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.amount.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.notPaid.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {stat.notPaidAcc.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {(stat.notPaidRate * 100).toFixed(1)}%
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {Math.floor(stat.amountPerStudent).toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {(stat.attendRate * 100).toFixed(1)}%
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {index !== statsGroup.length - 1 && (
                        <Button
                          style={{
                            width: "70%",
                            height: "40%",
                            fontSize: "16px",
                          }}
                          colorScheme="blue"
                          onClick={() => {
                            window.location.href = `/youthdb/teacher-team/${stat.teacherId}/${year}/${month}`;
                          }}
                        >
                          출석상세
                        </Button>
                      )}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {index !== statsGroup.length - 1 && (
                        <Button
                          style={{
                            width: "70%",
                            height: "40%",
                            fontSize: "16px",
                          }}
                          colorScheme="red"
                          onClick={() => {
                            window.location.href = `/youthdb/youth-student?teacherId=${stat.teacherId}`;
                          }}
                        >
                          회원 관리
                        </Button>
                      )}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                      pt={2}
                      pb={2}
                      px={1}
                    >
                      {index !== statsGroup.length - 1 ? (
                        <Button
                          style={{
                            width: "70%",
                            height: "40%",
                            fontSize: "16px",
                          }}
                          colorScheme="orange"
                          onClick={() => {
                            window.location.href = `/youthdb/consultmemo/teacher/${stat.teacherId}`;
                          }}
                        >
                          보고현황
                        </Button>
                      ) : (
                        <Button
                          style={{
                            width: "70%",
                            height: "40%",
                            fontSize: "16px",
                          }}
                          colorScheme="orange"
                          onClick={() => {
                            window.location.href = `/youthdb/consultmemo/teacher-all`;
                          }}
                        >
                          전체 보고현황
                        </Button>
                      )
                      }
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={15} textAlign={"center"} color="red">
                    데이터가 없습니다.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Box mt={10} display={"flex"} width="100%">
        <Select
          placeholder={"강사를 선택하세요..."}
          onChange={(e) => {
            setSelectedTeacher(e.target.value);
            setSelectedChart("amountToPayGraph");
          }}
          width={{ base: "150px", md: "150px" }}
          height={{ base: "40px" }}
          borderColor="tomato"
          fontSize={{ base: "16px" }}
          defaultValue={""}
          mr={2}
        >
          {teacherOption.map((teacher: any) => (
            <option key={teacher.value} value={teacher.value} selected={teacher.value === selectedTeacher}>
              {teacher.label}
            </option>
          ))}
        </Select>
        <Select
          width={{ base: "150px", md: "150px" }}
          height={{ base: "40px" }}
          borderColor="tomato"
          fontSize={{ base: "16px" }}
          value={selectedChart}
          onChange={handleGraphSwitch}
        >
          {graphOptions.map((option) => (
            <option key={option.value} value={option.value} selected={option.value === selectedChart}>
              {option.label}
            </option>
          ))}
        </Select>
      </Box>
      {loadingGraph ? (
        <div>Loading...</div>
      ) : errorGraph ? (
        <div>
          개인 통계를 보기 위해서는 조회하고자 하는 강사를 선택하여 주십시오.
        </div>
      ) : (
        <>
          <Center mt={4} width={"100%"}>
            <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
          </Center>
        </>
      )}
      </VStack>
    </Box>
  );
};

export default GroupMainScreen;
