import React from 'react';
import DOMPurify from 'dompurify';

interface HTMLRendererProps {
  htmlContent: string;
}

const HTMLRenderer: React.FC<HTMLRendererProps> = ({ htmlContent }) => {
  const createMarkup = (html: string) => {
    const cleanHtml = DOMPurify.sanitize(html);
    return { __html: cleanHtml };
  };

  return (
    <div dangerouslySetInnerHTML={createMarkup(htmlContent)} />
  );
};

export default HTMLRenderer;
