import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getYouthStudentDetail,
  updateYouthStudent,
} from "../../redux/actions/YouthStudentActions";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { YOUTHSTUDENT_DETAIL_RESET } from "../../redux/constants/YouthStudentConstants";
import accessCheck from "../../utils/accessCheck";

const StudentUpdateScreen = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const studentId = id ?? "";
  const dispatch: any = useDispatch();

  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [parentPhoneNum, setParentPhoneNum] = useState("");
  const [school, setSchool] = useState("");
  const [address, setAddress] = useState("");
  const [bankTags, setBankTags] = useState<string[]>([]);
  const [rawBankTagsInput, setRawBankTagsInput] = useState("");
  const [jerseyNum, setJerseyNum] = useState("");

  const formatPhoneNumber = (phoneNum: any) => {
    const cleaned = ("" + phoneNum).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNum;
  };

  const youthStudentDetail = useSelector(
    (state: any) => state.youthStudentDetail
  );
  const { loading, error, youthStudent } = youthStudentDetail;

  useEffect(() => {
    dispatch({ type: YOUTHSTUDENT_DETAIL_RESET });
    dispatch(getYouthStudentDetail(studentId));
  }, [dispatch, studentId]);

  useEffect(() => {
    setName(youthStudent && youthStudent.name);
    setBirthday(youthStudent && youthStudent.birthday && youthStudent.birthday.substring(0, 10));
    setPhoneNum(youthStudent && youthStudent.phoneNum);
    setParentPhoneNum(youthStudent && youthStudent.parentPhoneNum);
    setSchool(youthStudent && youthStudent.school);
    setAddress(youthStudent && youthStudent.address);
    setBankTags(youthStudent && youthStudent.bankTags);
    setJerseyNum(youthStudent && youthStudent.jerseyNum);
  }, [youthStudent]);

  useEffect(() => {
    setRawBankTagsInput(bankTags && bankTags.join(", "));
  }, [bankTags]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRawBankTagsInput(e.target.value);
  };

  const handleBirth = (e: any) => {
    const value = e.target.value.replace(/-/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;

        default:
          break;
      }
      result += value[i];
    }

    e.target.value = result;

    setBirthday(result);
  };

  const updateBankTags = () => {
    const tags = rawBankTagsInput
      ? rawBankTagsInput.split(",").map((tag) => tag.trim())
      : [];
    setBankTags(tags);
  };

  const submitHandler = (e: any) => {
    e.preventDefault();

    const isValidDate = (dateString: string) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(dateString)) {
        return false;
      }

      // 날짜 값을 분해합니다.
      const [year, month, day] = dateString.split('-').map(Number);

      // 월이 1과 12 사이인지 확인합니다.
      if (month < 1 || month > 12) {
        return false;
      }

      // 날짜가 1과 해당 월의 마지막 날 사이인지 확인합니다.
      const lastDayOfMonth = new Date(year, month, 0).getDate();
      if (day < 1 || day > lastDayOfMonth) {
        return false;
      }

      // 유효한 날짜인지 확인합니다.
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return false;
      }

      // 입력된 날짜와 생성된 날짜가 동일한지 확인합니다.
      // 이를 통해 2021-02-30과 같은 잘못된 날짜를 걸러냅니다.
      const formattedDate = date.toISOString().split('T')[0];
      return formattedDate === dateString;
    }

    if (!isValidDate(birthday)) {
      alert("생년월일이 유효하지 않습니다.");
      return;
    }

    const youthStudent = {
      _id: studentId,
      name,
      birthday,
      phoneNum,
      parentPhoneNum,
      school,
      address,
      bankTags,
      jerseyNum,
    };

    dispatch(updateYouthStudent(youthStudent));

    alert("수정되었습니다.");

    window.location.reload();
  };

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <Text>loading...</Text>
      ) : error ? (
        <Text>{error}</Text>
      ) : (
        <VStack textAlign="center" minW={"500px"} gap={3}>
          <Text fontSize={"3xl"} fontWeight={"bold"} mb={10}>
            회원 정보 수정
          </Text>
          <FormControl>
            <FormLabel>이름</FormLabel>
            {/* <Text textAlign={"left"}>{youthStudent && youthStudent.name}</Text> */}
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>생년월일</FormLabel>
            {/* <Text textAlign={"left"}>{youthStudent && youthStudent.birthday && youthStudent.birthday.substring(0, 10)}</Text> */}
            <Input
              type="text"
              value={birthday}
              onChange={handleBirth}
            />
          </FormControl>
          <FormControl>
            <FormLabel>소속 팀</FormLabel>
            <Box
              display="flex"
              alignItems="center"
              w={"100%"}
            >
              <Text textAlign={"left"} mr={3}>{youthStudent && youthStudent.youthTeamName}</Text>
              {accessCheck(["원장", "부원장", "조장"]) && (
                <Button
                  colorScheme="blue"
                  onClick={() => window.location.href = `/youthdb/youth-student/transfer/${youthStudent._id}?fromTeamId=${youthStudent.youthTeamId}`}
                  size="sm"
                  height={"20px"}
                >
                  팀 변경
                </Button>
              )}
            </Box>
          </FormControl>
          <FormControl>
            <FormLabel>등번호</FormLabel>
            <Input
              type="text"
              value={jerseyNum}
              onChange={(e) => setJerseyNum(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>지점</FormLabel>
            <Text textAlign={"left"}>안산점</Text>
          </FormControl>
          <FormControl id="phoneNum" isRequired>
            <FormLabel>회원 전화번호</FormLabel>
            <Input
              value={phoneNum}
              onChange={(e) => setPhoneNum(formatPhoneNumber(e.target.value))}
              placeholder="회원 전화번호를 입력하세요 : 하이픈은 자동 생성됩니다."
            />
          </FormControl>
          <FormControl id="parentPhoneNum" isRequired>
            <FormLabel>보호자 전화번호</FormLabel>
            <Input
              value={parentPhoneNum}
              onChange={(e) =>
                setParentPhoneNum(formatPhoneNumber(e.target.value))
              }
              placeholder="보호자 전화번호를 입력하세요 : 하이픈은 자동 생성됩니다."
            />
          </FormControl>
          <FormControl id="school" isRequired>
            <FormLabel>학교</FormLabel>
            <Input
              value={school}
              onChange={(e) => setSchool(e.target.value)}
              placeholder="회원의 학교를 입력하세요..."
            />
          </FormControl>
          <FormControl id="address" isRequired>
            <FormLabel>집 주소</FormLabel>
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="예) 경기도 OO시 OO구 OOOO길 OO, OOO아파트 101동 101호"
            />
          </FormControl>
          <FormControl id="bankTags" isRequired>
            <FormLabel>입금자명 태그</FormLabel>
            <Text textAlign={"left"}>
              반드시 쉼표(,)로 구분하세요 : 예) 신한은행, 홍길동
              <span style={{ color: "red", fontWeight: "bold" }}>
                , 홍길동부모
              </span>
              <br />
              태그 삭제시 콤마(,)를 포함해서 삭제하세요 : 예) 신한은행, 홍길동
            </Text>
            <Input
              value={rawBankTagsInput}
              onChange={handleInputChange}
              onBlur={updateBankTags}
              placeholder="예) 1, 2, 3"
            />
          </FormControl>
          <VStack>
            <Button
              mt={10}
              minW={"200px"}
              colorScheme="orange"
              onClick={submitHandler}
            >
              수정하기
            </Button>
          </VStack>
        </VStack>
      )}
    </Box>
  );
};

export default StudentUpdateScreen;
