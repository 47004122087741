import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Text,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  getBranchInfo,
  getTeacherStats,
  updateBranch,
} from "../../redux/actions/BranchActions";
import { findTeacher } from "../../redux/actions/UserAction";
import { MultiSelect, useMultiSelect } from "chakra-multiselect";
import Select from "react-select";
import axios from "axios";
import { PhoneIcon } from "@chakra-ui/icons";
import { API_SERVER_URL } from "../../redux/actions/actionUrl";
import accessCheck from "../../utils/accessCheck";

const EmployeeManageScreen = () => {
  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const userId = userInfo._id;

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );
  const [employeeId, setEmployeeId] = useState("");
  const [employeePhoneNum, setEmployeePhoneNum] = useState("");

  const [teacherGroupList, setTeacherGroupList] = useState<any>([]);
  const [teacherGroup2, setTeacherGroup2] = useState<any>([]);
  const [masterList, setMasterList] = useState<any>([]);

  const branchInfoList = useSelector((state: any) => state.branchInfoList);
  const { loading, error, branchInfo } = branchInfoList;

  const userFindTeacher = useSelector((state: any) => state.userFindTeacher);
  const {
    loading: loadingFindTeacher,
    error: errorFindTeacher,
    teacher,
  } = userFindTeacher;

  const branchUpdate = useSelector((state: any) => state.branchUpdate);
  const { loading: loadingUpdate, error: errorUpdate, branch } = branchUpdate;

  const branchId = branchInfo && branchInfo._id;

  const [getBranchInfoCompleted, setGetBranchInfoCompleted] = useState(false);

  interface Teacher {
    teacherId: number;
    teacherName: string;
  }

  // const teacherOptions = [];
  const teacherOptions: any = [];

  branchInfo &&
    branchInfo.teacherInfo &&
    branchInfo.teacherInfo.map((teacher: any) => {
      teacherOptions.push({
        value: teacher.teacherId,
        label: teacher.teacherName,
      });
    });

  useEffect(() => {
    if (branchInfo) {
      const tempMasterList: any = [];

      // branchInfo?.teacherInfo?.map((teacher: any) => {
      //   if (teacher.groupName === "조장") {
      //     tempMasterList.push({
      //       value: teacher.teacherId,
      //       label: teacher.teacherName,
      //     });
      //   }
      // });
      //to for loop
      for (let i = 0; i < branchInfo?.teacherInfo?.length; i++) {
        // if (branchInfo.teacherInfo[i].groupName === "조장") {
        if (["조장", "원장", "부원장"].includes(branchInfo.teacherInfo[i].groupName)) {
          tempMasterList.push({
            value: branchInfo.teacherInfo[i].teacherId,
            label: branchInfo.teacherInfo[i].teacherName,
          });
        }
      }

      setMasterList(tempMasterList);

      const newTeacherGroup2: any = [];

      for (let i = 0; i < tempMasterList.length; i++) {
        const existingGroup = branchInfo?.teacherGroup2?.find(
          (group: any) => group.masterId === tempMasterList[i].value
        );

        if (!existingGroup) {
          newTeacherGroup2.push({
            masterId: tempMasterList[i].value,
            groupTeacher: [],
          });
        } else {
          newTeacherGroup2.push(existingGroup);
        }
      }

      setTeacherGroup2(newTeacherGroup2);

      let tempTeacherGroupList: any = [];

      branchInfo?.teacherInfo?.map((teacher: any, index: number) => {
        tempTeacherGroupList.push({
          value: teacher.groupName,
          label: teacher.groupName,
        });
      });

      setTeacherGroupList(tempTeacherGroupList);
    }
  }, [branchInfo]);

  useEffect(() => {
    // if (!accessCheck(["원장", "부원장"])) {
    //   alert("접근 권한이 없습니다.");
    //   window.location.href = "/youthdb/my";
    // }
  }, []);

  useEffect(() => {
    dispatch(getBranchInfo(teacherInfo.branch._id)).then(() => {
      setGetBranchInfoCompleted(true);
    });
  }, [dispatch, userId]);

  const groupOption: { value: string; label: string }[] = [
    { value: "미지정", label: "미지정" },
    { value: "강사1", label: "강사1" },
    { value: "강사2", label: "강사2" },
    { value: "사원", label: "사원" },
    { value: "조장", label: "조장" },
    { value: "부원장", label: "부원장" },
    { value: "원장", label: "원장" },
  ];

  const accessGrantHandler = async (inputId: string, inputPhoneNum: string) => {
    if (inputId === "" || inputPhoneNum === "") {
      alert("직원id와 전화번호를 입력해주세요.");
    } else {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          `${API_SERVER_URL}/api/user/find/teacher?userId=${inputId}&phoneNum=${inputPhoneNum}`,
          config
        );

        const teacher = response.data;

        if (teacher) {
          if (
            window.confirm(
              `${teacher && teacher.name}님의 권한을 부여하시겠습니까?`
            )
          ) {
            dispatch(
              updateBranch({
                _id: teacherInfo.branch._id,
                teachers: [...branchInfo.teachers, teacher._id],
              })
            );

            window.location.reload();
          }
        } else {
          alert("해당 사용자가 존재하지 않습니다.");
        }
      } catch (error) {
        alert(`에러가 발생했습니다. ${error}`);
      }
    }
  };

  const deleteEmployeeHandler = (teacherId: string) => {
    const newTeacherList = branchInfo?.teachers?.filter(
      (teacher: any) => teacher !== teacherId
    );
    const newTeacherGroup = branchInfo?.teacherGroup?.map((group: any) => {
      const newGroupTeacher = group.groupTeacher.filter(
        (teacher: any) => teacher !== teacherId
      );
      return {
        ...group,
        groupTeacher: newGroupTeacher,
      };
    });
    const newChiefList = branchInfo?.chiefList?.filter(
      (chief: any) => chief !== teacherId
    );

    if (window.confirm("※ 주의 ※ 직원의 팀까지 삭제됩니다.\n해당 직원을 삭제하시겠습니까?")) {
      dispatch(
        updateBranch({
          _id: teacherInfo.branch._id,
          teachers: newTeacherList,
          teacherGroup: newTeacherGroup,
          chiefList: newChiefList,
        })
      );

      window.location.reload();
    }
  };

  const teacherGroupHandler = (e: any, index: number) => {
    let copy = [...teacherGroupList];
    if (e.value === "미지정") {
      copy[index] = { value: "미지정", label: "미지정" };
    } else {
      copy[index] = { value: e.value, label: e.label };
    }
    setTeacherGroupList(copy);
  };

  const teacherGroup2Handler = (e: any, index: number) => {
    let copy = [...teacherGroup2];
    if (e.length >= 1) {
      copy[index].groupTeacher = e.map((teacher: any) => teacher.value);

      setTeacherGroup2(copy);
    } else {
      copy[index].groupTeacher = [];
      setTeacherGroup2(copy);
    }
  };

  const submitHandler = (e: any) => {
    e.preventDefault();

    const newTeacherGroup = [
      {
        groupName: "원장",
        groupTeacher: [],
      },
      {
        groupName: "부원장",
        groupTeacher: [],
      },
      {
        groupName: "조장",
        groupTeacher: [],
      },
      {
        groupName: "강사1",
        groupTeacher: [],
      },
      {
        groupName: "강사2",
        groupTeacher: [],
      },
      {
        groupName: "사원",
        groupTeacher: [],
      },
    ];

    const newChief: string[] = [];

    for (let i = 0; i < branchInfo.teachers.length; i++) {
      const teacherId = branchInfo.teachers[i];
      const teacherGroup = teacherGroupList[i];
      const teacherGroupName = teacherGroup && teacherGroup.value;

      if (teacherGroupName !== "미지정") {
        newTeacherGroup.map((group: any) => {
          if (group.groupName === teacherGroupName) {
            group.groupTeacher.push(teacherId);
          }
          if (teacherGroupName === "원장" && !newChief.includes(teacherId)) {
            newChief.push(teacherId);
          }
        });
      }
    }

    // const masterList: any = newTeacherGroup.find(
    //   (group) => group.groupName === "조장"
    // );

    // let newTeacherGroup2 = teacherGroup2.filter((group: any) =>
    //   masterList.groupTeacher.includes(group.masterId)
    // );

    dispatch(
      updateBranch({
        _id: teacherInfo.branch._id,
        teachers: branchInfo.teachers,
        teacherGroup: newTeacherGroup,
        teacherGroup2: teacherGroup2,
        chief: newChief,
      })
    );
  };

  return (
    <Box mx={{ base: "4", md: "40" }}>
      {loading && loadingFindTeacher ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
        <VStack>
          <VStack mb={10}>
            <VStack>
              <Text
                textAlign={"center"}
                fontSize={{ base: "2xl", md: "4xl" }}
                fontWeight={"bold"}
              >
                직원 권한 부여
              </Text>
              <Text>본 지점에 강사의 권한을 지정합니다.</Text>
            </VStack>
            <HStack>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Text color={"gray.400"}>ID</Text>
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="직원 ID"
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <PhoneIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="전화번호 (하이픈 없이 입력)"
                  value={employeePhoneNum}
                  onChange={(e) => setEmployeePhoneNum(e.target.value)}
                />
              </InputGroup>
            </HStack>
            <Button
              colorScheme="orange"
              onClick={() => {
                accessGrantHandler(employeeId, employeePhoneNum);
              }}
            >
              권한 부여
            </Button>
          </VStack>
          <HStack>
            <Text
              textAlign={"center"}
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={"bold"}
              mb={10}
            >
              직원 관리
            </Text>
          </HStack>
          <TableContainer maxH="2000px" overflowX="auto">
            <Table variant="simple" border="2px solid" borderColor="gray.400">
              <Thead bg="gray.100">
                <Tr>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    직원 ID
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    직원 이름
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    직원 전화번호
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    직책
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    회원 관리
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    삭제
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {branchInfo &&
                  branchInfo.teacherInfo &&
                  branchInfo.teacherInfo.map((teacher: any, index: number) => (
                    <Tr>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                      >
                        {teacher.userId}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                      >
                        {teacher.teacherName}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                      >
                        {teacher.phoneNum.slice(0, 3) +
                          "-" +
                          teacher.phoneNum.slice(3, 7) +
                          "-" +
                          teacher.phoneNum.slice(7, 11)}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        paddingLeft="10px"
                        paddingRight="10px"
                        paddingTop="0px"
                        paddingBottom="0px"
                      >
                        <div
                          style={{
                            zIndex: 1000,
                          }}
                        >
                          <Select
                            id={`teacher${index}`}
                            options={groupOption}
                            onChange={(e) => teacherGroupHandler(e, index)}
                            defaultValue={
                              groupOption.find(
                                (option) => option.value === teacher.groupName
                              ) || { value: "미지정", label: "미지정" }
                            }
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </div>
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        paddingLeft="10px"
                        paddingRight="10px"
                        paddingTop="0px"
                        paddingBottom="0px"
                      >
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            window.location.href = `/youthdb/youth-student/${teacher.teacherId}`;
                          }}
                        >
                          관리
                        </Button>
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        paddingLeft="10px"
                        paddingRight="10px"
                        paddingTop="0px"
                        paddingBottom="0px"
                      >
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            deleteEmployeeHandler(teacher.teacherId);
                          }}
                        >
                          삭제
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <HStack>
            <Text
              textAlign={"center"}
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={"bold"}
              mb={10}
              marginTop={10}
            >
              직원 그룹화
            </Text>
          </HStack>
          <TableContainer>
            <Table variant="simple" border="2px solid" borderColor="gray.400">
              <Thead bg="gray.100">
                <Tr>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    조장
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    그룹원
                  </Th>
                </Tr>
              </Thead>
              <Tbody
                style={{
                  height: "400px",
                }}
              >
                {teacherGroup2.map((teacherGroup: any, index: number) => (
                  <Tr key={index}>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                    >
                      {
                        masterList.find(
                          (master: any) =>
                            master.value.toString() ===
                            teacherGroup.masterId.toString()
                        ).label
                      }
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                    >
                      <Select
                        isMulti
                        options={teacherOptions}
                        defaultValue={teacherOptions.filter((option: any) =>
                          teacherGroup.groupTeacher.includes(option.value)
                        )}
                        onChange={(e) => teacherGroup2Handler(e, index)}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <HStack>
            <Button mt={5} colorScheme="blue" onClick={submitHandler}>
              업데이트
            </Button>
          </HStack>
        </VStack>
      </>
    )}
    </Box>
  );
};

export default EmployeeManageScreen;
