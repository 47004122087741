import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { deleteEtcCategory, etcCategoryInput, getEtcCategoryList, getPaymentDetail } from '../../redux/actions/PaymentActions';
import { useSelector } from 'react-redux';

const EtcCategoryManageScreen: React.FC = () => {
  const dispatch: any = useDispatch();

  const searchParams = new URLSearchParams(useLocation().search);

  const urlYear = searchParams.get("year");
  const urlMonth = searchParams.get("month");

  const etcCategoryList = useSelector((state: any) => state.etcCategoryList);
  const { loading, error, etcCategories } = etcCategoryList;

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const [year, setYear] = useState<string>(urlYear || new Date().getFullYear().toString());
  const [month, setMonth] = useState<string>(urlMonth || (new Date().getMonth() + 1).toString());
  const [category, setCategory] = useState<string>('');

  useEffect(() => {
    dispatch(getEtcCategoryList(teacherInfo.branch._id, year.toString(), month.toString()));
  }, [dispatch, teacherInfo.branch._id, year, month]);

  const submitHandler = () => {
    dispatch(etcCategoryInput(teacherInfo.branch._id, year, month, category));
  }

  return (
    <>
      {loading ? (
        <Spinner />
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <Box maxW="md" mx="auto" mt={5} p={5}>
          <Center fontSize="2xl" mb={4}>
            {year}-{month} 비교육비 카테고리 목록
          </Center>
          <Center>
            <Box mt={2} mb={14} mx="auto" display="flex" alignItems="center" justifyContent="center" width="90%">
              <Table variant="simple" size="sm">
                <Tbody>
                  {etcCategories.map((c: any, index: number) => (
                    <Tr key={index}>
                      <Td>{c.category}</Td>
                      <Td>
                        <Button colorScheme="red" size="sm" onClick={() => dispatch(deleteEtcCategory(c._id))}>
                          삭제
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Center>
          <Center fontSize="2xl" mb={4}>
            비교육비 카테고리 등록
          </Center>
          <HStack spacing={4} mb={4}>
            <FormControl id="year">
              <FormLabel>연도</FormLabel>
              <Select value={year} onChange={(e) => setYear(e.target.value)}>
                {Array.from({ length: 3 }, (_, i) => new Date().getFullYear() + i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="month">
              <FormLabel>월</FormLabel>
              <Select value={month} onChange={(e) => setMonth(e.target.value)}>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          <FormControl id="category" mb={4}>
            <FormLabel>내용</FormLabel>
            <Input type="text" value={category} onChange={(e) => setCategory(e.target.value)} />
          </FormControl>
          <Button colorScheme="blue" onClick={submitHandler}>
            등록
          </Button>
        </Box>
      )}
    </>
  );
}
export default EtcCategoryManageScreen;