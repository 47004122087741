import {
  Box,
  Button,
  Center,
  Heading,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import LoginModal from "../../components/LoginModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import accessCustomize from "../../utils/accessCustomize";

export default function Home() {
  const { id } = useParams<{ id: string }>();
  const branchUrl = String(id);

  const dispatch: any = useDispatch();

  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen,
  } = useDisclosure();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const [isTeacher, setIsTeacher] = useState(false);

  useEffect(() => {
    if (
      teacherInfo &&
      teacherInfo.accessList &&
      teacherInfo.accessList.length > 0
    ) {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [localStorage.getItem("teacherInfo")]);

  return (
    <Box
      className="home__background__image"
      m={0}
      zIndex={1}
      bgImage={`url(${branchPage?.bgImage})`}
      width={"100%"}
      height={"95vh"}
      backgroundSize={"cover"}
      backgroundPosition="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Center>
        <Heading
          className="home__title__text"
          position={"absolute"}
          bottom={"50%"}
          fontSize={{
            base: "40",
            md: "70",
          }}
          fontWeight={{
            base: "900",
            md: "700",
          }}
          color={"white"}
          textAlign={"center"}
          textShadow="2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000"
        >
          {branchPage && branchPage.introduction}
        </Heading>
        {userInfo && userInfo._id ? (
          // <IconButton
          //   onClick={() => {
          //     window.location.href = "/mypage";
          //   }}
          //   aria-label="Toggle dark mode"
          //   icon={<FaUser />}
          //   position={"absolute"}
          //   bottom={"15%"}
          //   right={"10%"}
          //   w={{
          //     base: "60px",
          //     md: "80px",
          //   }}
          //   h={{
          //     base: "60px",
          //     md: "80px",
          //   }}
          //   fontSize={"36px"}
          //   bg={"orange"}
          //   colorScheme="white"
          // />
          <></>
        ) : (
          // <IconButton
          //   onClick={onLoginOpen}
          //   aria-label="Toggle dark mode"
          //   icon={<FaUser />}
          //   position={"absolute"}
          //   bottom={"15%"}
          //   right={"10%"}
          //   w={{
          //     base: "60px",
          //     md: "80px",
          //   }}
          //   h={{
          //     base: "60px",
          //     md: "80px",
          //   }}
          //   fontSize={"36px"}
          //   bg={"orange"}
          //   colorScheme="white"
          // />
          <></>
        )}
        {accessCustomize(teacherInfo, branchPage.branchId) && (
          <>
            <Button
              onClick={() => {
                window.location.href = `/${branchUrl}/page-customize`;
              }}
              aria-label="Toggle dark mode"
              position={"absolute"}
              bottom={"15%"}
              right={"10%"}
              w={{
                base: "60px",
                md: "80px",
              }}
              h={{
                base: "60px",
                md: "80px",
              }}
              fontSize={"18px"}
              bg={"black"}
              colorScheme="white"
            >
              홈페이지
              <br />
              설정
            </Button>
          </>
        )}
        {/* {userInfo && userInfo._id && (
          <>
            <Button
              onClick={() => {
                localStorage.removeItem("userInfo");
                localStorage.removeItem("teacherInfo");
                window.location.reload();
              }}
              aria-label="Toggle dark mode"
              position={"absolute"}
              bottom={"15%"}
              right={"5%"}
              w={{
                base: "60px",
                md: "80px",
              }}
              h={{
                base: "60px",
                md: "80px",
              }}
              fontSize={"18px"}
              bg={"red"}
              colorScheme="white"
            >
              로그아웃
            </Button>
          </>
        )} */}
      </Center>
      {/* <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} /> */}
    </Box>
  );
}
