import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { getBranchInfo } from "../../redux/actions/BranchActions";
import { addTransfer, getBranchYouthStudentList, getYouthStudentDetail } from "../../redux/actions/YouthStudentActions";
import axios from "axios";
import { API_SERVER_URL } from "../../redux/actions/actionUrl";
import accessCheck from "../../utils/accessCheck";

const TransferScreen = () => {
  interface Member {
    _id: string;
    name: string;
    birthday: string;
    parentPhoneNum: string;
    school: string;
    branchId: string;
    bankTags: string[];
    __v: number;
  }

  interface Team {
    teamId: string;
    teamName: string;
    members: Member[];
  }

  interface Teacher {
    teacherId: string;
    teacherName: string;
    teams: Team[];
  }

  const { id } = useParams<{ id: string }>();
  const studentId = id ?? "";

  const searchParams = new URLSearchParams(useLocation().search);

  const urlFromTeamId = searchParams.get("fromTeamId");

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const userId = userInfo._id;

  const youthStudentDetail = useSelector((state: any) => state.youthStudentDetail);
  const { loading, error, youthStudent } = youthStudentDetail;

  const branchYouthStudentList = useSelector((state: any) => state.branchYouthStudentList);
  const {
    loading: loadingBranchYouthStudent,
    error: errorBranchYouthStudent,
    branchYouthStudents
  } = branchYouthStudentList;

  const [selectedTeacher, setSelectedTeacher] = useState<any>(null);
  const [selectedTeams, setSelectedTeams] = useState<{ value: string; label: string }[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const [teacherOptions, setTeacherOptions] = useState<{ value: string; label: string }[]>([]);

  const [loadingTransfer, setLoadingTransfer] = useState<boolean>(false);

  useEffect(() => {
    // if (!accessCheck(["원장", "부원장", "조장"])) {
    //   alert("접근 권한이 없습니다.");
    //   window.location.href = "/youthdb/my";
    // }
  }, []);


  useEffect(() => {
    if (branchYouthStudents && branchYouthStudents.length > 0) {
      const tempTeacherOptions: { value: string; label: string }[] = [];
      const tempTeams: { value: string; label: string }[] = [];

      branchYouthStudents.map((teacher: Teacher) => {
        tempTeacherOptions.push({
          value: teacher.teacherId,
          label: teacher.teacherName,
        });
      });

      branchYouthStudents[0].teams.map((team: Team) => {
        tempTeams.push({
          value: team.teamId,
          label: team.teamName,
        });
      });

      setTeacherOptions(tempTeacherOptions);
      setSelectedTeams(tempTeams);
      if (tempTeams.length > 0) { setSelectedTeam(tempTeams[0].value); }
      setSelectedTeacher(tempTeacherOptions[0].value);
    }
  }, [branchYouthStudents]);


  useEffect(() => {
    dispatch(getBranchInfo(teacherInfo.branch._id));
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(getYouthStudentDetail(studentId));
    dispatch(getBranchYouthStudentList(teacherInfo.branch._id));
  }, [dispatch, studentId, teacherInfo.branch._id]);

  const transferHandler = async () => {
    if (!selectedTeam) {
      alert("팀을 선택해주세요.");
      return;
    }

    // dispatch(addTransfer(teacherInfo.branch._id, urlFromTeamId || "", selectedTeam, studentId))
    try {
      setLoadingTransfer(true);
      const response = await axios.put(
        `${API_SERVER_URL}/api/transfer/execute/immediate`,
        {
          branchId: teacherInfo.branch._id,
          fromTeamId: urlFromTeamId || "",
          toTeamId: selectedTeam,
          studentId: studentId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLoadingTransfer(false);
      alert("이동되었습니다.");

      window.location.reload();
    } catch (error: any) {
      setLoadingTransfer(false);
      alert(`이동에 실패했습니다. ${error.message}`);
    }
  }

  const teacherChangeHandler = (value: string) => {
    setSelectedTeacher(value);

    const newSelectedTeams: { value: string; label: string }[] = [];

    branchYouthStudents?.map((teacher: Teacher) => {
      if (teacher.teacherId === value) {
        teacher.teams.map((team: Team) => {
          newSelectedTeams.push({
            value: team.teamId,
            label: team.teamName,
          });
        });
      }
    });

    setSelectedTeams(newSelectedTeams);
    setSelectedTeam(newSelectedTeams.length > 0 ? newSelectedTeams[0].value : "");
  };

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <Box
          mt={10}
          mx={6}
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <VStack>
            <HStack>
              <Text fontWeight="bold" fontSize="2xl">
                {youthStudent.name}
              </Text>
              <Text>
                회원을 다른 강사의 팀으로 이동합니다.
              </Text>
            </HStack>
            {/* <Text
              fontSize="sm"
              color="gray.500"
            >
              요청받은 강사가 수락하면 이동이 완료됩니다.
            </Text> */}
            <Select
              onChange={(e) => teacherChangeHandler(e.target.value)}
            >
              {teacherOptions.map((teacher) => (
                <option key={teacher.value} value={teacher.value} selected={teacher.value === selectedTeacher}>
                  {teacher.label}
                </option>
              ))}
            </Select>
            <Select
              onChange={(e) => setSelectedTeam(e.target.value)}
            >
              {selectedTeams.map((team) => (
                <option key={team.value} value={team.value}>
                  {team.label}
                </option>
              ))}
            </Select>
            <Button colorScheme="red" w={50} onClick={transferHandler}>
              팀 이동
            </Button>
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default TransferScreen;
