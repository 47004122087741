import axios from "axios";
import {
  GET_DAY_MEMO_LIST_REQUEST,
  GET_DAY_MEMO_LIST_SUCCESS,
  GET_DAY_MEMO_LIST_FAIL,
  GET_DAY_MEMO_LIST_RESET,
  UPDATE_DAY_MEMO_LIST_REQUEST,
  UPDATE_DAY_MEMO_LIST_SUCCESS,
  UPDATE_DAY_MEMO_LIST_FAIL,
  CONSULT_LIST_REQUEST,
  CONSULT_LIST_SUCCESS,
  CONSULT_LIST_FAIL,
  CONSULT_LIST_RESET,
  UPDATE_DAY_CONSULT_LIST_REQUEST,
  UPDATE_DAY_CONSULT_LIST_SUCCESS,
  UPDATE_DAY_CONSULT_LIST_FAIL,
  CONSULT_INPUT_REQUEST,
  CONSULT_INPUT_SUCCESS,
  CONSULT_INPUT_FAIL,
  CONSULT_INPUT_RESET,
  CONSULT_LIST_TEACHER_REQUEST,
  CONSULT_LIST_TEACHER_SUCCESS,
  CONSULT_LIST_TEACHER_FAIL,
  CONSULT_LIST_TEACHER_RESET,
  CONSULT_LIST_TEACHER_ALL_REQUEST,
  CONSULT_LIST_TEACHER_ALL_SUCCESS,
  CONSULT_LIST_TEACHER_ALL_FAIL,
  CONSULT_LIST_TEACHER_ALL_RESET,
  CONSULT_DETAIL_REQUEST,
  CONSULT_DETAIL_SUCCESS,
  CONSULT_DETAIL_FAIL,
  CONSULT_DETAIL_RESET,
  CONSULT_UPDATE_REQUEST,
  CONSULT_UPDATE_SUCCESS,
  CONSULT_UPDATE_FAIL,
  CONSULT_UPDATE_RESET,
} from "../constants/MemoConstants";

export const getDayMemoListReducer = (
  state = { memos: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_DAY_MEMO_LIST_REQUEST:
      return { loading: true };
    case GET_DAY_MEMO_LIST_SUCCESS:
      return { loading: false, memos: action.payload };
    case GET_DAY_MEMO_LIST_FAIL:
      return { loading: false, error: action.payload };
    case GET_DAY_MEMO_LIST_RESET:
      return { payload: [] };
    default:
      return state;
  }
};

export const getConsultListReducer = (
  state = { consults: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CONSULT_LIST_REQUEST:
      return { loading: true };
    case CONSULT_LIST_SUCCESS:
      return { loading: false, consults: action.payload };
    case CONSULT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CONSULT_LIST_RESET:
      return { payload: [] };
    default:
      return state;
  }
};

export const updateDayMemoListReducer = (
  state = { memos: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_DAY_MEMO_LIST_REQUEST:
      return { loading: true };
    case UPDATE_DAY_MEMO_LIST_SUCCESS:
      return { loading: false, memos: action.payload };
    case UPDATE_DAY_MEMO_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateDayConsultListReducer = (
  state = { consults: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_DAY_CONSULT_LIST_REQUEST:
      return { loading: true };
    case UPDATE_DAY_CONSULT_LIST_SUCCESS:
      return { loading: false, consults: action.payload };
    case UPDATE_DAY_CONSULT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const consultInputReducer = (
  state = { consult: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CONSULT_INPUT_REQUEST:
      return { loading: true };
    case CONSULT_INPUT_SUCCESS:
      return { loading: false, consult: action.payload };
    case CONSULT_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case CONSULT_INPUT_RESET:
      return { consult: {} };
    default:
      return state;
  }
};

export const getConsultListByTeacherReducer = (
  state = { consultsTeacher: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CONSULT_LIST_TEACHER_REQUEST:
      return { loading: true };
    case CONSULT_LIST_TEACHER_SUCCESS:
      return { loading: false, consultsTeacher: action.payload };
    case CONSULT_LIST_TEACHER_FAIL:
      return { loading: false, error: action.payload };
    case CONSULT_LIST_TEACHER_RESET:
      return { consultsTeacher: [] };
    default:
      return state;
  }
};

export const getConsultListByTeacherAllReducer = (
  state = { consultsTeacherAll: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CONSULT_LIST_TEACHER_ALL_REQUEST:
      return { loading: true };
    case CONSULT_LIST_TEACHER_ALL_SUCCESS:
      return { loading: false, consultsTeacherAll: action.payload };
    case CONSULT_LIST_TEACHER_ALL_FAIL:
      return { loading: false, error: action.payload };
    case CONSULT_LIST_TEACHER_ALL_RESET:
      return { consultsTeacherAll: [] };
    default:
      return state;
  }
};

export const getConsultDetailReducer = (
  state = { consult: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CONSULT_DETAIL_REQUEST:
      return { loading: true };
    case CONSULT_DETAIL_SUCCESS:
      return { loading: false, consult: action.payload };
    case CONSULT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case CONSULT_DETAIL_RESET:
      return { consult: {} };
    default:
      return state;
  }
};

export const updateConsultReducer = (
  state = { consult: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CONSULT_UPDATE_REQUEST:
      return { loading: true };
    case CONSULT_UPDATE_SUCCESS:
      return { loading: false, consult: action.payload };
    case CONSULT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CONSULT_UPDATE_RESET:
      return { consult: {} };
    default:
      return state;
  }
};