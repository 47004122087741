import {
  Box,
  Button,
  Center,
  Flex,
  Select,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getYouthTeamDetail,
  getYouthTeamList,
} from "../../redux/actions/YouthTeamActions";
import Datepicker from "../../components/Datepicker";

import { addAttend, getAttendTable, listSupplement } from "../../redux/actions/AttendActions";
import { useParams } from "react-router-dom";
import formatPhoneNum from "../../utils/formatPhoneNum";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

interface AttendanceData {
  date: string;
  attendance: AttendanceItem[];
}

interface AttendanceItem {
  studentId: string;
  studentName: string;
  studentPhone: string;
  status:
  | "출석"
  | "결석"
  | "휴강"
  | "탈퇴"
  | "신규"
  | `신규${string}`
  | "복귀"
  | `복귀${string}`
  | "-";
}

const attendTableInit: {
  loading: boolean;
  error: null;
  attendTable: AttendanceData[];
} = {
  loading: false,
  error: null,
  attendTable: [
    {
      date: new Date().toISOString().split("T")[0],
      attendance: [],
    },
  ],
};

const AttendNewScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const teamId = String(id);
  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const teacherId = userInfo._id;

  const youthTeamList = useSelector((state: any) => state.youthTeamList);
  const { loading, error, youthTeams } = youthTeamList;

  const youthTeamDetail = useSelector((state: any) => state.youthTeamDetail);
  const { youthTeam } = youthTeamDetail;

  const attendTableList = useSelector((state: any) => state.attendTableList);
  const { attendTable } = attendTableList;

  const supplementList = useSelector((state: any) => state.supplementList);
  const { supplements } = supplementList;

  const [date, setDate] = useState<Date | undefined>(new Date());

  const [year, setYear] = useState(new Date().getFullYear());
  const [startMonth, setStartMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );
  const [endMonth, setEndMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );
  const [attendanceData, setAttendanceData] = useState<AttendanceData[]>([]);
  const [attendTableArray, setAttendTableArray] =
    useState<any>(attendTableInit);
  const [studentIds, setStudentIds] = useState<string[]>([]);
  const [youthTeamId, setYouthTeamId] = useState(teamId);

  useEffect(() => {
    dispatch({ type: "YOUTHTEAM_LIST_RESET" });
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, true));
  }, [dispatch, teacherId]);

  useEffect(() => {
    dispatch(getAttendTable(year, startMonth, endMonth, youthTeamId));
  }, [dispatch, year, startMonth, endMonth, youthTeamId]);

  useEffect(() => {
    dispatch(getYouthTeamDetail(youthTeamId));
  }, [dispatch, youthTeamId]);

  const handleDateChange = (newDate: Date | null) => {
    if (newDate === null) {
      setDate(undefined);
    } else {
      setDate(newDate);
      dispatch(getAttendTable(year, startMonth, endMonth, youthTeamId));
    }
  };

  useEffect(() => {
    setYouthTeamId(teamId);
  }, [teamId]);

  useEffect(() => {
    if (attendTableArray.attendTable.length > 0) {
      let tempStudentIds = attendTableArray.attendTable.flatMap((item: any) =>
        item.attendance.map((attendance: any) => attendance.studentId)
      );
      setStudentIds(tempStudentIds);
    }
  }, [attendTableArray]);

  useEffect(() => {
    dispatch(listSupplement(false, teamId, null));
  }, [dispatch, teamId]);

  useEffect(() => {
    if (youthTeam) {
      if (youthTeam.members && youthTeam.members.length > 0) {
        let attendanceArray: any = [];
        for (let i = 0; i < youthTeam.members.length; i++) {
          if (
            youthTeam.memberInfo[i] &&
            youthTeam.memberInfo[i].isRest === true
          ) {
            attendanceArray.push({
              studentId: youthTeam.memberInfo[i].memberId,
              studentName: youthTeam.memberInfo[i].memberName,
              studentPhone: youthTeam.memberInfo[i].parentPhoneNum && formatPhoneNum(youthTeam.memberInfo[i].parentPhoneNum),
              status: "휴강",
            });
          } else {
            attendanceArray.push({
              studentId: youthTeam.memberInfo[i].memberId,
              studentName:
                youthTeam &&
                youthTeam.memberInfo[i] &&
                youthTeam.memberInfo[i].memberName,
              studentPhone:
                youthTeam &&
                youthTeam.memberInfo[i] &&
                youthTeam.memberInfo[i].parentPhoneNum && formatPhoneNum(youthTeam.memberInfo[i].parentPhoneNum),
              status: "-",
            });
          }
        }
        let attendance: {
          loading: boolean;
          error: null;
          attendTable: AttendanceData[];
        } = {
          loading: false,
          error: null,
          attendTable: [
            {
              date: new Date().toISOString().split("T")[0],
              attendance: attendanceArray,
            },
          ],
        };

        setAttendTableArray(attendance);
      } else {
        let attendance: {
          loading: boolean;
          error: null;
          attendTable: AttendanceData[];
        } = {
          loading: false,
          error: null,
          attendTable: [
            {
              date: new Date().toISOString().split("T")[0],
              attendance: [],
            },
          ],
        };

        setAttendTableArray(attendance);
      }
    }
  }, [youthTeam]);

  const handleTeamChange = (e: any) => {
    window.location.href = `/youthdb/attend/${e.target.value}/new`;
  };

  const submitAttendHandler = (selectedDate: Date | undefined) => {
    const sixStringToBirthday = (birthDateStr: string) => {
      if (birthDateStr.length !== 6) {
        alert("생년월일은 6자리로 입력해주세요.");
      }

      const yearStr = birthDateStr.slice(0, 2);
      const monthStr = birthDateStr.slice(2, 4);
      const dayStr = birthDateStr.slice(4, 6);

      // 현재 연도와 비교하여 세기를 결정
      const currentYear = new Date().getFullYear();
      const currentCentury = Math.floor(currentYear / 100) * 100;
      const year = parseInt(yearStr, 10) + currentCentury;

      // 만약 현재 연도보다 큰 경우 이전 세기로 간주
      const fullYear = year > currentYear ? year - 100 : year;

      const month = parseInt(monthStr, 10) - 1; // 월은 0부터 시작
      const day = parseInt(dayStr, 10);

      return new Date(fullYear, month, day);
    }

    const transformedData = attendanceData.flatMap((item) =>
      item.attendance.map((attendance) => {
        return {
          studentId: attendance.studentId,
          status: attendance.status,
          note: "",
          isImportant: false,
        };
      })
    );

    const newStudentList = [];

    for (let i = 0; i < newStudentComponent.length; i++) {
      let newStudentName = (
        document.getElementById(`newStudentName${i}`) as HTMLInputElement
      ).value;

      let newStudentBirthdayString = (
        document.getElementById(`newStudentBirthday${i}`) as HTMLInputElement
      ).value;

      let newStudentBirthday = sixStringToBirthday(newStudentBirthdayString);

      let newStudentParentPhoneNum = (
        document.getElementById(`newStudentPhone${i}`) as HTMLInputElement
      ).value;

      if (newStudentName) {
        newStudentList.push({ newStudentName, newStudentBirthday, newStudentParentPhoneNum });
      }
    }

    const filteredData = transformedData.filter((item) => item.status !== "-");

    for (let i = 0; i < filteredData.length; i++) {
      const note = (
        document.getElementById(`note${filteredData[i].studentId}`) as HTMLInputElement
      ).value;
      filteredData[i].note = note;

      const isImportant = (
        document.getElementById(`isImportant${filteredData[i].studentId}`) as HTMLInputElement
      ).checked;
      filteredData[i].isImportant = isImportant;
    }

    const supplementDoneArray = [];
    if (supplements) {
      for (let i = 0; i < supplements.length; i++) {
        const isDone = (document.getElementById(`isDone${supplements[i]._id}`) as HTMLInputElement).checked;

        if (isDone) {
          supplementDoneArray.push(supplements[i]._id);
        }
      }
    }

    dispatch(
      addAttend(
        selectedDate?.toISOString().split("T")[0],
        youthTeamId,
        filteredData,
        newStudentList,
        teacherInfo.branch._id,
        supplementDoneArray
      )
    );
  };

  const teamOption: { value: string; label: string }[] = [];

  youthTeams?.youthTeam?.map((team: any) => {
    teamOption.push({ value: team._id, label: team.name });
  });

  useEffect(() => {
    if (attendTableArray) {
      setAttendanceData([...attendTableArray.attendTable]);
    }
  }, [attendTableArray]);

  const handleQuitClick = (studentId: string) => {
    setAttendanceData((prevData) =>
      prevData.map((item) => {
        return {
          ...item,
          attendance: item.attendance.map((attendance) => {
            if (attendance.studentId === studentId) {
              // 이미 탈퇴 상태인 경우 출석으로 변경
              const newStatus = attendance.status === "탈퇴" ? "출석" : "탈퇴";
              return {
                ...attendance,
                status: newStatus,
              };
            }
            return attendance;
          }),
        };
      })
    );
  };

  const dateFormatSupplement = (date: string) => {
    if (!date) return "";
    return (
      <>
        {format(new Date(date), "yyyy-MM-dd", { locale: ko })} <br />
        ({format(new Date(date), "eeee", { locale: ko })})
      </>
    );
  }

  const handleRestClick = (studentId: string) => {
    // if (alreadyRest) {
    //   return;
    // }
    setAttendanceData((prevData) =>
      prevData.map((item) => {
        return {
          ...item,
          attendance: item.attendance.map((attendance) => {
            if (attendance.studentId === studentId) {
              // 이미 휴강 상태인 경우 출석으로 변경
              const newStatus = attendance.status === "휴강" ? "출석" : "휴강";
              return {
                ...attendance,
                status: newStatus,
              };
            }
            return attendance;
          }),
        };
      })
    );
  };

  const handleCellClick = (date: string, studentId: string) => {
    setAttendanceData((prevData) =>
      prevData.map((item) => {
        if (item.date === date) {
          return {
            ...item,
            attendance: item.attendance.map((attendance) => {
              if (attendance.studentId === studentId) {
                let status: "-" | "출석" | "결석" | "휴강" | "신규" | "복귀";
                switch (attendance.status) {
                  case "-":
                    status = "출석";
                    break;
                  case "출석":
                    status = "결석";
                    break;
                  case "결석":
                    status = "출석";
                    break;
                  case "휴강":
                    status = "복귀";
                    break;
                  case "복귀":
                    status = "복귀";
                    break;
                  default:
                    status = "-";
                }
                return {
                  ...attendance,
                  status,
                };
              }
              return attendance;
            }),
          };
        }
        return item;
      })
    );
  };

  interface TableProps {
    data: AttendanceData[];
    onCellClick: (date: string, studentId: string) => void;
  }

  const styles = {
    headerCell: {
      backgroundColor: "#e0e0e0",
      padding: "10px",
      border: "1px solid #ccc",
    },
    nameCell: {
      backgroundColor: "#f0f0f0",
      padding: "10px",
      border: "1px solid #ccc",
    },
    dataCell: {
      padding: "10px",
      border: "1px solid #ccc",
    },
  };

  const studentHideHandler = (studentId: string) => {
    const confirm = window.confirm("해당 회원을 출석부에서 숨기겠습니까?");

    if (!confirm) {
      return;
    }

    setAttendanceData((prevData) =>
      prevData.map((item) => {
        return {
          ...item,
          attendance: item.attendance.map((attendance) => {
            if (attendance.studentId === studentId) {
              return {
                ...attendance,
                status: "-",
              };
            }
            return attendance;
          }),
        };
      })
    );
  };

  let newStudentName: string;

  const dateFormat = (date: Date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}/${day}`;
  };

  const newStudentRow = (index: number) => {
    return (
      <>
        <Box mb={4}>
          <Tr key={index}>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontWeight={"bold"}
              display="block"
            >
              신규회원
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              display="block"
            >
              <Flex direction="row" alignItems="left" justifyContent="space-between" w="100%">
                <Text fontSize={15} w="40%">회원명</Text>
                <input
                  style={{
                    padding: "3px",
                    flex: 1,
                    border: "1px solid #ccc",
                    marginLeft: "10px",
                    width: "60%"
                  }}
                  type="text"
                  id={`newStudentName${index}`}
                  value={newStudentName}
                />
              </Flex>
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              padding={4}
              display="block"
            >
              <Flex direction="row" alignItems="left" justifyContent="space-between" w="100%">
                <Text fontSize={15} w="40%">생년월일(6자리)</Text>
                <input
                  style={{
                    padding: "3px",
                    flex: 1,
                    border: "1px solid #ccc",
                    marginLeft: "10px",
                    width: "60%"
                  }}
                  type="text"
                  id={`newStudentBirthday${index}`}
                />
              </Flex>
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              padding={4}
              display="block"
            >
              <Flex direction="row" alignItems="left" justifyContent="space-between" w="100%">
                <Text fontSize={15} w="40%">보호자 연락처(하이픈X)</Text>
                <input
                  style={{
                    padding: "3px",
                    flex: 1,
                    border: "1px solid #ccc",
                    marginLeft: "10px",
                    width: "60%"
                  }}
                  type="text"
                  id={`newStudentPhone${index}`}
                />
              </Flex>
            </Td>
            {/* <Td
            cursor="pointer"
            textAlign="center"
            borderWidth="1px"
            color={"red"}
            borderColor="gray.400"
            display="block"
          >
            {`신규 (${dateFormat(date as Date)})`}
          </Td> */}
          </Tr>
        </Box>
      </>
    );
  };

  const [newStudentComponent, setNewStudentComponent] = useState<JSX.Element[]>(
    []
  );

  const addNewStudentRow = () => {
    const newStudent = newStudentRow(newStudentComponent.length);
    setNewStudentComponent((prev) => [...prev, newStudent]);
  };

  const attendanceFormat = (status: string) => {
    if (status === "출석") {
      return <>⭕️</>;
    } else if (status === "결석") {
      return <>❌</>;
    } else {
      return <>{status}</>;
    }
  };

  return (
    <Box
      mx={{ base: "4", md: "40" }}
      pb={{ base: "40", md: "0" }}
      justifyContent="center"
      overflow="auto"
    >
      <Center>
        <Text fontSize="4xl" as="b" mb={4}>
          출석부 작성하기
        </Text>
      </Center>
      <Center
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
      >
        <VStack mx={4} mb={2}>
          <Text mx={4}>반 선택</Text>
          <Select
            placeholder={"반을 선택하세요..."}
            onChange={handleTeamChange}
            width={{ base: "200px" }}
            height={{ base: "40px" }}
            borderColor="tomato"
            fontSize={{ base: "16px" }}
            defaultValue={""}
          >
            {teamOption.map((item: any) => (
              <option
                key={item.value}
                value={item.value}
                selected={item.value === teamId}
              >
                {item.label}
              </option>
            ))}
          </Select>
        </VStack>
        <VStack mx={4} mb={2}>
          <Datepicker selectedDate={date} onDateChange={handleDateChange} />
        </VStack>
      </Center>
      <Center>
        <Flex>
          <VStack>
            <Flex w={"100%"}>
              <Box textAlign={"left"} w={"60%"}>
                <Text textAlign="left" mt={4} fontSize={"12px"}>
                  조작 방법 : 출결(터치) | 휴강,탈퇴(체크)
                </Text>
              </Box>
              <Box textAlign={"right"} w={"40%"}>
                <Button
                  textAlign={"right"}
                  mt={4}
                  colorScheme="orange"
                  style={{ height: "20px" }}
                  fontSize={"12px"}
                  onClick={() => {
                    window.location.href = `/youthdb/attend/${youthTeamId}`;
                  }}
                >
                  이 달의 출석률 :{" "}
                  {attendTable?.totalPresent !== 0 ? (
                    (
                      (attendTable?.totalPresent /
                        (attendTable?.totalPresent +
                          attendTable?.totalAbsent)) *
                      100
                    ).toFixed(1)
                  ) : (
                    " - "
                  )}
                  %
                </Button>
              </Box>
            </Flex>
            <TableContainer
              maxH="2000px"
              maxW={{ base: "300px", sm: "500px", md: "700px", lg: "1500px" }}
              minW={{ base: "300px", md: "400px" }}
              overflowX="auto"
            >
              <Table variant="simple" width="100%">
                <Thead bg="gray.100">
                  <Tr>
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      padding={1}
                    >
                      이름
                    </Th>
                    {attendTableArray.attendTable.map((item: any) => (
                      <Th
                        key={item.date}
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        padding={1}
                      >
                        상태
                      </Th>
                    ))}
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      padding={4}
                    >
                      보호자 연락처
                    </Th>
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      padding={4}
                    >
                      휴강
                    </Th>
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      padding={4}
                    >
                      탈퇴
                    </Th>
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      padding={4}
                      minW={{ base: "200px", md: "200px" }}
                    >
                      메모
                    </Th>
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      padding={4}
                    >
                      중요
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {studentIds.map((studentId, index) => {
                    const studentName = attendTableArray.attendTable[0].attendance.find(
                      (attendance: any) => attendance.studentId === studentId
                    )?.studentName;
                    const studentPhone = attendTableArray.attendTable[0].attendance.find(
                      (attendance: any) => attendance.studentId === studentId
                    )?.studentPhone;
                    return (
                      <Tr key={studentId}>
                        <Td
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          fontWeight={"bold"}
                          padding={1}
                        >
                          <Button
                            style={{
                              backgroundColor: "white",
                              height: "100%",
                            }}
                            padding={1}
                            onClick={() => studentHideHandler(studentId)}
                          >
                            {studentName}
                          </Button>
                        </Td>
                        {attendanceData.map((item, index) => {
                          const attendanceItem = item.attendance.find(
                            (attendance) => attendance.studentId === studentId
                          );
                          return (
                            <Td
                              key={item.date + studentId}
                              onClick={() => handleCellClick(item.date, studentId)}
                              cursor="pointer"
                              textAlign="center"
                              borderWidth="1px"
                              borderColor="gray.400"
                              padding={2}
                              style={{
                                color:
                                  attendanceItem &&
                                  (attendanceItem.status === "출석"
                                    ? "black"
                                    : attendanceItem.status === "복귀"
                                      ? "green"
                                      : attendanceItem.status === "휴강"
                                        ? "blue"
                                        : "#BDBDBD"),
                              }}
                            >
                              {attendanceItem ? attendanceFormat(attendanceItem.status) : "-"}
                            </Td>
                          );
                        })}
                        <Td
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          padding={2}
                        >
                          <a href={`tel:${studentPhone}`}>{studentPhone}</a>
                        </Td>
                        <Td
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          padding={2}
                        >
                          <input
                            type="checkbox"
                            name="isQuit"
                            id={`isQuit${studentId}`}
                            onChange={() => handleRestClick(studentId)}
                            checked={
                              attendanceData[0].attendance.find(
                                (attendance: any) =>
                                  attendance.studentId === studentId
                              )?.status === "휴강"
                            }
                          />
                        </Td>
                        <Td
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          padding={2}
                        >
                          <input
                            type="checkbox"
                            name="isQuit"
                            id={`isQuit${studentId}`}
                            onChange={() => handleQuitClick(studentId)}
                            checked={
                              attendanceData[0].attendance.find(
                                (attendance: any) =>
                                  attendance.studentId === studentId
                              )?.status === "탈퇴"
                            }
                          />
                        </Td>
                        <Td
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          padding={2}
                        >
                          <input
                            key={studentId}
                            style={{
                              width: "100%",
                              padding: "10px",
                              border: "1px solid #ccc",
                            }}
                            type="text"
                            id={`note${studentId}`}
                          />
                        </Td>
                        <Td
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          padding={2}
                        >
                          <input
                            key={studentId}
                            style={{
                              width: "100%",
                              padding: "10px",
                              border: "1px solid #ccc",
                            }}
                            type="checkbox"
                            id={`isImportant${studentId}`}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Box textAlign={"left"}>
              {youthTeamId && (
                <Button size="lg" onClick={() => addNewStudentRow()}>
                  신규 회원 추가
                </Button>
              )}
            </Box>
          </VStack>
        </Flex>
      </Center>
      <Center mt={8}>
        <Table
          variant="simple"
          mt={6}
          width={1000}
          maxW={{ base: "300px", md: "400px", lg: "400px" }}
        >
          <Tbody>{newStudentComponent}</Tbody>
        </Table>
      </Center>
      <Center mt={8}>
        <TableContainer
          maxH="2000px"
          maxW={{ base: "300px", sm: "500px", md: "700px", lg: "1500px" }}
          minW={{ base: "300px", md: "400px" }}
          overflowX="auto"
        >
          {supplements && supplements.length > 0 && (
            <Table
              variant="simple"
              mt={6}
              width={1000}
              maxW={{ base: "300px", md: "400px", lg: "400px" }}
            >
              <Thead>
                <Tr>
                  <Th>팀명</Th>
                  <Th>회원명</Th>
                  <Th>결석일</Th>
                  <Th>보강완료</Th>
                </Tr>
              </Thead>
              <Tbody>
                {supplements.map((supplement: any) => (
                  <Tr key={supplement._id}>
                    <Td>{supplement.fromTeamName}</Td>
                    <Td>{supplement.studentName}</Td>
                    <Td>{dateFormatSupplement(supplement.absentDate)}</Td>
                    <Td>
                      <input
                        type="checkbox"
                        name="isDone"
                        id={`isDone${supplement._id}`}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Center>
      <Center mt={8}>
        <Button
          colorScheme="orange"
          size="lg"
          onClick={() => submitAttendHandler(date)}
        >
          저장하기
        </Button>
      </Center>
    </Box>
  );
};

export default AttendNewScreen;