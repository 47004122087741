import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getConsultListByTeacherAll,
} from "../../redux/actions/MemoActions";
import {
  Box,
  Center,
  Checkbox,
  Divider,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

const ConsultTeacherAllScreen = () => {
  const dispatch: any = useDispatch();

  const consultByTeacherAll = useSelector((state: any) => state.consultByTeacherAll);
  const { loading, error, consultsTeacherAll } = consultByTeacherAll;

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const dateWithDayOfWeek = (date: Date) => {
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}(${days[date.getDay()]})`;
  }

  const [selectedConsults, setSelectedConsults] = useState<any>([]);

  const sortOptions = [
    { value: "전체", label: "전체" },
    { value: "이슈", label: "이슈" },
    { value: "휴강", label: "휴강" },
    { value: "탈퇴", label: "탈퇴" },
    { value: "상담일지", label: "상담일지" }
  ];

  const consultChangeHandler = (value: string) => {
    if (value === "전체") {
      setSelectedConsults(consultsTeacherAll);
    } else if (value === "상담일지") {
      setSelectedConsults(consultsTeacherAll.filter((consult: any) => consult.memoType === "consult"));
    } else {
      setSelectedConsults(consultsTeacherAll.filter((consult: any) => consult.category === value));
    }
  };

  useEffect(() => {
    dispatch(getConsultListByTeacherAll(teacherInfo.branch._id));
  }, [dispatch, teacherInfo.branch._id]);

  useEffect(() => {
    if (consultsTeacherAll) {
      setSelectedConsults(consultsTeacherAll);
    }
  }, [consultsTeacherAll]);

  const consultComponent = (consult: any, index: number) => {
    const consultCategory = (category: string) => {
      if (category === "이슈") {
        return <Text as="span" color="gray.500">{category}</Text>  
      } else if (category === "휴강") {
        return <Text as="span" color="blue.500">{category}</Text>
      } else if (category === "탈퇴") {
        return <Text as="span" color="red.500">{category}</Text>
      }
    }

    if (consult.memoType === "consult") {
      return (
        <>
          <Tr key={index} minH="80px">
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
              padding={0}
            >
              {dateWithDayOfWeek(new Date(consult.date))}
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
            >
              {consult?.teacherName}
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
            >
              {consult?.teamName}
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
            >
              {consult?.studentName}
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
              paddingLeft="2"
              paddingRight="2"
              paddingTop="2"
              paddingBottom="2"
            >
              <Text textAlign={"left"} mb={1} fontWeight="bold" color={"orange.500"}>
                강사
              </Text>
              <Text textAlign={"left"}>{consult.consultTeacher}</Text>
              <Divider my={2} borderColor="gray.300" />
              <Text mt={2} mb={1} textAlign={"left"} fontWeight="bold" color={"blue.500"}>
                학부모
              </Text>
              <Text textAlign={"left"}>{consult.consultParents}</Text>
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
            >
              <Checkbox
                size="lg"
                colorScheme="orange"
                isChecked={consult.isImportant}
              ></Checkbox>
            </Td>
          </Tr>
        </>
      )
    } else {
      return (
        <>
          <Tr key={index} minH="80px">
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
            >
              {dateWithDayOfWeek(new Date(consult.date))}
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
            >
              {consult?.teacherName}
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
            >
              {consult.teamName}
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
            >
              {consult.studentName}
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
              paddingLeft="2"
              paddingRight="2"
              paddingTop="2"
              paddingBottom="2"
            >
              <Text textAlign={"left"}>{consultCategory(consult.category)} | {consult.content}</Text>
            </Td>
            <Td
              textAlign="center"
              borderWidth="1px"
              borderColor="gray.400"
              fontSize={"md"}
            >
              <Checkbox
                size="lg"
                colorScheme="orange"
                isChecked={consult.isImportant}
              ></Checkbox>
            </Td>
          </Tr>
        </>
      )
    }
  }

  return (
    <Box mx={{ base: "4", md: "40" }} pb={{ base: "40", md: "0" }} mb={20}>
      <Center>
        <VStack>
          <Text fontSize="4xl" fontWeight="bold" mt={20}>
            {teacherInfo.branch.name} 보고 현황
          </Text>
        </VStack>
      </Center>
      <Select
        size="lg"
        w="200px"
        mt={10}
        onChange={(e) => consultChangeHandler(e.target.value)}
      >
        {sortOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <Center mt={30}>
        <TableContainer overflowX="auto">
          <Table variant="simple" border="2px solid" borderColor="gray.400">
            <Thead bg="gray.100">
              <Tr>
                <Th
                  textAlign="center"
                  left="0"
                  borderWidth="1px"
                  borderColor="gray.400"
                  bg="gray.100"
                  fontSize={"md"}
                  padding={0}
                >
                  날짜
                </Th>
                <Th
                  textAlign="center"
                  left="0"
                  borderWidth="1px"
                  borderColor="gray.400"
                  bg="gray.100"
                  fontSize={"md"}
                >
                  강사명
                </Th>
                <Th
                  textAlign="center"
                  left="0"
                  borderWidth="1px"
                  borderColor="gray.400"
                  bg="gray.100"
                  fontSize={"md"}
                >
                  팀명
                </Th>
                <Th
                  textAlign="center"
                  left="0"
                  borderWidth="1px"
                  borderColor="gray.400"
                  bg="gray.100"
                  fontSize={"md"}
                >
                  이름
                </Th>
                <Th
                  textAlign="center"
                  bg="gray.100"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                  w={"1000px"}
                >
                  상담일지 내용
                </Th>
                <Th
                  textAlign="center"
                  bg="gray.100"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                >
                  중요
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Center>
                  <Text>로딩중...</Text>
                </Center>
              ) : error ? (
                <Center>
                  <Text>에러 발생! 다시 시도해주세요.</Text>
                </Center>
              ) : (
                selectedConsults.map((consult: any, index: number) => (
                  consultComponent(consult, index)
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
    </Box>
  );
};

export default ConsultTeacherAllScreen;