import axios from "axios";
import { Dispatch } from "redux";
import {
  YOUTHSTUDENT_DETAIL_REQUEST,
  YOUTHSTUDENT_DETAIL_SUCCESS,
  YOUTHSTUDENT_DETAIL_FAIL,
  YOUTHSTUDENT_DETAIL_RESET,
  YOUTHSTUDENT_ADD_REQUEST,
  YOUTHSTUDENT_ADD_SUCCESS,
  YOUTHSTUDENT_ADD_FAIL,
  YOUTHSTUDENT_ADD_RESET,
  YOUTHSTUDENT_UPDATE_REQUEST,
  YOUTHSTUDENT_UPDATE_SUCCESS,
  YOUTHSTUDENT_UPDATE_FAIL,
  YOUTHSTUDENT_UPDATE_RESET,
  YOUTHSTUDENT_LIST_REQUEST,
  YOUTHSTUDENT_LIST_SUCCESS,
  YOUTHSTUDENT_LIST_FAIL,
  YOUTHSTUDENT_LIST_RESET,
  YOUTHSTUDENT_QUIT_LIST_REQUEST,
  YOUTHSTUDENT_QUIT_LIST_SUCCESS,
  YOUTHSTUDENT_QUIT_LIST_FAIL,
  YOUTHSTUDENT_BATCH_ADD_REQUEST,
  YOUTHSTUDENT_BATCH_ADD_SUCCESS,
  YOUTHSTUDENT_BATCH_ADD_FAIL,
  BRANCH_YOUTHSTUDENT_LIST_REQUEST,
  BRANCH_YOUTHSTUDENT_LIST_SUCCESS,
  BRANCH_YOUTHSTUDENT_LIST_FAIL,
  TRANSFER_ADD_REQUEST,
  TRANSFER_ADD_SUCCESS,
  TRANSFER_ADD_FAIL,
  TRANSFER_ADD_RESET,
  TRANSFER_LIST_REQUEST,
  TRANSFER_LIST_SUCCESS,
  TRANSFER_LIST_FAIL,
  TRANSFER_LIST_RESET,
} from "../constants/YouthStudentConstants";
import { useNavigate } from "react-router-dom";
import { API_SERVER_URL } from "./actionUrl";

export const addYouthStudent =
  (
    name: string,
    birthday: string,
    phoneNum: string,
    parentPhoneNum: string,
    school: string,
    branchId: string,
    address: string,
    youthTeamId: string
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: YOUTHSTUDENT_ADD_REQUEST,
      });

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/youth-student`,
        {
          name,
          birthday,
          phoneNum,
          parentPhoneNum,
          school,
          branchId,
          address,
          youthTeamId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log({
        name,
        birthday,
        phoneNum,
        parentPhoneNum,
        school,
        branchId,
        address,
        youthTeamId,
      })

      dispatch({
        type: YOUTHSTUDENT_ADD_SUCCESS,
        payload: data,
      });
      alert("회원이 추가되었습니다.");
      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: YOUTHSTUDENT_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


export const getYouthStudentList =
  (
    branchId: string,
    pageNumber: number,
    keyword?: string
  ):
  ((dispatch: Dispatch) => Promise<void>) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: YOUTHSTUDENT_LIST_REQUEST,
      });

      const { data } = await axios.get(
        // 나중에 teacherId 같은 것 추가해야할 것 같다
        `${API_SERVER_URL}/api/youth-student?branchId=${branchId}&keyword=${keyword}&pageNumber=${pageNumber}`
      );

      dispatch({
        type: YOUTHSTUDENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: YOUTHSTUDENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getYouthStudentDetail =
  (studentId: string): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: YOUTHSTUDENT_DETAIL_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/youth-student/${studentId}`
      );

      dispatch({
        type: YOUTHSTUDENT_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: YOUTHSTUDENT_DETAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateYouthStudent =
  (youthStudent: any): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: YOUTHSTUDENT_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${API_SERVER_URL}/api/youth-student/${youthStudent._id}`,
        youthStudent,
        config
      );

      dispatch({
        type: YOUTHSTUDENT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: YOUTHSTUDENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getYouthStudentQuitList =
  (
    branchId: string,
    pageNumber: number,
    year: number,
    keyword?: string
  ): ((dispatch: Dispatch) => Promise<void>) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: YOUTHSTUDENT_QUIT_LIST_REQUEST,
      });

      const { data } = await axios.get(
        // 나중에 teacherId 같은 것 추가해야할 것 같다
        `${API_SERVER_URL}/api/youth-student/quit/student?branchId=${branchId}&pageNumber=${pageNumber}&year=${year}&keyword=${keyword}`
      );

      dispatch({
        type: YOUTHSTUDENT_QUIT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: YOUTHSTUDENT_QUIT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const batchAddYouthStudent =
  (studentData: any[], youthTeamId: string): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: YOUTHSTUDENT_BATCH_ADD_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/youth-student/batch`,
        { studentData, youthTeamId },
        config
      );

      dispatch({
        type: YOUTHSTUDENT_BATCH_ADD_SUCCESS,
        payload: data,
      });
      alert("추가되었습니다.");
    } catch (error: any) {
      dispatch({
        type: YOUTHSTUDENT_BATCH_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getBranchYouthStudentList =
  (branchId: string): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: BRANCH_YOUTHSTUDENT_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/youth-student/branch/${branchId}`
      );

      dispatch({
        type: BRANCH_YOUTHSTUDENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BRANCH_YOUTHSTUDENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addTransfer =
  (
    branchId: string,
    fromTeamId: string,
    toTeamId: string,
    studentId: string
  ): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: TRANSFER_ADD_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/transfer`,
        { branchId, fromTeamId, toTeamId, studentId },
        config
      );

      dispatch({
        type: TRANSFER_ADD_SUCCESS,
        payload: data,
      });
      alert("요청되었습니다.");
    } catch (error: any) {
      dispatch({
        type: TRANSFER_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });

      alert("요청에 실패했습니다.");
    }
  }

export const getTransferList = (branchId: string, teacherId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: TRANSFER_LIST_REQUEST,
    });

    const { data } = await axios.get(
      `${API_SERVER_URL}/api/transfer?branchId=${branchId}&teacherId=${teacherId}`
    );

    dispatch({
      type: TRANSFER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: TRANSFER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};