import {
  YOUTHSTUDENT_DETAIL_REQUEST,
  YOUTHSTUDENT_DETAIL_SUCCESS,
  YOUTHSTUDENT_DETAIL_FAIL,
  YOUTHSTUDENT_DETAIL_RESET,
  YOUTHSTUDENT_ADD_REQUEST,
  YOUTHSTUDENT_ADD_SUCCESS,
  YOUTHSTUDENT_ADD_FAIL,
  YOUTHSTUDENT_ADD_RESET,
  YOUTHSTUDENT_UPDATE_REQUEST,
  YOUTHSTUDENT_UPDATE_SUCCESS,
  YOUTHSTUDENT_UPDATE_FAIL,
  YOUTHSTUDENT_UPDATE_RESET,
  YOUTHSTUDENT_LIST_REQUEST,
  YOUTHSTUDENT_LIST_SUCCESS,
  YOUTHSTUDENT_LIST_FAIL,
  YOUTHSTUDENT_LIST_RESET,
  YOUTHSTUDENT_QUIT_LIST_REQUEST,
  YOUTHSTUDENT_QUIT_LIST_SUCCESS,
  YOUTHSTUDENT_QUIT_LIST_FAIL,
  YOUTHSTUDENT_QUIT_LIST_RESET,
  YOUTHSTUDENT_BATCH_ADD_REQUEST,
  YOUTHSTUDENT_BATCH_ADD_SUCCESS,
  YOUTHSTUDENT_BATCH_ADD_FAIL,
  YOUTHSTUDENT_BATCH_ADD_RESET,
  BRANCH_YOUTHSTUDENT_LIST_REQUEST,
  BRANCH_YOUTHSTUDENT_LIST_SUCCESS,
  BRANCH_YOUTHSTUDENT_LIST_FAIL,
  BRANCH_YOUTHSTUDENT_LIST_RESET,
  TRANSFER_ADD_REQUEST,
  TRANSFER_ADD_SUCCESS,
  TRANSFER_ADD_FAIL,
  TRANSFER_ADD_RESET,
  TRANSFER_LIST_REQUEST,
  TRANSFER_LIST_SUCCESS,
  TRANSFER_LIST_FAIL,
  TRANSFER_LIST_RESET,
} from "../constants/YouthStudentConstants";

export const getYouthStudentListReducer = (
  state = { youthStudents: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHSTUDENT_LIST_REQUEST:
      return { loading: true };
    case YOUTHSTUDENT_LIST_SUCCESS:
      return { loading: false, youthStudents: action.payload };
    case YOUTHSTUDENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHSTUDENT_LIST_RESET:
      return { youthStudents: [] };
    default:
      return state;
  }
};

export const getYouthStudentDetailReducer = (
  state = { youthStudent: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHSTUDENT_DETAIL_REQUEST:
      return { loading: true };
    case YOUTHSTUDENT_DETAIL_SUCCESS:
      return { loading: false, youthStudent: action.payload };
    case YOUTHSTUDENT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHSTUDENT_DETAIL_RESET:
      return { youthStudent: {} };
    default:
      return state;
  }
};

export const updateYouthStudentReducer = (
  state = { youthStudent: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHSTUDENT_UPDATE_REQUEST:
      return { loading: true };
    case YOUTHSTUDENT_UPDATE_SUCCESS:
      return { loading: false, success: true, youthStudent: action.payload };
    case YOUTHSTUDENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHSTUDENT_UPDATE_RESET:
      return { youthStudent: {} };
    default:
      return state;
  }
};

export const addYouthStudentReducer = (
  state = { youthStudent: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHSTUDENT_ADD_REQUEST:
      return { loading: true };
    case YOUTHSTUDENT_ADD_SUCCESS:
      return { loading: false, success: true, youthStudent: action.payload };
    case YOUTHSTUDENT_ADD_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHSTUDENT_ADD_RESET:
      return { youthStudent: {} };
    default:
      return state;
  }
}

export const getYouthStudentQuitListReducer = (
  state = { quitStudent: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHSTUDENT_QUIT_LIST_REQUEST:
      return { loading: true };
    case YOUTHSTUDENT_QUIT_LIST_SUCCESS:
      return { loading: false, quitStudent: action.payload };
    case YOUTHSTUDENT_QUIT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHSTUDENT_QUIT_LIST_RESET:
      return { quitStudent: [] };
    default:
      return state;
  }
}

export const batchAddYouthStudentReducer = (
  state = { youthStudents: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTHSTUDENT_BATCH_ADD_REQUEST:
      return { loading: true };
    case YOUTHSTUDENT_BATCH_ADD_SUCCESS:
      return { loading: false, success: true };
    case YOUTHSTUDENT_BATCH_ADD_FAIL:
      return { loading: false, error: action.payload };
    case YOUTHSTUDENT_BATCH_ADD_RESET:
      return { youthStudents: [] };
    default:
      return state;
  }
};

export const getBranchYouthStudentListReducer = (
  state = { branchYouthStudents: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_YOUTHSTUDENT_LIST_REQUEST:
      return { loading: true };
    case BRANCH_YOUTHSTUDENT_LIST_SUCCESS:
      return { loading: false, branchYouthStudents: action.payload };
    case BRANCH_YOUTHSTUDENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_YOUTHSTUDENT_LIST_RESET:
      return { branchYouthStudents: [] };
    default:
      return state;
  }
};

export const addTransferReducer = (
  state = { transfer: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case TRANSFER_ADD_REQUEST:
      return { loading: true };
    case TRANSFER_ADD_SUCCESS:
      return { loading: false, transfer: action.payload };
    case TRANSFER_ADD_FAIL:
      return { loading: false, error: action.payload };
    case TRANSFER_ADD_RESET:
      return { transfer: {} };
    default:
      return state;
  }
};

export const getTransferListReducer = (
  state = { transfers: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case TRANSFER_LIST_REQUEST:
      return { loading: true };
    case TRANSFER_LIST_SUCCESS:
      return { loading: false, transfers: action.payload };
    case TRANSFER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case TRANSFER_LIST_RESET:
      return { transfers: [] };
    default:
      return state;
  }
};
