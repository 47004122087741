import {
  Box,
  Button,
  Center,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getYouthStudentQuitList } from "../../redux/actions/YouthStudentActions";
import { Link, useParams } from "react-router-dom";
import formatPhoneNum from "../../utils/formatPhoneNum";
import Paginate from "../../components/Paginate";
import Searcher from "../../components/Searcher";

const QuitStudentScreen = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const teacherId = userInfo._id;

  const { keyword } = useParams<{ keyword: string }>();
  const keywordString = keyword ? keyword : "";
  const { pageNumber } = useParams<{ pageNumber: string }>() || 1;
  const year = 2024;

  const dispatch: any = useDispatch();

  const youthStudentQuitList = useSelector((state: any) => state.youthStudentQuitList);
  const { loading: loadingQuit, error: errorQuit, quitStudent } = youthStudentQuitList;

  useEffect(() => {
    console.log(keywordString)
    dispatch(getYouthStudentQuitList(teacherInfo.branch._id, Number(pageNumber), year, keywordString));
  }, [dispatch, teacherId, teacherInfo.branch._id, pageNumber, year, keyword]);

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack>
        <HStack mb={10}>
          <Text
            textAlign={"center"}
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight={"bold"}
          >
            탈퇴 회원
          </Text>
        </HStack>
        <Center>
          <Searcher keyword={keyword} onSearch={(keyword: string) => {
            window.location.href = `/youthdb/youth-student/quit/1/${keyword}`;
          }} />
        </Center>
        <HStack>
          <TableContainer
            maxH="2000px"
            minW={{ base: "240px", md: "300px" }}
            overflowX="auto"
          >
            <Table variant="simple" colorScheme="orange" border="ActiveBorder">
              <Thead bg="gray.100">
                <Tr>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    p={1}
                  >
                    순번
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    회원명
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    보호자 연락처
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    탈퇴일
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    재가입
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loadingQuit ? (
                  <h2>Loading...</h2>
                ) : errorQuit ? (
                  <h2>{errorQuit}</h2>
                ) : (
                  quitStudent &&
                  quitStudent.youthStudent?.map((student: any) => (
                    <Tr key={student._id}>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        p={0}
                        w={2}
                      >
                        {student.indexNum}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        p={1}
                        w={40}
                      >
                        {student.name}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        p={1}
                        w={40}
                      >
                        {student.parentPhoneNum && formatPhoneNum(student.parentPhoneNum)}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        p={1}
                        w={40}
                      >
                        {student.quitDate ? student.quitDate.substring(0, 10) : ""}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        p={1}
                        w={40}
                      >
                        <Link to={`/youthdb/youth-student/rejoin/${student.studentId}`}>
                          <Button
                            colorScheme="orange"
                            size="sm"
                          >재가입</Button>
                        </Link>
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </HStack>
        <Center>
          <Paginate
            pages={quitStudent?.pages}
            page={quitStudent?.page}
            onPageChange={(page: number) => {
              window.location.href = `/youthdb/youth-student/quit/${page}`;
            }}
          />
        </Center>
      </VStack>
    </Box>
  );
};

export default QuitStudentScreen;