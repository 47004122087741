import { Box, Flex } from "@chakra-ui/react";
import { Outlet, useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "../index.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getBranchPageByUrl } from "../redux/actions/HomePageActions";
import { useDispatch } from "react-redux";

export default function Root() {
  const { id } = useParams<{ id: string }>();
  const branchUrl = String(id);

  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getBranchPageByUrl(branchUrl));
  }, [branchUrl]);

  return (
    <>
      {branchPage && branchPage.footerInfo && (
        <Flex direction="column" minHeight="100vh">
          <Header
            headerBg={branchPage.headerBg}
            branchUrl={branchUrl}
            programGuideList={branchPage.programGuideList || []}
            logo={branchPage.logo}
          />
          <Box flex="1">
            <Outlet />
          </Box>
          <Footer
            footerInfo={branchPage.footerInfo}
          />
        </Flex>
      )}
    </>
  );
}
