import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import NotFound from "./routes/NotFound";
import Home from "./branch/screens/Home";
import Top from "./routes/Top";
import Greeting from "./branch/screens/Greeting";
import Location from "./branch/screens/Location";
import Instructors from "./branch/screens/Instructors";
import NoticeGame from "./branch/screens/NoticeGame";
import AdminMainScreen from "./youthdb/adminscreens/AdminMainScreen";
import AdminRoot from "./components/AdminRoot";
import AttendScreen from "./youthdb/attendscreens/AttendScreen";
import AttendNewScreen from "./youthdb/attendscreens/AttendNewScreen";
import PaymentAutoScreen from "./youthdb/paymentscreens/PaymentAutoScreen";
import AttendUpdateScreen from "./youthdb/attendscreens/AttendUpdateScreen";
import StudentMainScreen from "./youthdb/studentscreens/StudentMainScreen";
import StudentListScreen from "./youthdb/studentscreens/StudentListScreen";
import StudentDetailScreen from "./youthdb/studentscreens/StudentDetailScreen";
import OnelineMainScreen from "./youthdb/memoscreens/OnelineMainScreen";
import ConsultMainScreen from "./youthdb/memoscreens/ConsultMainScreen";
import StudentAddScreen from "./youthdb/studentscreens/StudentAddScreen";
import StudentUpdateScreen from "./youthdb/studentscreens/StudentUpdateScreen";
import PaymentMainScreen from "./youthdb/paymentscreens/PaymentMainScreen";
import PaymentLogScreen from "./youthdb/paymentscreens/PaymentLogScreen";
import TeamUpdateScreen from "./youthdb/studentscreens/TeamUpdateScreen";
import BranchMainScreen from "./youthdb/branchscreens/BranchMainScreen";
import BranchMyScreen from "./youthdb/branchscreens/BranchMyScreen";
import EmployeeManageScreen from "./youthdb/employeescreens/EmployeeManageScreen";
import QuitStudentScreen from "./youthdb/studentscreens/QuitStudentScreen";
import StudentRejoinScreen from "./youthdb/studentscreens/StudentRejoinScreen";
import StudentBatchAddScreen from "./youthdb/studentscreens/StudentBatchAddScreen";
import XlsxStudentAddScreen from "./youthdb/studentscreens/XlsxStudentAddScreen";
import TeamAddScreen from "./youthdb/studentscreens/TeamAddScreen";
import TeacherTeamMainScreen from "./youthdb/teacherscreens/TeacherTeamMainScreen";
import AttendListScreen from "./youthdb/attendscreens/AttendListScreen";
import StudentScreen from "./youthdb/studentscreens/StudentScreen";
import AdminLoginScreen from "./youthdb/adminscreens/AdminLoginScreen";
import ConsultTeacherScreen from "./youthdb/memoscreens/ConsultTeacherScreen";
import ConsultUpdateScreen from "./youthdb/memoscreens/ConsultUpdateScreen";
import ConsultTeacherAllScreen from "./youthdb/memoscreens/ConsultTeacherAllScreen";
import AttendHiddenTeamScreen from "./youthdb/attendscreens/AttendHiddenTeamScreen";
import TeamMoveScreen from "./youthdb/studentscreens/TeamMoveScreen";
import Post from "./branch/screens/Post";
import PostWrite from "./branch/adminscreens/PostWrite";
import PageCustomize from "./branch/adminscreens/PageCustomize";
import ModifyInstructors from "./branch/adminscreens/ModifyInstructors";
import PostDetail from "./branch/screens/PostDetail";
import PostModify from "./branch/adminscreens/PostModify";
import GroupMainScreen from "./youthdb/branchscreens/GroupMainScreen";
import ProgramGuide from "./branch/screens/ProgramGuide";
import SupplementAddScreen from "./youthdb/attendscreens/SupplementAddScreen";
import PaymentAutoCardScreen from "./youthdb/paymentscreens/PaymentAutoCardScreen";
import UploadTypeSelectScreen from "./youthdb/paymentscreens/UploadTypeSelectScreen";
import SupplementListScreen from "./youthdb/attendscreens/SupplementListScreen";
import StudentSearchScreen from "./youthdb/studentscreens/StudentSearchScreen";
import EtcPaymentAddScreen from "./youthdb/paymentscreens/EtcPaymentAddScreen";
import EtcPaymentListScreen from "./youthdb/paymentscreens/EtcPaymentListScreen";
import EtcCategoryManageScreen from "./youthdb/paymentscreens/EtcCategoryManageScreen";
import GreetingCustomize from "./branch/adminscreens/GreetingCustomize";
import Login from "./branch/screens/Login";
import Register from "./branch/screens/Register";
import LocationCustomize from "./branch/adminscreens/LocationCustomize";
import AttendScreenYear from "./youthdb/attendscreens/AttendScreenYear";
import TransferScreen from "./youthdb/studentscreens/TransferScreen";
import TransferAcceptScreen from "./youthdb/studentscreens/TransferAcceptScreen";

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   errorElement: <NotFound />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Top />,
  //     },
  //   ],
  // },
  // {
  //   path: "admin-login",
  //   element: <AdminLoginScreen />,
  // },
  // {
  //   path: "screens",
  //   element: <Root />,
  //   errorElement: <NotFound />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Home />,
  //     },
  //     {
  //       path: "greeting",
  //       element: <Greeting />,
  //     },
  //     {
  //       path: "location",
  //       element: <Location />,
  //     },
  //     {
  //       path: "instructors",
  //       element: <Instructors />,
  //     },
  //     {
  //       path: "notice",
  //       element: <Notice />,
  //     },
  //     {
  //       path: "notice-game",
  //       element: <NoticeGame />,
  //     },
  //   ],
  // },
  {
    path: "admin-login",
    element: <AdminLoginScreen />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "register/:sid?",
    element: <Register />,
  },
  {
    path: "/:id",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "greeting",
        element: <Greeting />,
      },
      {
        path: "location",
        element: <Location />,
      },
      {
        path: "instructors",
        element: <Instructors />,
      },
      {
        path: "instructors-customize",
        element: <ModifyInstructors />,
      },
      {
        path: "notice-game",
        element: <NoticeGame />,
      },
      {
        path: "post/:category/:postId",
        element: <PostDetail />,
      },
      {
        path: "post/:type/:postId/edit",
        element: <PostModify />,
      },
      {
        path: "post/:category",
        element: <Post />,
      },
      {
        path: "program-guide/:category",
        element: <ProgramGuide />,
      },
      {
        path: "post-write/:type",
        element: <PostWrite />,
      },
      {
        path: "page-customize",
        element: <PageCustomize />,
      },
      {
        path: "greeting-customize",
        element: <GreetingCustomize />,
      },
      {
        path: "location-customize",
        element: <LocationCustomize />,
      },
    ],
  },
  {
    path: "youthdb",
    element: <AdminRoot />,
    errorElement: <NotFound />,
    children: [
      {
        path: "admin",
        element: <AdminMainScreen />,
      },
      {
        path: "my",
        element: <BranchMyScreen />,
      },
      {
        path: "attend",
        element: <AttendListScreen />,
      },
      {
        path: "attend/supplement-list",
        element: <SupplementListScreen />,
      },
      {
        path: "attend/:id",
        element: <AttendScreen />,
      },
      {
        path: "attend-year/:id",
        element: <AttendScreenYear />,
      },
      {
        path: "attend/supplement/:id",
        element: <SupplementAddScreen />,
      },
      {
        path: "attend-hidden",
        element: <AttendHiddenTeamScreen />,
      },
      {
        path: "attend/:id/new",
        element: <AttendNewScreen />,
      },
      {
        path: "attend/update/:id",
        element: <AttendUpdateScreen />,
      },
      {
        path: "payment",
        element: <PaymentMainScreen />,
      },
      {
        path: "etc-payment/:id/new",
        element: <EtcPaymentAddScreen />,
      },
      {
        path: "etc-payment",
        element: <EtcPaymentListScreen />,
      },
      {
        path: "etc-category/manage",
        element: <EtcCategoryManageScreen />,
      },
      {
        path: "upload",
        element: <PaymentLogScreen />,
      },
      {
        path: "payment-type",
        element: <UploadTypeSelectScreen />,
      },
      {
        path: "payment-auto",
        element: <PaymentAutoScreen />,
      },
      {
        path: "payment-auto-card",
        element: <PaymentAutoCardScreen />,
      },
      {
        path: "youth-student",
        element: <StudentMainScreen />,
      },
      {
        path: "youth-student/quit/:pageNumber",
        element: <QuitStudentScreen />,
      },
      {
        path: "youth-student/quit/:pageNumber/:keyword",
        element: <QuitStudentScreen />,
      },
      {
        path: "youth-student/rejoin/:id",
        element: <StudentRejoinScreen />,
      },
      {
        path: "youth-student/:id",
        element: <StudentListScreen />,
      },
      {
        path: "youth-student/:id/edit",
        element: <TeamUpdateScreen />,
      },
      {
        path: "youth-student/:id/move",
        element: <TeamMoveScreen />,
      },
      {
        path: "youth-team/new",
        element: <TeamAddScreen />,
      },
      {
        path: "youth-student/search/:pageNumber",
        element: <StudentSearchScreen />,
      },
      {
        path: "youth-student/search/:pageNumber/:keyword",
        element: <StudentSearchScreen />,
      },
      {
        path: "youth-student/new",
        element: <StudentAddScreen />,
      },
      {
        path: "youth-student/:id/batch",
        element: <StudentBatchAddScreen />,
      },
      {
        path: "youth-student/xlsx",
        element: <XlsxStudentAddScreen />,
      },
      {
        path: "youth-student-detail/:id",
        element: <StudentDetailScreen />,
      },
      {
        path: "youth-student/update/:id",
        element: <StudentUpdateScreen />,
      },
      {
        path: "youth-student/transfer/:id",
        element: <TransferScreen />,
      },
      {
        path: "youth-student/transfer-list",
        element: <TransferAcceptScreen />,
      },
      {
        path: "youth-student/branch",
        element: <StudentScreen />,
      },
      {
        path: "onelinememo/:date/:id",
        element: <OnelineMainScreen />,
      },
      {
        path: "consultmemo/student/:id",
        element: <ConsultMainScreen />,
      },
      {
        path: "consultmemo/teacher/:id",
        element: <ConsultTeacherScreen />,
      },
      {
        path: "consultmemo/teacher-all",
        element: <ConsultTeacherAllScreen />,
      },
      {
        path: "consultmemo/:id",
        element: <ConsultUpdateScreen />,
      },
      {
        path: "branch",
        element: <BranchMainScreen />,
      },
      {
        path: "group",
        element: <GroupMainScreen />,
      },
      {
        path: "employee",
        element: <EmployeeManageScreen />,
      },
      {
        path: "teacher-team/:id/:initYear/:initMonth",
        element: <TeacherTeamMainScreen />,
      }
    ],
  },
]);

export default router;
