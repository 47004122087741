import axios from 'axios';
import { API_SERVER_URL } from '../redux/actions/actionUrl';

const identifyUser = async (id: string, moveYouthDB: boolean, redirectUrl?: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const { data } = await axios.post(
    `${API_SERVER_URL}/api/user/youth/identify`,
    { id },
    config
  );

  localStorage.setItem("teacherInfo", JSON.stringify(data));

  if (moveYouthDB) {
    window.location.href = "/youthdb/my";
  } else if (redirectUrl) {
    window.location.href = `/${redirectUrl}`;
  }
}

export default identifyUser;