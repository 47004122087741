export const YOUTHTEAM_LIST_REQUEST = "YOUTHTEAM_LIST_REQUEST" as const;
export const YOUTHTEAM_LIST_SUCCESS = "YOUTHTEAM_LIST_SUCCESS" as const;
export const YOUTHTEAM_LIST_FAIL = "YOUTHTEAM_LIST_FAIL" as const;
export const YOUTHTEAM_LIST_RESET = "YOUTHTEAM_LIST_RESET" as const;

export const YOUTHTEAM_HIDDEN_LIST_REQUEST = "YOUTHTEAM_HIDDEN_LIST_REQUEST" as const;
export const YOUTHTEAM_HIDDEN_LIST_SUCCESS = "YOUTHTEAM_HIDDEN_LIST_SUCCESS" as const;
export const YOUTHTEAM_HIDDEN_LIST_FAIL = "YOUTHTEAM_HIDDEN_LIST_FAIL" as const;
export const YOUTHTEAM_HIDDEN_LIST_RESET = "YOUTHTEAM_HIDDEN_LIST_RESET" as const;

export const YOUTHTEAM_BRANCH_LIST_REQUEST = "YOUTHTEAM_BRANCH_LIST_REQUEST" as const;
export const YOUTHTEAM_BRANCH_LIST_SUCCESS = "YOUTHTEAM_BRANCH_LIST_SUCCESS" as const;
export const YOUTHTEAM_BRANCH_LIST_FAIL = "YOUTHTEAM_BRANCH_LIST_FAIL" as const;
export const YOUTHTEAM_BRANCH_LIST_RESET = "YOUTHTEAM_BRANCH_LIST_RESET" as const;

export const YOUTHTEAM_ADD_REQUEST = "YOUTHTEAM_ADD_REQUEST" as const;
export const YOUTHTEAM_ADD_SUCCESS = "YOUTHTEAM_ADD_SUCCESS" as const;
export const YOUTHTEAM_ADD_FAIL = "YOUTHTEAM_ADD_FAIL" as const;
export const YOUTHTEAM_ADD_RESET = "YOUTHTEAM_ADD_RESET" as const;

export const YOUTHTEAM_DETAIL_REQUEST = "YOUTHTEAM_DETAIL_REQUEST" as const;
export const YOUTHTEAM_DETAIL_SUCCESS = "YOUTHTEAM_DETAIL_SUCCESS" as const;
export const YOUTHTEAM_DETAIL_FAIL = "YOUTHTEAM_DETAIL_FAIL" as const;
export const YOUTHTEAM_DETAIL_RESET = "YOUTHTEAM_DETAIL_RESET" as const;

export const YOUTHTEAM_UPDATE_REQUEST = "YOUTHTEAM_UPDATE_REQUEST" as const;
export const YOUTHTEAM_UPDATE_SUCCESS = "YOUTHTEAM_UPDATE_SUCCESS" as const;
export const YOUTHTEAM_UPDATE_FAIL = "YOUTHTEAM_UPDATE_FAIL" as const;

export const YOUTHTEAM_DELETE_REQUEST = "YOUTHTEAM_DELETE_REQUEST" as const;
export const YOUTHTEAM_DELETE_SUCCESS = "YOUTHTEAM_DELETE_SUCCESS" as const;
export const YOUTHTEAM_DELETE_FAIL = "YOUTHTEAM_DELETE_FAIL" as const;
export const YOUTHTEAM_DELETE_RESET = "YOUTHTEAM_DELETE_RESET" as const;