import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Form, useParams } from "react-router-dom";
import { addYouthTeam, getYouthTeamDetail } from "../../redux/actions/YouthTeamActions";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { updateYouthTeam } from "../../redux/actions/YouthTeamActions";

const TeamAddScreen = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const dispatch: any = useDispatch();

  const [name, setName] = useState("");

  const optionsBirthYear = [
    { value: "연합", label: "연합"},
    { value: "초저부", label: "초저부" },
    { value: "초고부", label: "초고부" },
    { value: "중등부", label: "중등부" },
    { value: "고등부", label: "고등부" },
    { value: "기타", label: "기타"},
    { value: "성인", label: "성인"}
  ]

  const submitHandler = () => {
    if (name === "") {
      alert("모든 항목을 입력해주세요.");
      return;
    }

    dispatch(addYouthTeam({
      name: name,
      teacherId: userInfo._id,
      branchId: teacherInfo.branch._id,
    })).then((response: any) => {
      if (response.status === 202) {
        alert("이미 등록된 팀명입니다.");
      } else if (response.status === 201) {
        alert("팀 등록이 완료되었습니다.");
      } else {
        alert("팀 등록에 실패하였습니다.");
      }
    });

    window.location.href = "/youthdb/youth-student";
  };

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack gap={20}>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          borderRadius={"25"}
          padding={10}
        >
          <Text fontSize={28} textAlign={"center"} mb={10}>
            팀 정보
          </Text>
          <VStack textAlign="center" minW={"500px"} gap={10}>
            <FormControl>
              <FormLabel>팀명</FormLabel>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
              <FormLabel>팀 종류</FormLabel>
              <Select>
                {optionsBirthYear.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Button colorScheme="orange" w={120} onClick={submitHandler}>
              등록
            </Button>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default TeamAddScreen;
