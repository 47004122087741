import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getYouthStudentDetail } from "../../redux/actions/YouthStudentActions";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Center,
  Heading,
  Stack,
  StackDivider,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { YOUTHSTUDENT_DETAIL_RESET } from "../../redux/constants/YouthStudentConstants";
import formatPhoneNum from "../../utils/formatPhoneNum";

const StudentDetailScreen = () => {
  const { id } = useParams<{ id: string }>();
  const studentId = id ?? "";
  const dispatch: any = useDispatch();

  const youthStudentDetail = useSelector(
    (state: any) => state.youthStudentDetail
  );
  const { loading, error, youthStudent } = youthStudentDetail;

  useEffect(() => {
    dispatch(getYouthStudentDetail(studentId));
  }, [dispatch, studentId]);

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <Center>
          <VStack>
            {/* <Card
              minW={{ base: "300px", md: "1000px" }}
              textAlign={"center"}
              backgroundColor={"orange.300"}
              borderRadius={"25px"}
            >
              <CardHeader>
                <Heading size="lg">{youthStudent.name}</Heading>
              </CardHeader>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <Box>
                    <Heading size="md">
                      연락처 : {youthStudent.phoneNum && formatPhoneNum(youthStudent.phoneNum)}
                    </Heading>
                    <Heading size="md">
                      보호자 연락처 : {youthStudent.parentPhoneNum && formatPhoneNum(youthStudent.parentPhoneNum)}
                    </Heading>
                  </Box>
                  <Box>
                    <Heading size="md">학교 : {youthStudent.school}</Heading>
                    <Heading size="md">주소 : {youthStudent.address ? youthStudent.address : "-"}</Heading>
                  </Box>
                </Stack>
              </CardBody>
            </Card> */}
            <Table variant="simple" colorScheme="orange" textAlign="center">
              <TableCaption placement="top">
                <Heading size="lg" mb={6}>
                  {youthStudent.name} 회원 정보
                </Heading>
              </TableCaption>
              <Thead bg="gray.100">
                <Tr>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400">
                    연락처
                  </Th>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400">
                    보호자 연락처
                  </Th>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400">
                    학교
                  </Th>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400">
                    등번호
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td textAlign="center" borderWidth="1px" borderColor="gray.400">
                    {youthStudent.phoneNum && formatPhoneNum(youthStudent.phoneNum)}
                  </Td>
                  <Td textAlign="center" borderWidth="1px" borderColor="gray.400">
                    {youthStudent.parentPhoneNum && formatPhoneNum(youthStudent.parentPhoneNum)}
                  </Td>
                  <Td textAlign="center" borderWidth="1px" borderColor="gray.400">
                    {youthStudent.school}
                  </Td>
                  <Td textAlign="center" borderWidth="1px" borderColor="gray.400">
                    {youthStudent.jerseyNum ? youthStudent.jerseyNum : "-"}
                  </Td>
                </Tr>
              </Tbody>
              <Thead bg="gray.100">
                <Tr>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400" colSpan={4}>
                    주소
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td textAlign="center" borderWidth="1px" borderColor="gray.400" colSpan={4}>
                    {youthStudent.address ? youthStudent.address : "-"}
                  </Td>
                </Tr>
              </Tbody>
            </Table>

            <Table variant="simple" colorScheme="orange" textAlign="center">
              <TableCaption placement="top">
                <Heading size="md" mb={1}>
                  회원 상담일지
                </Heading>
              </TableCaption>
              <Thead bg="gray.100">
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  w={"15%"}
                >
                  날짜
                </Th>
                <Th textAlign="center" borderWidth="1px" borderColor="gray.400">
                  내용
                </Th>
              </Thead>
              <Tbody>
                {youthStudent &&
                  youthStudent.oneLineMemo &&
                  youthStudent.oneLineMemo.length > 0 ? (
                  youthStudent.oneLineMemo.map((memo: any) => (
                    <Tr>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                      >
                        {memo.date.substring(0, 10)}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                      >
                        {memo.memo}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      colSpan={2}
                    >
                      상담일지 기록이 없습니다.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            <Table variant="simple" colorScheme="orange" textAlign="center">
              <TableCaption placement="top">
                <Heading size="md" mb={1}>
                  회원 한줄평 메모
                </Heading>
              </TableCaption>
              <Thead bg="gray.100">
                <Th
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  w={"15%"}
                >
                  날짜
                </Th>
                <Th textAlign="center" borderWidth="1px" borderColor="gray.400">
                  내용
                </Th>
              </Thead>
              <Tbody>
                {youthStudent &&
                  youthStudent.consultMemo &&
                  youthStudent.consultMemo.length > 0 ? (
                  youthStudent.consultMemo.map((memo: any) => (
                    <Tr>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                      >
                        {memo.date.substring(0, 10)}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                      >
                        {memo.memo}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      colSpan={2}
                    >
                      한줄평 메모 기록이 없습니다.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </VStack>
        </Center>
      )}
    </Box>
  );
};

export default StudentDetailScreen;
