import { Box, Text, Center } from "@chakra-ui/react";
import React from "react";

const AdminFooter = () => {
  return (
    <Box
      backgroundColor={"gray.100"}
      py={4}
      position="absolute"
      bottom="-150px"
      width="100%"
    >
      <Center>
        <Text fontSize="sm" color="gray.500">
          © 2023 SCHOOLB All rights reserved.
        </Text>
      </Center>
    </Box>
  );
};

export default AdminFooter;
