import {
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_RESET,
  PAYMENT_DETAIL_REQUEST,
  PAYMENT_DETAIL_SUCCESS,
  PAYMENT_DETAIL_FAIL,
  PAYMENT_DETAIL_RESET,
  GET_PAYMENT_BY_MONTH_REQUEST,
  GET_PAYMENT_BY_MONTH_SUCCESS,
  GET_PAYMENT_BY_MONTH_FAIL,
  GET_PAYMENT_BY_MONTH_RESET,
  BATCH_UPDATE_PAYMENT_REQUEST,
  BATCH_UPDATE_PAYMENT_SUCCESS,
  BATCH_UPDATE_PAYMENT_FAIL,
  BATCH_UPDATE_PAYMENT_RESET,
  XLSX_UPLOAD_REQUEST,
  XLSX_UPLOAD_SUCCESS,
  XLSX_UPLOAD_FAIL,
  XLSX_UPLOAD_RESET,
  XLSX_UPDATE_REQUEST,
  XLSX_UPDATE_SUCCESS,
  XLSX_UPDATE_FAIL,
  XLSX_UPDATE_RESET,
  PAYMENT_LOG_LIST_REQUEST,
  PAYMENT_LOG_LIST_SUCCESS,
  PAYMENT_LOG_LIST_FAIL,
  PAYMENT_LOG_LIST_RESET,
  PAYMENT_LOG_DELETE_REQUEST,
  PAYMENT_LOG_DELETE_SUCCESS,
  PAYMENT_LOG_DELETE_FAIL,
  PAYMENT_LOG_DELETE_RESET,
  PAYMENT_LOG_DELETE_FILENAME_REQUEST,
  PAYMENT_LOG_DELETE_FILENAME_SUCCESS,
  PAYMENT_LOG_DELETE_FILENAME_FAIL,
  PAYMENT_LOG_DELETE_FILENAME_RESET,
  ETC_PAYMENT_INPUT_REQUEST,
  ETC_PAYMENT_INPUT_SUCCESS,
  ETC_PAYMENT_INPUT_FAIL,
  ETC_PAYMENT_INPUT_RESET,
  ETC_PAYMENT_LIST_REQUEST,
  ETC_PAYMENT_LIST_SUCCESS,
  ETC_PAYMENT_LIST_FAIL,
  ETC_PAYMENT_LIST_RESET,
  ETC_PAYMENT_DELETE_REQUEST,
  ETC_PAYMENT_DELETE_SUCCESS,
  ETC_PAYMENT_DELETE_FAIL,
  ETC_PAYMENT_DELETE_RESET,
  ETC_CATEGORY_INPUT_REQUEST,
  ETC_CATEGORY_INPUT_SUCCESS,
  ETC_CATEGORY_INPUT_FAIL,
  ETC_CATEGORY_INPUT_RESET,
  ETC_CATEGORY_LIST_REQUEST,
  ETC_CATEGORY_LIST_SUCCESS,
  ETC_CATEGORY_LIST_FAIL,
  ETC_CATEGORY_LIST_RESET,
  ETC_CATEGORY_DELETE_REQUEST,
  ETC_CATEGORY_DELETE_SUCCESS,
  ETC_CATEGORY_DELETE_FAIL,
  ETC_CATEGORY_DELETE_RESET,
} from "../constants/PaymentConstants";

export const getPaymentListReducer = (
  state = { payments: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case PAYMENT_LIST_REQUEST:
      return { loading: true };
    case PAYMENT_LIST_SUCCESS:
      return { loading: false, payments: action.payload };
    case PAYMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_LIST_RESET:
      return { payments: [] };
    default:
      return state;
  }
};

export const getPaymentDetailReducer = (
  state = { payment: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case PAYMENT_DETAIL_REQUEST:
      return { loading: true };
    case PAYMENT_DETAIL_SUCCESS:
      return { loading: false, payment: action.payload };
    case PAYMENT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_DETAIL_RESET:
      return { payment: {} };
    default:
      return state;
  }
};

export const getPaymentByMonthReducer = (
  state = { payments: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_PAYMENT_BY_MONTH_REQUEST:
      return { loading: true };
    case GET_PAYMENT_BY_MONTH_SUCCESS:
      return { loading: false, payments: action.payload };
    case GET_PAYMENT_BY_MONTH_FAIL:
      return { loading: false, error: action.payload };
    case GET_PAYMENT_BY_MONTH_RESET:
      return { payments: [] };
    default:
      return state;
  }
};

export const batchUpdatePaymentReducer = (
  state = { payments: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BATCH_UPDATE_PAYMENT_REQUEST:
      return { loading: true };
    case BATCH_UPDATE_PAYMENT_SUCCESS:
      return { loading: false, payments: action.payload };
    case BATCH_UPDATE_PAYMENT_FAIL:
      return { loading: false, error: action.payload };
    case BATCH_UPDATE_PAYMENT_RESET:
      return { payments: [] };
    default:
      return state;
  }
};

export const xlsxUploadReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case XLSX_UPLOAD_REQUEST:
      return { loading: true };
    case XLSX_UPLOAD_SUCCESS:
      return { loading: false, success: true };
    case XLSX_UPLOAD_FAIL:
      return { loading: false, error: action.payload };
    case XLSX_UPLOAD_RESET:
      return {};
    default:
      return state;
  }
};

export const xlsxUpdateReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case XLSX_UPDATE_REQUEST:
      return { loading: true };
    case XLSX_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case XLSX_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case XLSX_UPDATE_RESET:
      return {};
    default:
      return state;
  }
}

export const getPaymentLogListReducer = (
  state = { paymentLogs: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case PAYMENT_LOG_LIST_REQUEST:
      return { loading: true };
    case PAYMENT_LOG_LIST_SUCCESS:
      return { loading: false, paymentLogs: action.payload };
    case PAYMENT_LOG_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_LOG_LIST_RESET:
      return { paymentLogs: [] };
    default:
      return state;
  }
};

export const deletePaymentLogReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case PAYMENT_LOG_DELETE_REQUEST:
      return { loading: true };
    case PAYMENT_LOG_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PAYMENT_LOG_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_LOG_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const deletePaymentLogFileNameReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case PAYMENT_LOG_DELETE_FILENAME_REQUEST:
      return { loading: true };
    case PAYMENT_LOG_DELETE_FILENAME_SUCCESS:
      return { loading: false, success: true };
    case PAYMENT_LOG_DELETE_FILENAME_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_LOG_DELETE_FILENAME_RESET:
      return {};
    default:
      return state;
  }
};

export const etcPaymentInputReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ETC_PAYMENT_INPUT_REQUEST:
      return { loading: true };
    case ETC_PAYMENT_INPUT_SUCCESS:
      return { loading: false, success: true };
    case ETC_PAYMENT_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case ETC_PAYMENT_INPUT_RESET:
      return {};
    default:
      return state;
  }
};

export const etcPaymentListReducer = (
  state = { etcPayments: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ETC_PAYMENT_LIST_REQUEST:
      return { loading: true };
    case ETC_PAYMENT_LIST_SUCCESS:
      return { loading: false, etcPayments: action.payload };
    case ETC_PAYMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ETC_PAYMENT_LIST_RESET:
      return { etcPayments: [] };
    default:
      return state;
  }
};

export const etcPaymentDeleteReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ETC_PAYMENT_DELETE_REQUEST:
      return { loading: true };
    case ETC_PAYMENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ETC_PAYMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ETC_PAYMENT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const etcCategoryInputReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ETC_CATEGORY_INPUT_REQUEST:
      return { loading: true };
    case ETC_CATEGORY_INPUT_SUCCESS:
      return { loading: false, success: true };
    case ETC_CATEGORY_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case ETC_CATEGORY_INPUT_RESET:
      return {};
    default:
      return state;
  }
};

export const etcCategoryListReducer = (
  state = { etcCategories: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ETC_CATEGORY_LIST_REQUEST:
      return { loading: true };
    case ETC_CATEGORY_LIST_SUCCESS:
      return { loading: false, etcCategories: action.payload };
    case ETC_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ETC_CATEGORY_LIST_RESET:
      return { etcCategories: [] };
    default:
      return state;
  }
};

export const etcCategoryDeleteReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case ETC_CATEGORY_DELETE_REQUEST:
      return { loading: true };
    case ETC_CATEGORY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ETC_CATEGORY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ETC_CATEGORY_DELETE_RESET:
      return {};
    default:
      return state;
  }
};