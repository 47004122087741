import axios from "axios";
import {
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_RESET,
  PAYMENT_DETAIL_REQUEST,
  PAYMENT_DETAIL_SUCCESS,
  PAYMENT_DETAIL_FAIL,
  GET_PAYMENT_BY_MONTH_REQUEST,
  GET_PAYMENT_BY_MONTH_SUCCESS,
  GET_PAYMENT_BY_MONTH_FAIL,
  XLSX_UPLOAD_REQUEST,
  XLSX_UPLOAD_SUCCESS,
  XLSX_UPLOAD_FAIL,
  XLSX_UPDATE_REQUEST,
  XLSX_UPDATE_SUCCESS,
  XLSX_UPDATE_FAIL,
  PAYMENT_LOG_LIST_REQUEST,
  PAYMENT_LOG_LIST_SUCCESS,
  PAYMENT_LOG_LIST_FAIL,
  PAYMENT_LOG_DELETE_REQUEST,
  PAYMENT_LOG_DELETE_SUCCESS,
  PAYMENT_LOG_DELETE_FAIL,
  PAYMENT_LOG_DELETE_FILENAME_REQUEST,
  PAYMENT_LOG_DELETE_FILENAME_SUCCESS,
  PAYMENT_LOG_DELETE_FILENAME_FAIL,
  BATCH_UPDATE_PAYMENT_REQUEST,
  BATCH_UPDATE_PAYMENT_SUCCESS,
  BATCH_UPDATE_PAYMENT_FAIL,
  ETC_PAYMENT_INPUT_REQUEST,
  ETC_PAYMENT_INPUT_SUCCESS,
  ETC_PAYMENT_INPUT_FAIL,
  ETC_PAYMENT_LIST_REQUEST,
  ETC_PAYMENT_LIST_SUCCESS,
  ETC_PAYMENT_LIST_FAIL,
  ETC_PAYMENT_DELETE_REQUEST,
  ETC_PAYMENT_DELETE_SUCCESS,
  ETC_PAYMENT_DELETE_FAIL,
  ETC_CATEGORY_INPUT_REQUEST,
  ETC_CATEGORY_INPUT_SUCCESS,
  ETC_CATEGORY_INPUT_FAIL,
  ETC_CATEGORY_LIST_REQUEST,
  ETC_CATEGORY_LIST_SUCCESS,
  ETC_CATEGORY_LIST_FAIL,
  ETC_CATEGORY_DELETE_REQUEST,
  ETC_CATEGORY_DELETE_SUCCESS,
  ETC_CATEGORY_DELETE_FAIL,
} from "../constants/PaymentConstants";
import { API_SERVER_URL } from "./actionUrl";

export const getPaymentList =
  (year: number, startMonth: number, endMonth: number, youthTeamId: string, branchId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: PAYMENT_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/payment?year=${year}&startMonth=${startMonth}&endMonth=${endMonth}&youthTeamId=${youthTeamId}&branchId=${branchId}`
      );

      dispatch({
        type: PAYMENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: PAYMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getPaymentDetail = (paymentId: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  try {
    dispatch({
      type: PAYMENT_DETAIL_REQUEST,
    });

    const { data } = await axios.get(`${API_SERVER_URL}/api/payment/${paymentId}`);

    dispatch({
      type: PAYMENT_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: PAYMENT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getPaymentByMonth =
  (teacherId: any, year: number, month: number, branchId: string, youthTeamId: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: GET_PAYMENT_BY_MONTH_REQUEST,
      });

      console.log(teacherId, year, month, branchId, youthTeamId)

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/payment?teacherId=${teacherId}&year=${year}&month=${month}&branchId=${branchId}&youthTeamId=${youthTeamId}`
      );

      dispatch({
        type: GET_PAYMENT_BY_MONTH_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_PAYMENT_BY_MONTH_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const batchUpdatePayment = (paymentList: any, branchId: string, teacherId: string, year: number, month: number) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  try {
    dispatch({
      type: BATCH_UPDATE_PAYMENT_REQUEST,
    });

    const { data } = await axios.put(
      `${API_SERVER_URL}/api/payment/batch/update/${branchId}`,
      paymentList,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: BATCH_UPDATE_PAYMENT_SUCCESS,
      payload: data,
    });

    window.location.href = `/youthdb/payment?teacherId=${teacherId}&year=${year}&month=${month}`;
  } catch (error: any) {
    dispatch({
      type: BATCH_UPDATE_PAYMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const xlsxUpload =
  (
    year: number,
    month: number,
    data: any[],
    branchId: string
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: XLSX_UPLOAD_REQUEST,
      });

      const { data: response } = await axios.post(
        `${API_SERVER_URL}/api/payment/xlsx/upload`,
        { year, month, data, branchId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: XLSX_UPLOAD_SUCCESS,
        payload: response,
      });

      alert("업로드가 완료되었습니다.");

      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: XLSX_UPLOAD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const xlsxUpdate =
  (
    year: number,
    month: number,
    data: any[],
    branchId: string
  ) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: XLSX_UPLOAD_REQUEST,
      });

      const { data: response } = await axios.put(
        `${API_SERVER_URL}/api/payment/xlsx/update`,
        { year, month, data, branchId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: XLSX_UPLOAD_SUCCESS,
        payload: response,
      });

      alert("업로드가 완료되었습니다.");

      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: XLSX_UPLOAD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


export const getPaymentLogList =
  (year: number, month: number, branchId: string, isMapped: string, isCard: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: PAYMENT_LOG_LIST_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/payment-log?year=${year}&month=${month}&branchId=${branchId}&isMapped=${isMapped}&isCard=${isCard}`
      );

      dispatch({
        type: PAYMENT_LOG_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: PAYMENT_LOG_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deletePaymentLog = (paymentLogId: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  try {
    dispatch({
      type: PAYMENT_LOG_DELETE_REQUEST,
    });

    const { data } = await axios.delete(
      `${API_SERVER_URL}/api/payment-log/${paymentLogId}`
    );

    dispatch({
      type: PAYMENT_LOG_DELETE_SUCCESS,
      payload: data,
    });

    window.location.reload();
  } catch (error: any) {
    dispatch({
      type: PAYMENT_LOG_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const deletePaymentLogFileName = (filename: string, branchId: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  try {
    dispatch({
      type: PAYMENT_LOG_DELETE_FILENAME_REQUEST,
    });

    const { data } = await axios.delete(
      `${API_SERVER_URL}/api/payment-log/by/file-name?fileName=${filename}&branchId=${branchId}`
    );

    dispatch({
      type: PAYMENT_LOG_DELETE_FILENAME_SUCCESS,
      payload: data,
    });

    window.location.reload();
  } catch (error: any) {
    dispatch({
      type: PAYMENT_LOG_DELETE_FILENAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const etcPaymentInput =
  (
    branchId: string,
    year: string,
    month: string,
    title: string,
    studentId: string,
    studentName: string,
    amount: number,
    urlTeacherId: string,
    paymentId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: ETC_PAYMENT_INPUT_REQUEST,
      });

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/etc-payment?paymentId=${paymentId}`,
        { branchId, year, month, title, studentId, studentName, amount },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: ETC_PAYMENT_INPUT_SUCCESS,
        payload: data,
      });

      alert("등록이 완료되었습니다.");

      window.location.href = `/youthdb/payment?teacherId=${urlTeacherId}`;
    } catch (error: any) {
      dispatch({
        type: ETC_PAYMENT_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getEtcPaymentList = (branchId: string, year: string, month: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  try {
    dispatch({
      type: ETC_PAYMENT_LIST_REQUEST,
    });

    const { data } = await axios.get(
      `${API_SERVER_URL}/api/etc-payment?branchId=${branchId}&year=${year}&month=${month}`
    );

    dispatch({
      type: ETC_PAYMENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: ETC_PAYMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteEtcPayment = (etcPaymentId: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  try {
    dispatch({
      type: ETC_PAYMENT_DELETE_REQUEST,
    });

    const { data } = await axios.delete(
      `${API_SERVER_URL}/api/etc-payment/${etcPaymentId}`
    );

    dispatch({
      type: ETC_PAYMENT_DELETE_SUCCESS,
      payload: data,
    });

    window.location.reload();
  } catch (error: any) {
    dispatch({
      type: ETC_PAYMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const etcCategoryInput =
  (branchId: string, year: string, month: string, category: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: ETC_CATEGORY_INPUT_REQUEST,
      });

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/etc-category`,
        { branchId, year, month, category },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: ETC_CATEGORY_INPUT_SUCCESS,
        payload: data,
      });

      alert("등록이 완료되었습니다.");

      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: ETC_CATEGORY_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getEtcCategoryList = (branchId: string, year: string, month: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  try {
    dispatch({
      type: ETC_CATEGORY_LIST_REQUEST,
    });

    const { data } = await axios.get(
      `${API_SERVER_URL}/api/etc-category?branchId=${branchId}&year=${year}&month=${month}`
    );

    dispatch({
      type: ETC_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: ETC_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  };
};

export const deleteEtcCategory = (etcCategoryId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: ETC_CATEGORY_DELETE_REQUEST,
      });

      const { data } = await axios.delete(
        `${API_SERVER_URL}/api/etc-category/${etcCategoryId}`
      );

      dispatch({
        type: ETC_CATEGORY_DELETE_SUCCESS,
        payload: data,
      });

      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: ETC_CATEGORY_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };