import {
  Box,
  Button,
  Card,
  CardBody,
  Grid,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
  Input,
  FormControl,
  FormLabel,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTeacherIntro, getTeacherIntroList, inputTeacherIntro, updateTeacherIntro } from "../../redux/actions/HomePageActions";
import axios from "axios";
import { API_SERVER_URL } from "../../redux/actions/actionUrl";
import { deleteImage } from "../../utils/deleteImage";
import { imageUrlGenerate } from "../../utils/imageUrlGenerate";

export default function ModifyInstructors() {
  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const teacherIntroList = useSelector((state: any) => state.teacherIntroList);
  const { loading, error, teacherIntros } = teacherIntroList;

  const dispatch: any = useDispatch();

  interface Teacher {
    _id: string;
    teacherName: string;
    teacherRank: string;
    teacherSpec: string[];
    image: string;
  }

  const [editingTeacher, setEditingTeacher] = useState<Teacher | null>(null);
  const [formValues, setFormValues] = useState({
    teacherName: "",
    teacherRank: "",
    teacherSpec: [""],
  });

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [uploading, setUploading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (branchPage) {
      dispatch(getTeacherIntroList(branchPage?.branchId));
    }
  }, [dispatch, branchPage?.branchId]);

  const handleInputChange = (e: any, index?: number) => {
    const { name, value } = e.target;
    if (index !== undefined) {
      const newSpecs = [...formValues.teacherSpec];
      newSpecs[index] = value;
      setFormValues({ ...formValues, teacherSpec: newSpecs });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleAddSpec = () => {
    setFormValues({ ...formValues, teacherSpec: [...formValues.teacherSpec, ""] });
  };

  const handleRemoveSpec = (index: number) => {
    const newSpecs = formValues.teacherSpec.filter((_, i) => i !== index);
    setFormValues({ ...formValues, teacherSpec: newSpecs });
  };

  const handleEdit = (teacher: any) => {
    setEditingTeacher(teacher);
    setFormValues(teacher);
    onOpen();
  };

  const handleDelete = (id: string) => {
    dispatch(deleteTeacherIntro(id));
  };

  const handleSubmit = async () => {
    setUploading(true);
    const imageUrl = await imageUrlGenerate(selectedImage, "branchpage");

    if (editingTeacher && (editingTeacher.image !== null) && (imageUrl !== null)) {
      await deleteImage("branchpage", editingTeacher.image);
    }

    setUploading(false);

    if (editingTeacher) {
      dispatch(updateTeacherIntro({ ...formValues, branchId: branchPage?.branchId, image: imageUrl || editingTeacher.image, _id: editingTeacher._id }));
    } else {
      dispatch(inputTeacherIntro({ ...formValues, branchId: branchPage?.branchId, image: imageUrl || "" }));
    }
    onClose();
  };

  const teacherSpecComponent = (teacherSpec: string[]) => {
    return teacherSpec.map((spec: string, index: number) => (
      <Text key={index}>{spec}</Text>
    ));
  };

  return (
    <>
      <Flex justifyContent="center" mb={4}>
        <Text fontSize="2xl" fontWeight="bold">지도자 안내 페이지 관리</Text>
      </Flex>
      <Grid
        columnGap={8}
        rowGap={8}
        templateColumns={{
          sm: "1fr",
          md: "1fr 1fr",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
          "2xl": "repeat(4, 1fr)",
        }}
        mx={{ base: 5, md: 200 }}
        mt={{ base: 30, md: 50 }}
        mb={{ base: 10, md: 30 }}
      >
        {loading ? (
          <Spinner />
        ) : error ? (
          <Text>{error}</Text>
        ) : (
          teacherIntros.map((teacher: any) => (
            <Card maxW="sm" key={teacher._id}>
              <CardBody>
                <Image src={teacher.image} alt={teacher.teacherName} />
                <Stack mt="6" spacing="3">
                  <Heading size="md">
                    {teacher.teacherRank} {teacher.teacherName}
                  </Heading>
                  {teacher.teacherSpec.length > 0 && (<Text as="b">약력</Text>)}
                  {teacherSpecComponent(teacher.teacherSpec)}
                  <Button onClick={() => handleEdit(teacher)}>수정</Button>
                  <Button onClick={() => handleDelete(teacher._id)}>삭제</Button>
                </Stack>
              </CardBody>
            </Card>
          ))
        )}
      </Grid>
      <Center>
        <Button
          colorScheme="blue"
          mb={10}
          onClick={() => { setEditingTeacher(null); setFormValues({ teacherName: "", teacherRank: "", teacherSpec: [""] }); onOpen(); }}>
          지도자 추가
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editingTeacher ? "Edit Teacher" : "Add Teacher"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>강사명</FormLabel>
              <Input
                type="text"
                name="teacherName"
                value={formValues.teacherName}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>강사 직급</FormLabel>
              <Input
                type="text"
                name="teacherRank"
                value={formValues.teacherRank}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>약력</FormLabel>
              {formValues.teacherSpec.map((spec, index) => (
                <Box key={index} display="flex">
                  <Input
                    type="text"
                    name={`teacherSpec[${index}]`}
                    value={spec}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <Button
                    onClick={() => handleRemoveSpec(index)}
                    ml={2}
                  >삭제</Button>
                </Box>
              ))}
              <Button
                onClick={handleAddSpec}
                mt={2}
              >약력 추가</Button>
            </FormControl>
            <FormControl>
              <FormLabel>사진</FormLabel>
              <img
                src={selectedImage ? URL.createObjectURL(selectedImage) : teacherIntros?.find((teacher: any) => teacher._id === editingTeacher?._id)?.image}
                alt="image"
                style={{ width: '100px' }}
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    setSelectedImage(file);
                  }
                }}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSubmit}>
              {editingTeacher ? "업데이트" : "확인"}
            </Button>
            <Button onClick={onClose}>취소</Button>
            {uploading && <Spinner />}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
