import {
  Box,
  Button,
  Center,
  Flex,
  Select,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getYouthTeamDetail,
  getYouthTeamList,
} from "../../redux/actions/YouthTeamActions";
import Datepicker from "../../components/Datepicker";

import { addAttend, deleteSupplement, getAttendTable, listSupplement } from "../../redux/actions/AttendActions";
import { useParams } from "react-router-dom";
import formatPhoneNum from "../../utils/formatPhoneNum";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

const SupplementListScreen: React.FC = () => {
  const dispatch: any = useDispatch();
  const { id } = useParams<{ id: string }>();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  const supplementList = useSelector((state: any) => state.supplementList);
  const { loading, error, supplements } = supplementList;

  useEffect(() => {
    dispatch(listSupplement(null, null, userInfo._id));
  }, [dispatch]);

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack>
        <Center>
          <Text fontSize="2xl" fontWeight="bold" p={5}>
            보강 관리
          </Text>
        </Center>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>회원명</Th>
                <Th>소속 팀</Th>
                <Th>결석일</Th>
                <Th>보강 팀</Th>
                <Th>보강 완료</Th>
                <Th>삭제</Th>
              </Tr>
            </Thead>
            <Tbody>
              {supplements &&
                supplements.map((supplement: any) => (
                  <Tr key={supplement._id}>
                    <Td>{supplement.studentName}</Td>
                    <Td>{supplement.fromTeamName}</Td>
                    <Td>{format(new Date(supplement.absentDate), "yyyy-MM-dd", { locale: ko })}</Td>
                    <Td>{supplement.toTeamName}</Td>
                    <Td>
                      {supplement.isDone ? (
                        <Tag colorScheme="green">O</Tag>
                      ) : (
                        <Tag colorScheme="red">X</Tag>
                      )}
                    </Td>
                    <Td>
                      <Button
                        isDisabled={supplement.isDone}
                        onClick={() => {
                          if (window.confirm("정말 삭제하시겠습니까?")) {
                            dispatch(deleteSupplement(supplement._id));
                          }
                        }}
                      >
                        삭제
                      </Button>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Box>
  );
}

export default SupplementListScreen;