import {
  Badge,
  Box,
  Button,
  Center,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getYouthPost } from "../../redux/actions/HomePageActions";
import { Link, useParams, useSearchParams } from "react-router-dom";
import AdminHeaderBox from "../../components/AdminHeaderBox";
import { differenceInDays } from "date-fns";
import HTMLRenderer from "../../components/HTMLRenderer";
import accessCustomize from "../../utils/accessCustomize";

export default function ProgramGuide() {
  const [searchParams] = useSearchParams();
  const pageNumber: number = Number(searchParams.get("p")) || 1;

  const { category } = useParams<{ category: string }>();
  const categoryStr = String(category);

  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const youthPostList = useSelector((state: any) => state.youthPostList);
  const { loading, error, youthPosts } = youthPostList;

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  useEffect(() => {
    dispatch(getYouthPost(branchPage?.branchId, "", pageNumber, categoryStr));
  }, [dispatch, branchPage?.branchId, categoryStr, pageNumber]);

  const text: string = categoryStr;

  return (
    <>
      {loading ? (
        <Box textAlign="center" mt={20}>
          <Spinner size="xl" />
        </Box>
      ) : error ? (
        <Box textAlign="center" mt={20}>
          <Text color="red.500">오류가 발생했습니다: {error}</Text>
        </Box>
      ) : (
        <>
          <AdminHeaderBox text={text} adminHeaderBg={branchPage && branchPage.adminHeaderBg} />
          <Box
            maxW={"1300px"}
            mx={"auto"}
            mt={{ base: 30, md: 50 }}
            mb={{ base: 30, md: 50 }}
          >
            <Center>
              {accessCustomize(teacherInfo, branchPage.branchId) && (
                <Button
                  onClick={() => {
                    window.location.href = `/${branchPage && branchPage.branchUrl}/post-write/${categoryStr}`;
                  }}
                >
                  새 안내문 작성
                </Button>
              )}
            </Center>
            <Box>
              {youthPosts.youthPost && youthPosts.youthPost.map((y: any, index: number) => (
                <Center px={4} mt={4} key={index}>
                  {teacherInfo && teacherInfo.accessList && teacherInfo.accessList.includes("원장") &&
                    <>
                      <Button as={Link} to={`/${branchPage && branchPage.branchUrl}/post/${categoryStr}/${y.postNum}/edit`}>
                        {y.title} 안내문 수정
                      </Button>
                    </>
                  }
                  <Center w={{ base: "100%", md: "70%" }}>
                    <HTMLRenderer htmlContent={y.content} />
                  </Center>
                </Center>
              ))}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
