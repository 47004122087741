import React from 'react';
import { Box, Button, HStack, IconButton, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface PaginateProps {
  pages: number;
  page: number;
  keyword?: string; // keyword는 선택적 prop입니다.
  onPageChange: (page: number) => void;
}

const Paginate: React.FC<PaginateProps> = ({ pages, page, keyword = "", onPageChange }) => {
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= pages) {
      onPageChange(newPage);
    }
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const maxPageButtons = 9;
    let startPage = Math.max(1, page - Math.floor(maxPageButtons / 2));
    let endPage = startPage + maxPageButtons - 1;

    if (endPage > pages) {
      endPage = pages;
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }

    if (startPage > 1) {
      pageButtons.push(
        <Button key={1} onClick={() => handlePageChange(1)}>
          1
        </Button>
      );
      if (startPage > 2) {
        pageButtons.push(<Text key="start-ellipsis">...</Text>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          isActive={i === page}
        >
          {i}
        </Button>
      );
    }

    if (endPage < pages) {
      if (endPage < pages - 1) {
        pageButtons.push(<Text key="end-ellipsis">...</Text>);
      }
      pageButtons.push(
        <Button key={pages} onClick={() => handlePageChange(pages)}>
          {pages}
        </Button>
      );
    }

    return pageButtons;
  };

  return (
    <HStack spacing={2} justify="center" mt={4}>
      <IconButton
        aria-label="Previous Page"
        icon={<ChevronLeftIcon />}
        isDisabled={page === 1}
        onClick={() => handlePageChange(page - 1)}
      />
      {renderPageButtons()}
      <IconButton
        aria-label="Next Page"
        icon={<ChevronRightIcon />}
        isDisabled={page === pages}
        onClick={() => handlePageChange(page + 1)}
      />
    </HStack>
  );
};

export default Paginate;
