import React from "react";
import { Button, Box, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const UploadTypeSelectScreen: React.FC = () => {
  return (
    <Box>
      <Text fontSize="3xl" textAlign="center" mb={4}>
        업로드 유형 선택
      </Text>
      <Flex justify="center" height="100vh">
        <Box mx={4}>
          <Link to="/youthdb/payment-auto">
            <Button size="lg" width="200px" height="100px" fontSize="2xl" color={"white"} bg={"blue.500"}>
              계좌이체
            </Button>
          </Link>
        </Box>
        <Box mx={4}>
          <Link to="/youthdb/payment-auto-card">
            <Button size="lg" width="200px" height="100px" fontSize="2xl" color={"white"} bg={"blue.500"}>
              카드 및 기타
            </Button>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default UploadTypeSelectScreen;
