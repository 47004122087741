export const YOUTHSTUDENT_LIST_REQUEST = "YOUTHSTUDENT_LIST_REQUEST" as const;
export const YOUTHSTUDENT_LIST_SUCCESS = "YOUTHSTUDENT_LIST_SUCCESS" as const;
export const YOUTHSTUDENT_LIST_FAIL = "YOUTHSTUDENT_LIST_FAIL" as const;
export const YOUTHSTUDENT_LIST_RESET = "YOUTHSTUDENT_LIST_RESET" as const;

export const YOUTHSTUDENT_DETAIL_REQUEST =
  "YOUTHSTUDENT_DETAIL_REQUEST" as const;
export const YOUTHSTUDENT_DETAIL_SUCCESS =
  "YOUTHSTUDENT_DETAIL_SUCCESS" as const;
export const YOUTHSTUDENT_DETAIL_FAIL = "YOUTHSTUDENT_DETAIL_FAIL" as const;
export const YOUTHSTUDENT_DETAIL_RESET = "YOUTHSTUDENT_DETAIL_RESET" as const;

export const YOUTHSTUDENT_ADD_REQUEST = "YOUTHSTUDENT_ADD_REQUEST" as const;
export const YOUTHSTUDENT_ADD_SUCCESS = "YOUTHSTUDENT_ADD_SUCCESS" as const;
export const YOUTHSTUDENT_ADD_FAIL = "YOUTHSTUDENT_ADD_FAIL" as const;
export const YOUTHSTUDENT_ADD_RESET = "YOUTHSTUDENT_ADD_RESET" as const;

export const YOUTHSTUDENT_UPDATE_REQUEST =
  "YOUTHSTUDENT_UPDATE_REQUEST" as const;
export const YOUTHSTUDENT_UPDATE_SUCCESS =
  "YOUTHSTUDENT_UPDATE_SUCCESS" as const;
export const YOUTHSTUDENT_UPDATE_FAIL = "YOUTHSTUDENT_UPDATE_FAIL" as const;
export const YOUTHSTUDENT_UPDATE_RESET = "YOUTHSTUDENT_UPDATE_RESET" as const;

export const YOUTHSTUDENT_QUIT_LIST_REQUEST = "YOUTHSTUDENT_QUIT_LIST_REQUEST" as const;
export const YOUTHSTUDENT_QUIT_LIST_SUCCESS = "YOUTHSTUDENT_QUIT_LIST_SUCCESS" as const;
export const YOUTHSTUDENT_QUIT_LIST_FAIL = "YOUTHSTUDENT_QUIT_LIST_FAIL" as const;
export const YOUTHSTUDENT_QUIT_LIST_RESET = "YOUTHSTUDENT_QUIT_LIST_RESET" as const;

export const YOUTHSTUDENT_BATCH_ADD_REQUEST = "YOUTHSTUDENT_BATCH_ADD_REQUEST" as const;
export const YOUTHSTUDENT_BATCH_ADD_SUCCESS = "YOUTHSTUDENT_BATCH_ADD_SUCCESS" as const;
export const YOUTHSTUDENT_BATCH_ADD_FAIL = "YOUTHSTUDENT_BATCH_ADD_FAIL" as const;
export const YOUTHSTUDENT_BATCH_ADD_RESET = "YOUTHSTUDENT_BATCH_ADD_RESET" as const;

export const BRANCH_YOUTHSTUDENT_LIST_REQUEST = "BRANCH_YOUTHSTUDENT_LIST_REQUEST" as const;
export const BRANCH_YOUTHSTUDENT_LIST_SUCCESS = "BRANCH_YOUTHSTUDENT_LIST_SUCCESS" as const;
export const BRANCH_YOUTHSTUDENT_LIST_FAIL = "BRANCH_YOUTHSTUDENT_LIST_FAIL" as const;
export const BRANCH_YOUTHSTUDENT_LIST_RESET = "BRANCH_YOUTHSTUDENT_LIST_RESET" as const;

export const TRANSFER_ADD_REQUEST = "TRANSFER_ADD_REQUEST" as const;
export const TRANSFER_ADD_SUCCESS = "TRANSFER_ADD_SUCCESS" as const;
export const TRANSFER_ADD_FAIL = "TRANSFER_ADD_FAIL" as const;
export const TRANSFER_ADD_RESET = "TRANSFER_ADD_RESET" as const;

export const TRANSFER_LIST_REQUEST = "TRANSFER_ACCEPT_REQUEST" as const;
export const TRANSFER_LIST_SUCCESS = "TRANSFER_ACCEPT_SUCCESS" as const;
export const TRANSFER_LIST_FAIL = "TRANSFER_ACCEPT_FAIL" as const;
export const TRANSFER_LIST_RESET = "TRANSFER_ACCEPT_RESET" as const;