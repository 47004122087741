import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  addConsult,
  getConsultList,
  updateConsultList,
  updateMemoList,
} from "../../redux/actions/MemoActions";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Tab,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { getYouthStudentDetail } from "../../redux/actions/YouthStudentActions";

const ConsultMainScreen = () => {
  let { id } = useParams();

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const studentId = id!.toString();

  const consultList = useSelector((state: any) => state.consultList);
  const { loading, error, consults } = consultList;

  const youthStudentDetail = useSelector((state: any) => state.youthStudentDetail);
  const { youthStudent } = youthStudentDetail;

  const [consultParents, setConsultParents] = useState<string>("");
  const [consultTeacher, setConsultTeacher] = useState<string>("");
  const [checkboxState, setCheckboxState] = useState<boolean>(false);

  const [date, setDate] = useState<Date | undefined>(new Date());

  const handleDateChange = (newDate: Date | null) => {
    if (newDate === null) {
      setDate(undefined);
    } else {
      setDate(newDate);
    }
  }

  const dateWithDayOfWeek = (date: Date) => {
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}(${days[date.getDay()]})`;
  }

  const submitHandler = () => {
    if (date && consultParents !== "" && consultTeacher !== "") {
      dispatch(addConsult(date, studentId, userInfo._id, teacherInfo.branch._id, consultParents, consultTeacher, checkboxState));
    } else {
      alert("상담일지를 작성해주세요.");
    }
  }

  useEffect(() => {
    dispatch(getYouthStudentDetail(studentId));
  }, [dispatch, studentId]);

  useEffect(() => {
    dispatch(getConsultList(studentId, "", ""));
  }, [dispatch, studentId]);

  return (
    <Box mx={{ base: "4", md: "40" }} pb={{ base: "40", md: "0" }} mb={20}>
      <Center>
        <VStack>
          <Text fontSize="4xl" fontWeight="bold">
            {youthStudent?.name} 회원 상담일지 작성
          </Text>
          {/* <Text textAlign={"center"}>
            반드시 한 회원에 대하여 질문칸과 답변칸 모두 작성을 해야합니다.
            <br /> 어느 한쪽을 작성하지 않을 시, 해당 데이터는 업데이트가 되지
            않습니다.
            <br />
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}
            >
              중요한 상담일지는 체크박스를 체크해주세요.
            </span>
          </Text> */}
        </VStack>
      </Center>
      <Center mt={50}>
        <TableContainer overflowX="auto">
          <Table variant="simple" border="2px solid" borderColor="gray.400">
            <Thead bg="gray.100">
              <Tr>
                <Th
                  textAlign="center"
                  left="0"
                  borderWidth="1px"
                  borderColor="gray.400"
                  bg="gray.100"
                  fontSize={"md"}
                  w={"200px"}
                >
                  날짜 선택
                </Th>
                <Th
                  textAlign="center"
                  bg="gray.100"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                  w={"1000px"}
                >
                  상담일지 내용
                </Th>
                <Th
                  textAlign="center"
                  bg="gray.100"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                >
                  중요
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr minH="80px">
                <Td
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                >
                  <input
                    type="date"
                    value={date?.toISOString().split("T")[0]}
                    onChange={(e) => handleDateChange(new Date(e.target.value))}
                    style={{
                      width: "100%",
                      height: "70px",
                      padding: "10px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Td>
                <Td
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                  paddingLeft="2"
                  paddingRight="2"
                  paddingTop="2"
                  paddingBottom="2"
                >
                  <Text textAlign={"left"} mb={1}>
                    강사
                  </Text>
                  <Textarea
                    placeholder="강사 내용란"
                    resize="vertical"
                    minH="unset"
                    overflowY="auto"
                    w="100%"
                    h="70px"
                    onChange={(e) => setConsultTeacher(e.target.value)}
                  />
                  <br />
                  <Text mt={2} mb={1} textAlign={"left"}>
                    학부모
                  </Text>
                  <Textarea
                    placeholder="학부모 내용란"
                    resize="vertical"
                    minH="unset"
                    overflowY="auto"
                    w="100%"
                    h="70px"
                    onChange={(e) => setConsultParents(e.target.value)}
                  />
                </Td>
                <Td
                  textAlign="center"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                >
                  <Checkbox
                    size="lg"
                    colorScheme="orange"
                    isChecked={checkboxState}
                    onChange={() => setCheckboxState(!checkboxState)}
                  ></Checkbox>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
      <Center mt={10}>
        <Button
          colorScheme="orange"
          w={"150px"}
          h={"50px"}
          onClick={submitHandler}
        >
          작성하기
        </Button>
      </Center>
      <Center>
        <VStack>
          <Text fontSize="4xl" fontWeight="bold" mt={20}>
            {youthStudent?.name} 회원 상담일지 목록
          </Text>
          <Text color={"gray.500"} fontSize={"md"}>
            상담일지 수정 - 제목 클릭
          </Text>
        </VStack>
      </Center>
      <Center mt={50}>
        <TableContainer overflowX="auto">
          <Table variant="simple" border="2px solid" borderColor="gray.400">
            <Thead bg="gray.100">
              <Tr>
                <Th
                  textAlign="center"
                  left="0"
                  borderWidth="1px"
                  borderColor="gray.400"
                  bg="gray.100"
                  fontSize={"md"}
                  padding={2}
                >
                  날짜
                </Th>
                <Th
                  textAlign="center"
                  bg="gray.100"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                  w={"1000px"}
                >
                  상담일지 내용
                </Th>
                <Th
                  textAlign="center"
                  bg="gray.100"
                  borderWidth="1px"
                  borderColor="gray.400"
                  fontSize={"md"}
                >
                  중요
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Center>
                  <Text>로딩중...</Text>
                </Center>
              ) : error ? (
                <Center>
                  <Text>에러 발생! 다시 시도해주세요.</Text>
                </Center>
              ) : (
                consults &&
                consults &&
                consults.map((consult: any, index: number) => (
                  <Tr key={index} minH="80px">
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontSize={"md"}
                    >
                      <Link
                        to={`/youthdb/consultmemo/${consult._id}`}
                      >
                        {dateWithDayOfWeek(new Date(consult.date))}
                      </Link>
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontSize={"md"}
                      paddingLeft="2"
                      paddingRight="2"
                      paddingTop="2"
                      paddingBottom="2"
                    >
                      <Text textAlign={"left"} mb={1} color={"gray.500"}>
                        강사
                      </Text>
                      <Text textAlign={"left"} fontWeight="bold">{consult.consultTeacher}</Text>
                      <Divider my={2} borderColor="gray.300" />
                      <Text mt={2} mb={1} textAlign={"left"} color={"gray.500"}>
                        학부모
                      </Text>
                      <Text textAlign={"left"} fontWeight="bold">{consult.consultParents}</Text>
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontSize={"md"}
                    >
                      <Checkbox
                        size="lg"
                        colorScheme="orange"
                        isChecked={consult.isImportant}
                      ></Checkbox>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
    </Box>
  );
};

export default ConsultMainScreen;
// import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { useParams } from "react-router-dom";
// import { getMemoList, updateMemoList } from "../../redux/actions/MemoActions";
// import {
//   Box,
//   Button,
//   Center,
//   Tab,
//   Table,
//   TableContainer,
//   Tbody,
//   Td,
//   Text,
//   Textarea,
//   Th,
//   Thead,
//   Tr,
//   VStack,
// } from "@chakra-ui/react";

// const ConsultMainScreen = () => {
//   let { id } = useParams();

//   const dispatch: any = useDispatch();

//   const teamId = id!.toString();

//   const memoTeamList = useSelector((state: any) => state.memoTeamList);
//   const { loading, error, memos } = memoTeamList;

//   const memoTeamUpdate = useSelector((state: any) => state.memoTeamUpdate);
//   const { loading: updateLoading, error: updateError } = memoTeamUpdate;

//   return (
//     <Box mx={{ base: "4", md: "40" }} pb={{ base: "40", md: "0" }}>
//       <Center>
//         <VStack>
//           <Text fontSize="4xl" fontWeight="bold">
//             상담일지 작성
//           </Text>
//         </VStack>
//       </Center>
//       <Center mt={50}>
//         <TableContainer maxH="2000px" overflowX="auto">
//           <Table variant="simple" border="2px solid" borderColor="gray.400">
//             <Thead bg="gray.100">
//               <Tr>
//                 <Th
//                   textAlign="center"
//                   left="0"
//                   borderWidth="1px"
//                   borderColor="gray.400"
//                   bg="gray.100"
//                   fontSize={"md"}
//                 >
//                   순번
//                 </Th>
//                 <Th
//                   textAlign="center"
//                   bg="gray.100"
//                   borderWidth="1px"
//                   borderColor="gray.400"
//                   fontSize={"md"}
//                 >
//                   이름
//                 </Th>
//                 <Th
//                   textAlign="center"
//                   bg="gray.100"
//                   borderWidth="1px"
//                   borderColor="gray.400"
//                   fontSize={"md"}
//                   w={"1000px"}
//                 >
//                   한줄평 내용
//                 </Th>
//               </Tr>
//             </Thead>
//             <Tbody>
//               {loading ? (
//                 <Center>
//                   <Text>로딩중...</Text>
//                 </Center>
//               ) : error ? (
//                 <Center>
//                   <Text>에러 발생! 다시 시도해주세요.</Text>
//                 </Center>
//               ) : (
//                 memos &&
//                 memos.memo &&
//                 memos.memo.map((memo: any, index: number) => (
//                   <Tr key={index} minH="80px">
//                     <Td
//                       textAlign="center"
//                       borderWidth="1px"
//                       borderColor="gray.400"
//                       fontSize={"md"}
//                     >
//                       {index + 1}
//                     </Td>
//                     <Td
//                       textAlign="center"
//                       borderWidth="1px"
//                       borderColor="gray.400"
//                       fontSize={"md"}
//                     >
//                       {memo.studentName}
//                     </Td>
//                     <Td
//                       textAlign="center"
//                       borderWidth="1px"
//                       borderColor="gray.400"
//                       fontSize={"md"}
//                       paddingLeft="2"
//                       paddingRight="2"
//                       paddingTop="2"
//                       paddingBottom="2"
//                     >
//                       <Textarea
//                         placeholder="한줄평을 작성해주세요."
//                         defaultValue={memo.memo}
//                         resize="vertical"
//                         minH="unset"
//                         overflowY="auto"
//                         w="100%"
//                         h="70px"
//                         onChange={(e) => {
//                           memos.memo[index].memo = e.target.value;
//                         }}
//                       />
//                     </Td>
//                   </Tr>
//                 ))
//               )}
//             </Tbody>
//           </Table>
//         </TableContainer>
//       </Center>
//       <Center mt={10}>
//         <Button
//           colorScheme="orange"
//           w={"150px"}
//           h={"50px"}
//           // onClick={memoUpdateHandler}
//         >
//           업데이트
//         </Button>
//       </Center>
//     </Box>
//   );
// };

// export default ConsultMainScreen;
