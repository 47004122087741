import {
  USER_LOGIN_FAIL,
  USER_LOGIN_LOGOUT,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_FIND_TEACHER_REQUEST,
  USER_FIND_TEACHER_SUCCESS,
  USER_FIND_TEACHER_FAIL,
  USER_FIND_TEACHER_RESET,
} from "../constants/UserConstants";

export const userLoginReducer = (
  state = {},
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGIN_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userFindTeacherReducer = (
  state = { teacher: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case USER_FIND_TEACHER_REQUEST:
      return { loading: true };
    case USER_FIND_TEACHER_SUCCESS:
      return { loading: false, teacher: action.payload };
    case USER_FIND_TEACHER_FAIL:
      return { loading: false, error: action.payload };
    case USER_FIND_TEACHER_RESET:
      return { teacher: {} };
    default:
      return state;
  }
};