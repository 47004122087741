import axios from "axios";
import { API_SERVER_URL } from "../redux/actions/actionUrl";

export const deleteImage = async (bucket: string, url: string) => {
  const key = url.split("/").pop();

  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    
    await axios.delete(
      `${API_SERVER_URL}/api/upload/youthpage/${bucket}/${key}`,
      config
    );

    return true;
  } catch (error) {
    console.error(error);

    return false;
  }
};