import { Box, Text, Center } from "@chakra-ui/react";
import React from "react";

type FooterProps = {
  footerInfo: {
    zipCode: string;
    address: string;
    chiefName: string;
    companyRegistrationNum: string;
    email: string;
  };
};

const Footer: React.FC<FooterProps> = ({ footerInfo }) => {
  return (
    <Box backgroundColor={"gray.100"} pt={6} pb={6}>
      <Text fontSize="xs" color="gray.500" textAlign={"center"}>
        ({footerInfo.zipCode}) {footerInfo.address}
        <br />
        대표 {footerInfo.chiefName} | 사업자등록번호 {footerInfo.companyRegistrationNum} | Email : {footerInfo.email}
      </Text>
      <Text fontSize="sm" color="gray.500" textAlign={"center"} mt={4}>
        © 2024 SCHOOLB All rights reserved.
      </Text>
    </Box>
  );
};

export default Footer;
