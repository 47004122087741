import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Steps, Step } from "chakra-ui-steps";
import { getNiceButtonSession, getNiceIdentificationResult } from "../../redux/actions/nice";
import { useDispatch } from "react-redux";
import { register } from "../../redux/actions/UserAction";
import { API_SERVER_URL } from "../../redux/actions/actionUrl";
import axios from "axios";
import PopupPostCode from "../../components/PopupPostCode";
import PopupDom from "../../components/PopupDom";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import RegistrationTerm from "../../static/registrationTerm";

interface FormData {
  token_version_id: string;
  enc_data: string;
  integrity_value: string;
}

interface PreForm {
  name: string;
  mobile: string;
  birthdate: string;
}

const ActionButton: React.FC<{ text: string; onClick: () => void }> = ({ text, onClick }) => (
  <Box display="flex" justifyContent="center" marginTop="24px">
    <Button
      height="50px"
      fontSize="18px"
      fontWeight="bold"
      backgroundColor="#031b31"
      color="white"
      width="50%"
      onClick={onClick}
    >
      {text}
    </Button>
  </Box>
);

const IdentificationComponent: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    token_version_id: "",
    enc_data: "",
    integrity_value: "",
  });

  useEffect(() => {
    getNiceButtonSession()
      .then((sessionResult) => {
        if (sessionResult) {
          const { sid, ...formData } = sessionResult;
          setFormData(formData);
          // sid를 사용하는 경우
        } else {
          // sessionResult가 null인 경우의 처리
          console.error("Session result is null");
        }
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <VStack spacing={4} align="stretch" mt={5}>
      <Text fontSize="2xl" textAlign="center">
        본인인증
      </Text>
      <Text textAlign="center">회원가입을 위해, 본인인증을 완료해주세요.</Text>
      <form
        name="nice-ident-form"
        id="nice-ident-form"
        method="post"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
      >
        <Input type="hidden" id="m" name="m" value="service" />
        <Input type="hidden" id="token_version_id" name="token_version_id" value={formData.token_version_id} />
        <Input type="hidden" id="enc_data" name="enc_data" value={formData.enc_data} />
        <Input type="hidden" id="integrity_value" name="integrity_value" value={formData.integrity_value} />
        <Button
          type="submit"
          backgroundColor="#031B31"
          color="white"
          width="220px"
          height="110px"
          fontWeight="bold"
          fontSize="32px"
          isDisabled={
            !(
              formData.token_version_id &&
              formData.enc_data &&
              formData.integrity_value
            )
          }
        >
          본인인증
        </Button>
      </form>
    </VStack>
  );
};

const TermsComponent: React.FC<{ onAgree: () => void }> = ({ onAgree }) => {
  const onClickNext = () => {
    const checked = document.getElementById("term-check") as HTMLInputElement;
    if (!checked.checked) {
      alert("이용약관에 동의하여야 회원가입이 가능합니다.");
    } else {
      onAgree();
    }
  };

  return (
    <VStack spacing={4} align="stretch" mt={5} p={5}>
      <Text fontSize="2xl" textAlign="center">
        이용약관
      </Text>
          <RegistrationTerm />
      <FormControl display="flex" alignItems="center" justifyContent="center">
        <FormLabel htmlFor="term-check" mb="0">
          <Text>이용약관 동의</Text>
          <Text color="red" display="inline"> [필수]</Text>
        </FormLabel>
        <Checkbox id="term-check" />
      </FormControl>
      <ActionButton onClick={onClickNext} text="다음" />
    </VStack>
  );
};

const FormComponent: React.FC<{ session: string; preform: PreForm }> = ({ session, preform }) => {
  const [userId, setUserId] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confPassword, setConfPassword] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [addressDetail, setAddressDetail] = useState<string>("");
  const [playerId, setPlayerId] = useState<string>("");

  const [message, setMessage] = useState<string>("");

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(false);

  const [matched, setMatched] = useState<boolean>(false);
  const [matchedPlayer, setMatchedPlayer] = useState<any>(null);
  const [notMatchedOption, setNotMatchedOption] = useState<
    { label: string; value: string }[]
  >([]);

  const dispatch = useDispatch();
  const toast = useToast();

  async function checkDuplicateId(userId: string) {
    try {
      let res = await axios.get(
        `${API_SERVER_URL}/api/user/duplicate-id/check?userId=${userId}`
      );
      let data = res.data;
      if (data === true) {
        toast({ title: "이미 사용중인 아이디입니다.", status: "error" });
      } else {
        toast({ title: "사용 가능한 아이디입니다.", status: "success" });
        setFlag(true);
      }
    } catch (error) {
      console.error(error);
      toast({ title: "오류가 발생했습니다.", status: "error" });
    }
  }

  const duplicateIdHandler = () => {
    checkDuplicateId(userId);
  };

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();

    if (!flag) {
      toast({ title: "아이디 중복확인을 해주세요.", status: "error" });
      return;
    }
    if (
      userId === "" ||
      email === "" ||
      password === "" ||
      confPassword === "" ||
      address1 === "" ||
      addressDetail === ""
    ) {
      toast({ title: "모든 정보를 입력해주세요.", status: "error" });
      return;
    }
    if (password !== confPassword) {
      toast({ title: "비밀번호가 일치하지 않습니다.", status: "error" });
      return;
    }

    const address = `${address1}, ${addressDetail}`;
    (dispatch as ThunkDispatch<any, any, AnyAction>)(
      register(session, userId, password, email, address, playerId)
    );
  };

  useEffect(() => {
    async function fetchData() {
      if (preform) {
        try {
          const existUser = await axios.get(
            `${API_SERVER_URL}/api/user/find/exist?name=${preform.name}&birthday=${preform.birthdate}`
          );
          const existData = existUser.data;
          if (existData) {
            alert(`이미 가입 된 사용자입니다. 로그인 해 주십시오. ID:${existData}`);
            window.location.href = "/login";
          }

          const res = await axios.get(
            `${API_SERVER_URL}/api/user/match/player?name=${preform.name}&birthday=${preform.birthdate}`
          );
          const data = res.data;
          if (data.matched) {
            setMatched(true);
            setMatchedPlayer(data.playerProfile);
            setMessage(data.message);
            setPlayerId(data.playerProfile._id);
          } else {
            setMatched(false);
            setMessage("등록된 선수가 없습니다.");
            const option = [{ label: "(새 선수 등록)", value: "" }];
            setNotMatchedOption(option);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchData();
  }, [preform]);

  return (
    <form onSubmit={submitForm}>
      <VStack spacing={4} align="stretch" mt={5}>
        <FormControl>
          <FormLabel>이름</FormLabel>
          <Input type="text" value={preform.name} isDisabled />
        </FormControl>
        <FormControl>
          <FormLabel>생년월일</FormLabel>
          <Input type="text" value={preform.birthdate} isDisabled />
        </FormControl>
        <FormControl>
          <FormLabel>휴대폰 번호</FormLabel>
          <Input type="text" value={preform.mobile} isDisabled />
        </FormControl>
        <FormControl>
          <FormLabel>아이디</FormLabel>
          <Box display="flex" alignItems="center">
            <Input
              type="text"
              placeholder="아이디"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
            <Button onClick={duplicateIdHandler} ml={2}>
              중복확인
            </Button>
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel>비밀번호</FormLabel>
          <Input
            type="password"
            placeholder="비밀번호"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>비밀번호 확인</FormLabel>
          <Input
            type="password"
            placeholder="비밀번호 확인"
            value={confPassword}
            onChange={(e) => setConfPassword(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>이메일 주소</FormLabel>
          <Input
            type="email"
            placeholder="아이디 / 비밀번호 찾기에 활용됩니다"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>주소</FormLabel>
          <Box display="flex" alignItems="center">
            <Input
              type="text"
              value={address1}
              isDisabled
            />
            <Button onClick={() => setPopupOpen(true)} ml={2}>
              주소찾기
            </Button>
          </Box>
          {popupOpen && (
            <PopupDom>
              <PopupPostCode
                onClose={() => setPopupOpen(false)}
                onAddData={(data: any) => setAddress1(data.address)}
              />
            </PopupDom>
          )}
          <Input
            type="text"
            placeholder="[필수] 상세 주소(동∙호수∙층)"
            value={addressDetail}
            onChange={(e) => setAddressDetail(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>선수 계정 연동</FormLabel>
          {matched ? (
            <>
              <FormLabel>{message}</FormLabel>
              <Input
                type="text"
                value={`${matchedPlayer.name} / ${(matchedPlayer.teamName ? matchedPlayer.teamName.join(", ") : "팀 없음")}`}
                isDisabled
              />
            </>
          ) : (
            <>
              <FormLabel>{message}</FormLabel>
              <RadioGroup>
                {notMatchedOption.map((option) => (
                  <Radio key={option.value} value={option.value} onChange={() => setPlayerId(option.value)}>
                    {option.label}
                  </Radio>
                ))}
              </RadioGroup>
            </>
          )}
        </FormControl>
        <ActionButton text="가입하기" onClick={() => (console.log("가입"))} />
      </VStack>
    </form>
  );
};

const Register: React.FC = () => {
  const { sid } = useParams<{ sid: string }>();
  const isCreatedSession = () => !!sid;

  const [stage, setStage] = useState<number>(isCreatedSession() ? 1 : 0);
  const [preform, setPreform] = useState<PreForm>({
    name: "",
    mobile: "",
    birthdate: "",
  });

  useEffect(() => {
    if (isCreatedSession()) {
      getNiceIdentificationResult(sid!).then((result) => {
        if (result)
          setPreform({
            name: result.name,
            mobile: result.mobile,
            birthdate: result.birthdate,
          });
      });
    }
  }, [sid]);

  const steps = [
    { label: "본인인증" },
    { label: "약관동의" },
    { label: "회원정보" },
  ];

  return (
    <VStack spacing={5}>
      <Text fontSize="2xl" fontWeight="bold">
        스쿨비 가입하기
      </Text>
      <Text>
        회원가입하여 지금 바로 모든 기록을 확인하세요!
      </Text>
      {/* <Steps activeStep={stage} colorScheme="teal" size="lg">
        {steps.map((step, index) => (
          <Step key={index} label={step.label} />
        ))}
      </Steps> */}
      {stage === 0 && <IdentificationComponent />}
      {stage === 1 && <TermsComponent onAgree={() => setStage(2)} />}
      {stage === 2 && <FormComponent session={sid!} preform={preform} />}
    </VStack>
  );
};

export default Register;