import {
  Box,
  Button,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getAttendById,
} from "../../redux/actions/AttendActions";
import { getYouthTeamBranchList, getYouthTeamDetail, getYouthTeamList } from "../../redux/actions/YouthTeamActions";
import { addSupplement } from "../../redux/actions/AttendActions";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

const SupplementAddScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const attendId = String(id);
  const [searchParams] = useSearchParams();
  const studentId: string = searchParams.get("studentId") || "";

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const attendInfo = useSelector((state: any) => state.attendInfo);
  const { loading, error, attend } = attendInfo;

  const youthTeamBranchList = useSelector((state: any) => state.youthTeamBranchList);
  const { branchYouthTeams } = youthTeamBranchList;

  const [toTeamId, setToTeamId] = useState<string>("");

  const submitHandler = () => {
    dispatch(addSupplement(attend.youthTeamId, toTeamId, attend._id, studentId, attend.date, false));
  };

  const dateFormat = (date: string) => {
    if (!date) return "";
    return (
      <>
        {format(new Date(date), "yyyy-MM-dd", { locale: ko })}
        ({format(new Date(date), "eeee", { locale: ko })})
      </>
    );
  }

  useEffect(() => {
    dispatch(getYouthTeamBranchList(teacherInfo.branch._id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAttendById(attendId));
  }, [dispatch, attendId]);

  const teacherOption = branchYouthTeams && branchYouthTeams.map((t: any) => ({
    value: t.teacherId,
    label: t.teacherName
  }));

  const [youthTeamList, setYouthTeamList] = useState<any>([]);

  const teacherSelectHandler = (teacherId: string) => {
    console.log(teacherId)
    const tempList = branchYouthTeams.find((t: any) => t.teacherId.toString() === teacherId);

    setYouthTeamList(tempList.youthTeam.map((t: any) => ({
      value: t._id,
      label: t.name
    })));
  };

  return (
    <Box>
      {loading ? (
        <Center minH="100vh">
          <Spinner size="xl" />
        </Center>
      ) : error ? (
        <Center minH="100vh">
          <Text color="red.500">오류가 발생했습니다: {error}</Text>
        </Center>
      ) : (
        <Box>
          <Box maxW="1300px" mx="auto" mt={20}>
            <Box
              mt="50px"
              p="20px"
              bg="white"
              borderRadius="md"
              border={"1px solid #E2E8F0"}
            >
              <VStack mt={4} spacing={4}>
                <Text fontSize="xl" fontWeight="bold">
                  보강 신청
                </Text>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>회원 이름</Th>
                        <Th>출석 날짜</Th>
                        <Th>출석 여부</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>{attend && attend.attendance && attend.attendance.find((a: any) => a.studentId === studentId)?.studentName}</Td>
                        <Td>{attend && dateFormat(attend?.date)}</Td>
                        <Td>{attend.isDone ? "출석" : "결석"}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
                <Flex
                  align="center"
                  mb={4}
                >
                  <Text mr={4}>강사 선택</Text>
                  <Select
                    options={teacherOption}
                    placeholder="강사 선택"
                    onChange={(e: any) => teacherSelectHandler(e.value)}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        width: "200px",
                      }),
                    }}
                  />
                </Flex>
                <Flex
                  align="center"
                  mb={4}
                >
                  <Text mr={4}>팀 선택</Text>
                  <Select
                    options={youthTeamList}
                    placeholder="팀 선택"
                    onChange={(e: any) => setToTeamId(e.value)}
                    styles={{
                      control: (styles) => ({
                        ...styles,
                        width: "200px",
                      }),
                    }}
                  />
                </Flex>
                <Button onClick={submitHandler}>제출</Button>
              </VStack>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SupplementAddScreen;