export const ATTEND_REQUEST = "ATTEND_REQUEST" as const;
export const ATTEND_SUCCESS = "ATTEND_SUCCESS" as const;
export const ATTEND_FAIL = "ATTEND_FAIL" as const;
export const ATTEND_RESET = "ATTEND_RESET" as const;

export const ATTEND_TABLE_REQUEST = "ATTEND_TABLE_REQUEST" as const;
export const ATTEND_TABLE_SUCCESS = "ATTEND_TABLE_SUCCESS" as const;
export const ATTEND_TABLE_FAIL = "ATTEND_TABLE_FAIL" as const;
export const ATTEND_TABLE_RESET = "ATTEND_TABLE_RESET" as const;

export const ATTEND_INPUT_REQUEST = "ATTEND_INPUT_REQUEST" as const;
export const ATTEND_INPUT_SUCCESS = "ATTEND_INPUT_SUCCESS" as const;
export const ATTEND_INPUT_FAIL = "ATTEND_INPUT_FAIL" as const;
export const ATTEND_INPUT_RESET = "ATTEND_INPUT_RESET" as const;

export const GET_ATTEND_BY_ID_REQUEST = "GET_ATTEND_BY_ID_REQUEST" as const;
export const GET_ATTEND_BY_ID_SUCCESS = "GET_ATTEND_BY_ID_SUCCESS" as const;
export const GET_ATTEND_BY_ID_FAIL = "GET_ATTEND_BY_ID_FAIL" as const;
export const GET_ATTEND_BY_ID_RESET = "GET_ATTEND_BY_ID_RESET" as const;

export const UPDATE_ATTEND_BY_ID_REQUEST =
  "UPDATE_ATTEND_BY_ID_REQUEST" as const;
export const UPDATE_ATTEND_BY_ID_SUCCESS =
  "UPDATE_ATTEND_BY_ID_SUCCESS" as const;
export const UPDATE_ATTEND_BY_ID_FAIL = "UPDATE_ATTEND_BY_ID_FAIL" as const;

export const BATCH_UPDATE_ATTEND_REQUEST = "BATCH_UPDATE_ATTEND_REQUEST" as const;
export const BATCH_UPDATE_ATTEND_SUCCESS = "BATCH_UPDATE_ATTEND_SUCCESS" as const;
export const BATCH_UPDATE_ATTEND_FAIL = "BATCH_UPDATE_ATTEND_FAIL" as const;

export const DELETE_ATTEND_BY_ID_REQUEST =
  "DELETE_ATTEND_BY_ID_REQUEST" as const;
export const DELETE_ATTEND_BY_ID_SUCCESS =
  "DELETE_ATTEND_BY_ID_SUCCESS" as const;
export const DELETE_ATTEND_BY_ID_FAIL = "DELETE_ATTEND_BY_ID_FAIL" as const;

export const SUPPLEMENT_INPUT_REQUEST = "SUPPLEMENT_INPUT_REQUEST" as const;
export const SUPPLEMENT_INPUT_SUCCESS = "SUPPLEMENT_INPUT_SUCCESS" as const;
export const SUPPLEMENT_INPUT_FAIL = "SUPPLEMENT_INPUT_FAIL" as const;
export const SUPPLEMENT_INPUT_RESET = "SUPPLEMENT_INPUT_RESET" as const;

export const SUPPLEMENT_LIST_REQUEST = "SUPPLEMENT_LIST_REQUEST" as const;
export const SUPPLEMENT_LIST_SUCCESS = "SUPPLEMENT_LIST_SUCCESS" as const;
export const SUPPLEMENT_LIST_FAIL = "SUPPLEMENT_LIST_FAIL" as const;
export const SUPPLEMENT_LIST_RESET = "SUPPLEMENT_LIST_RESET" as const;

export const SUPPLEMENT_DELETE_REQUEST = "SUPPLEMENT_DELETE_REQUEST" as const;
export const SUPPLEMENT_DELETE_SUCCESS = "SUPPLEMENT_DELETE_SUCCESS" as const;
export const SUPPLEMENT_DELETE_FAIL = "SUPPLEMENT_DELETE_FAIL" as const;
export const SUPPLEMENT_DELETE_RESET = "SUPPLEMENT_DELETE_RESET" as const;