import axios from "axios";
import {
  BRANCH_PAGE_REQUEST,
  BRANCH_PAGE_SUCCESS,
  BRANCH_PAGE_FAIL,
  BRANCH_PAGE_UPDATE_REQUEST,
  BRANCH_PAGE_UPDATE_SUCCESS,
  BRANCH_PAGE_UPDATE_FAIL,
  YOUTH_POST_INPUT_REQUEST,
  YOUTH_POST_INPUT_SUCCESS,
  YOUTH_POST_INPUT_FAIL,
  YOUTH_POST_LIST_REQUEST,
  YOUTH_POST_LIST_SUCCESS,
  YOUTH_POST_LIST_FAIL,
  YOUTH_POST_DETAIL_REQUEST,
  YOUTH_POST_DETAIL_SUCCESS,
  YOUTH_POST_DETAIL_FAIL,
  YOUTH_POST_UPDATE_REQUEST,
  YOUTH_POST_UPDATE_SUCCESS,
  YOUTH_POST_UPDATE_FAIL,
  TEACHER_INTRO_LIST_REQUEST,
  TEACHER_INTRO_LIST_SUCCESS,
  TEACHER_INTRO_LIST_FAIL,
  TEACHER_INTRO_INPUT_REQUEST,
  TEACHER_INTRO_INPUT_SUCCESS,
  TEACHER_INTRO_INPUT_FAIL,
  TEACHER_INTRO_UPDATE_REQUEST,
  TEACHER_INTRO_UPDATE_SUCCESS,
  TEACHER_INTRO_UPDATE_FAIL,
  TEACHER_INTRO_DELETE_REQUEST,
  TEACHER_INTRO_DELETE_SUCCESS,
  TEACHER_INTRO_DELETE_FAIL,
} from "../constants/HomePageConstants";
import { API_SERVER_URL } from "./actionUrl";

export const getBranchPageByUrl = (url: string) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    dispatch({
      type: BRANCH_PAGE_REQUEST,
    });

    const { data } = await axios.get(`${API_SERVER_URL}/api/branch-page/branch/url?branchUrl=${url}`);

    dispatch({
      type: BRANCH_PAGE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: BRANCH_PAGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateBranchPage =
  (branchPage: {
    branchId: string;
    introduction?: string,
    headerBg?: string;
    adminHeaderBg?: string;
    programGuideList?: string[];
    logo?: string;
    bgImage?: string;
    address?: string;
    latlng?: {
      lat?: number;
      lng?: number;
    }
    phoneNum?: string;
    additionalInfo?: string;
    footerInfo?: {
      zipCode?: string;
      address?: string;
      chiefName?: string;
      companyRegistrationNum?: string;
      email?: string;
    },
    greetingInfo?: {
      content?: string;
      specList?: {
        specName: string;
        specContent: string[];
      }[];
      image?: string[];
    },
    _id: string
  }) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: BRANCH_PAGE_UPDATE_REQUEST,
      });

      const { data } = await axios.put(`${API_SERVER_URL}/api/branch-page/${branchPage._id}`,
        branchPage
      );

      dispatch({
        type: BRANCH_PAGE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BRANCH_PAGE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const inputYouthPost = (
  branchId: string,
  title: string,
  content: string,
  category: string,
  author: string,
  authorId: string,
) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void,
  getState: () => { (): any; new(): any; userLogin: { userInfo: any; }; },
) => {
    try {
      const { userLogin: { userInfo } } = getState();

      dispatch({
        type: YOUTH_POST_INPUT_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userInfo.token}`
        },
      };

      const { data } = await axios.post(`${API_SERVER_URL}/api/youth-post`, {
        branchId,
        title,
        content,
        category,
        author,
        authorId,
      },
        config
      );

      dispatch({
        type: YOUTH_POST_INPUT_SUCCESS,
        payload: data,
      });

      alert("작성이 완료되었습니다.");
    } catch (error: any) {
      dispatch({
        type: YOUTH_POST_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }

export const getYouthPost =
  (
    branchId: string,
    keyword: string = "",
    pageNumber: number = 1,
    category: string = "",
  ) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({ type: YOUTH_POST_LIST_REQUEST });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/youth-post?branchId=${branchId}&keyword=${keyword}&pageNumber=${pageNumber}&category=${category}`
      );

      dispatch({
        type: YOUTH_POST_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: YOUTH_POST_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getYouthPostDetail = (postId: string) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    dispatch({ type: YOUTH_POST_DETAIL_REQUEST });

    const { data } = await axios.get(`${API_SERVER_URL}/api/youth-post/${postId}`);

    dispatch({
      type: YOUTH_POST_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: YOUTH_POST_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateYouthPost = (
  youthPost: { postId: string; title: string; content: string; category: string; }
) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void,
  getState: () => { (): any; new(): any; userLogin: { userInfo: any; }; },
) => {
  try {
    const { userLogin: { userInfo } } = getState(); 

    dispatch({ type: YOUTH_POST_UPDATE_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userInfo.token}`
      },
    };

    const { data } = await axios.put(`${API_SERVER_URL}/api/youth-post/${youthPost.postId}`,
      youthPost,
      config
    );

    dispatch({
      type: YOUTH_POST_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: YOUTH_POST_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTeacherIntroList = (branchId: string) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    dispatch({ type: TEACHER_INTRO_LIST_REQUEST });

    const { data } = await axios.get(`${API_SERVER_URL}/api/teacher-intro?branchId=${branchId}`);

    dispatch({
      type: TEACHER_INTRO_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: TEACHER_INTRO_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const inputTeacherIntro =
  (teacherIntro: { branchId: string; teacherName: string; teacherRank: string; teacherSpec: string[]; image: string; }) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({ type: TEACHER_INTRO_INPUT_REQUEST });

      const { data } = await axios.post(`${API_SERVER_URL}/api/teacher-intro`,
        teacherIntro
      );

      dispatch({
        type: TEACHER_INTRO_INPUT_SUCCESS,
        payload: data,
      });

      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: TEACHER_INTRO_INPUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateTeacherIntro =
  (teacherIntro: { branchId: string; teacherName: string; teacherRank: string; teacherSpec: string[]; image: string; _id: string; }) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({ type: TEACHER_INTRO_UPDATE_REQUEST });

      const { data } = await axios.put(`${API_SERVER_URL}/api/teacher-intro/${teacherIntro._id}`,
        teacherIntro
      );

      dispatch({
        type: TEACHER_INTRO_UPDATE_SUCCESS,
        payload: data,
      });

      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: TEACHER_INTRO_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteTeacherIntro = (id: string) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  try {
    dispatch({ type: TEACHER_INTRO_DELETE_REQUEST });

    const { data } = await axios.delete(`${API_SERVER_URL}/api/teacher-intro/${id}`);

    dispatch({
      type: TEACHER_INTRO_DELETE_SUCCESS,
      payload: data,
    });

    window.location.reload();
  } catch (error: any) {
    dispatch({
      type: TEACHER_INTRO_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

