import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import "../index.css";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
import { useEffect } from "react";
import accessCheck from "../utils/accessCheck";

export default function AdminRoot() {
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  useEffect(() => {
    const isTeacher = accessCheck(["원장", "부원장", "조장", "사원", "강사1", "강사2", "미지정"]);

    // if (!isTeacher) {
    //   alert("접근 권한이 없습니다.");

    //   window.location.href = "/admin-login";
    // }
  }, [teacherInfo]);

  return (
    <Box minH={{ base: "100vh" }}>
      <AdminHeader />
      <Outlet />
      <AdminFooter />
    </Box>
  );
}
