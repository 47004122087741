import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { deleteYouthTeam, getYouthTeamDetail } from "../../redux/actions/YouthTeamActions";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { updateYouthTeam } from "../../redux/actions/YouthTeamActions";
import formatPhoneNum from "../../utils/formatPhoneNum";
import { getBranchInfo } from "../../redux/actions/BranchActions";
import { addTransfer, getBranchYouthStudentList, getTransferList, getYouthStudentDetail } from "../../redux/actions/YouthStudentActions";
import axios from "axios";
import { API_SERVER_URL } from "../../redux/actions/actionUrl";

const TransferAcceptScreen = () => {
  interface Member {
    _id: string;
    name: string;
    birthday: string;
    parentPhoneNum: string;
    school: string;
    branchId: string;
    bankTags: string[];
    __v: number;
  }

  interface Team {
    teamId: string;
    teamName: string;
    members: Member[];
  }

  interface Teacher {
    teacherId: string;
    teacherName: string;
    teams: Team[];
  }

  const { id } = useParams<{ id: string }>();
  const studentId = id ?? "";

  const searchParams = new URLSearchParams(useLocation().search);

  const urlFromTeamId = searchParams.get("fromTeamId");

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const userId = userInfo._id;
  
  const transferList = useSelector((state: any) => state.transferList);
  const { loading, error, transfers } = transferList;

  const branchYouthStudentList = useSelector((state: any) => state.branchYouthStudentList);
  const {
    loading: loadingBranchYouthStudent,
    error: errorBranchYouthStudent,
    branchYouthStudents
  } = branchYouthStudentList;
  


  const [selectedTeacher, setSelectedTeacher] = useState<any>(null);
  const [selectedTeams, setSelectedTeams] = useState<{ value: string; label: string }[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const [teacherOptions, setTeacherOptions] = useState<{ value: string; label: string }[]>([]);

  const [loadingTransfer, setLoadingTransfer] = useState<boolean>(false);

  useEffect(() => {
    if (branchYouthStudents && branchYouthStudents.length > 0) {
      const tempTeacherOptions: { value: string; label: string }[] = [];
      const tempTeams: { value: string; label: string }[] = [];

      branchYouthStudents.map((teacher: Teacher) => {
        tempTeacherOptions.push({
          value: teacher.teacherId,
          label: teacher.teacherName,
        });
      });

      branchYouthStudents[0].teams.map((team: Team) => {
        tempTeams.push({
          value: team.teamId,
          label: team.teamName,
        });
      });

      setTeacherOptions(tempTeacherOptions);
      setSelectedTeams(tempTeams);
      if (tempTeams.length > 0) { setSelectedTeam(tempTeams[0].value); }
      setSelectedTeacher(tempTeacherOptions[0].value);
    }
  }, [branchYouthStudents]);

  useEffect(() => {
    dispatch(getTransferList(teacherInfo.branch._id, userId));
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(getBranchInfo(teacherInfo.branch._id));
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(getYouthStudentDetail(studentId));
    dispatch(getBranchYouthStudentList(teacherInfo.branch._id));
  }, [dispatch, studentId, teacherInfo.branch._id]);

  const executeTransferHandler = async (transferId: string) => {
    try {
      setLoadingTransfer(true);
      const response = await axios.put(
        `${API_SERVER_URL}/api/transfer/execute/${transferId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLoadingTransfer(false);
      alert("이동되었습니다.");

      window.location.reload();
    } catch (error: any) {
      setLoadingTransfer(false);
      alert(`이동에 실패했습니다. ${error.message}`);
    }
  }


  const teacherChangeHandler = (value: string) => {
    setSelectedTeacher(value);

    const newSelectedTeams: { value: string; label: string }[] = [];

    branchYouthStudents?.map((teacher: Teacher) => {
      if (teacher.teacherId === value) {
        teacher.teams.map((team: Team) => {
          newSelectedTeams.push({
            value: team.teamId,
            label: team.teamName,
          });
        });
      }
    });

    setSelectedTeams(newSelectedTeams);
    setSelectedTeam(newSelectedTeams.length > 0 ? newSelectedTeams[0].value : "");
  };

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <Box
          mt={10}
          mx={6}
          alignItems="center"
          display="flex"
          justifyContent="center"
          overflowX="auto"
        >
      <VStack>
        <Center>
          <Text fontSize="2xl" fontWeight="bold" p={5}>
            회원 팀 이동 수락
          </Text>
        </Center>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>회원명</Th>
                <Th>기존 팀</Th>
                <Th>이동할 팀</Th>
                <Th>수락</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transfers &&
                transfers.map((transfer: any) => (
                  <Tr key={transfer._id}>
                    <Td>{transfer.studentName}</Td>
                    <Td>
                      {transfer.fromTeamName} ({transfer.fromTeamTeacherName} 강사)</Td>
                    <Td>{transfer.toTeamName}</Td>
                    <Td>
                      <Button
                        onClick={() => { executeTransferHandler(transfer._id); }}
                      >
                        수락
                      </Button>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
        </Box>
      )}
    </Box>
  );
};

export default TransferAcceptScreen;
