import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getYouthTeamDetail,
  getYouthTeamList,
} from "../../redux/actions/YouthTeamActions";
import { addYouthStudent } from "../../redux/actions/YouthStudentActions";

const StudentAddScreen = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherId = userInfo._id;

  const dispatch: any = useDispatch();

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const youthStudentNew = useSelector((state: any) => state.youthStudentNew);
  const {
    loading: loadingNew,
    error: errorNew,
    youthStudent,
  } = youthStudentNew;

  const youthTeamList = useSelector((state: any) => state.youthTeamList);
  const { loading, error, youthTeams } = youthTeamList;

  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [parentPhoneNum, setParentPhoneNum] = useState("");
  const [school, setSchool] = useState("");
  const [address, setAddress] = useState("");
  const [teamId, setTeamId] = useState("");

  const formatPhoneNumber = (phoneNum: any) => {
    const cleaned = ("" + phoneNum).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNum;
  };

  useEffect(() => {
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, true));
  }, [dispatch, teacherId, teacherInfo.branch._id]);

  useEffect(() => {
    dispatch(getYouthTeamDetail(teamId));
  }, [dispatch, teamId]);

  // const teamOption: { value: string; label: string }[] = [];
  const [teamOption, setTeamOption] = useState<{ value: string; label: string }[]>([]);

  // youthTeams?.youthTeam?.map((team: any) => {
  //   teamOption.push({ value: team._id, label: team.name });
  // });

  useEffect(() => {
    if (youthTeams) {
      const initTeamOption: { value: string; label: string }[] = [];

      youthTeams && youthTeams.youthTeam && youthTeams.youthTeam.forEach((team: any) => {
        initTeamOption.push({ value: team._id, label: team.name });
      });

      setTeamOption(initTeamOption);
      
      if (initTeamOption.length > 0) {
        setTeamId(initTeamOption[0].value);
      }
    }
  }, [youthTeams]);

  const submitHandler = () => {
    const birthdayValidCheck = (input: string) => {
      if (/^\d+$/.test(input)) {
        if (input.length !== 6) {
          return false;
        }
        
        const firstTwoDigits = parseInt(input.substring(0, 2), 10);
        const thirdAndFourthDigits = parseInt(input.substring(2, 4), 10);
        const fifthAndSixthDigits = parseInt(input.substring(4, 6), 10);
        
        const isValidFirstTwoDigits = firstTwoDigits >= 0 && firstTwoDigits <= 99;
        const isValidThirdAndFourthDigits = thirdAndFourthDigits >= 1 && thirdAndFourthDigits <= 12;
        const isValidFifthAndSixthDigits = fifthAndSixthDigits >= 1 && fifthAndSixthDigits <= 31;
        
        return isValidFirstTwoDigits && isValidThirdAndFourthDigits && isValidFifthAndSixthDigits;
      }
      
      return false;
    }

    const branch = teacherInfo.branch._id;
    // if (
    //   !name ||
    //   !birthday ||
    //   !phoneNum ||
    //   !parentPhoneNum ||
    //   !school ||
    //   !address ||
    //   !branch
    // ) {
    //   alert("모든 항목을 입력해주세요.");
    //   return;
    // }

    // if (!birthdayValidCheck(birthday)) {
    //   alert("생년월일을 확인해주세요.");
    //   return;
    // }

    dispatch(
      addYouthStudent(
        name,
        birthday,
        phoneNum,
        parentPhoneNum,
        school,
        branch,
        address,
        teamId,
      )
    );
  };

  return (
    <Box
      mt={10}
      mx={6}
      mb={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <Text>loading...</Text>
      ) : error ? (
        <Text>{error}</Text>
      ) : (
        <VStack gap={10} minW={"500px"}>
          <Text fontSize={"3xl"} fontWeight={"bold"}>
            신규 회원 추가
          </Text>
          <FormControl id="name" isRequired>
            <FormLabel>이름</FormLabel>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="회원 이름을 입력하세요..."
            />
          </FormControl>
          <FormControl id="birthday" isRequired>
            <FormLabel>생년월일(6자리)</FormLabel>
            <Input
              type="text"
              placeholder="예) 021210"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
            />
          </FormControl>
          <FormControl id="phoneNum" isRequired>
            <FormLabel>회원 전화번호</FormLabel>
            <Input
              value={phoneNum}
              onChange={(e) => setPhoneNum(formatPhoneNumber(e.target.value))}
              placeholder="회원 전화번호를 입력하세요 : 하이픈은 자동 생성됩니다."
            />
          </FormControl>
          <FormControl id="parentPhoneNum" isRequired>
            <FormLabel>보호자 전화번호</FormLabel>
            <Input
              value={parentPhoneNum}
              onChange={(e) =>
                setParentPhoneNum(formatPhoneNumber(e.target.value))
              }
              placeholder="보호자 전화번호를 입력하세요 : 하이픈은 자동 생성됩니다."
            />
          </FormControl>
          <FormControl id="school" isRequired>
            <FormLabel>학교</FormLabel>
            <Input
              value={school}
              onChange={(e) => setSchool(e.target.value)}
              placeholder="회원의 학교를 입력하세요..."
            />
          </FormControl>
          <FormControl id="address" isRequired>
            <FormLabel>집 주소</FormLabel>
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="예) 경기도 OO시 OO구 OOOO길 OO, OOO아파트 101동 101호"
            />
          </FormControl>
          <FormControl>
            <FormLabel>소속 지점</FormLabel>
            <Text>{teacherInfo.branch.name}</Text>
          </FormControl>
          <FormControl>
            <FormLabel>소속 팀</FormLabel>
            <Select value={teamId} onChange={(e) => setTeamId(e.target.value)}>
              {teamOption.map((team) => (
                <option key={team.value} value={team.value}>
                  {team.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <VStack>
            <Button
              mt={10}
              minW={"200px"}
              colorScheme="orange"
              onClick={submitHandler}
            >
              추가하기
            </Button>
          </VStack>
        </VStack>
      )}
    </Box>
  );
};

export default StudentAddScreen;
