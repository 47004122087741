import axios from "axios";
import {
  BRANCH_GET_TEACHER_STAT_REQUEST,
  BRANCH_GET_TEACHER_STAT_SUCCESS,
  BRANCH_GET_TEACHER_STAT_FAIL,
  BRANCH_GET_TEACHER_STAT_RESET,
  BRANCH_GET_GROUP_STAT_REQUEST,
  BRANCH_GET_GROUP_STAT_SUCCESS,
  BRANCH_GET_GROUP_STAT_FAIL,
  BRANCH_GET_GROUP_STAT_RESET,
  BRANCH_GET_TEACHER_SINGLE_STAT_REQUEST,
  BRANCH_GET_TEACHER_SINGLE_STAT_SUCCESS,
  BRANCH_GET_TEACHER_SINGLE_STAT_FAIL,
  BRANCH_GET_TEACHER_SINGLE_STAT_RESET,
  BRANCH_GET_TEACHER_TEAM_STAT_REQUEST,
  BRANCH_GET_TEACHER_TEAM_STAT_SUCCESS,
  BRANCH_GET_TEACHER_TEAM_STAT_FAIL,
  BRANCH_GET_TEACHER_TEAM_STAT_RESET,
  BRANCH_INFO_REQUEST,
  BRANCH_INFO_SUCCESS,
  BRANCH_INFO_FAIL,
  BRANCH_INFO_RESET,
  BRANCH_GET_GRAPH_REQUEST,
  BRANCH_GET_GRAPH_SUCCESS,
  BRANCH_GET_GRAPH_FAIL,
  BRANCH_GET_GRAPH_RESET,
  BRANCH_UPDATE_REQUEST,
  BRANCH_UPDATE_SUCCESS,
  BRANCH_UPDATE_FAIL,
  BRANCH_UPDATE_RESET,
  BRANCH_GET_GRAPH_GROUP_REQUEST,
  BRANCH_GET_GRAPH_GROUP_SUCCESS,
  BRANCH_GET_GRAPH_GROUP_FAIL,
  BRANCH_GET_GRAPH_GROUP_RESET,
  GET_TEACHERS_BY_USERID_REQUEST,
  GET_TEACHERS_BY_USERID_SUCCESS,
  GET_TEACHERS_BY_USERID_FAIL,
  GET_TEACHERS_BY_USERID_RESET,
} from "../constants/BranchConstants";

export const getTeacherStatsReducer = (
  state = { stats: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_GET_TEACHER_STAT_REQUEST:
      return { loading: true };
    case BRANCH_GET_TEACHER_STAT_SUCCESS:
      return { loading: false, stats: action.payload };
    case BRANCH_GET_TEACHER_STAT_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_GET_TEACHER_STAT_RESET:
      return { teacherStats: [] };
    default:
      return state;
  }
};

export const getGroupStatsReducer = (
  state = { statsGroup: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_GET_GROUP_STAT_REQUEST:
      return { loading: true };
    case BRANCH_GET_GROUP_STAT_SUCCESS:
      return { loading: false, statsGroup: action.payload };
    case BRANCH_GET_GROUP_STAT_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_GET_GROUP_STAT_RESET:
      return { statsGroup: [] };
    default:
      return state;
  }
};

export const getBranchInfoReducer = (
  state = { branchInfo: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_INFO_REQUEST:
      return { loading: true };
    case BRANCH_INFO_SUCCESS:
      return { loading: false, branchInfo: action.payload };
    case BRANCH_INFO_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_INFO_RESET:
      return { branchInfo: [] };
    default:
      return state;
  }
};

export const getBranchGraphGroupReducer = (
  state = { graph: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_GET_GRAPH_GROUP_REQUEST:
      return { loading: true };
    case BRANCH_GET_GRAPH_GROUP_SUCCESS:
      return { loading: false, graphGroup: action.payload };
    case BRANCH_GET_GRAPH_GROUP_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_GET_GRAPH_GROUP_RESET:
      return { graph: [] };
    default:
      return state;
  }
};

export const getBranchGraphReducer = (
  state = { graph: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_GET_GRAPH_REQUEST:
      return { loading: true };
    case BRANCH_GET_GRAPH_SUCCESS:
      return { loading: false, graph: action.payload };
    case BRANCH_GET_GRAPH_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_GET_GRAPH_RESET:
      return { graph: [] };
    default:
      return state;
  }
};

export const updateBranchReducer = (
  state = { branch: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_UPDATE_REQUEST:
      return { loading: true };
    case BRANCH_UPDATE_SUCCESS:
      return { loading: false, branch: action.payload };
    case BRANCH_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_UPDATE_RESET:
      return { branch: {} };
    default:
      return state;
  }
};

export const getTeacherSingleStatReducer = (
  state = { stat: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_GET_TEACHER_SINGLE_STAT_REQUEST:
      return { loading: true };
    case BRANCH_GET_TEACHER_SINGLE_STAT_SUCCESS:
      return { loading: false, stat: action.payload };
    case BRANCH_GET_TEACHER_SINGLE_STAT_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_GET_TEACHER_SINGLE_STAT_RESET:
      return { stat: {} };
    default:
      return state;
  }
}

export const getTeacherTeamStatReducer = (
  state = { teamStat: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_GET_TEACHER_TEAM_STAT_REQUEST:
      return { loading: true };
    case BRANCH_GET_TEACHER_TEAM_STAT_SUCCESS:
      return { loading: false, teamStat: action.payload };
    case BRANCH_GET_TEACHER_TEAM_STAT_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_GET_TEACHER_TEAM_STAT_RESET:
      return { teamStat: {} };
    default:
      return state;
  }
}

export const getTeachersByUserIdReducer = (
  state = { teachers: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_TEACHERS_BY_USERID_REQUEST:
      return { loading: true };
    case GET_TEACHERS_BY_USERID_SUCCESS:
      return { loading: false, teachers: action.payload };
    case GET_TEACHERS_BY_USERID_FAIL:
      return { loading: false, error: action.payload };
    case GET_TEACHERS_BY_USERID_RESET:
      return { teachers: [] };
    default:
      return state;
  }
}