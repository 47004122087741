import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getYouthTeamList } from "../../redux/actions/YouthTeamActions";
import { Link, useLocation } from "react-router-dom";
import {
  batchUpdatePayment,
  getPaymentByMonth,
} from "../../redux/actions/PaymentActions";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import accessCheck from "../../utils/accessCheck";
import { getTeacherTeamStat, getBranchInfo } from "../../redux/actions/BranchActions";

const PaymentMainScreen = ({ history }: any) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const teacherId = userInfo._id;
  const dispatch: any = useDispatch();

  const searchParams = new URLSearchParams(useLocation().search);
  const urlTeacherId = searchParams.get('teacherId');
  const urlTeamId = searchParams.get('teamId');
  const urlYear = Number(searchParams.get('year'));
  const urlMonth = Number(searchParams.get('month'));

  const [teacherOption, setTeacherOption] = useState<any[]>([]);
  const [teamOption, setTeamOption] = useState<any[]>([]);

  const youthTeamList = useSelector((state: any) => state.youthTeamList);
  const { youthTeams } = youthTeamList;

  const paymentByMonth = useSelector((state: any) => state.paymentByMonth);
  const { loading, error, payments } = paymentByMonth;

  const paymentBatchUpdate = useSelector((state: any) => state.paymentBatchUpdate);
  const {
    loading: loadingBatchUpdate,
    error: errorBatchUpdate,
    success: successBatchUpdate,
  } = paymentBatchUpdate;

  const teacherTeamStat = useSelector((state: any) => state.teacherTeamStat);
  const {
    loading: loadingTeamStat,
    error: errorTeamStat,
    teamStat,
  } = teacherTeamStat;

  const branchInfoList = useSelector((state: any) => state.branchInfoList);
  const { branchInfo } = branchInfoList;

  const findTeamStatObj = (youthTeamId: string) => {
    return teamStat?.teamStatistics?.find(
      (team: any) => team.teamId === youthTeamId
    );
  };

  const [amountToPayList, setAmountToPayList] = useState<number[][]>([]);
  const [amountList, setAmountList] = useState<number[][]>([]);
  const [etcPaymentList, setEtcPaymentList] = useState<number[][]>([]);
  const [enteredAmountList, setEnteredAmountList] = useState<number[]>([]);

  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [selectedPaymentTable, setSelectedPaymentTable] = useState<any[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<string>(teacherId);

  const [isNotPaidFiltered, setIsNotPaidFiltered] = useState<boolean>(false);

  const dateFormat = (date: string) => {
    return date.slice(5, 7) + "/" + date.slice(8, 10);
  }

  const listInit = (paymentTable: any) => {
    const newAmountToPayList = paymentTable.map((team: any) =>
      team.payment.map((p: any) => p.amountToPay)
    );

    const newAmountList = paymentTable.map((team: any) =>
      team.payment.map((p: any) => p.amount)
    );

    const newEtcPaymentList = paymentTable.map((team: any) =>
      team.payment.map((p: any) =>
        (p.amount - p.amountToPay < 0) ? 0 : p.amount - p.amountToPay
      )
    );

    setAmountToPayList(newAmountToPayList);
    setAmountList(newAmountList);
    setEtcPaymentList(newEtcPaymentList);
    setEnteredAmountList(new Array(newAmountToPayList.length).fill(0));
    setSelectedPaymentTable(paymentTable);
  }

  useEffect(() => {
    dispatch(getBranchInfo(teacherInfo.branch._id));
  }, [dispatch, teacherInfo.branch._id]);

  useEffect(() => {
    if (branchInfo && branchInfo.teacherInfo && youthTeams && youthTeams.youthTeam) {
      console.log(1)
      let tempTeacherOption: { value: string; label: string }[] = [];

      for (let i = 0; i < branchInfo.teacherInfo.length; i++) {
        tempTeacherOption.push({
          value: branchInfo.teacherInfo[i].teacherId,
          label: branchInfo.teacherInfo[i].teacherName,
        });
      }

      setTeacherOption(tempTeacherOption);
      setSelectedTeacher(urlTeacherId ? urlTeacherId : tempTeacherOption[0].value);

      const teamList = youthTeams?.youthTeam?.filter(
        (team: any) => team.teacherId === (urlTeacherId ? urlTeacherId : tempTeacherOption[0].value)
      );

      const teamOption: { value: string; label: string }[] = teamList
        ? [
          { value: "all", label: "전체" },
          ...teamList.map((team: any) => ({
            value: team._id,
            label: team.name,
          })),
        ]
        : [];

      setTeamOption(teamOption);

      setSelectedTeam(urlTeamId ? urlTeamId : "all");

      dispatch(getPaymentByMonth(
        urlTeacherId ? urlTeacherId : tempTeacherOption[0].value,
        urlYear,
        urlMonth,
        teacherInfo.branch._id,
        urlTeamId ? urlTeamId : null
      ));
    }
  }, [dispatch, branchInfo, youthTeams]);

  const monthChangeHandler = (isNext: boolean) => {
    const updateQueryParams = (url: string, param: string, value: string) => {
      let urlObj = new URL(url);
      urlObj.searchParams.set(param, value);
      return urlObj.toString();
    }

    let url = window.location.href;

    let newMonth, newYear;

    if (isNext) {
      newMonth = urlMonth + 1;
      newYear = urlYear;
      if (newMonth === 13) {
        newYear = urlYear + 1;
        newMonth = 1;
      }
    } else {
      newMonth = urlMonth - 1;
      newYear = urlYear;
      if (newMonth === 0) {
        newYear = urlYear - 1;
        newMonth = 12;
      }
    }

    let newUrl = updateQueryParams(url, "year", newYear.toString());
    newUrl = updateQueryParams(newUrl, "month", newMonth.toString());
    window.location.href = newUrl;
  }

  useEffect(() => {
    dispatch(
      getTeacherTeamStat(selectedTeacher, urlYear, urlMonth, teacherInfo.branch._id)
    );
  }, [dispatch, urlYear, urlMonth, selectedTeacher, teacherInfo.branch._id]);

  const handleTeamClick = (teamValue: string) => {
    if (teamValue === "전체") {
      window.location.href = `/youthdb/payment?year=${urlYear}&month=${urlMonth}&teacherId=${selectedTeacher}`;
    } else {
      window.location.href = `/youthdb/payment?year=${urlYear}&month=${urlMonth}&teacherId=${selectedTeacher}&teamId=${teamValue}`;
    }
  };

  useEffect(() => {
    if (payments && payments.paymentTable) {
      listInit(payments.paymentTable);
    }
  }, [payments]);

  const handleTeacherClick = (teacherValue: string) => {
    window.location.href = `/youthdb/payment?year=${urlYear}&month=${urlMonth}&teacherId=${teacherValue}`;
  };

  useEffect(() => {
    // if (!accessCheck(["원장", "부원장", "조장"])) {
    //   alert("접근 권한이 없습니다.");
    //   window.location.href = "/youthdb/my";
    // }
  }, []);

  useEffect(() => {
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, true));
  }, [dispatch, teacherId, teacherInfo.branch._id]);

  const handleBulkInput = (youthTeamIndex: number) => {
    const enteredAmount = enteredAmountList[youthTeamIndex];
    const copy = [...amountToPayList];

    for (let i = 0; i < selectedPaymentTable[youthTeamIndex].payment.length; i++) {
      copy[youthTeamIndex][i] = enteredAmount;
    }

    setAmountToPayList(copy);
  };

  const notPaidDateText = (notPaidDate: any) => {
    let resultText = "";

    for (let i = 0; i < notPaidDate.length; i++) {
      resultText += `${notPaidDate[i].year}년 ${notPaidDate[i].month}월 : ${notPaidDate[i].notPaid}원\n`;
    }

    return resultText;
  };

  const formatNumber = (num: number) => {
    if (!num) return num;
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const isNumeric = (value: string) => {
    if (value.length === 0) return true;
    return /^(\d|,)+$/.test(value);
  };

  const unformatNumber = (num: string) => {
    return num.replace(/,/g, "");
  };

  const submitHandler = () => {
    const updatedPayments = [];

    for (let i = 0; i < selectedPaymentTable.length; i++) {
      for (let j = 0; j < selectedPaymentTable[i].payment.length; j++) {
        const amount = amountList[i][j];
        const amountToPay = amountToPayList[i][j];

        updatedPayments.push({
          year: urlYear,
          month: urlMonth,
          studentId: selectedPaymentTable[i].payment[j].studentId,
          amount: amount,
          amountToPay: amountToPay,
        });
      }
    }

    dispatch(
      batchUpdatePayment(
        { paymentList: updatedPayments },
        teacherInfo.branch._id,
        selectedTeacher,
        urlYear,
        urlMonth
      )
    );

    // window.location.href = `/youthdb/payment?teacherId=${teacherId}&year=${urlYear}&month=${urlMonth}`;
  };

  const notPaidHandler = () => {
    if (isNotPaidFiltered) {
      setIsNotPaidFiltered(false);
      setSelectedPaymentTable(payments.paymentTable);
    } else {
      let copy = payments.paymentTable.map((team: any) => {
        let copyTeam = { ...team };
        copyTeam.payment = team.payment.filter((p: any) => p.notPaid > 0);
        return copyTeam;
      }

      );
      setIsNotPaidFiltered(true);
      setSelectedPaymentTable(copy);
    }
  }

  let amount: number;
  let amountToPay: number;

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow={"auto"}
    >
      {loadingBatchUpdate && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          zIndex={1000}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size="xl" />
        </Box>
      )}
      <VStack gap={4}>
        <HStack overflowX="auto">
          <IconButton
            aria-label="prev"
            icon={<ArrowBackIcon />}
            mx={4}
            onClick={() => monthChangeHandler(false)}
          />
          <Text fontSize="2xl" fontWeight="bold">
            {urlYear}년 {urlMonth}월
          </Text>
          <IconButton
            aria-label="next"
            icon={<ArrowForwardIcon />}
            mx={4}
            onClick={() => monthChangeHandler(true)}
          />
        </HStack>
        <HStack spacing={2}>
          <Select
            onChange={(e) => handleTeacherClick(e.target.value)}
            width={{ base: "120px", md: "200px" }}
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={{ base: "sm", md: "xl" }}
            variant="filled"
            bg="blue.200"
          >
            {teacherOption.map((item) => (
              <option key={item.value} value={item.value} selected={item.value === selectedTeacher}>
                {item.label}
              </option>
            ))}
          </Select>
          <Select
            onChange={(e) => handleTeamClick(e.target.value)}
            width={{ base: "200px", md: "300px" }}
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={{ base: "sm", md: "xl" }}
            variant="filled"
            bg="blue.200"
          >
            {teamOption.map((item) => (
              <option key={item.value} value={item.value} selected={item.value === selectedTeam}>
                {item.label}
              </option>
            ))}
          </Select>
        </HStack>
        {loading ? (
          <h2>loading</h2>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          payments &&
          payments.income && (
            <HStack
              justifyContent="space-between"
              w={"100%"}
              overflowX="auto"
              maxW={{ base: "300px", sm: "500px", md: "700px", lg: "1500px" }}
            >
              <Tag display="flex" alignItems="center" variant={"solid"} colorScheme="gray" size={{ base: "sm", md: "lg" }}>
                미납만 보기
                <Checkbox
                  isChecked={isNotPaidFiltered}
                  onChange={notPaidHandler}
                  ml={2}
                />
              </Tag>
              <Tag
                variant="solid"
                colorScheme="gray"
                size={{ base: "sm", md: "lg" }}
              >
                <Link to={`/youthdb/etc-payment`}>
                  비교육비 관리
                </Link>
              </Tag>
              <HStack style={{ marginLeft: "auto" }} textAlign={"right"}>
                <Tag
                  variant="solid"
                  colorScheme="orange"
                  size={{ base: "sm", md: "lg" }}
                >
                  당월 예정액 : {payments.income.amountToPay.toLocaleString()}
                </Tag>
                <Tag
                  variant="solid"
                  colorScheme="red"
                  size={{ base: "sm", md: "lg" }}
                >
                  당월 입금액 : {payments.income.amount.toLocaleString()}
                </Tag>
              </HStack>
            </HStack>
          )
        )}
        {loading ? (
          <h2>Loading...</h2>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          payments &&
          teamStat &&
          payments.paymentTable &&
          selectedPaymentTable.map((p: any, teamIndex: number) => {
            return (
              <VStack
                key={teamIndex}
                overflowX="auto"
                maxW={{ base: "300px", sm: "500px", md: "700px", lg: "1500px" }}
              >
                <HStack
                  w={"100%"}
                  overflowX="auto"
                  justifyContent="space-between"
                >
                  <Text
                    fontSize={{ base: "sm", md: "2xl" }}
                    fontWeight="bold"
                    textAlign={"left"}
                    w={"100%"}
                  >
                    {p.youthTeamName}
                  </Text>
                  {/* <NumberInput
                    id={`enteredAmount${teamIndex}`}
                    defaultValue={0}
                    max={1000000}
                    clampValueOnBlur={false}
                    maxW={150}
                    textAlign={"right"}
                    p={0}
                  >
                    <NumberInputField />
                  </NumberInput> */}
                  <Input
                    value={enteredAmountList && formatNumber(enteredAmountList[teamIndex])}
                    onChange={(e) => {
                      if (!isNumeric(e.target.value)) {
                        return;
                      }
                      const copy = [...enteredAmountList];
                      copy[teamIndex] = Number(unformatNumber(e.target.value));
                      setEnteredAmountList(copy);
                    }}
                    max={1000000}
                    textAlign={"right"}
                    maxW={150}
                  />
                  <Button
                    colorScheme="blue"
                    onClick={() => handleBulkInput(teamIndex)}
                    minW={{ base: "80px", md: "120px" }}
                    fontSize={{ base: "xs", md: "md" }}
                    maxW={180}
                  >
                    당월 일괄입력
                  </Button>
                  <Box ml={100}>
                    <Button
                      onClick={submitHandler}
                      size="md"
                      maxW={150}
                      ml={30}
                      minW={{ base: "80px", md: "120px" }}
                      fontSize={{ base: "xs", md: "md" }}
                    >
                      업데이트
                    </Button>
                  </Box>
                </HStack>
                <Flex w={"100%"}>
                  <TableContainer maxH="2000px" overflowX="auto">
                    <Table
                      variant="simple"
                      colorScheme="orange"
                      border="ActiveBorder"
                      maxW="1200px"
                      minW="800px"
                    >
                      <Thead bg="gray.100">
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          whiteSpace="nowrap"
                          p={1}
                        >
                          총원
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          whiteSpace="nowrap"
                          p={1}
                        >
                          신규
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          p={1}
                          whiteSpace="nowrap"
                        >
                          복귀
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          p={1}
                          whiteSpace="nowrap"
                        >
                          휴강
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          whiteSpace="nowrap"
                          p={1}
                        >
                          탈퇴
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          whiteSpace="nowrap"
                          p={1}
                        >
                          현재원
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          whiteSpace="nowrap"
                          p={1}
                        >
                          당월 예정액
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          whiteSpace="nowrap"
                          p={1}
                        >
                          당월 입금액
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          whiteSpace="nowrap"
                          p={1}
                        >
                          미납
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          whiteSpace="nowrap"
                          p={1}
                        >
                          누적미납
                        </Th>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            whiteSpace="nowrap"
                            p={1}
                          >
                            {findTeamStatObj(p.youthTeamId)?.totalStudent}
                          </Td>
                          <Td
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            whiteSpace="nowrap"
                            p={1}
                          >
                            {findTeamStatObj(p.youthTeamId)?.newStudent}
                          </Td>
                          <Td
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            p={1}
                            whiteSpace="nowrap"
                          >
                            {findTeamStatObj(p.youthTeamId)?.returnStudent}
                          </Td>
                          <Td
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            p={1}
                            whiteSpace="nowrap"
                          >
                            {findTeamStatObj(p.youthTeamId)?.restStudent}
                          </Td>
                          <Td
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            whiteSpace="nowrap"
                            p={1}
                          >
                            {findTeamStatObj(p.youthTeamId)?.quitStudent}
                          </Td>
                          <Td
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            whiteSpace="nowrap"
                            p={1}
                          >
                            {findTeamStatObj(p.youthTeamId)?.nowStudent}
                          </Td>
                          <Td
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            whiteSpace="nowrap"
                            p={1}
                          >
                            {p.amountToPay.toLocaleString()}
                          </Td>
                          <Td
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            whiteSpace="nowrap"
                            p={1}
                          >
                            {p.amount.toLocaleString()}
                          </Td>
                          <Td
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            whiteSpace="nowrap"
                            p={1}
                          >
                            {p.notPaid < 0 ? 0 : p.notPaid.toLocaleString()}
                          </Td>
                          <Td
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            whiteSpace="nowrap"
                            p={1}
                          >
                            {p.notPaidAcc.toLocaleString()}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                  {/* <TableContainer textAlign={"right"} w={"40%"} overflowX="auto" ml={10}>
                      <Table>
                        <Thead bg="red.100">
                          <Th
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            whiteSpace="nowrap"
                            p={1}
                            maxW={5}
                          >
                            당월 예정액
                          </Th>
                          <Th
                            textAlign="center"
                            borderWidth="1px"
                            borderColor="gray.400"
                            whiteSpace="nowrap"
                            p={1}
                            maxW={5}
                          >
                            당월 입금액
                          </Th>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td
                              textAlign="center"
                              borderWidth="1px"
                              borderColor="gray.400"
                              whiteSpace="nowrap"
                              maxW={5}
                              p={1}
                            >
                              {p.amountToPay.toLocaleString()}
                            </Td>
                            <Td
                              textAlign="center"
                              borderWidth="1px"
                              borderColor="gray.400"
                              whiteSpace="nowrap"
                              maxW={5}
                              p={1}
                            >
                              {p.amount.toLocaleString()}
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer> */}
                </Flex>
                <TableContainer maxH="2000px" overflowX="auto">
                  <Table
                    variant="simple"
                    colorScheme="orange"
                    border="ActiveBorder"
                    maxW="1200px"
                    minW="800px"
                  >
                    <Thead bg="gray.100">
                      <Th
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        whiteSpace="nowrap"
                        pt={2}
                        pb={2}
                        pr={1}
                        pl={1}
                      >
                        순번
                      </Th>
                      <Th
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        pt={2}
                        pb={2}
                        whiteSpace="nowrap"
                      >
                        이름
                      </Th>
                      <Th
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        pt={2}
                        pb={2}
                        whiteSpace="nowrap"
                      >
                        구분
                      </Th>
                      <Th
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        whiteSpace="nowrap"
                        pt={2}
                        pb={2}
                        minW={100}
                        maxW={130}
                      >
                        당월
                      </Th>
                      <Th
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        whiteSpace="nowrap"
                        pt={2}
                        pb={2}
                        minW={100}
                        maxW={130}
                      >
                        입금
                      </Th>
                      <Th
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        whiteSpace="nowrap"
                        pt={2}
                        pb={2}
                      >
                        입금일
                      </Th>
                      <Th
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        whiteSpace="nowrap"
                        pt={2}
                        pb={2}
                      >
                        미납
                      </Th>
                      <Th
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        whiteSpace="nowrap"
                        pt={2}
                        pb={2}
                      >
                        누적미납
                      </Th>
                      <Th
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        whiteSpace="nowrap"
                        pt={2}
                        pb={2}
                      >
                        초과입금
                      </Th>
                    </Thead>
                    <Tbody>
                      {loading ? (
                        <h2>Loading...</h2>
                      ) : error ? (
                        <h2>{error}</h2>
                      ) : (
                        p.payment &&
                        p.payment.map((p1: any, paymentIndex: number) => {
                          return (
                            <Tr key={paymentIndex}>
                              <Td
                                textAlign="center"
                                borderWidth="1px"
                                borderColor="gray.400"
                                fontWeight={"bold"}
                                pt={2}
                                pb={2}
                                pr={1}
                                pl={1}
                              >
                                {paymentIndex + 1}
                              </Td>
                              <Td
                                textAlign="center"
                                borderWidth="1px"
                                borderColor="gray.400"
                                fontWeight={"bold"}
                                pt={2}
                                pb={2}
                              >
                                <Link
                                  to={`/youthdb/youth-student-detail/${p1.studentId}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  {p1.youthStudentName}
                                </Link>
                              </Td>
                              <Td
                                textAlign="center"
                                borderWidth="1px"
                                borderColor="gray.400"
                                fontWeight={"bold"}
                                pt={2}
                                pb={2}
                              >
                                {p1.memo}
                              </Td>
                              <Td
                                textAlign="center"
                                borderWidth="1px"
                                borderColor="gray.400"
                                fontWeight={"bold"}
                                padding={0}
                                px={2}
                                minW={100}
                                maxW={130}
                                style={{
                                  backgroundColor:
                                    p1.amount === p1.amountToPay
                                      ? "white"
                                      : "red",
                                  color:
                                    p1.amount === p1.amountToPay
                                      ? "black"
                                      : "white",
                                }}
                              >
                                <Input
                                  defaultValue={amountToPayList && amountToPayList[teamIndex][paymentIndex]}
                                  value={amountToPayList && formatNumber(amountToPayList[teamIndex][paymentIndex])}
                                  max={1000000}
                                  onChange={(e) => {
                                    if (!isNumeric(e.target.value)) {
                                      return;
                                    }
                                    amountToPayList[teamIndex][paymentIndex] = Number(
                                      unformatNumber(e.target.value)
                                    );
                                    setAmountToPayList([...amountToPayList]);

                                    // amount = amountList[teamIndex][paymentIndex];
                                    // amountToPay = amountToPayList[teamIndex][paymentIndex];

                                    // etcPaymentList[teamIndex][paymentIndex] = amount - amountToPay < 0 ? 0 : amount - amountToPay;
                                    // setEtcPaymentList([...etcPaymentList]);
                                  }}
                                />
                              </Td>
                              <Td
                                key={p1.amount}
                                textAlign="center"
                                borderWidth="1px"
                                borderColor="gray.400"
                                fontWeight={"bold"}
                                padding={0}
                                px={2}
                                minW={100}
                                maxW={130}
                                style={{
                                  backgroundColor:
                                    p1.amount === p1.amountToPay
                                      ? "white"
                                      : "red",
                                  color:
                                    p1.amount === p1.amountToPay
                                      ? "black"
                                      : "white",
                                }}
                              >
                                <Input
                                  defaultValue={amountList && amountList[teamIndex][paymentIndex]}
                                  value={amountList && formatNumber(amountList[teamIndex][paymentIndex])}
                                  max={1000000}
                                  onChange={(e) => {
                                    if (!isNumeric(e.target.value)) {
                                      return;
                                    }
                                    amountList[teamIndex][paymentIndex] = Number(
                                      unformatNumber(e.target.value)
                                    );
                                    setAmountList([...amountList]);

                                    // amount = amountList[teamIndex][paymentIndex];
                                    // amountToPay = amountToPayList[teamIndex][paymentIndex];

                                    // etcPaymentList[teamIndex][paymentIndex] = amount - amountToPay < 0 ? 0 : amount - amountToPay;
                                    // setEtcPaymentList([...etcPaymentList]);
                                  }}
                                />
                              </Td>
                              <Td
                                textAlign="center"
                                borderWidth="1px"
                                borderColor="gray.400"
                                pt={2}
                                pb={2}
                              >
                                {p1.depositDate ? dateFormat(p1.depositDate) : ""}
                              </Td>
                              <Td
                                textAlign="center"
                                borderWidth="1px"
                                borderColor="gray.400"
                                fontWeight={"bold"}
                                padding={0}
                                px={2}
                                pt={2}
                                pb={2}
                                minW={100}
                                maxW={130}
                              >
                                <Flex>
                                  <span>{p1.notPaid < 0 ? 0 : p1.notPaid.toLocaleString()}</span>
                                </Flex>
                              </Td>
                              <Td
                                textAlign="center"
                                borderWidth="1px"
                                borderColor="gray.400"
                                fontWeight={"bold"}
                                padding={0}
                                px={2}
                                pt={2}
                                pb={2}
                                minW={130}
                                maxW={160}
                              >
                                <Flex
                                  justify="space-between"
                                  align="center"
                                  width="100%"
                                >
                                  <span>{p1.notPaidAcc.toLocaleString()}</span>
                                  {p1.notPaidDate.length > 0 && (
                                    <Button
                                      colorScheme="blue"
                                      size="xs" // 버튼 크기를 'sm'으로 설정
                                      onClick={() => {
                                        alert(notPaidDateText(p1.notPaidDate));
                                      }}
                                      whiteSpace="nowrap" // 버튼 텍스트가 줄 바꿈되지 않도록 설정
                                      p={1} // 버튼의 패딩을 줄입니다
                                      minW="fit-content" // 버튼의 최소 너비를 내용에 맞게 설정
                                    >
                                      미납정보
                                    </Button>
                                  )}
                                </Flex>
                              </Td>
                              <Td
                                textAlign="center"
                                borderWidth="1px"
                                borderColor="gray.400"
                                fontWeight={"bold"}
                                padding={0}
                                px={2}
                                pt={2}
                                pb={2}
                                minW={130}
                                maxW={160}
                              >
                                <Flex
                                  justify="space-between"
                                  align="center"
                                  width="100%"
                                >
                                  <Input
                                    defaultValue={etcPaymentList && etcPaymentList[teamIndex][paymentIndex]}
                                    value={etcPaymentList && formatNumber(etcPaymentList[teamIndex][paymentIndex])}
                                    max={1000000}
                                    onChange={(e) => {
                                      if (!isNumeric(e.target.value)) {
                                        return;
                                      }
                                      if (Number(unformatNumber(e.target.value)) > amountList[teamIndex][paymentIndex]) {
                                        alert("초과입금액은 입금액보다 많을 수 없습니다.");
                                        return;
                                      }
                                      etcPaymentList[teamIndex][paymentIndex] = Number(
                                        unformatNumber(e.target.value)
                                      );
                                      setEtcPaymentList([...etcPaymentList]);
                                    }}
                                  />
                                  <Button
                                    colorScheme="blue"
                                    size="xs"
                                    onClick={() => {
                                      if (etcPaymentList[teamIndex][paymentIndex] === 0) {
                                        alert("금액이 0원인 경우 등록할 수 없습니다.");
                                        return;
                                      }
                                      window.location.href = `/youthdb/etc-payment/${p1.paymentId}/new?amount=${etcPaymentList[teamIndex][paymentIndex]}&teacherId=${selectedTeacher}`;
                                    }}
                                    whiteSpace="nowrap"
                                    p={1}
                                    minW="fit-content"
                                    fontSize="10px"
                                  >
                                    비교육비
                                    <br />
                                    등록
                                  </Button>
                                </Flex>
                              </Td>
                            </Tr>
                          );
                        })
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </VStack>
            );
          })
        )}
      </VStack>
    </Box>
  );
};

export default PaymentMainScreen;