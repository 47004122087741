import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Text,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  VStack,
  IconButton,
  Center,
  Select,
  Spinner,
} from "@chakra-ui/react";
import {
  getBranchGraphGroup,
  getBranchInfo,
  getBranchGraph,
  getTeacherStats,
} from "../../redux/actions/BranchActions";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import accessCheck from "../../utils/accessCheck";
import { BRANCH_INFO_RESET } from "../../redux/constants/BranchConstants";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  DatasetComponent,
  TransformComponent,
  CanvasRenderer,
]);

// interface TeacherData {
//   teacherName: string;
//   amountToPayGraph: number[];
// }

interface FormattedData {
  teacherName: string;
  values: { month: string; value: number }[] | any[];
}

const BranchMainScreen = () => {
  const chartRef = useRef(null);
  const chartRefGroup = useRef(null);

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const userId = userInfo._id;

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );

  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedMaster, setSelectedMaster] = useState("");

  const branchInfoList = useSelector((state: any) => state.branchInfoList);
  const { loading, error, branchInfo } = branchInfoList;

  const branchId = branchInfo && branchInfo._id;

  const [getBranchInfoCompleted, setGetBranchInfoCompleted] = useState(false);

  const graphOptions = [
    { value: "totalIncomeGraph", label: "총매출" },
    { value: "amountGraph", label: "당월입금" },
    { value: "notPaidGraph", label: "당월미납" },
    { value: "notPaidAccGraph", label: "연체미납" },
    { value: "totalStudentGraph", label: "총원" },
    { value: "newStudentReturnStudentGraph", label: "신규/복귀" },
    { value: "quitStudentGraph", label: "탈퇴" },
    { value: "standardStudentGraph", label: "평균인원" },
  ]

  useEffect(() => {
    // if (!accessCheck(["원장", "부원장", "조장"])) {
    //   alert("접근 권한이 없습니다.");
    //   window.location.href = "/youthdb/my";
    // }
  }, []);

  useEffect(() => {
    dispatch({ type: BRANCH_INFO_RESET });

    dispatch(getBranchInfo(teacherInfo.branch._id)).then(() => {
      setGetBranchInfoCompleted(true);
    });
  }, [dispatch, userId]);

  useEffect(() => {
    if (branchInfo && branchInfo.teacherInfo && branchInfo.teacherInfo.length > 0) {
      setSelectedTeacher(branchInfo.teacherInfo[0].teacherId);
    }
  }, [branchInfo]);

  const teacherStats = useSelector((state: any) => state.teacherStats);
  const { loading: loadingStats, error: errorStats, stats } = teacherStats;

  console.log(stats)

  useEffect(() => {
    if (getBranchInfoCompleted && branchInfo) {
      dispatch(getTeacherStats(branchId, year, month));
    }
  }, [dispatch, getBranchInfoCompleted, branchInfo, branchId, year, month]);

  useEffect(() => {
    if (month === 13) {
      setYear(year + 1);
      setMonth(1);
    }
    if (month === 0) {
      setYear(year - 1);
      setMonth(12);
    }
  }, [month]);

  const branchGraph = useSelector((state: any) => state.branchGraph);
  const { loading: loadingGraph, error: errorGraph, graph } = branchGraph;

  const [combinedGraphData, setCombinedGraphData] = useState<FormattedData[]>(
    []
  );

  const [combinedGraphGroupData, setCombinedGraphGroupData] = useState<
    FormattedData[]
  >([]);

  const [selectedChart, setSelectedChart] = useState("totalIncomeGraph");
  const [selectedChartGroup, setSelectedChartGroup] =
    useState("totalIncomeGraph");
  const [chartTitle, setChartTitle] = useState("총매출");

  const branchGraphGroup = useSelector((state: any) => state.branchGraphGroup);
  const {
    loading: loadingGraphGroup,
    error: errorGraphGroup,
    graphGroup,
  } = branchGraphGroup;

  // const fetchDataGroup = async (chartType: string) => {
  //   try {
  //     await dispatch(getBranchGraphGroup(branchId, year, selectedMaster));
  //     if (graphGroup) {
  //       const formattedData: FormattedData[] = [];
  //       graphGroup.forEach((data: any) => {
  //         const teacherName: string = data.teacherName;
  //         const values = Array.from({ length: 12 }, (_, index) => {
  //           const monthData = data[chartType][index];
  //           return { month: `${index + 1}월`, value: monthData || 0 };
  //         });
  //         formattedData.push({ teacherName, values });
  //       });
  //       setCombinedGraphGroupData(formattedData);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const fetchData = async (chartType: string) => {
  //   try {
  //     await dispatch(getBranchGraph(year, selectedTeacher, branchId));
  //     if (graph) {
  //       const formattedData: FormattedData[] = [];
  //       const teacherName: string = graph.teacherName;
  //       const values = Array.from({ length: 12 }, (_, index) => {
  //         const monthData = graph[chartType][index];
  //         return { month: `${index + 1}월`, value: monthData || 0 };
  //       });
  //       formattedData.push({ teacherName, values });
  //       setCombinedGraphData(formattedData);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (combinedGraphData && combinedGraphData.length > 0) {
  //     const options = {
  //       title: {
  //         text: `${year}년 ${chartTitle} 통계 차트`,
  //       },
  //       tooltip: {
  //         trigger: "axis",
  //       },
  //       legend: {
  //         data: combinedGraphData.map((data) => data.teacherName),
  //       },
  //       xAxis: {
  //         type: "category",
  //         boundaryGap: false,
  //         data: combinedGraphData[0].values.map((entry) => entry.month),
  //       },
  //       yAxis: {
  //         type: "value",
  //       },
  //       series: combinedGraphData.map((data) => ({
  //         name: data.teacherName,
  //         type: "line",
  //         data: data.values.map((entry) => entry.value),
  //       })),
  //     };

  //     console.log(options.legend)
  //     console.log(selectedTeacher)
  //     if (chartRef.current) {
  //       console.log(options.legend)
  //       const chartInstance = echarts.init(chartRef.current);
  //       chartInstance.setOption(options);
  //     }
  //   }
  // }, [combinedGraphData, selectedTeacher]);


  // useEffect(() => {
  //   if (combinedGraphGroupData && combinedGraphGroupData.length > 0) {
  //     const options = {
  //       title: {
  //         text: `${year}년 ${chartTitle} 통계 차트`,
  //       },
  //       tooltip: {
  //         trigger: "axis",
  //       },
  //       legend: {
  //         data: combinedGraphGroupData.map((data) => data.teacherName),
  //       },
  //       xAxis: {
  //         type: "category",
  //         boundaryGap: false,
  //         data: combinedGraphGroupData[0].values.map((entry) => entry.month),
  //       },
  //       yAxis: {
  //         type: "value",
  //       },
  //       series: combinedGraphGroupData.map((data) => ({
  //         name: data.teacherName,
  //         type: "line",
  //         data: data.values.map((entry) => entry.value),
  //       })),
  //     };

  //     if (chartRefGroup.current) {
  //       const chartInstance = echarts.init(chartRefGroup.current);
  //       chartInstance.setOption(options);
  //     }
  //   }
  // }, [combinedGraphGroupData, selectedMaster]);

  // useEffect(() => {
  //   fetchData(selectedChart);
  // }, [dispatch, getBranchInfoCompleted, branchInfo, branchId, year, selectedTeacher]);

  // -----------------------------------------------------------------------------------------------
  useEffect(() => {
    dispatch(getBranchGraph(year, selectedTeacher, branchId));
  }, [dispatch, year, selectedTeacher, branchId]);

  useEffect(() => {
    if (graph !== undefined && graph.length !== 0) {
      const formattedData: FormattedData[] = [];
      const teacherName: string = graph.teacherName;
      const values = Array.from({ length: 12 }, (_, index) => {
        const monthData = graph[selectedChart][index];
        return { month: `${index + 1}월`, value: monthData || 0 };
      });
      formattedData.push({ teacherName, values });

      const options = {
        title: {
          text: `${year}년 ${chartTitle} 통계 차트`,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: formattedData.map((data) => data.teacherName),
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: formattedData[0].values.map((entry) => entry.month),
        },
        yAxis: {
          type: "value",
        },
        series: formattedData.map((data) => ({
          name: data.teacherName,
          type: "line",
          data: data.values.map((entry) => entry.value),
        })),
      };

      const chartInstance = echarts.init(chartRef.current);
      chartInstance.setOption(options);
    }
  }, [graph, selectedChart]);

  // useEffect(() => {
  //   fetchDataGroup(selectedChartGroup);
  // }, [dispatch, getBranchInfoCompleted, branchInfo, branchId, year, selectedMaster]);

  const teacherOption: { value: string; label: string }[] = [];

  branchInfo &&
    branchInfo.teacherInfo &&
    branchInfo.teacherInfo.map((teacher: any) => {
      teacherOption.push({
        value: teacher.teacherId,
        label: teacher.teacherName,
      });
    });

  const groupOption: { value: string; label: string }[] = [];

  branchInfo &&
    branchInfo.teacherInfo &&
    branchInfo.teacherInfo.map((teacher: any) => {
      if (teacher.groupName === "조장") {
        groupOption.push({
          value: teacher.teacherId,
          label: teacher.teacherName + " 조"
        });
      }
    })

  const handleGraphSwitchGroup = (e: any) => {
    const findOption = graphOptions.find((option) => option.value === e.target.value);
    setSelectedChartGroup(e.target.value);
    setChartTitle(findOption?.label || "매출");
    // fetchDataGroup(findOption?.value || "amountToPayGraph");
  }

  const handleGraphSwitch = (e: any) => {
    const findOption = graphOptions.find((option) => option.value === e.target.value);
    setSelectedChart(e.target.value);
    setChartTitle(findOption?.label || "매출");
    // fetchData(findOption?.value || "amountToPayGraph");
  }

  return (
    <Box mx={{ base: "4", md: "40" }}>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <VStack>
          <HStack>
            <Text
              textAlign={"center"}
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight={"bold"}
              mb={10}
            >
              {branchInfo.name} 직원 분석표
            </Text>
          </HStack>
          <HStack>
            <IconButton
              aria-label="prev"
              icon={<ArrowBackIcon />}
              mx={4}
              onClick={() => {
                setMonth(month - 1);
              }}
            />
            <Text fontSize="2xl" fontWeight="bold">
              {year}년 {month}월
            </Text>
            <IconButton
              aria-label="next"
              icon={<ArrowForwardIcon />}
              mx={4}
              onClick={() => {
                setMonth(month + 1);
              }}
            />
          </HStack>
        </VStack>
      )}
      <VStack mt={10}>
        {loadingStats ? (
          <Spinner />
        ) : errorStats ? (
          <div>{errorStats}</div>
        ) : (
          <TableContainer maxH="2000px" overflowX="auto">
            <Table variant="simple" mt={6}>
              <Thead bg="gray.100">
                <Tr>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                  >
                    지도자
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={50}
                  >
                    팀수
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={50}
                  >
                    총원
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={50}
                  >
                    신규
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={50}
                  >
                    복귀
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={50}
                  >
                    휴강
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={50}
                  >
                    탈퇴
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={50}
                  >
                    현재원
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={50}
                  >
                    평균인원
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={135}
                  >
                    총매출
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={135}
                  >
                    당월입금
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={135}
                  >
                    당월미납
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={135}
                  >
                    연체미납
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={10}
                  >
                    미납률
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                    width={135}
                  >
                    1인당 교육비
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                  >
                    출석률
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                  >
                    출석상세
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                  >
                    회원 관리
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    pt={2}
                    pb={2}
                    px={1}
                  >
                    보고현황
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {stats && stats.length > 0 ? (
                  stats.map((stat: any, index: number) => (
                    <Tr
                      key={stat.teacherId}
                      style={
                        index === stats.length - 1
                          ? { backgroundColor: "red", color: "white" }
                          : {}
                      }
                    >
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.teacherName}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.teamCount.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.totalStudent.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.newStudent.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.returnStudent.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.restStudent.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.quitStudent.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.nowStudent.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.standardStudent.toFixed(1).toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.totalIncome.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.amount.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.notPaid.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {stat.notPaidAcc.toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {(stat.notPaidRate * 100).toFixed(1)}%
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {Math.floor(stat.amountPerStudent).toLocaleString()}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {(stat.attendRate * 100).toFixed(1)}%
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {index !== stats.length - 1 && (
                          <Button
                            style={{
                              width: "70%",
                              height: "40%",
                              fontSize: "16px",
                            }}
                            colorScheme="blue"
                            onClick={() => {
                              window.location.href = `/youthdb/teacher-team/${stat.teacherId}/${year}/${month}`;
                            }}
                          >
                            출석상세
                          </Button>
                        )}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {index !== stats.length - 1 && (
                          <Button
                            style={{
                              width: "70%",
                              height: "40%",
                              fontSize: "16px",
                            }}
                            colorScheme="red"
                            onClick={() => {
                              window.location.href = `/youthdb/youth-student?teacherId=${stat.teacherId}`;
                            }}
                          >
                            회원 관리
                          </Button>
                        )}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        pt={2}
                        pb={2}
                        px={1}
                      >
                        {index !== stats.length - 1 ? (
                          <Button
                            style={{
                              width: "70%",
                              height: "40%",
                              fontSize: "16px",
                            }}
                            colorScheme="orange"
                            onClick={() => {
                              window.location.href = `/youthdb/consultmemo/teacher/${stat.teacherId}`;
                            }}
                          >
                            보고현황
                          </Button>
                        ) : (
                          <Button
                            style={{
                              width: "70%",
                              height: "40%",
                              fontSize: "16px",
                            }}
                            colorScheme="orange"
                            onClick={() => {
                              window.location.href = `/youthdb/consultmemo/teacher-all`;
                            }}
                          >
                            전체 보고현황
                          </Button>
                        )
                        }
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={15} textAlign={"center"} color="red">
                      데이터가 없습니다.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="center"
          w={"100%"}
        >
          <Text>
            팀 이동: {stats && stats[stats && stats.length - 1]?.transferCount}
          </Text>
        </Box>
        {/* <Box mt={10}>
        <Select
          placeholder={"그룹을 선택하세요..."}
          onChange={(e) => setSelectedMaster(e.target.value)}
          width={{ base: "150px", md: "200px" }}
          height={{ base: "40px" }}
          borderColor="tomato"
          fontSize={{ base: "16px" }}
          defaultValue={""}
        >
          {groupOption.map((group: any) => (
            <option key={group.value} value={group.value}>
              {group.label}
            </option>
          ))}
        </Select>
      </Box>
      {loadingGraphGroup ? (
        <div>Loading...</div>
      ) : errorGraphGroup ? (
        <div>그룹 통계를 보기 위해서는 그룹을 선택하여 주십시오.</div>
      ) : (
        <>
          <HStack mt={6}>
            <Select
              style={{ width: "200px", marginLeft: "auto" }}
              value={selectedChartGroup}
              onChange={handleGraphSwitchGroup}
            >
              {graphOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </HStack>
          <Center mt={10}>
            <div ref={chartRefGroup} style={{ width: "100%", height: "400px" }} />
          </Center>
        </>
      )} */}
        <Box mt={10} display="flex" width="100%">
          <Select
            placeholder={"강사를 선택하세요..."}
            onChange={(e) => {
              setSelectedTeacher(e.target.value);
              setSelectedChart("amountToPayGraph");
            }}
            width={{ base: "150px", md: "150px" }}
            height={{ base: "40px" }}
            borderColor="tomato"
            fontSize={{ base: "16px" }}
            defaultValue={""}
            mr={2}
          >
            {teacherOption.map((teacher: any) => (
              <option key={teacher.value} value={teacher.value} selected={teacher.value === selectedTeacher}>
                {teacher.label}
              </option>
            ))}
          </Select>
          <Select
            width={{ base: "150px", md: "150px" }}
            height={{ base: "40px" }}
            borderColor="tomato"
            fontSize={{ base: "16px" }}
            value={selectedChart}
            onChange={handleGraphSwitch}
          >
            {graphOptions.map((option) => (
              <option key={option.value} value={option.value} selected={option.value === selectedChart}>
                {option.label}
              </option>
            ))}
          </Select>
        </Box>
        {loadingGraph ? (
          <div>Loading...</div>
        ) : errorGraph ? (
          <div>
            개인 통계를 보기 위해서는 조회하고자 하는 강사를 선택하여 주십시오.
          </div>
        ) : (
          <>
            <Center mt={4} width={"100%"}>
              <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
            </Center>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default BranchMainScreen;
