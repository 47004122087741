import {
  Box,
  Button,
  Center,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
  VStack,
  Grid,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { getYouthTeamList } from "../../redux/actions/YouthTeamActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getYouthStudentQuitList } from "../../redux/actions/YouthStudentActions";
import { getTeachersByUserId } from "../../redux/actions/BranchActions";

const StudentMainScreen = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const teacherId = userInfo._id;
  const dispatch: any = useDispatch();

  const searchParams = new URLSearchParams(useLocation().search);
  const urlTeacherId = searchParams.get("teacherId");

  const [teacherOption, setTeacherOption] = useState<any[]>([]);
  const [team, setTeam] = useState<any[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<string>("");

  const youthTeamList = useSelector((state: any) => state.youthTeamList);
  const { loading, error, youthTeams } = youthTeamList;

  const teachersByUserId = useSelector((state: any) => state.teachersByUserId);
  const { teachers } = teachersByUserId;

  useEffect(() => {
    dispatch(getTeachersByUserId(teacherInfo.branch._id, teacherId));
  }, [dispatch, teacherId, teacherInfo.branch._id]);

  useEffect(() => {
    if (teachers?.length > 0) {
      const teacherList = teachers.map((teacher: any) => ({
        value: teacher.teacherId,
        label: teacher.teacherName,
      }));

      setTeacherOption(teacherList);

      if (urlTeacherId) {
        setSelectedTeacher(urlTeacherId);
        dispatch(getYouthTeamList(urlTeacherId, teacherInfo.branch._id, false));
      } else {
        setSelectedTeacher(teachers[0].teacherId);
        dispatch(
          getYouthTeamList(teachers[0].teacherId, teacherInfo.branch._id, false)
        );
      }
    }
  }, [teachers]);

  const handleTeacherClick = (teacherId: string) => {
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, false));
  };

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack>
        <HStack mb={2}>
          <Text
            textAlign={"center"}
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight={"bold"}
          >
            {youthTeams?.teacherName} 강사 회원 관리
          </Text>
        </HStack>
        <Grid
          columnGap={4}
          rowGap={2}
          templateColumns={{
            base: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(2, 1fr)",
            xl: "repeat(2, 1fr)",
            "2xl": "repeat(2, 1fr)",
          }}
          textAlign={"center"}
          mb={4}
        >
          <Link to={"/youthdb/youth-student/new"}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">신규 회원 추가</Button>
          </Link>
          <Link to={"/youthdb/youth-team/new"}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">신규 팀 추가</Button>
          </Link>
          <Link to={`/youthdb/youth-student/quit/1`}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">탈퇴 회원 관리</Button>
          </Link>
          <Link to={`/youthdb/youth-student/search/1`}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">회원 검색 🔍</Button>
          </Link>
          <Link to={`/youthdb/attend/supplement-list`}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">보강 관리</Button>
          </Link>
        </Grid>
        <HStack spacing={4}>
          <Link to={`/youthdb/youth-student/branch`}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">전체 회원</Button>
          </Link>
          <Select
            onChange={(e) => handleTeacherClick(e.target.value)}
            width={{ base: "100px", md: "200px" }}
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={{ base: "16px", md: "xl" }}
            variant="filled"
            bg="blue.200"
          >
            {teacherOption.map((item) => (
              <option key={item.value} value={item.value} selected={item.value === selectedTeacher}>
                {item.label}
              </option>
            ))}
          </Select>
          <Link to={"/youthdb/youth-student/xlsx"}>
            <Button colorScheme="orange" w="120px" h="30px" fontSize="12px">신규 회원 추가(엑셀)</Button>
          </Link>
        </HStack>
        <HStack>
          <TableContainer
            maxH="2000px"
            maxW={{ base: "350px", sm: "500px", md: "700px", lg: "1500px" }}
            minW={{ base: "300px", md: "400px" }}
            overflowX="auto"
          >
            <Table variant="simple" colorScheme="orange" border="ActiveBorder">
              <Thead bg="gray.100">
                <Tr>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    팀명
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    회원수
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                  >
                    관리
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <h2>Loading...</h2>
                ) : error ? (
                  <h2>{error}</h2>
                ) : (
                  youthTeams?.youthTeam?.map((team: any) => (
                    <Tr key={team._id}>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                      >
                        <Center>
                          {/* <Text
                            cursor="pointer"
                            fontWeight={"bold"}
                            _hover={{ color: "blue.500" }}
                          >
                            <Link to={`${team._id}`}>{team.name}</Link>
                          </Text> */}
                          {team.name}
                        </Center>
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                      >
                        {team.members.length}
                      </Td>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                      >
                        <HStack
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Button
                            colorScheme="blue"
                            onClick={() => {
                              window.location.href = `/youthdb/youth-student/${team._id}/edit`;
                            }}
                          >
                            팀 관리
                          </Button>
                          {teacherInfo?.accessList.includes("원장") && (
                            <>
                              <Button
                                colorScheme="red"
                                onClick={() => {
                                  window.location.href = `/youthdb/youth-student/${team._id}/move`;
                                }}
                              >
                                팀 이동
                              </Button>
                            </>
                          )}
                        </HStack>
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </HStack>
      </VStack>
    </Box>
  );
};

export default StudentMainScreen;