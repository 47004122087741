import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  HStack,
  Heading,
  Stack,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { deleteYouthTeam, getYouthTeamDetail } from "../../redux/actions/YouthTeamActions";
import { useDispatch } from "react-redux";
import formatPhoneNum from "../../utils/formatPhoneNum";

const StudentListScreen = () => {
  const { id } = useParams<{ id: string }>();
  const teamId = id ?? "";
  const dispatch: any = useDispatch();

  const youthTeamDetail = useSelector((state: any) => state.youthTeamDetail);
  const { loading, error, youthTeam } = youthTeamDetail;

  const youthTeamDelete = useSelector((state: any) => state.youthTeamDelete);
  const { success: successDelete } = youthTeamDelete;

  const startRef = useRef();

  useEffect(() => {
    dispatch(getYouthTeamDetail(teamId));
  }, [dispatch, teamId]);

  const deleteHandler = (youthTeamId: string) => {
    if (window.confirm(`[${youthTeam.name}]반을 삭제하시겠습니까?`)) {
      dispatch(deleteYouthTeam(youthTeamId));

      window.location.href = "/youthdb/youth-student";
    }
  }

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <VStack gap={10}>
          <Card
            minW={{ base: "300px", md: "1000px" }}
            textAlign={"center"}
            backgroundColor={"orange.300"}
            borderRadius={"25px"}
          >
            <CardHeader>
              <Heading size="xl">{youthTeam.name}</Heading>
            </CardHeader>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="md">지점 : {youthTeam.branchName}</Heading>
                  <Heading size="md">
                    회원 수 :{" "}
                    {youthTeam && youthTeam.members && youthTeam.members.length}
                  </Heading>
                </Box>
                <Box>
                  <Heading size="md">
                    담당 선생님 : {youthTeam.teacherName}
                  </Heading>
                </Box>
              </Stack>
            </CardBody>
          </Card>
          <Table variant="simple" colorScheme="orange" textAlign="center">
            <Thead bg="gray.100">
              <Tr>
                <Th textAlign="center" borderWidth="1px" borderColor="gray.400">
                  성명
                </Th>
                <Th textAlign="center" borderWidth="1px" borderColor="gray.400">
                  보호자 연락처
                </Th>
                <Th textAlign="center" borderWidth="1px" borderColor="gray.400">
                  휴강 여부
                </Th>
                <Th textAlign="center" borderWidth="1px" borderColor="gray.400">
                  관리
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {youthTeam && youthTeam.memberInfo != null ? (
                youthTeam.memberInfo.map((member: any) => (
                  <Tr key={member._id}>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      fontWeight={"bold"}
                    >
                      <Center>
                        <Text
                          cursor="pointer"
                          fontWeight={"bold"}
                          _hover={{ color: "blue.500" }}
                        >
                          <Link to={`${member.memberId}`}>{member.memberName}</Link>
                        </Text>
                      </Center>
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                    >
                      {member.parentPhoneNum && formatPhoneNum(member.parentPhoneNum)}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                    >
                      {member.isRest ? "O" : "-"}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                    >
                      <Button colorScheme="red" variant={"ghost"}>
                        <Link to={`${member.memberId}/update`}>정보수정</Link>
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td textAlign={"center"}>회원이 없습니다.</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          <Box mt={40} mb={40}></Box>
          <HStack gap={10}>
            <Link to={"batch"}>
              <Button colorScheme="orange" w={160}>
                신규 회원 일괄 추가
              </Button>
            </Link>
            <Button
              colorScheme="red"
              w={160}
              onClick={() => deleteHandler(youthTeam._id)}
            >
              팀 삭제
            </Button>
          </HStack>
        </VStack>
      )}
    </Box>
  );
};

export default StudentListScreen;
