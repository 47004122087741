import {
  Badge,
  Box,
  Button,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getYouthPost } from "../../redux/actions/HomePageActions";
import { Link, useParams, useSearchParams } from "react-router-dom";
import AdminHeaderBox from "../../components/AdminHeaderBox";
import { differenceInDays } from "date-fns";

export default function Post() {
  const [searchParams] = useSearchParams();
  const pageNumber: number = Number(searchParams.get("p")) || 1;

  const { category } = useParams<{ category: string }>();
  const categoryStr = String(category);

  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const youthPostList = useSelector((state: any) => state.youthPostList);
  const { loading, error, youthPosts } = youthPostList;

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  useEffect(() => {
    dispatch(getYouthPost(branchPage?.branchId, "", pageNumber, categoryStr));
  }, [dispatch, branchPage?.branchId, categoryStr, pageNumber]);

  const text: string = categoryStr;

  const dateFormat = (date: string) => {
    const month = date.substring(5, 7);
    const day = date.substring(8, 10);

    return `${month}/${day}`;
  };

  return (
    <>
      {loading ? (
        <Box textAlign="center" mt={20}>
          <Spinner size="xl" />
        </Box>
      ) : error ? (
        <Box textAlign="center" mt={20}>
          <Text color="red.500">오류가 발생했습니다: {error}</Text>
        </Box>
      ) : (
        <>
          <AdminHeaderBox
            text={text}
            adminHeaderBg={branchPage && branchPage.adminHeaderBg}
          />
          <Box
            maxW={"1000px"}
            mx={"auto"}
            mt={{ base: 30, md: 50 }}
            mb={{ base: 30, md: 50 }}
          >
            {teacherInfo &&
              teacherInfo.accessList &&
              teacherInfo.accessList.length > 0 && (
                <Box px={4} mt={4} textAlign="right">
                  <Link
                    to={`/${
                      branchPage && branchPage.branchUrl
                    }/post-write/${categoryStr}`}
                  >
                    <Button mt="20px" mx={4} colorScheme="orange" variant="link">
                      새 안내문 작성
                    </Button>
                  </Link>
                </Box>
              )}
            <TableContainer>
              <Table variant="simple" mt={4}>
                <Thead
                  borderTop={"2px solid"}
                  borderBottom={"2px solid #E9ECEF"}
                  backgroundColor={"gray.100"}
                >
                  <Tr>
                    <Th w={"120px"} textAlign={"center"}>
                      날짜
                    </Th>
                    <Th textAlign={"center"}>제목</Th>
                    <Th
                      w={"120px"}
                      textAlign={"center"}
                      display={{ base: "none", md: "table-cell" }} // 모바일에서 숨김
                    >
                      작성자
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {youthPosts.youthPost &&
                    youthPosts.youthPost.map((y: any, index: number) => (
                      <Tr
                        key={index}
                        borderBottom={"2px solid #E9ECEF"}
                        sx={{
                          "&:hover": {
                            backgroundColor: "gray.100",
                          },
                        }}
                      >
                        <Td textAlign={"center"}>{dateFormat(y.date)}</Td>
                        <Td
                          sx={{
                            "&:hover": {
                              fontWeight: "bold",
                              textDecoration: "underline",
                            },
                          }}
                        >
                          <Link to={`${y._id}?p=${pageNumber}`}>
                            {y.title}
                            {differenceInDays(new Date(), new Date(y.date)) <=
                              4 && (
                              <Badge colorScheme="blue" ml={2}>
                                N
                              </Badge>
                            )}
                          </Link>
                        </Td>
                        <Td
                          textAlign={"center"}
                          display={{ base: "none", md: "table-cell" }} // 모바일에서 숨김
                        >
                          {y.author}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
}
