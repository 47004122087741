import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { deleteYouthTeam, getYouthTeamDetail } from "../../redux/actions/YouthTeamActions";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { updateYouthTeam } from "../../redux/actions/YouthTeamActions";
import formatPhoneNum from "../../utils/formatPhoneNum";
import { getBranchInfo } from "../../redux/actions/BranchActions";

const TeamMoveScreen = () => {
  const { id } = useParams<{ id: string }>();
  const teamId = id ?? "";
  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const userId = userInfo._id;

  const youthTeamDetail = useSelector((state: any) => state.youthTeamDetail);
  const { loading, error, youthTeam } = youthTeamDetail;

  const branchInfoList = useSelector((state: any) => state.branchInfoList);
  const {
    loading: loadingBranchInfo,
    error: errorBranchInfo,
    branchInfo
  } = branchInfoList;

  const [selectedTeacher, setSelectedTeacher] = useState<any>(null);

  const teacherOptions: any = [];

  branchInfo &&
    branchInfo.teacherInfo &&
    branchInfo.teacherInfo.map((teacher: any) => {
      teacherOptions.push({
        value: teacher.teacherId,
        label: teacher.teacherName,
      });
    });

  useEffect(() => {
    dispatch(getBranchInfo(teacherInfo.branch._id));
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(getYouthTeamDetail(teamId));
  }, [dispatch, teamId]);

  const moveTeamHandler = (member: any) => {
    const updatedYouthTeam = {
      ...youthTeam,
      teacherId: selectedTeacher,
    };

    dispatch(updateYouthTeam(teamId, updatedYouthTeam));

    window.location.href = "/youthdb/youth-student";
  }

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <Box
          mt={10}
          mx={6}
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <VStack>
            <HStack>
              <Text fontWeight="bold" fontSize="2xl">
                {youthTeam?.name}
              </Text>
              <Text>

                팀을 다른 선생님으로 이동합니다.
              </Text>
            </HStack>
            <Select
              options={teacherOptions}
              onChange={(e: any) => setSelectedTeacher(e.value)}
            />
            <Button colorScheme="red" w={50} onClick={moveTeamHandler}>
              팀 이동
            </Button>
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default TeamMoveScreen;
