import axios from "axios";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  Box,
  HStack,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getYouthTeamList } from "../../redux/actions/YouthTeamActions";
import { xlsxUpload } from "../../redux/actions/PaymentActions";
import { API_SERVER_URL } from "../../redux/actions/actionUrl";
import accessCheck from "../../utils/accessCheck";

const PaymentAutoCardScreen: React.FC = () => {
  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const teacherId = userInfo._id;

  const youthTeamList = useSelector((state: any) => state.youthTeamList);
  const { loading, error, youthTeams } = youthTeamList;

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [resData, setResData] = useState<any>(null);
  const [loadingData, setLoadingData] = useState(false);

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    Number(new Date().toISOString().slice(5, 7))
  );

  interface Student {
    _id: string;
    name: string;
  }

  const [selectedStudentObj, setSelectedStudentObj] = useState<Student[]>([]);


  useEffect(() => {
    // if (!accessCheck(["원장", "부원장", "조장"])) {
    //   alert("접근 권한이 없습니다.");
    //   window.location.href = "/youthdb/my";
    // }
  }, []);

  useEffect(() => {
    if (month === 0) {
      setMonth(12);
      setYear(year - 1);
    } else if (month === 13) {
      setMonth(1);
      setYear(year + 1);
    }
  }, [month, year]);

  useEffect(() => {
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, true));
  }, [dispatch, teacherId, teacherInfo.branch._id]);

  const studentOption: any[] = [];

  youthTeams?.youthTeam?.map((youthTeam: any) => {
      youthTeam.memberInfo?.map((student: any) => {
        studentOption.push({
          value: student.memberId,
          label:
            student.memberName + "(" + youthTeam.name + ")",
        });
      });
    });

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);

      if (
        e.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        e.target.files[0].type !== "application/vnd.ms-excel"
      ) {
        alert("엑셀 파일만 업로드 가능합니다.");
        return;
      }

      const formData = new FormData();
      formData.append("file", e.target.files[0]);

      try {
        setLoadingData(true);
        const response = await axios.post(
          `${API_SERVER_URL}/api/xlsx/card?year=${year}&month=${month}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setLoadingData(false);
        setResData(response.data);
        console.log(response.data)
      } catch (error: any) {
        alert(error.response.data.message);

        setLoadingData(false);
      }
    }
  };

  type DataArray = (string | number | null)[][];

  const bgSet = (index: number, isExist: boolean) => {
    return selectedStudentObj[index] ? "gray.300" : isExist ? "red.400" : "white"
  }

  interface Props {
    data: DataArray;
  }

  const studentChangeHandler = (selectedValue: any, index: number) => {
    const newSelectedValues = [...selectedStudentObj];

    newSelectedValues[index] = {
      _id: selectedValue.value,
      name: selectedValue.label,
    };

    setSelectedStudentObj(newSelectedValues);
  };

  const submitHandler = () => {
    const submitData: any = [];
    for (let i = 1; i < resData.data.length; i++) {
      let bankTag: string[] = [];

      if (selectedStudentObj[i - 1]) {
        bankTag = [
          resData.data[i][4],
          selectedStudentObj[i - 1].name.split("(")[0],
        ];
      }

      submitData.push({
        fileName: selectedFile?.name,
        depositDate: new Date(resData.data[i][0]),
        memo: resData.data[i][2],
        amount: resData.data[i][1],
        content: resData.data[i][3],
        bank: resData.data[i][4],
        studentId: selectedStudentObj[i - 1] ? selectedStudentObj[i - 1]._id : "",
        studentName: selectedStudentObj[i - 1] ? selectedStudentObj[i - 1].name : "",
        bankTag: bankTag,
      });
    }

    dispatch(xlsxUpload(year, month, submitData, teacherInfo.branch._id));
  };

  return (
    <div>
      {/* {(resData !== null) && <TransactionTable data={resData.data} />}
       */}
      <Box
        mt={10}
        mb={10}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <VStack gap={10}>
          <HStack>
            <IconButton
              aria-label="prev"
              icon={<ArrowBackIcon />}
              mx={4}
              onClick={() => {
                setMonth(month - 1);
              }}
            />
            <Text fontSize="2xl" fontWeight="bold">
              {year}년 {month}월
            </Text>
            <IconButton
              aria-label="next"
              icon={<ArrowForwardIcon />}
              mx={4}
              onClick={() => {
                setMonth(month + 1);
              }}
            />
          </HStack>
          {!resData && (
          <VStack>
            <Text fontSize="l">
              1. 지정된 입금표 양식만 사용하십시오.
            </Text>
            <Text fontSize="l">
              2. 기존 은행 파일은 업로드할 수 없습니다.
            </Text>
            <Text fontSize="l">
              3. 맵핑 설정에 영향을 주는 칸을 모두 채워야 합니다. (날짜, 입금자명, 은행 및 카드사)
            </Text>
          </VStack>
          )}
          {loadingData && <Spinner />}
          <input type="file" onChange={handleFileChange} />
          <HStack>
            <TableContainer
              fontSize={12}
            >
              <Table
                variant="simple"
                colorScheme="orange"
                border="ActiveBorder"
                sx={{
                  "& th, & td": {
                    border: "1px",
                    borderColor: "gray.400",
                    padding: "8px",
                  },
                }}
              >
                <Thead bg="gray.100">
                  <Tr>
                    {resData &&
                      resData.data[0].slice(0, -1).map((header: any, index: number) => (
                        <Th
                          key={index}
                          textAlign="center"
                          border="1px"
                          borderColor="gray.400"
                          fontWeight={"bold"}
                          bg="black"
                          color="white"
                        >
                          {header}
                        </Th>
                      ))
                    }
                    {resData && (
                      <>
                        <Th
                          textAlign="center"
                          border="1px"
                          borderColor="gray.400"
                          fontWeight={"bold"}
                          bg="black"
                          color="white"
                          minW={200}
                        >
                          회원 찾기
                        </Th>
                        <Th
                          textAlign="center"
                          border="1px"
                          borderColor="gray.400"
                          fontWeight={"bold"}
                          minW={200}
                        >
                          맵핑
                        </Th>
                        <Th
                          textAlign="center"
                          border="1px"
                          borderColor="gray.400"
                          fontWeight={"bold"}
                        >
                          연결 회원
                        </Th>
                        <Th
                          textAlign="center"
                          border="1px"
                          borderColor="gray.400"
                          fontWeight={"bold"}
                        >
                          삭제
                        </Th>
                      </>
                    )}
                  </Tr>
                </Thead>
                <Tbody
                  bg="white"
                  color="black"
                  overflow="auto"
                >
                  {resData &&
                    resData.data.slice(1, resData.data.length).map((p: any, index: number) => (
                      <Tr key={index}>
                        {p.slice(0, -2).map((cell: any, cellIndex: number) => (
                          <Td
                            textAlign="center"
                            border="1px"
                            borderColor="gray.400"
                            bg={bgSet(index, p[p.length - 1])}
                          >
                            {cell}
                          </Td>
                        ))}
                        {
                          <Td
                            textAlign="center"
                            border="1px"
                            borderColor="gray.400"
                            bg={bgSet(index, p[p.length - 1])}
                            position="relative"
                          >
                            <Select
                              onChange={(e: any) => studentChangeHandler(e, index)}
                              placeholder="회원 선택"
                              isSearchable
                              options={studentOption.map((student, index) => ({
                                value: student.value,
                                label: student.label,
                              }))}
                              menuPosition="fixed"
                            />
                          </Td>
                        }
                        {p[p.length - 2].length !== 0 ? (
                          <Td
                            textAlign="center"
                            border="1px"
                            borderColor="gray.400"
                            bg={bgSet(index, p[p.length - 1])}
                            position="relative"
                          >
                            <Select
                              onChange={(e: any) => studentChangeHandler(e, index)}
                              placeholder="회원 선택"
                              isSearchable
                              options={p[p.length - 2].map(
                                (student: any, index: number) => ({
                                  value: student._id,
                                  label:
                                    student.name +
                                    "(" +
                                    student.youthTeamName +
                                    ")",
                                })
                              )}
                              menuPosition="fixed"
                            />
                          </Td>
                        ) : (
                          <Td
                          textAlign="center"
                          border="1px"
                          borderColor="gray.400"
                          bg={bgSet(index, p[p.length - 1])}
                          ></Td>
                        )}
                        <Td
                          textAlign="center"
                          border="1px"
                          borderColor="black"
                          bg={bgSet(index, p[p.length - 1])}
                          fontWeight={"bold"}
                        >
                          {selectedStudentObj[index] && selectedStudentObj[index].name}
                        </Td>
                        <Td
                          textAlign="center"
                          border="1px"
                          borderColor="black"
                          bg={bgSet(index, p[p.length - 1])}
                          fontWeight={"bold"}
                        >
                          <Button
                            onClick={() => {
                              const newResData = { ...resData };
                              
                              newResData.data.splice(index + 3, 1)
                              setResData(newResData);
                            }}
                          >
                            삭제
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </HStack>
          <HStack>
            <Button colorScheme="blue" onClick={submitHandler}>
              입력
            </Button>
          </HStack>
        </VStack>
      </Box>
    </div>
  );
};

export default PaymentAutoCardScreen;