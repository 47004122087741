import axios from 'axios';
import { API_SERVER_URL } from '../redux/actions/actionUrl';

class MyUploadAdapter {
  loader: any;

  constructor(loader: any) {
    this.loader = loader;
  }

  async upload() {
    const data = new FormData();
    const file = await this.loader.file;
    data.append('image', file);

    return axios.post(`${API_SERVER_URL}/api/upload/youthpost`, data)
      .then(response => {
        return {
          default: response.data
        };
      })
      .catch(error => {
        throw error;
      });
  }

  abort() {
    // Aborts the upload process.
  }
}

function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new MyUploadAdapter(loader);
  };
}

export default MyCustomUploadAdapterPlugin;