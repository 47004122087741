import { Box, Button, Center, Flex, Image, Text } from "@chakra-ui/react";
import AdminHeaderBox from "../../components/AdminHeaderBox";
import { useParams } from "react-router-dom";
import HTMLRenderer from "../../components/HTMLRenderer";
import { useSelector } from "react-redux";
import accessCustomize from "../../utils/accessCustomize";

export default function Greeting() {
  const { id } = useParams<{ id: string }>();
  const branchUrl = String(id);

  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const text: string = "인사말";

  return (
    <>
      {branchPage && branchPage.footerInfo && branchPage.greetingInfo && branchPage.greetingInfo.specList && (
        <Box bg={"white"} color="black">
          <AdminHeaderBox text={text} adminHeaderBg={branchPage && branchPage.adminHeaderBg} />
          <Box mx={"auto"} maxW={"1100px"} pb={100} px={4}>
            <Box textAlign={"center"}>
              <Flex mt={24} mb={30} gap={12} display={{ base: "block", md: "flex" }}>
                <Text flex={1} fontSize={{ base: 12, md: 16 }} textAlign={"left"} mb={10}>
                  <Center mt={{ base: "0px", md: "50px" }} mb="20px">
                    <Image src={branchPage.greetingInfo.image[0]} width={"1100px"} />
                  </Center>
                  <HTMLRenderer htmlContent={branchPage.greetingInfo.content} />
                </Text>
                <Text flex={1} fontSize={{ base: 12, md: 16 }} textAlign={"left"}>
                  <Center mt={{ base: "0px", md: "50px" }} mb="20px">
                    <Image src={branchPage.greetingInfo.image[1]} width={"1100px"} />
                  </Center>
                  {branchPage.greetingInfo.specList.map((item: any, index: number) => (
                    <Box
                      key={index}
                      borderBottom={index === branchPage.greetingInfo.specList.length - 1 ? "none" : "1px"}
                      borderColor={"gray.300"}
                      pb={4}
                      mb={4}
                    >
                      <b>{item.specName}</b>
                      {item.specContent.map((content: string, index: number) => (
                        <div key={index}>· {content}</div>
                      ))}
                    </Box>
                  ))}
                </Text>
              </Flex>
              {accessCustomize(teacherInfo, branchPage.branchId) && (
                <Button
                  onClick={() => {
                    window.location.href = `/${branchUrl}/greeting-customize`;
                  }}
                >
                  인사말 페이지 관리
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
