import axios from "axios";
import {
  BRANCH_GET_REQUEST,
  BRANCH_GET_SUCCESS,
  BRANCH_GET_FAIL,
  BRANCH_GET_TEACHER_STAT_REQUEST,
  BRANCH_GET_TEACHER_STAT_SUCCESS,
  BRANCH_GET_TEACHER_STAT_FAIL,
  BRANCH_GET_GROUP_STAT_REQUEST,
  BRANCH_GET_GROUP_STAT_SUCCESS,
  BRANCH_GET_GROUP_STAT_FAIL,
  BRANCH_GET_TEACHER_SINGLE_STAT_REQUEST,
  BRANCH_GET_TEACHER_SINGLE_STAT_SUCCESS,
  BRANCH_GET_TEACHER_SINGLE_STAT_FAIL,
  BRANCH_GET_TEACHER_TEAM_STAT_REQUEST,
  BRANCH_GET_TEACHER_TEAM_STAT_SUCCESS,
  BRANCH_GET_TEACHER_TEAM_STAT_FAIL,
  BRANCH_INFO_REQUEST,
  BRANCH_INFO_SUCCESS,
  BRANCH_INFO_FAIL,
  BRANCH_GET_GRAPH_REQUEST,
  BRANCH_GET_GRAPH_SUCCESS,
  BRANCH_GET_GRAPH_FAIL,
  BRANCH_UPDATE_REQUEST,
  BRANCH_UPDATE_SUCCESS,
  BRANCH_UPDATE_FAIL,
  BRANCH_UPDATE_RESET,
  BRANCH_GET_GRAPH_GROUP_REQUEST,
  BRANCH_GET_GRAPH_GROUP_SUCCESS,
  BRANCH_GET_GRAPH_GROUP_FAIL,
  GET_TEACHERS_BY_USERID_REQUEST,
  GET_TEACHERS_BY_USERID_SUCCESS,
  GET_TEACHERS_BY_USERID_FAIL,
  GET_TEACHERS_BY_USERID_RESET,
} from "../constants/BranchConstants";
import { Dispatch } from "redux";
import { API_SERVER_URL } from "./actionUrl";

export const getBranch =
  (id: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: BRANCH_GET_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/branch/info/user?userId=${id}`
        );

      dispatch({
        type: BRANCH_GET_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BRANCH_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getTeacherStats =
  (branchId: string, year: number, month: number) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: BRANCH_GET_TEACHER_STAT_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/branch/stats/teacher?branchId=${branchId}&year=${year}&month=${month}`
      );

      dispatch({
        type: BRANCH_GET_TEACHER_STAT_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BRANCH_GET_TEACHER_STAT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getGroupStats =
  (branchId: string, year: number, month: number, teacherId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: BRANCH_GET_GROUP_STAT_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/branch/stats/group/${teacherId}?branchId=${branchId}&year=${year}&month=${month}`
      );

      dispatch({
        type: BRANCH_GET_GROUP_STAT_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BRANCH_GET_GROUP_STAT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getTeacherSingleStat =
  (teacherId: string, year: number, month: number, branchId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: BRANCH_GET_TEACHER_SINGLE_STAT_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/branch/stats/teacher/${teacherId}?year=${year}&month=${month}&branchId=${branchId}`
      );

      dispatch({
        type: BRANCH_GET_TEACHER_SINGLE_STAT_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BRANCH_GET_TEACHER_SINGLE_STAT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getTeacherTeamStat =
  (teacherId: string, year: number, month: number, branchId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: BRANCH_GET_TEACHER_TEAM_STAT_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/branch/stats/teacher-team/${teacherId}?year=${year}&month=${month}&branchId=${branchId}`
      );

      dispatch({
        type: BRANCH_GET_TEACHER_TEAM_STAT_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BRANCH_GET_TEACHER_TEAM_STAT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


export const getBranchGraph =
  (year: number, teacherId: string, branchId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: BRANCH_GET_GRAPH_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/branch-stats/graph/individual?year=${year}&teacherId=${teacherId}&branchId=${branchId}`
      );

      dispatch({
        type: BRANCH_GET_GRAPH_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BRANCH_GET_GRAPH_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getBranchGraphGroup =
  (branchId: string, year: number, masterId: string) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: BRANCH_GET_GRAPH_GROUP_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/branch-stats/graph/group/${branchId}?year=${year}&masterId=${masterId}`
      );

      dispatch({
        type: BRANCH_GET_GRAPH_GROUP_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BRANCH_GET_GRAPH_GROUP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getBranchInfo =
  (branchId: string): ((dispatch: Dispatch) => Promise<void>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: BRANCH_INFO_REQUEST,
      });

      const { data } = await axios.get(
        `${API_SERVER_URL}/api/branch/info/${branchId}`
      );

      dispatch({
        type: BRANCH_INFO_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: BRANCH_INFO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateBranch =
  (branch: any) =>
  async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    try {
      dispatch({
        type: BRANCH_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${API_SERVER_URL}/api/branch/${branch._id}`,
        branch,
        config
      );

      dispatch({
        type: BRANCH_UPDATE_SUCCESS,
        payload: data,
      });
      
      window.location.reload();
    } catch (error: any) {
      dispatch({
        type: BRANCH_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


export const getTeachersByUserId = (branchId: string, userId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: GET_TEACHERS_BY_USERID_REQUEST,
    });

    const { data } = await axios.get(
      `${API_SERVER_URL}/api/branch/teachers/user?branchId=${branchId}&userId=${userId}`
    );

    dispatch({
      type: GET_TEACHERS_BY_USERID_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: GET_TEACHERS_BY_USERID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}