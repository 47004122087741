import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Text,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  VStack,
  IconButton,
  Center,
  Select,
} from "@chakra-ui/react";
import {
  getBranchGraphGroup,
  getBranchInfo,
  getBranchGraph,
  getTeacherStats,
  getTeacherTeamStat,
} from "../../redux/actions/BranchActions";
import accessCheck from "../../utils/accessCheck";
import { useParams } from "react-router-dom";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

interface FormattedData {
  teacherName: string;
  values: { month: string; value: number }[] | any[];
}

const TeacherTeamMainScreen = () => {
  const { id = "" } = useParams<{ id?: string }>();
  const { initYear = "", initMonth = "" } = useParams<{ initYear?: string; initMonth?: string }>();

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const [year, setYear] = useState(initYear ? Number(initYear) : new Date().getFullYear());
  const [month, setMonth] = useState(initMonth ? Number(initMonth) : new Date().getMonth() + 1);
  const [sortBy, setSortBy] = useState("attendRate");



  useEffect(() => {
    // if (!accessCheck(["원장", "부원장", "조장"])) {
    //   alert("접근 권한이 없습니다.");
    //   window.location.href = "/youthdb/my";
    // }
  }, []);

  const teacherTeamStat = useSelector((state: any) => state.teacherTeamStat);
  const { loading, error, teamStat } = teacherTeamStat;

  useEffect(() => {
    dispatch(getTeacherTeamStat(id, year, month, teacherInfo.branch._id));
  }, [dispatch, year, month, id, teacherInfo.branch._id]);

  useEffect(() => {
    if (month === 13) {
      setYear(year + 1);
      setMonth(1);
    }
    if (month === 0) {
      setYear(year - 1);
      setMonth(12);
    }
  }, [month]);

  const handleChangeSortBy = (value: string) => {
    setSortBy(value);
  };

  const sortedTeamStatistics = teamStat?.teamStatistics?.slice().sort((a: any, b: any) => {
    if (sortBy === "attendRate") {
      return b.attendRate - a.attendRate;
    } else if (sortBy === "totalStudent") {
      return b.totalStudent - a.totalStudent;
    }
    return 0;
  });

  return (
    <Box mx={{ base: "4", md: "40" }}>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <VStack>
            <Text
              textAlign="center"
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight="bold"
              mb={10}
            >
              {teamStat.branchName} {teamStat.teacherName} 직원 분석표
            </Text>
          </VStack>
          <VStack mb={6}>
            <HStack>
              <IconButton
                aria-label="prev"
                icon={<ArrowBackIcon />}
                onClick={() => {
                  setMonth(month - 1);
                }}
              />
              <Text fontSize="2xl" fontWeight="bold">
                {year}년 {month}월
              </Text>
              <IconButton
                aria-label="next"
                icon={<ArrowForwardIcon />}
                onClick={() => {
                  setMonth(month + 1);
                }}
              />
            </HStack>
            <Select
              value={sortBy}
              onChange={(e) => handleChangeSortBy(e.target.value)}
              maxWidth="200px"
            >
              <option value="attendRate">출석률</option>
              <option value="totalStudent">인원 수</option>
              <option value="newStudent">신규</option>
              <option value="returnStudent">복귀</option>
              <option value="restStudent">휴강</option>
              <option value="quitStudent">탈퇴</option>
            </Select>
          </VStack>
          <TableContainer maxH="2000px" overflowX="auto">
            <Table variant="simple" mt={6}>
              <Thead bg="gray.100">
                <Tr>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400" pt={2} pb={2} px={1}>팀명</Th>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400" pt={2} pb={2} px={1}>인원 수</Th>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400" pt={2} pb={2} px={1}>신규</Th>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400" pt={2} pb={2} px={1}>복귀</Th>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400" pt={2} pb={2} px={1}>휴강</Th>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400" pt={2} pb={2} px={1}>탈퇴</Th>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400" pt={2} pb={2} px={1}>출석률</Th>
                  <Th textAlign="center" borderWidth="1px" borderColor="gray.400" pt={2} pb={2} px={1}>출석부 보기</Th>
                </Tr>
              </Thead>
              <Tbody>
                {sortedTeamStatistics?.length > 0 ? (
                  sortedTeamStatistics.map((stat: any) => (
                    <Tr key={stat.teacherId}>
                      <Td textAlign="center" borderWidth="1px" borderColor="gray.400" fontWeight="bold" pt={2} pb={2} px={1}>{stat.teamName}</Td>
                      <Td textAlign="center" borderWidth="1px" borderColor="gray.400" fontWeight="bold" pt={2} pb={2} px={1}>{stat.totalStudent.toLocaleString()}</Td>
                      <Td textAlign="center" borderWidth="1px" borderColor="gray.400" fontWeight="bold" pt={2} pb={2} px={1}>{stat.newStudent.toLocaleString()}</Td>
                      <Td textAlign="center" borderWidth="1px" borderColor="gray.400" fontWeight="bold" pt={2} pb={2} px={1}>{stat.returnStudent.toLocaleString()}</Td>
                      <Td textAlign="center" borderWidth="1px" borderColor="gray.400" fontWeight="bold" pt={2} pb={2} px={1}>{stat.restStudent.toLocaleString()}</Td>
                      <Td textAlign="center" borderWidth="1px" borderColor="gray.400" fontWeight="bold" pt={2} pb={2} px={1}>{stat.quitStudent.toLocaleString()}</Td>
                      <Td textAlign="center" borderWidth="1px" borderColor="gray.400" fontWeight="bold" pt={2} pb={2} px={1}>{(stat.attendRate * 100).toFixed(1)}%</Td>
                      <Td textAlign="center" borderWidth="1px" borderColor="gray.400" pt={2} pb={2} px={1}>
                        <Button
                          size="sm"
                          onClick={() => {
                            window.location.href = `/youthdb/attend/${stat.teamId}`;
                          }}
                        >
                          출석부 보기
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={15} textAlign="center" color="red">데이터가 없습니다.</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
}

export default TeacherTeamMainScreen;