import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getYouthStudentDetail, getYouthStudentQuitList, updateYouthStudent } from "../../redux/actions/YouthStudentActions";
import { useParams } from "react-router-dom";
import { getYouthTeamList, updateYouthTeam } from "../../redux/actions/YouthTeamActions";

const StudentRejoinScreen = () => {
  const { id } = useParams<{ id: string }>();
  const studentId = id ?? "";

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const teacherId = userInfo._id;
  const dispatch: any = useDispatch();

  const [youthTeamId, setYouthTeamId] = useState<string>("");

  const youthTeamList = useSelector((state: any) => state.youthTeamList);
  const {
    loading: loadingTeam,
    error: errorTeam,
    youthTeams
  } = youthTeamList;

  const youthStudentDetail = useSelector(
    (state: any) => state.youthStudentDetail
  );
  const { loading, error, youthStudent } = youthStudentDetail;

  const youthTeamUpdate = useSelector((state: any) => state.youthTeamUpdate);
  const { success } = youthTeamUpdate;

  useEffect(() => {
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, true));
  }, [dispatch, teacherId, teacherInfo.branch._id]);

  useEffect(() => {
    dispatch(getYouthStudentDetail(studentId));
  }, [dispatch, studentId]);

  const teamOption: { value: string; label: string }[] = [];

  youthTeams?.youthTeam?.map((team: any) => {
    teamOption.push({ value: team._id, label: team.name });
  });

  const rejoinHandler = () => {
    const answer = window.confirm(
      `${youthStudent.name} 회원을 재가입 처리하시겠습니까?`
    );

    if (answer) {
      const selectedYouthTeam = youthTeams?.youthTeam?.find((team: any) => team._id === youthTeamId);

      const updatedYouthTeam = {
        ...selectedYouthTeam,
        members: [...selectedYouthTeam.members, youthStudent._id],
      };

      dispatch(updateYouthTeam(youthTeamId, updatedYouthTeam));
      dispatch(updateYouthStudent({ ...youthStudent, quitDate: null }))
      alert("재가입 처리되었습니다.")

      window.location.href = `/youthdb/youth-student/quit/1`;
    } else {
      return;
    }
  }

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <VStack>
          <HStack mb={10}>
            <Text
              textAlign={"center"}
              fontSize={{ base: "2xl", md: "4xl" }}
            >
              {youthStudent.name} 회원이 가입할 팀을 선택해주세요.
            </Text>
          </HStack>
          {loadingTeam ? (
            <div>팀 목록 불러오는 중...</div>
          ) : errorTeam ? (
            <div>{errorTeam}</div>
          ) : (
            <>
              <HStack>
                <Select
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: 300,
                    }),
                  }}
                  placeholder="팀 선택(검색 가능)"
                  options={teamOption}
                  onChange={(e: any) => setYouthTeamId(e.value)}
                  isSearchable
                />
              </HStack>
              <HStack>
                <Button
                  colorScheme="orange"
                  variant="outline"
                  onClick={rejoinHandler}
                >
                  재가입
                </Button>
              </HStack></>
          )}
        </VStack>
      )}
    </Box>
  );
};

export default StudentRejoinScreen;