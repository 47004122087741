// const viewLocalSetup = (id) => {
//   let today = new Date().toLocaleDateString();
//   let viewSet = JSON.parse(localStorage.getItem(`viewSet`));
//   if (!viewSet) {
//     viewSet = [];
//   }
  
//   let item = viewSet.find((item) => item.id === id);
//   if (!item) {
//     item = {
//       id: id,
//       setupDate: null,
//       setup: 'false',
//     };
//     viewSet.push(item);
//   }
//   if (item.setupDate === null || item.setupDate !== today) {
//     item.setupDate = today;
//     item.setup = 'true';
//   } else {
//     item.setup = 'false';
//   }
//   localStorage.setItem(`viewSet`, JSON.stringify(viewSet));
// };

// async function increaseView(id, type) {
//   let viewSet = JSON.parse(localStorage.getItem(`viewSet`));
//   let item = viewSet.find((item) => item.id === id);
//   if (item.setup === 'true') {
//     if (type === "competition") { await axios.put( `${API_SERVER_URL}/api/competition/increase-view/${id}`); }
//     else if (type === "community") { await axios.put( `${API_SERVER_URL}/api/community/increase-view/${id}`); }
//   }
// }

// const setupOptimize = () => {
//   let viewSet = JSON.parse(localStorage.getItem(`viewSet`));
//   if (!viewSet) {
//     viewSet = [];
//   }
//   let today = new Date().toLocaleDateString();
  
//   viewSet = viewSet.filter((item) => item.setupDate === today);
//   localStorage.setItem(`viewSet`, JSON.stringify(viewSet));
// };

export default function dailyLogout() {
  let today = new Date().toLocaleDateString();
  
  let logoutDate = localStorage.getItem(`logoutDate`);
  if (logoutDate === null || logoutDate !== today) {
    localStorage.setItem(`logoutDate`, today);
    localStorage.removeItem(`userInfo`);
    localStorage.removeItem(`teacherInfo`);

    //logout
    window.location.reload();
  }
}