import { useDispatch, useSelector } from "react-redux";
import { Box, Center, Flex, Text, VStack, HStack, Input, IconButton, Spinner, Textarea } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { updateBranchPage } from "../../redux/actions/HomePageActions";
import { Button } from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";
import { API_SERVER_URL } from "../../redux/actions/actionUrl";
import { deleteImage } from "../../utils/deleteImage";
import { imageUrlGenerate } from "../../utils/imageUrlGenerate";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyCustomUploadAdapterPlugin from '../../utils/MyCustomUploadAdapterPlugin';

export default function LocationCustomize() {
  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  const [address, setAddress] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  useEffect(() => {
    if (branchPage && branchPage.latlng) {
      setAddress(branchPage.address);
      setPhoneNum(branchPage.phoneNum);
      setAdditionalInfo(branchPage.additionalInfo);
      setLat(branchPage.latlng.lat);
      setLng(branchPage.latlng.lng);
    }
  }, [branchPage]);

  const updateHandler = async () => {
    dispatch(updateBranchPage({
      branchId: branchPage.branchId,
      address,
      phoneNum,
      additionalInfo,
      _id: branchPage._id,
    }));

    alert("저장되었습니다.");
    window.location.reload();
  };

  return (
    <Box bg="white" color="black" p={10}>
      <Flex justifyContent="center" mb={4}>
        <Text fontSize="2xl" fontWeight="bold">지점 위치 페이지 관리</Text>
      </Flex>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>주소</Text>
        <Input
          style={{ maxWidth: '700px' }}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </Box>
      <Text color="gray.400" fontSize="sm">추후 지도에서 선택해 위도,경도 설정 없이 위치 등록하도록 할 예정</Text>
      <Flex>
        <Box mb={6} mr={2}>
          <Text fontSize="lg" fontWeight="bold" mb={2}>위도</Text>
          <Input
            style={{ maxWidth: '700px' }}
            value={lat}
            onChange={(e) => setLat(Number(e.target.value))}
          />
        </Box>
        <Box mb={6}>
          <Text fontSize="lg" fontWeight="bold" mb={2}>경도</Text>
          <Input
            style={{ maxWidth: '700px' }}
            value={lng}
            onChange={(e) => setLng(Number(e.target.value))}
          />
        </Box>
      </Flex>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>전화번호</Text>
        <Input
          style={{ maxWidth: '700px' }}
          value={phoneNum}
          onChange={(e) => setPhoneNum(e.target.value)}
        />
      </Box>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>추가 정보</Text>
        <Textarea
          style={{ maxWidth: '700px' }}
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
        />
      </Box>
      <Box>
        <Button colorScheme="blue" onClick={updateHandler}>저장</Button>
      </Box>
    </Box>
  );
}