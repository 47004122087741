import React, { HTMLAttributes, forwardRef, Ref } from "react";
import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker/chakra-react-datepicker.css";
import ko from "date-fns/locale/ko";

const customDateInput = (
  { value, onClick, onChange }: any,
  ref: Ref<HTMLInputElement>
) => (
  <Input
    autoComplete="off"
    background="white"
    value={value}
    ref={ref}
    onClick={onClick}
    onChange={onChange}
  />
);
customDateInput.displayName = "DateInput";

const CustomInput = forwardRef(customDateInput);

const icon = <CalendarIcon fontSize="sm" />;

interface Props {
  isClearable?: boolean;
  onChange: (date: Date | null) => any;
  selectedDate: Date | undefined;
  showPopperArrow?: boolean;
}

const ReactDatePickerComponent = ({
  selectedDate,
  onChange,
  isClearable = false,
  showPopperArrow = false,
  ...props
}: Props & HTMLAttributes<HTMLElement>) => {
  const localSelectedDate: Date | null = selectedDate
    ? utcToZonedTime(selectedDate, "your_timezone_here")
    : null;

  const handleDateChange = (date: Date | null) => {
    if (date === null) {
      onChange(null);
    } else {
      const utcDate = zonedTimeToUtc(date, "your_timezone_here");
      onChange(utcDate);
    }
  };

  return (
    <>
      <InputGroup>
        <ReactDatePicker
          selected={localSelectedDate}
          onChange={handleDateChange}
          isClearable={isClearable}
          showPopperArrow={showPopperArrow}
          className="react-datepicker__input-text"
          dateFormat="MM/dd/yyyy"
          customInput={<CustomInput />}
        />
        <InputRightElement color="gray.500" children={icon} />
      </InputGroup>
    </>
  );
};

const DatePicker2 = ({ selectedDate, onChange, ...props }: Props) => {
  const localSelectedDate: Date | null = selectedDate
    ? utcToZonedTime(selectedDate, "Asia/Tokyo")
    : null;

  const handleDateChange = (date: Date | null) => {
    const utcDate = date ? zonedTimeToUtc(date, "Asia/Tokyo") : null;
    onChange(utcDate);
  };

  return (
    <>
      <InputGroup className="dark-theme" style={{ position: 'relative', zIndex: 1000 }}>
        <ReactDatePicker
          selected={localSelectedDate}
          onChange={handleDateChange}
          className="react-datepicker__input-text"
          customInput={<CustomInput />}
          dateFormat="MM월 dd일 eeee"
          locale={ko}
          {...props}
        />
        <InputRightElement color="gray.500" children={icon} />
      </InputGroup>
    </>
  );
};

export default DatePicker2;
