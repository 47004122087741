import React, { useEffect, useState } from "react";
import { getBranchInfo } from "../../redux/actions/BranchActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export default function AdminMainScreen() {
  const dispatch: any = useDispatch();

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const branchInfoList = useSelector((state: any) => state.branchInfoList);
  const { loading, error, branchInfo } = branchInfoList;

  interface Branch {
    branch: string;
    chief: string;
    teacherCount: number;
    youthTeamCount: number;
    memberCount: number;
  }

  interface TableProps {
    branches: Branch[];
  }

  // useEffect(() => {
  //   dispatch(getBranchInfo());
  // }, [dispatch]);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <Box mx={40}>
          <TableContainer maxH="2000px" overflowX="auto">
            <Table>
              <Thead>
                <Tr>
                  <Th textAlign={"center"}>지점명</Th>
                  <Th textAlign={"center"}>원장</Th>
                  <Th textAlign={"center"}>지도자수</Th>
                  <Th textAlign={"center"}>팀수</Th>
                  <Th textAlign={"center"}>회원수</Th>
                  <Th textAlign={"center"}>관리</Th>
                </Tr>
              </Thead>
              <Tbody>
                {branchInfo.map((branch: Branch) => (
                  <Tr key={branch.branch}>
                    <Td textAlign={"center"}>{branch.branch}</Td>
                    <Td textAlign={"center"}>{branch.chief}</Td>
                    <Td textAlign={"center"}>{branch.teacherCount}</Td>
                    <Td textAlign={"center"}>{branch.youthTeamCount}</Td>
                    <Td textAlign={"center"}>{branch.memberCount}</Td>
                    <Td textAlign={"center"}>
                      <Button mx={2} colorScheme="facebook">
                        수정
                      </Button>
                      <Button mx={2} colorScheme="red">
                        삭제
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Box mt={20} textAlign={"center"}>
            <Button mx={2} colorScheme="orange">
              새 지점 생성
            </Button>
            <Button mx={2} colorScheme="orange">
              새 직원 추가
            </Button>
            {/* 전체직원 리스트가 나오고, 직원 개개인 정보를 수정 또는 삭제 할 수 있는 화면 */}
            <Button mx={2} colorScheme="orange">
              전체 직원 관리
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
