import React, { useEffect } from "react";
import { Box, Flex, Text, Spinner, Center, Button } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getYouthPostDetail } from "../../redux/actions/HomePageActions";
import HTMLRenderer from "../../components/HTMLRenderer";
import AdminHeaderBox from "../../components/AdminHeaderBox";
import accessCustomize from "../../utils/accessCustomize";

export default function PostDetail() {
  const { postId } = useParams<{ postId: string }>();
  const postIdStr = String(postId);

  const dispatch: any = useDispatch();

  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const youthPostDetail = useSelector((state: any) => state.youthPostDetail);
  const { loading, error, youthPost } = youthPostDetail;

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  useEffect(() => {
    dispatch(getYouthPostDetail(postIdStr));
  }, [dispatch, postIdStr]);

  const moveToList = () => {
    const currentUrl = window.location.href;
    const newUrl = currentUrl.replace(`/${postId}`, "");
    window.location.href = newUrl;
  };

  const moveToEdit = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.search = "";
    currentUrl.pathname += `/edit`;
    window.location.href = currentUrl.toString();
  };

  if (loading) {
    return (
      <Center minH="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center minH="100vh">
        <Text color="red.500">오류가 발생했습니다: {error}</Text>
      </Center>
    );
  }

  return (
    <Box>
      <Box maxW="1300px" mx="auto">
        <Box
          mt="50px"
          p="20px"
          bg="white"
          borderRadius="md"
          border={"1px solid #E2E8F0"}
        >
          <Text fontSize="2xl" fontWeight="bold" mb="4" px={4}>
            {youthPost?.title}
          </Text>
          <hr />
          <Flex justifyContent="space-between" mb="4" mt="4" px={4}>
            <Text fontSize="md" color="gray.600">
              작성자 {youthPost?.author}
            </Text>
            <Text fontSize="md" color="gray.600">
              작성일 {youthPost?.date?.slice(0, 10)}
            </Text>
          </Flex>
          <hr />
          <Box px={4} mt={4}>
            <HTMLRenderer htmlContent={youthPost?.content} />
          </Box>
          <Box display={"flex"} px={4}>
            <Button
              onClick={() => moveToList()}
              mt="4"
              colorScheme="orange"
              size="lg"
              mr={2}
            >
              목록
            </Button>
            {accessCustomize(teacherInfo, branchPage.branchId) && (
              <Button
                onClick={() => moveToEdit()}
                mt="4"
                colorScheme="orange"
                size="lg"
              >
                수정
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
