import React, { useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import AdminHeaderBox from "../../components/AdminHeaderBox";
import accessCustomize from "../../utils/accessCustomize";
import { useParams } from "react-router-dom";


export default function Location() {
  const { id } = useParams<{ id: string }>();
  const branchUrl = String(id);

  const text: string = "지점 위치";
  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  // useEffect(() => {
  //   console.log(kakao)
  //   if (kakao) {
  //     const container = document.getElementById("map");
  //     const options = {
  //       center: new kakao.maps.LatLng(branchPage?.latlng?.lat || 0, branchPage?.latlng?.lng || 0),
  //       level: 3,
  //     };
  //     const map = new kakao.maps.Map(container, options);
  //     const marker = new kakao.maps.Marker({
  //       position: map.getCenter(),
  //     });
  //     marker.setMap(map);
  //   }
  // }, [branchPage]);

  useEffect(() => {
    const appKey = "b34195ab0548b78cbc2d4fa92636924d"

    const script = document.createElement('script');
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${appKey}&autoload=false`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      const kakao = (window as any).kakao;
      
      kakao.maps.load(() => {
        if (kakao) {
          const container = document.getElementById('map');
          const options = {
            center: new kakao.maps.LatLng(branchPage?.latlng?.lat || 0, branchPage?.latlng?.lng || 0),
            level: 3,
          };
          const map = new kakao.maps.Map(container, options);
          const marker = new kakao.maps.Marker({
            position: map.getCenter(),
          });
          marker.setMap(map);
        }
      });
    };

    return () => {
      document.head.removeChild(script);
    };
  }, [branchPage]);

  return (
    <Box bg={"white"} color="black" mb={20}>
      <AdminHeaderBox text={text} adminHeaderBg={branchPage && branchPage.adminHeaderBg} />
      <Center mt={"28"} mx={"auto"} maxW={"1100px"} px={4}>
        <Box
          id="map"
          w={"100%"}
          h={{ base: 200, md: 400 }}
          border={"3px solid #e9e9e9"}
        ></Box>
      </Center>
      <Stack
        mt={{ base: 10, md: 50 }}
        spacing={4}
        maxW={"1100px"}
        mx={"auto"}
        px={4}
      >
        <VStack spacing={4} align="start">
          <Box w="100%">
            <Text fontSize={{ base: 14, md: 18 }}>
              <HStack>
                <Box>
                  <FaMapMarkerAlt />
                </Box>
                <Box fontSize={{ base: 14, md: 18 }}>주소</Box>
              </HStack>
            </Text>
            <Text fontSize={{ base: 12, md: 16 }}>{branchPage && branchPage.address}</Text>
          </Box>
          <Divider />
          <Box w="100%">
            <Text fontSize={{ base: 14, md: 18 }}>
              <HStack>
                <Box>
                  <FaPhoneAlt />
                </Box>
                <Box fontSize={{ base: 14, md: 18 }}>대표전화</Box>
              </HStack>
            </Text>
            <Text fontSize={{ base: 12, md: 16 }}>
              {/* <a href="tel:0314190999">{branchPage?.phoneNum}</a> */}
              <p>{branchPage && branchPage.phoneNum}</p>
            </Text>
          </Box>
        </VStack>
        {branchPage && branchPage.additionalInfo && (
          <Box>
            <Text fontSize={{ base: 16, md: 20 }} fontWeight="bold" mt={6}>
              추가 정보
            </Text>
            <Text fontSize={{ base: 14, md: 16 }}>
              {branchPage && branchPage.additionalInfo}
            </Text>
          </Box>
        )}
      </Stack>
      <Center>
        {accessCustomize(teacherInfo, branchPage.branchId) && (
          <Button
            onClick={() => {
              window.location.href = `/${branchUrl}/location-customize`;
            }}
          >
            지점 위치 페이지 관리
          </Button>
        )}
      </Center>
    </Box>
  );
}