export const PAYMENT_LIST_REQUEST = "PAYMENT_LIST_REQUEST" as const;
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS" as const;
export const PAYMENT_LIST_FAIL = "PAYMENT_LIST_FAIL" as const;
export const PAYMENT_LIST_RESET = "PAYMENT_LIST_RESET" as const;

export const PAYMENT_DETAIL_REQUEST = "PAYMENT_DETAIL_REQUEST" as const;
export const PAYMENT_DETAIL_SUCCESS = "PAYMENT_DETAIL_SUCCESS" as const;
export const PAYMENT_DETAIL_FAIL = "PAYMENT_DETAIL_FAIL" as const;
export const PAYMENT_DETAIL_RESET = "PAYMENT_DETAIL_RESET" as const;

export const GET_PAYMENT_BY_MONTH_REQUEST =
  "GET_PAYMENT_BY_MONTH_REQUEST" as const;
export const GET_PAYMENT_BY_MONTH_SUCCESS =
  "GET_PAYMENT_BY_MONTH_SUCCESS" as const;
export const GET_PAYMENT_BY_MONTH_FAIL = "GET_PAYMENT_BY_MONTH_FAIL" as const;
export const GET_PAYMENT_BY_MONTH_RESET = "GET_PAYMENT_BY_MONTH_RESET" as const;

export const BATCH_UPDATE_PAYMENT_REQUEST = "BATCH_UPDATE_PAYMENT_REQUEST" as const;
export const BATCH_UPDATE_PAYMENT_SUCCESS = "BATCH_UPDATE_PAYMENT_SUCCESS" as const;
export const BATCH_UPDATE_PAYMENT_FAIL = "BATCH_UPDATE_PAYMENT_FAIL" as const;
export const BATCH_UPDATE_PAYMENT_RESET = "BATCH_UPDATE_PAYMENT_RESET" as const;

export const XLSX_UPLOAD_REQUEST = "XLSX_UPLOAD_REQUEST" as const;
export const XLSX_UPLOAD_SUCCESS = "XLSX_UPLOAD_SUCCESS" as const;
export const XLSX_UPLOAD_FAIL = "XLSX_UPLOAD_FAIL" as const;
export const XLSX_UPLOAD_RESET = "XLSX_UPLOAD_RESET" as const;

export const XLSX_UPDATE_REQUEST = "XLSX_UPDATE_REQUEST" as const;
export const XLSX_UPDATE_SUCCESS = "XLSX_UPDATE_SUCCESS" as const;
export const XLSX_UPDATE_FAIL = "XLSX_UPDATE_FAIL" as const;
export const XLSX_UPDATE_RESET = "XLSX_UPDATE_RESET" as const;

export const PAYMENT_LOG_LIST_REQUEST = "PAYMENT_LOG_LIST_REQUEST" as const;
export const PAYMENT_LOG_LIST_SUCCESS = "PAYMENT_LOG_LIST_SUCCESS" as const;
export const PAYMENT_LOG_LIST_FAIL = "PAYMENT_LOG_LIST_FAIL" as const;
export const PAYMENT_LOG_LIST_RESET = "PAYMENT_LOG_LIST_RESET" as const;

export const PAYMENT_LOG_DELETE_REQUEST = "PAYMENT_LOG_DELETE_REQUEST" as const;
export const PAYMENT_LOG_DELETE_SUCCESS = "PAYMENT_LOG_DELETE_SUCCESS" as const;
export const PAYMENT_LOG_DELETE_FAIL = "PAYMENT_LOG_DELETE_FAIL" as const;
export const PAYMENT_LOG_DELETE_RESET = "PAYMENT_LOG_DELETE_RESET" as const;

export const PAYMENT_LOG_DELETE_FILENAME_REQUEST = "PAYMENT_LOG_DELETE_FILENAME_REQUEST" as const;
export const PAYMENT_LOG_DELETE_FILENAME_SUCCESS = "PAYMENT_LOG_DELETE_FILENAME_SUCCESS" as const;
export const PAYMENT_LOG_DELETE_FILENAME_FAIL = "PAYMENT_LOG_DELETE_FILENAME_FAIL" as const;
export const PAYMENT_LOG_DELETE_FILENAME_RESET = "PAYMENT_LOG_DELETE_FILENAME_RESET" as const;

export const ETC_PAYMENT_INPUT_REQUEST = "ETC_PAYMENT_INPUT_REQUEST" as const;
export const ETC_PAYMENT_INPUT_SUCCESS = "ETC_PAYMENT_INPUT_SUCCESS" as const;
export const ETC_PAYMENT_INPUT_FAIL = "ETC_PAYMENT_INPUT_FAIL" as const;
export const ETC_PAYMENT_INPUT_RESET = "ETC_PAYMENT_INPUT_RESET" as const;

export const ETC_PAYMENT_LIST_REQUEST = "ETC_PAYMENT_LIST_REQUEST" as const;
export const ETC_PAYMENT_LIST_SUCCESS = "ETC_PAYMENT_LIST_SUCCESS" as const;
export const ETC_PAYMENT_LIST_FAIL = "ETC_PAYMENT_LIST_FAIL" as const;
export const ETC_PAYMENT_LIST_RESET = "ETC_PAYMENT_LIST_RESET" as const;

export const ETC_PAYMENT_DELETE_REQUEST = "ETC_PAYMENT_DELETE_REQUEST" as const;
export const ETC_PAYMENT_DELETE_SUCCESS = "ETC_PAYMENT_DELETE_SUCCESS" as const;
export const ETC_PAYMENT_DELETE_FAIL = "ETC_PAYMENT_DELETE_FAIL" as const;
export const ETC_PAYMENT_DELETE_RESET = "ETC_PAYMENT_DELETE_RESET" as const;

export const ETC_CATEGORY_INPUT_REQUEST = "ETC_CATEGORY_INPUT_REQUEST" as const;
export const ETC_CATEGORY_INPUT_SUCCESS = "ETC_CATEGORY_INPUT_SUCCESS" as const;
export const ETC_CATEGORY_INPUT_FAIL = "ETC_CATEGORY_INPUT_FAIL" as const;
export const ETC_CATEGORY_INPUT_RESET = "ETC_CATEGORY_INPUT_RESET" as const;

export const ETC_CATEGORY_LIST_REQUEST = "ETC_CATEGORY_LIST_REQUEST" as const;
export const ETC_CATEGORY_LIST_SUCCESS = "ETC_CATEGORY_LIST_SUCCESS" as const;
export const ETC_CATEGORY_LIST_FAIL = "ETC_CATEGORY_LIST_FAIL" as const;
export const ETC_CATEGORY_LIST_RESET = "ETC_CATEGORY_LIST_RESET" as const;

export const ETC_CATEGORY_DELETE_REQUEST = "ETC_CATEGORY_DELETE_REQUEST" as const;
export const ETC_CATEGORY_DELETE_SUCCESS = "ETC_CATEGORY_DELETE_SUCCESS" as const;
export const ETC_CATEGORY_DELETE_FAIL = "ETC_CATEGORY_DELETE_FAIL" as const;
export const ETC_CATEGORY_DELETE_RESET = "ETC_CATEGORY_DELETE_RESET" as const;