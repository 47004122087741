import {
  BRANCH_PAGE_REQUEST,
  BRANCH_PAGE_SUCCESS,
  BRANCH_PAGE_FAIL,
  BRANCH_PAGE_RESET,
  BRANCH_PAGE_UPDATE_REQUEST,
  BRANCH_PAGE_UPDATE_SUCCESS,
  BRANCH_PAGE_UPDATE_FAIL,
  BRANCH_PAGE_UPDATE_RESET,
  YOUTH_POST_INPUT_REQUEST,
  YOUTH_POST_INPUT_SUCCESS,
  YOUTH_POST_INPUT_FAIL,
  YOUTH_POST_INPUT_RESET,
  YOUTH_POST_LIST_REQUEST,
  YOUTH_POST_LIST_SUCCESS,
  YOUTH_POST_LIST_FAIL,
  YOUTH_POST_LIST_RESET,
  YOUTH_POST_DETAIL_REQUEST,
  YOUTH_POST_DETAIL_SUCCESS,
  YOUTH_POST_DETAIL_FAIL,
  YOUTH_POST_DETAIL_RESET,
  YOUTH_POST_UPDATE_REQUEST,
  YOUTH_POST_UPDATE_SUCCESS,
  YOUTH_POST_UPDATE_FAIL,
  YOUTH_POST_UPDATE_RESET,
  TEACHER_INTRO_LIST_REQUEST,
  TEACHER_INTRO_LIST_SUCCESS,
  TEACHER_INTRO_LIST_FAIL,
  TEACHER_INTRO_LIST_RESET,
  TEACHER_INTRO_INPUT_REQUEST,
  TEACHER_INTRO_INPUT_SUCCESS,
  TEACHER_INTRO_INPUT_FAIL,
  TEACHER_INTRO_INPUT_RESET,
  TEACHER_INTRO_UPDATE_REQUEST,
  TEACHER_INTRO_UPDATE_SUCCESS,
  TEACHER_INTRO_UPDATE_FAIL,
  TEACHER_INTRO_UPDATE_RESET,
  TEACHER_INTRO_DELETE_REQUEST,
  TEACHER_INTRO_DELETE_SUCCESS,
  TEACHER_INTRO_DELETE_FAIL,
  TEACHER_INTRO_DELETE_RESET,
} from "../constants/HomePageConstants";

export const getBranchPageReducer = (
  state = { branchPage: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_PAGE_REQUEST:
      return { loading: true };
    case BRANCH_PAGE_SUCCESS:
      return { loading: false, branchPage: action.payload };
    case BRANCH_PAGE_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_PAGE_RESET:
      return { branchPage: {} };
    default:
      return state;
  }
};

export const updateBranchPageReducer = (
  state = { branchPage: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case BRANCH_PAGE_UPDATE_REQUEST:
      return { loading: true };
    case BRANCH_PAGE_UPDATE_SUCCESS:
      return { loading: false, branchPage: action.payload };
    case BRANCH_PAGE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case BRANCH_PAGE_UPDATE_RESET:
      return { branchPage: {} };
    default:
      return state;
  }
};

export const youthPostInputReducer = (
  state = { youthPost: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTH_POST_INPUT_REQUEST:
      return { loading: true };
    case YOUTH_POST_INPUT_SUCCESS:
      return { loading: false, youthPost: action.payload };
    case YOUTH_POST_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case YOUTH_POST_INPUT_RESET:
      return { youthPost: {} };
    default:
      return state;
  }
};

export const youthPostListReducer = (
  state = { youthPosts: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTH_POST_LIST_REQUEST:
      return { loading: true };
    case YOUTH_POST_LIST_SUCCESS:
      return { loading: false, youthPosts: action.payload };
    case YOUTH_POST_LIST_FAIL:
      return { loading: false, error: action.payload };
    case YOUTH_POST_LIST_RESET:
      return { youthPosts: [] };
    default:
      return state;
  }
};

export const youthPostDetailReducer = (
  state = { youthPost: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTH_POST_DETAIL_REQUEST:
      return { loading: true };
    case YOUTH_POST_DETAIL_SUCCESS:
      return { loading: false, youthPost: action.payload };
    case YOUTH_POST_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case YOUTH_POST_DETAIL_RESET:
      return { youthPost: {} };
    default:
      return state;
  }
};

export const youthPostUpdateReducer = (
  state = { youthPost: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case YOUTH_POST_UPDATE_REQUEST:
      return { loading: true };
    case YOUTH_POST_UPDATE_SUCCESS:
      return { loading: false, youthPost: action.payload };
    case YOUTH_POST_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case YOUTH_POST_UPDATE_RESET:
      return { youthPost: {} };
    default:
      return state;
  }
};

export const teacherIntroListReducer = (
  state = { teacherIntros: [] },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case TEACHER_INTRO_LIST_REQUEST:
      return { loading: true };
    case TEACHER_INTRO_LIST_SUCCESS:
      return { loading: false, teacherIntros: action.payload };
    case TEACHER_INTRO_LIST_FAIL:
      return { loading: false, error: action.payload };
    case TEACHER_INTRO_LIST_RESET:
      return { teacherIntros: [] };
    default:
      return state;
  }
};

export const teacherIntroInputReducer = (
  state = { teacherIntro: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case TEACHER_INTRO_INPUT_REQUEST:
      return { loading: true };
    case TEACHER_INTRO_INPUT_SUCCESS:
      return { loading: false, teacherIntro: action.payload };
    case TEACHER_INTRO_INPUT_FAIL:
      return { loading: false, error: action.payload };
    case TEACHER_INTRO_INPUT_RESET:
      return { teacherIntro: {} };
    default:
      return state;
  }
};

export const teacherIntroUpdateReducer = (
  state = { teacherIntro: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case TEACHER_INTRO_UPDATE_REQUEST:
      return { loading: true };
    case TEACHER_INTRO_UPDATE_SUCCESS:
      return { loading: false, teacherIntro: action.payload };
    case TEACHER_INTRO_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TEACHER_INTRO_UPDATE_RESET:
      return { teacherIntro: {} };
    default:
      return state;
  }
};

export const teacherIntroDeleteReducer = (
  state = { teacherIntro: {} },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case TEACHER_INTRO_DELETE_REQUEST:
      return { loading: true };
    case TEACHER_INTRO_DELETE_SUCCESS:
      return { loading: false, teacherIntro: action.payload };
    case TEACHER_INTRO_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case TEACHER_INTRO_DELETE_RESET:
      return { teacherIntro: {} };
    default:
      return state;
  }
};
