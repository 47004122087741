import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Flex, Input, Select, Spinner, Text } from "@chakra-ui/react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getYouthPostDetail, updateYouthPost } from '../../redux/actions/HomePageActions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import MyCustomUploadAdapterPlugin from '../../utils/MyCustomUploadAdapterPlugin';
import { useParams } from 'react-router-dom';
import { deleteImage } from '../../utils/deleteImage';

export default function PostModify() {
  const { type, postId } = useParams<{ type: string, postId: string }>();
  const typeStr = String(type);

  const isNotice: boolean = typeStr === '공지사항';
  const postIdStr = String(postId);

  const youthPostDetail = useSelector((state: any) => state.youthPostDetail);
  const { loading, error, youthPost } = youthPostDetail;

  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getYouthPostDetail(postIdStr));
  }, [dispatch, postIdStr]);

  useEffect(() => {
    if (youthPost) {
      setTitle(youthPost.title);
      setContent(youthPost.content);
      setCategory(youthPost.category);
    }
  }, [youthPost]);

  const editorConfig = {
    toolbar: {
      items: ['undo', 'redo', '|', 'selectAll', '|', 'bold', 'italic', '|', 'accessibilityHelp', '|', 'imageUpload'],
      shouldNotGroupWhenFull: false
    },
    extraPlugins: [MyCustomUploadAdapterPlugin],
  };

  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");

  const findS3Urls: (content: string) => string[] = (content) => {
    const s3UrlPattern = /https:\/\/[a-zA-Z0-9.-]+\.s3\.amazonaws\.com\/[a-zA-Z0-9./_-]+/g;
    const urls = content.match(s3UrlPattern);
    return urls || [];
  };

  const submitHandler = () => {
    if (category === "") { alert('카테고리를 선택해주세요'); return; }
    if (title === "") { alert('제목을 입력해주세요'); return; }
    if (content === "") { alert('내용을 입력해주세요'); return; }

    const originalS3Urls = findS3Urls(youthPost.content);
    const newS3Urls = findS3Urls(content);
    const deleteS3Urls = originalS3Urls.filter((url) => !newS3Urls.includes(url));

    deleteS3Urls.forEach(async (url) => {
      await deleteImage("youthpost", url);
    });

    dispatch(updateYouthPost({
      postId: postIdStr,
      title,
      content,
      category,
    }));

    window.location.href = `/${branchPage?.branchUrl}/${isNotice ? "post" : "program-guide"}/${category}?p=1`;
  }

  const categoryOptions: { value: string, label: string }[] = [];

  branchPage?.programGuideList?.map((category: any) => {
    categoryOptions.push({ value: category, label: category });
  });

  useEffect(() => {
    if (branchPage && branchPage.programGuideList && branchPage.programGuideList.length > 0) {
      setCategory(branchPage.programGuideList[0]);
    }
  }, [branchPage]);

  return (
    <>
      {loading ? (
        <Center minH="100vh">
          <Spinner size="xl" />
        </Center>
      ) : error ? (
        <Center minH="100vh">
          <Text color="red.500">오류가 발생했습니다: {error}</Text>
        </Center>
      ) : (
        <><Box bg="white" color="black" p={10}>
          <Flex>
            <Center>
              <Text fontSize="xl">{isNotice ? '공지사항' : '프로그램 안내'} 수정</Text>
            </Center>
          </Flex>
          {!isNotice && (
            <>
              <Box py={4}>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {categoryOptions.map((category: any) => (
                    <option key={category.value} value={category.value}>{category.label}</option>
                  ))}
                </Select>
              </Box>
            </>
          )}
          <Box py={4}>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder='제목을 입력해주세요' />
          </Box>
          <Box
            className="editor-container"
            minH="500px"
            css={{
              '.ck-editor__editable_inline': {
                minHeight: '500px',
              },
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              config={editorConfig}
              data={content}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
          </Box>
          <Button onClick={submitHandler}>수정하기</Button>
        </Box></>
      )}
    </>
  );
}
