import {
  Box,
  Button,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  deleteAttendById,
  getAttendById,
  listSupplement,
  updateAttendById,
} from "../../redux/actions/AttendActions";
import { getYouthTeamDetail } from "../../redux/actions/YouthTeamActions";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

const AttendUpdateScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const attendId = String(id);

  const dispatch: any = useDispatch();

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const attendInfo = useSelector((state: any) => state.attendInfo);
  const { loading, error, attend } = attendInfo;

  const youthTeamDetail = useSelector((state: any) => state.youthTeamDetail);
  const { youthTeam } = youthTeamDetail;

  const supplementList = useSelector((state: any) => state.supplementList);
  const { supplements } = supplementList;

  const [attendanceData, setAttendanceData] = useState<AttendanceData[]>([]);
  const [date, setDate] = useState<Date>(new Date());

  const [mergedMemberInfo, setMergedMemberInfo] = useState<any[]>([]);

  interface AttendanceData {
    studentId: string;
    studentName: string;
    note: string;
    isImportant: boolean;
    status: "출석" | "결석" | "휴강" | "탈퇴" | "신규" | "복귀" | "-";
  }

  interface TableProps {
    data: AttendanceData[];
    onCellClick: (studentId: string) => void;
  }

  useEffect(() => {
    dispatch(getAttendById(attendId));
  }, [dispatch, attendId]);

  useEffect(() => {
    if (attend && attend.attendance) {
      setAttendanceData(attend.attendance);
      setDate(new Date(attend.date));
      dispatch(getYouthTeamDetail(attend.youthTeamId));
    }
  }, [dispatch, attend]);

  useEffect(() => {
    if (youthTeam && youthTeam.memberInfo && attend && attend.attendance) {
      let temp: any[] = [...youthTeam.memberInfo];

      for (let i = 0; i < attend.attendance.length; i++) {
        if (!youthTeam.members.includes(attend.attendance[i].studentId.toString())) {
          const studentId = attend.attendance[i].studentId;
          const studentName = attend.attendance[i].studentName;

          temp.push({ memberId: studentId, memberName: studentName });
        }
      }

      console.log(temp)
      
      setMergedMemberInfo(temp);
    }
  }, [youthTeam, attend]);

  let newStudentName: string;

  const dateFormat = (date: Date) => {
    //Fri Mar 15 2024 16:44:17 GMT+0900 (한국 표준시) -> 03/15
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}/${day}`;
  }

  const newStudentRow = (index: number) => {
    return (
      <>
        <Tr key={index}>
          <Td
            textAlign="center"
            borderWidth="1px"
            borderColor="gray.400"
            fontWeight={"bold"}
          >
            신규회원
          </Td>
          <Td
            textAlign="center"
            borderWidth="1px"
            borderColor="gray.400"
            fontWeight={"bold"}
          >
            <input
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
              }}
              type="text"
              placeholder="이름을 입력하세요..."
              id={`newStudentName${index}`}
              value={newStudentName}
            />
          </Td>
          <Td
            cursor="pointer"
            textAlign="center"
            borderWidth="1px"
            color={"red"}
            borderColor="gray.400"
          >
            {`신규 (${dateFormat(date as Date)})`}
          </Td>
        </Tr>
      </>
    )
  };

  const [newStudentComponent, setNewStudentComponent] = useState<JSX.Element[]>([]);

  const addNewStudentRow = () => {
    const newStudent = newStudentRow(newStudentComponent.length);
    setNewStudentComponent((prev) => [...prev, newStudent]);
  }


  useEffect(() => {
    dispatch(listSupplement(false, attend && attend.youthTeamId, null));
  }, [dispatch, attend && attend.youthTeamId]);

  const submitHandler = () => {
    // const newStudentNameList: string[] = [];

    // for (let i = 0; i < newStudentComponent.length; i++) {
    //   const newStudentName = (document.getElementById(`newStudentName${i}`) as HTMLInputElement).value;

    //   if (newStudentName) {
    //     newStudentNameList.push(newStudentName);
    //   }
    // }

    //delete which status is "-"
    const filteredData = attendanceData;

    for (let i = 0; i < filteredData.length; i++) {
      if (youthTeam.members.find((member: any) => member.toString() === filteredData[i].studentId.toString()) !== undefined) {
        const note = (document.getElementById(`note${filteredData[i].studentId}`) as HTMLInputElement).value;
        filteredData[i].note = note;

        const isImportant = (document.getElementById(`isImportant${filteredData[i].studentId}`) as HTMLInputElement).checked;
        filteredData[i].isImportant = isImportant;
      }
    }

    const supplementDoneArray = [];
    if (supplements) {
      for (let i = 0; i < supplements.length; i++) {
        const isDone = (document.getElementById(`isDone${supplements[i]._id}`) as HTMLInputElement).checked;

        if (isDone) {
          supplementDoneArray.push(supplements[i]._id);
        }
      }
    }

    dispatch(
      updateAttendById(
        attendId,
        date,
        attend.youthTeamId,
        filteredData,
        [],
        teacherInfo.branch._id,
        supplementDoneArray
      )
    );
  };

  const submitHandlerDelete = () => {
    const isConfirmed = (window.confirm as any)(
      "정말로 삭제 하시겠습니까? 삭제하시면 기존 데이터를 되돌릴수 없습니다."
    );
    if (isConfirmed) {
      dispatch(deleteAttendById(attendId));
    } else {
      console.log("Deletion cancelled!");
    }
  };

  const handleCellClick = (studentId: string) => {
    setAttendanceData((prevData) =>
      prevData.map((item) =>
        item.studentId === studentId
          ? { ...item, status: getNextStatus(item.status) }
          : item
      )
    );
  };

  const attendanceFormat = (status: string) => {
    if (status === "출석") {
      return <>⭕️</>;
    } else if (status === "결석") {
      return <>❌</>;
    } else {
      return <>{status}</>;
    }
  };

  const dateFormatSupplement = (date: string) => {
    if (!date) return "";
    return (
      <>
        {format(new Date(date), "yyyy-MM-dd", { locale: ko })} <br />
        ({format(new Date(date), "eeee", { locale: ko })})
      </>
    );
  }

  const getNextStatus = (currentStatus: string) => {
    switch (currentStatus) {
      case "출석":
        return "결석";
      case "결석":
        return "휴강";
      case "휴강":
        return "탈퇴";
      case "탈퇴":
        return "신규";
      case "신규":
        return "출석";
      default:
        return "출석";
    }
  };

  const handleDateChange = (e: any) => {
    setDate(new Date(e.target.value));
  }

  const deleteFromAttendHandler = (studentId: string) => {
    const confirm = window.confirm("해당 날짜에서 회원의 출석 정보를 삭제합니다. 계속하시겠습니까?");
    if (!confirm) return;

    dispatch(
      updateAttendById(
        attendId,
        date,
        attend.youthTeamId,
        attendanceData.filter((item) => item.studentId !== studentId),
        [],
        teacherInfo.branch._id,
        []
      )
    );
  };

  return (
    <Box
      mx={{ base: "4", md: "40" }}
      pb={{ base: "40", md: "0" }}
      justifyContent="center"
      overflow="auto"
    >
      <Center>
        <Text fontSize="3xl" fontWeight="bold">
          출석부 수정
        </Text>
      </Center>
      <Center>
        <Text fontSize="xl">
          <input
            type="date"
            id="date"
            value={date.toISOString().split("T")[0]}
            onChange={handleDateChange}
          />
        </Text>
      </Center>
      <Center>
        <Flex>
          <VStack>
            {attendanceData && attendanceData.length > 0 && (
              <>
                <TableContainer
                  maxH="2000px"
                  maxW={{ base: "300px", sm: "500px", md: "700px", lg: "1500px" }}
                  minW={{ base: "300px", md: "400px" }}
                  overflowX="auto"
                >
                  <Table variant="simple" width="100%">
                    <Thead bg="gray.100">
                      <Tr>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          padding={1}
                        >
                          이름
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          padding={1}
                        >
                          상태
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          padding={1}
                        >
                          메모
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          padding={1}
                        >
                          중요
                        </Th>
                        <Th
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                          padding={1}
                        >
                          삭제
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {mergedMemberInfo.map((member: any) => (
                          <Tr key={member.memberId}>
                            <Td
                              textAlign="center"
                              borderWidth="1px"
                              borderColor="gray.400"
                              fontWeight={"bold"}
                              padding={1}
                            >
                              {member.memberName}
                            </Td>
                            {attendanceData.map((item) => {
                              if (item.studentId === member.memberId) {
                                return (
                                  <>
                                    <Td
                                      textAlign="center"
                                      onClick={() => handleCellClick(item.studentId)}
                                      cursor="pointer"
                                      borderWidth="1px"
                                      borderColor="gray.400"
                                      padding={2}
                                      style={{
                                        color:
                                          item &&
                                          (item.status === "출석"
                                            ? "black"
                                            : item.status === "신규"
                                              ? "red"
                                              : item.status === "휴강"
                                                ? "blue"
                                                : "#BDBDBD"),
                                      }}
                                    >
                                      {attendanceFormat(item.status)}
                                    </Td>
                                    <Td
                                      textAlign="center"
                                      borderWidth="1px"
                                      borderColor="gray.400"
                                      padding={2}
                                    >
                                      <input
                                        key={item.studentId}
                                        style={{
                                          width: "100%",
                                          border: "1px solid #ccc",
                                        }}
                                        type="text"
                                        id={`note${item.studentId}`}
                                        defaultValue={item.note}
                                      />
                                    </Td>
                                    <Td
                                      textAlign="center"
                                      borderWidth="1px"
                                      borderColor="gray.400"
                                      padding={2}
                                    >
                                      <input
                                        key={item.studentId}
                                        style={{
                                          width: "100%",
                                          border: "1px solid #ccc",
                                        }}
                                        type="checkbox"
                                        id={`isImportant${item.studentId}`}
                                        defaultChecked={item.isImportant}
                                      />
                                    </Td>
                                    <Td
                                      textAlign="center"
                                      borderWidth="1px"
                                      borderColor="gray.400"
                                      padding={2}
                                    >
                                      <Button
                                        colorScheme="red"
                                        size="xs"
                                        onClick={() => deleteFromAttendHandler(item.studentId)}
                                      >
                                        삭제
                                      </Button>
                                    </Td>
                                  </>
                                );
                              }
                            })}
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            )}
          </VStack>
        </Flex>
      </Center>
      <Center mt={4}>
        <Table variant="simple" mt={6} width={500}>
          <Tbody>
            {newStudentComponent}
          </Tbody>
        </Table>
      </Center>
      <Center mt={8}>
        <TableContainer
          maxH="2000px"
          maxW={{ base: "300px", sm: "500px", md: "700px", lg: "1500px" }}
          minW={{ base: "300px", md: "400px" }}
          overflowX="auto"
        >
          {supplements && supplements.length > 0 && (
            <Table
              variant="simple"
              mt={6}
              width={1000}
              maxW={{ base: "300px", md: "400px", lg: "400px" }}
            >
              <Thead>
                <Tr>
                  <Th>팀명</Th>
                  <Th>회원명</Th>
                  <Th>결석일</Th>
                  <Th>보강완료</Th>
                </Tr>
              </Thead>
              <Tbody>
                {supplements.map((supplement: any) => (
                  <Tr key={supplement._id}>
                    <Td>{supplement.fromTeamName}</Td>
                    <Td>{supplement.studentName}</Td>
                    <Td>{dateFormatSupplement(supplement.absentDate)}</Td>
                    <Td>
                      <input
                        type="checkbox"
                        name="isDone"
                        id={`isDone${supplement._id}`}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Center>
      <Center mt={4}>
        <Button
          mt={2}
          colorScheme="orange"
          width="200px"
          onClick={submitHandler}
        >
          수정하기
        </Button>
      </Center>
      <Center mt={4}>
        <Button
          mt={2}
          mb={20}
          colorScheme="red"
          width="70px"
          fontSize="small"
          onClick={submitHandlerDelete}
        >
          삭제하기
        </Button>
      </Center>
    </Box>
  );
};

export default AttendUpdateScreen;