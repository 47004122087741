import axios, { AxiosError } from "axios";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_LOGOUT,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_FIND_TEACHER_REQUEST,
  USER_FIND_TEACHER_SUCCESS,
  USER_FIND_TEACHER_FAIL,
  USER_FIND_TEACHER_RESET,
  USER_REGISTER_FAIL,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_REQUEST,
} from "../constants/UserConstants";
import { API_SERVER_URL } from "./actionUrl";

export const login =
  (userId: string, password: string) =>
    async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
      try {
        dispatch({
          type: USER_LOGIN_REQUEST,
        });
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.post(
          `${API_SERVER_URL}/api/user/login`,
          {
            userId,
            password,
            config,
          }
        );
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: data,
        });
        localStorage.setItem("userInfo", JSON.stringify(data));
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          const errorMessage =
            axiosError.message || "An error occurred during login.";
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: errorMessage,
          });
        } else {
          // Handle non-Axios errors
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: "An error occurred during login.",
          });
        }
      }
    };

export const logout = () => (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("teacherInfo");
  dispatch({ type: USER_LOGIN_LOGOUT });

  window.location.reload();
};

export const register =
  (
    sid: string,
    userId: string,
    password: string,
    email: string,
    address: string,
    playerId: string
  ) =>
    async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_SERVER_URL}/api/user`,
        {
          sid,
          userId,
          password,
          email,
          address,
          playerId,
        },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      alert("회원가입이 정상적으로 완료되었습니다!");

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));
      window.location.href = "/";
    } catch (error: any) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      alert("회원가입에 실패하였습니다. 관리자에게 문의해주세요.");
    }
  };

export const findTeacher =
  (userId: string, phoneNum: string) =>
    async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
      try {
        dispatch({
          type: USER_FIND_TEACHER_REQUEST,
        });
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.get(
          `${API_SERVER_URL}/api/user/find/teacher?userId=${userId}&phoneNum=${phoneNum}`,
          config
        );
        dispatch({
          type: USER_FIND_TEACHER_SUCCESS,
          payload: data,
        });
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          const errorMessage =
            axiosError.message || "An error occurred during login.";
          dispatch({
            type: USER_FIND_TEACHER_FAIL,
            payload: errorMessage,
          });
        } else {
          dispatch({
            type: USER_FIND_TEACHER_FAIL,
            payload: "An error occurred during login.",
          });
        }
      }
    };
