import React from "react";

interface SearcherProps {
  keyword?: string;
  onSearch: (keyword: string) => void;
}

const Searcher: React.FC<SearcherProps> = ({ keyword, onSearch }) => {
  const [searchKeyword, setSearchKeyword] = React.useState(keyword || "");

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    onSearch(searchKeyword);
  }

  return (
    <form onSubmit={submitHandler}>
      <input
        type="text"
        placeholder="검색어를 입력하세요."
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        style={{
          border: "1px solid #000",
        }}
      />
      <button
        type="submit"
        style={{
          border: "1px solid #000",
          marginLeft: "5px",
        }}
      >검색</button>
    </form>
  );
}

export default Searcher;