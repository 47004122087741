import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getYouthTeamDetail,
  getYouthTeamList,
} from "../../redux/actions/YouthTeamActions";
import {
  batchAddYouthStudent,
} from "../../redux/actions/YouthStudentActions";

const StudentBatchAddScreen = () => {
  const { id } = useParams<{ id: string }>();
  const teamId = id ?? "";

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherId = userInfo._id;

  const dispatch: any = useDispatch();

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const youthTeamDetail = useSelector((state: any) => state.youthTeamDetail);
  const { loading, error, youthTeam } = youthTeamDetail;

  const youthStudentNew = useSelector((state: any) => state.youthStudentNew);
  const {
    loading: loadingNew,
    error: errorNew,
    youthStudent,
  } = youthStudentNew;

  const youthTeamList = useSelector((state: any) => state.youthTeamList);
  const { youthTeams } = youthTeamList;

  const formatPhoneNumber = (phoneNum: any) => {
    const cleaned = ("" + phoneNum).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNum;
  };

  const [arrayIndex, setArrayIndex] = useState(15);

  let name: string;
  let birthday: string;
  let phoneNum: string;
  let parentPhoneNum: string;
  let school: string;
  let address: string;


  useEffect(() => {
    dispatch(getYouthTeamList(teacherId, teacherInfo.branch._id, true));
  }, [dispatch, teacherId, teacherInfo.branch._id]);
  
  useEffect(() => {
    dispatch(getYouthTeamDetail(teamId));
  }, [dispatch, teamId]);

  const teamOption: { value: string; label: string }[] = [];

  youthTeams?.youthTeam?.map((team: any) => {
      teamOption.push({ value: team._id, label: team.name });
    });

  const rowAddHandler = () => {
    setArrayIndex(arrayIndex + 1);
  }


  const submitHandler = () => {
    const removeHyphen = (str: string) => {
      return str.replace(/-/g, "");
    }

    const birthdayValidCheck = (input: string) => {
      if (/^\d+$/.test(input)) {
        if (input.length !== 6) {
          return false;
        }
        
        const firstTwoDigits = parseInt(input.substring(0, 2), 10);
        const thirdAndFourthDigits = parseInt(input.substring(2, 4), 10);
        const fifthAndSixthDigits = parseInt(input.substring(4, 6), 10);
        
        const isValidFirstTwoDigits = firstTwoDigits >= 0 && firstTwoDigits <= 99;
        const isValidThirdAndFourthDigits = thirdAndFourthDigits >= 1 && thirdAndFourthDigits <= 12;
        const isValidFifthAndSixthDigits = fifthAndSixthDigits >= 1 && fifthAndSixthDigits <= 31;
        
        return isValidFirstTwoDigits && isValidThirdAndFourthDigits && isValidFifthAndSixthDigits;
      }
      
      return false;
    }


    const result = [];

    for (let i = 0; i < arrayIndex; i++) {
      if ((document.getElementById(`name${i}`) as HTMLInputElement).value === "") {
        continue;
      }

      name = (document.getElementById(`name${i}`) as HTMLInputElement).value;
      birthday = (document.getElementById(`birthday${i}`) as HTMLInputElement).value;
      phoneNum = (document.getElementById(`phoneNum${i}`) as HTMLInputElement).value;
      parentPhoneNum = (document.getElementById(`parentPhoneNum${i}`) as HTMLInputElement).value;
      school = (document.getElementById(`school${i}`) as HTMLInputElement).value;
      address = (document.getElementById(`address${i}`) as HTMLInputElement).value;

      if (!birthdayValidCheck(birthday)) {
        alert(`${i + 1}번 회원의 생년월일을 확인해주세요.`);
        return;
      }

      result.push({
        name,
        birthday,
        phoneNum,
        parentPhoneNum: removeHyphen(parentPhoneNum),
        school,
        address,
      });
    }

    dispatch(batchAddYouthStudent(result, teamId));

    window.location.href = `/youthdb/youth-student/${teamId}`;
  };

  return (
    <Box
      mt={10}
      mx={6}
      mb={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack gap={4}>
        <HStack>
          {loading ? (
            <Text>loading...</Text>
          ) : error ? (
            <Text>{error}</Text>
          ) : (
            <TableContainer
              maxW="80vw"
              w="80vw"
              bg="white"
              borderRadius="lg"
              boxShadow="lg"
              fontSize={"sm"}
            >
              <Table
                variant="simple"
                size="sm"
                colorScheme="blackAlpha"
                borderRadius="lg"
              >
                <Thead>
                  <Tr>
                    <Th minW="10%">순번</Th>
                    <Th minW="10%">이름</Th>
                    <Th minW="10%">생년월일 (6자리)</Th>
                    <Th>회원 전화번호</Th>
                    <Th>보호자 전화번호</Th>
                    <Th>학교</Th>
                    <Th>주소</Th>
                    <Th>팀</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {/* 10 rows of empty input */}
                  {[...Array(arrayIndex)].map((_, i) => (
                    <Tr key={i}>
                      <Td>{i + 1}</Td>
                      <Td>
                        <input
                          id={`name${i}`}
                          type="text"
                          value={name}
                        />
                      </Td>
                      <Td>
                        <input
                          id={`birthday${i}`}
                          type="text"
                          value={birthday}
                        />
                      </Td>
                      <Td>
                        <input
                          id={`phoneNum${i}`}
                          type="text"
                          value={phoneNum}
                        />
                      </Td>
                      <Td>
                        <input
                          id={`parentPhoneNum${i}`}
                          type="text"
                          value={parentPhoneNum}
                        />
                      </Td>
                      <Td>
                        <input
                          id={`school${i}`}
                          type="text"
                          value={school}
                        />
                      </Td>
                      <Td>
                        <input
                          id={`address${i}`}
                          type="text"
                          value={address}
                        />
                      </Td>
                      <Td>
                        {youthTeam && youthTeam.name}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </HStack>
        <HStack>
          <Button
            colorScheme="teal"
            variant="outline"
            onClick={rowAddHandler}
          >
            +
          </Button>
        </HStack>
        <HStack>
          <Button
            colorScheme="blue"
            background={"blackAlpha.800"}
            fontWeight={"bold"}
            color={"white"}
            variant="outline"
            onClick={submitHandler}
          >
            저장
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default StudentBatchAddScreen;
