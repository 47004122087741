import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { etcPaymentInput, getEtcCategoryList, getPaymentDetail } from '../../redux/actions/PaymentActions';
import { useSelector } from 'react-redux';

const EtcPaymentAddScreen: React.FC = () => {
  const dispatch: any = useDispatch();
  const { id } = useParams<{ id: string }>();

  const searchParams = new URLSearchParams(useLocation().search);
  const amount = searchParams.get('amount');
  const urlTeacherId = searchParams.get('teacherId') || '';

  const paymentDetail = useSelector((state: any) => state.paymentDetail);
  const { loading, error, payment } = paymentDetail;

  const etcCategoryList = useSelector((state: any) => state.etcCategoryList);
  const { etcCategories } = etcCategoryList;

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [month, setMonth] = useState<string>((new Date().getMonth() + 1).toString());
  const [title, setTitle] = useState<string>('');
  const [categoryOption, setCategoryOption] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    dispatch(getEtcCategoryList(teacherInfo.branch._id, year.toString(), month.toString()));
  }, [dispatch, teacherInfo.branch._id, year, month]);

  const submitHandler = () => {
    dispatch(etcPaymentInput(teacherInfo.branch._id, year, month, title, payment.studentId, payment.studentName, Number(amount), urlTeacherId, id || ''));
  };

  useEffect(() => {
    if (id) {
      dispatch(getPaymentDetail(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (etcCategories) {
      setCategoryOption(etcCategories.map((c: any) => ({ value: c.category, label: c.category })));
    }
  }, [etcCategories]);

  return (
    <Box maxW="md" mx="auto" mt={5} p={5} borderWidth={1} borderRadius="lg">
      {loading ? (
        <Spinner />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <Text fontSize="2xl" mb={4}>
            비교육비 등록
          </Text>
          <Text>
            <b>{payment.year}</b>년 <b>{payment.month}</b>월 <b>{payment.studentName}</b> 회원의 교육비 <b>{payment.amount}</b>원에서 차감됩니다.
          </Text>
          <Text fontSize="xs" mb={16}>
            비교육비 등록 후 교육비 : <b>{payment.amount - Number(amount)}</b>원
          </Text>
          <HStack spacing={4} mb={6}>
            <FormControl id="year">
              <FormLabel><b>연도</b></FormLabel>
              <Select placeholder="Select year" value={year} onChange={(e) => setYear(e.target.value)}>
                {Array.from({ length: 3 }, (_, i) => new Date().getFullYear() + i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="month">
              <FormLabel><b>월</b></FormLabel>
              <Select value={month} onChange={(e) => setMonth(e.target.value)}>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          <FormControl id="title" mb={4}>
            <FormLabel><b>비교육비 내용</b></FormLabel>
            <Text fontSize="xs">
              목록에서 선택 또는 직접 입력
            </Text>
            <Select value={title} onChange={(e) => setTitle(e.target.value)} placeholder='내용을 선택해주세요.' mb={2} maxW={200}>
              {categoryOption.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
          </FormControl>
          <FormControl id="amount" mb={4}>
            <FormLabel>금액</FormLabel>
            <Text>{amount}</Text>
          </FormControl>
          <Button colorScheme="blue" onClick={submitHandler}>
            등록
          </Button>
        </>
      )}
    </Box>
  );
}

export default EtcPaymentAddScreen;