import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
  IconButton,
  Image,
  Text,
  useDisclosure,
  Flex,
  Avatar,
  Stack,
  Center,
  Select,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useState } from "react";
import LoginModal from "./LoginModal";
import { FaBell, FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { logout } from "../redux/actions/UserAction";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import accessCheck from "../utils/accessCheck";
import axios from "axios";
import { API_SERVER_URL } from "../redux/actions/actionUrl";

interface UserInfo {
  _id: string;
  userId: string;
  name: string;
  // 필요한 UserInfo 추가 해주기
}

export default function AdminHeader() {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const isDaeho = ["643619642e2f6ef00d37f168", "64845836a31834eb398eeaad"]

  const displayType = useBreakpointValue({
    base: "mobile",
    sm: "mobile",
    md: "desktop",
    lg: "desktop",
  });

  const [activeButton, setActiveButton] = useState("");
  const userLogin = useSelector((state: any) => state.userLogin);

  const userInfoString = localStorage.getItem("userInfo");
  const userInfo: UserInfo | null = userInfoString
    ? JSON.parse(userInfoString)
    : null;

  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");

  if (Object.keys(teacherInfo).length === 0) {
    window.location.href = "/admin-login";
  }
  
  const logoutHandler = () => {
    dispatch(logout());

    window.location.href = "/admin-login";
  };

  const handleClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen,
  } = useDisclosure();

  const adminTeacherInfoChange = async (branchId: string) => {
    localStorage.removeItem("teacherInfo");

    const { data } = await axios.get(
      `${API_SERVER_URL}/api/branch/${branchId}`
    );

    localStorage.setItem(
      "teacherInfo",
      JSON.stringify({
        branch: data,
        accessList: ["원장"],
      })
    );

    window.location.reload();
  }

  return (
    <>
      <Box mb={10}>
        {displayType === "desktop" ? (
          <Box
            borderBottom="2px"
            borderColor="gray.200"
            pt={2}
            backgroundColor={"gray.100"}
          >
            <Flex
              h={20}
              alignItems={"center"}
              justifyContent={"center"}
              mx={20}
            >
              <Stack
                justify={"center"}
                direction={"row"}
                spacing={14}
                alignItems={"center"}
              >
                <HStack gap={4}>
                  <Link to={"/youthdb/my"}>
                    <Image src={"../img/schoolb_logo.png"} width={"60px"} />
                  </Link>
                </HStack>
                <Link to={"my"}>
                  <Button
                    as={"a"}
                    fontSize={"md"}
                    fontWeight={600}
                    href={"#"}
                    variant={"none"}
                  >
                    나의 분석표
                  </Button>
                </Link>
                {accessCheck(["원장", "부원장"]) && (
                  <Link to={"branch"}>
                    <Button
                      as={"a"}
                      fontSize={"md"}
                      fontWeight={600}
                      href={"#"}
                      variant={"none"}
                    >
                      직원 분석표
                    </Button>
                  </Link>
                )}
                {accessCheck(["원장", "부원장", "조장"]) && (
                  <Link to={"group"}>
                    <Button
                      as={"a"}
                      fontSize={"md"}
                      fontWeight={600}
                      href={"#"}
                      variant={"none"}
                    >
                      조 분석표
                    </Button>
                  </Link>
                )}
                <Link to={"attend"}>
                  <Button
                    as={"a"}
                    fontSize={"md"}
                    fontWeight={600}
                    href={"#"}
                    variant={"none"}
                  >
                    출석부
                  </Button>
                </Link>
                {accessCheck(["원장", "부원장", "조장"]) && (
                  <Link to={`payment?year=${new Date().getFullYear()}&month=${new Date().getMonth() + 1}`}>
                    <Button
                      as={"a"}
                      fontSize={"md"}
                      fontWeight={600}
                      href={"#"}
                      variant={"none"}
                    >
                      교육비 관리
                    </Button>
                  </Link>
                )}
                {accessCheck(["원장", "부원장", "조장"]) && (
                  <Link to={"upload"}>
                    <Button
                      as={"a"}
                      fontSize={"md"}
                      fontWeight={600}
                      href={"#"}
                      variant={"none"}
                    >
                      입금 내역
                    </Button>
                  </Link>
                )}
                {/* <Link to={""}>
                  <Button
                    as={"a"}
                    fontSize={"md"}
                    fontWeight={600}
                    href={"#"}
                    variant={"none"}
                  >
                    지점별통계
                  </Button>
                </Link> */}
                {accessCheck(["원장", "부원장"]) && (
                  <Link to={"employee"}>
                    <Button
                      as={"a"}
                      fontSize={"md"}
                      fontWeight={600}
                      href={"#"}
                      variant={"none"}
                    >
                      직원관리
                    </Button>
                  </Link>
                )}
                <Link to={"youth-student"}>
                  <Button
                    as={"a"}
                    fontSize={"md"}
                    fontWeight={600}
                    href={"#"}
                    variant={"none"}
                  >
                    회원관리
                  </Button>
                </Link>

                {userInfo === null ? (
                  <>
                    <IconButton
                      onClick={onLoginOpen}
                      aria-label="Login button"
                      icon={<FaUser />}
                      w={{
                        base: "40px",
                        md: "60px",
                      }}
                      h={{
                        base: "40px",
                        md: "60px",
                      }}
                      fontSize={"36px"}
                      bg={"orange"}
                      colorScheme="white"
                    />
                    {/* <Box>
                      <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
                    </Box> */}
                  </>
                ) : (
                  <Center>
                    <HStack gap={6}>
                      {/* <Popover>
                        <PopoverTrigger>
                          <IconButton
                            aria-label="알림"
                            icon={<FaBell />}
                            color={"gray.500"}
                            fontSize={"24px"}
                          />
                        </PopoverTrigger>
                        <PopoverContent
                          bgColor={"gray.100"}
                          borderRadius={"15"}
                        >
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader
                            bgColor={"white"}
                            textAlign={"center"}
                            fontWeight={"bold"}
                            borderRadius={"15px 15px 0px 0px"}
                          >
                            나의 알림
                          </PopoverHeader>
                          <PopoverBody>
                            <Box
                              pt={6}
                              pb={6}
                              px={2}
                              mb={2}
                              bgColor={"white"}
                              borderRadius={"10"}
                            >
                              <Text fontSize={"sm"}>
                                OOO반의 OOO회원이 탈퇴하였습니다.
                              </Text>
                            </Box>
                            <Box
                              pt={6}
                              pb={6}
                              px={2}
                              mb={2}
                              bgColor={"white"}
                              borderRadius={"10"}
                            >
                              <Text fontSize={"sm"}>
                                OOO반의 OOO회원이 탈퇴하였습니다.
                              </Text>
                            </Box>
                            <Box
                              pt={6}
                              pb={6}
                              px={2}
                              mb={2}
                              bgColor={"white"}
                              borderRadius={"10"}
                            >
                              <Text fontSize={"sm"}>
                                OOO반의 OOO회원이 탈퇴하였습니다.
                              </Text>
                            </Box>
                            <Box
                              pt={6}
                              pb={6}
                              px={2}
                              mb={2}
                              bgColor={"white"}
                              borderRadius={"10"}
                            >
                              <Text fontSize={"sm"}>
                                OOO반의 OOO회원이 탈퇴하였습니다.
                              </Text>
                            </Box>
                            <Box
                              pt={6}
                              pb={6}
                              px={2}
                              mb={2}
                              bgColor={"white"}
                              borderRadius={"10"}
                            >
                              <Text fontSize={"sm"}>
                                OOO반의 OOO회원이 탈퇴하였습니다.
                              </Text>
                            </Box>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover> */}
                      <Menu>
                        <MenuButton>
                          <Avatar
                            name={userInfo.name}
                            src={"https://bit.ly/broken-link"}
                            size={"md"}
                          />
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={logoutHandler}>로그아웃</MenuItem>
                          {isDaeho.includes(userInfo._id) && (<>
                            <MenuItem onClick={() => adminTeacherInfoChange("65dd7d2ca5357bc9c1c51b8e")}>스쿨비안산</MenuItem>
                            <MenuItem onClick={() => adminTeacherInfoChange("65e01833dad73af3a6831999")}>PBTC</MenuItem>
                            <MenuItem onClick={() => adminTeacherInfoChange("65e00dee94e19fd0c79c7c72")}>TOP송도</MenuItem>
                            <MenuItem onClick={() => adminTeacherInfoChange("65e69d0b9506e6c43010204d")}>TOP미추홀</MenuItem>
                            <MenuItem onClick={() => adminTeacherInfoChange("65a6076e9394030970d0634a")}>test</MenuItem>
                          </>)}
                        </MenuList>
                      </Menu>
                    </HStack>
                  </Center>
                )}
              </Stack>
            </Flex>
          </Box>
        ) : (
          <Center mt={"10"}>
            <HStack gap={10} mb={6}>
              <Link to={"/youthdb/my"}>
                <Image src={"../img/schoolb_logo.png"} width={"60px"} />
              </Link>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="outline"
                  colorScheme="orange"
                >
                  메뉴선택
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={Link}
                    to="my"
                    onClick={() => handleClick("나의 분석표")}
                  >
                    나의 분석표
                  </MenuItem>
                  {accessCheck(["원장", "부원장"]) && (
                    <MenuItem
                      as={Link}
                      to="branch"
                      onClick={() => handleClick("직원 분석표")}
                    >
                      직원 분석표
                    </MenuItem>
                  )}
                  {accessCheck(["원장", "부원장", "조장"]) && (
                    <MenuItem
                      as={Link}
                      to="group"
                      onClick={() => handleClick("조 분석표")}
                    >
                      조 분석표
                    </MenuItem>
                  )}
                  <MenuItem
                    as={Link}
                    to="attend"
                    onClick={() => handleClick("출석부")}
                  >
                    출석부
                  </MenuItem>
                  {accessCheck(["원장", "부원장", "조장"]) && (
                    <MenuItem
                      as={Link}
                      to={`payment?year=${new Date().getFullYear()}&month=${new Date().getMonth() + 1}`}
                      onClick={() => handleClick("교육비 관리")}
                    >
                      교육비 관리
                    </MenuItem>
                  )}
                  {accessCheck(["원장", "부원장", "조장"]) && (
                    <MenuItem
                      as={Link}
                      to="upload"
                      onClick={() => handleClick("입금 내역")}
                    >
                      입금 내역
                    </MenuItem>
                  )}
                  {accessCheck(["원장", "부원장"]) && (
                    <MenuItem
                      as={Link}
                      to="employee"
                      onClick={() => handleClick("직원 관리")}
                    >
                      직원 관리
                    </MenuItem>
                  )}
                  <MenuItem
                    as={Link}
                    to="youth-student"
                    onClick={() => handleClick("회원 관리")}
                  >
                    회원 관리
                  </MenuItem>
                  {/* <MenuItem
                    as={Link}
                    to="youth-student"
                    onClick={() => handleClick("지점관리")}
                  >
                    지점관리
                  </MenuItem>
                  <MenuItem onClick={() => handleClick("직원관리")}>
                    직원관리
                  </MenuItem>
                  <MenuItem onClick={() => handleClick("회원관리")}>
                    회원관리
                  </MenuItem>
                  <MenuItem onClick={() => handleClick("교육비관리")}>
                    <Link to={"payment"}>교육비관리</Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleClick("대관관리")}>
                    대관관리
                  </MenuItem>
                  <MenuItem onClick={() => handleClick("일정표")}>
                    일정표
                  </MenuItem>
                  <MenuItem onClick={() => handleClick("전자결제")}>
                    전자결제
                  </MenuItem>
                  <MenuItem onClick={() => handleClick("게시판관리")}>
                    게시판관리
                  </MenuItem>
                  <MenuItem onClick={() => handleClick("문자발송")}>
                    문자발송
                  </MenuItem> */}
                </MenuList>
              </Menu>
            </HStack>
          </Center>
        )}
      </Box>
    </>
  );
}
