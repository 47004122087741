import { useDispatch, useSelector } from "react-redux";
import { Box, Center, Flex, Text, VStack, HStack, Input, IconButton, Spinner, Textarea } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { updateBranchPage } from "../../redux/actions/HomePageActions";
import { Button } from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import axios from "axios";
import { API_SERVER_URL } from "../../redux/actions/actionUrl";
import { deleteImage } from "../../utils/deleteImage";
import { imageUrlGenerate } from "../../utils/imageUrlGenerate";

declare global {
  interface Window {
    kakao: any;
  }
}

interface Address {
  address_name: string;
  road_address_name: string;
  x: string;
  y: string;
}

export default function PageCustomize() {
  const branchPageInfo = useSelector((state: any) => state.branchPageInfo);
  const { branchPage } = branchPageInfo;

  const dispatch: any = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

  const [introduction, setIntroduction] = useState("");
  const [headerBg, setHeaderBg] = useState("");
  const [adminHeaderBg, setAdminHeaderBg] = useState("");
  const [programGuideList, setProgramGuideList] = useState<string[]>([]);
  const [newProgramGuide, setNewProgramGuide] = useState("");
  const [selectedLogoImage, setSelectedLogoImage] = useState<any>(null);
  const [selectedBgImage, setSelectedBgImage] = useState<any>(null);
  const [logo, setLogo] = useState("");
  const [bgImage, setBgImage] = useState("");
  const [uploading, setUploading] = useState(false);

  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [chiefName, setChiefName] = useState("");
  const [companyRegistrationNum, setCompanyRegistrationNum] = useState("");
  const [email, setEmail] = useState("");

  const [kakaoAddress, setKakaoAddress] = useState('');
  const [results, setResults] = useState<Address[]>([]);
  const [selectedCoords, setSelectedCoords] = useState<{ lat: string; lng: string } | null>(null);

  useEffect(() => {
    const appKey = "b34195ab0548b78cbc2d4fa92636924d"

    const script = document.createElement('script');
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${appKey}&libraries=services,clusterer,drawing&autoload=false`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      const kakao = (window as any).kakao;

      kakao.maps.load(() => {
        if (kakao) {
          console.log(kakao)
          const container = document.getElementById('map');
          const options = {
            center: new window.kakao.maps.LatLng(37.5665, 126.9780), // 초기 지도 중심 좌표 (서울시청)
            level: 3,
          };

          const map = new window.kakao.maps.Map(container, options);
          setMap(map);
        }
      });
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const [map, setMap] = useState<any>(null);

  const searchAddress = () => {
    if (!window.kakao || !window.kakao.maps || !window.kakao.maps.services) {
      console.error("Kakao Maps API가 아직 로드되지 않았습니다.");
      return;
    }

    const geocoder = new window.kakao.maps.services.Geocoder();

    geocoder.addressSearch(address, function (result: any, status: any) {
      if (status === window.kakao.maps.services.Status.OK) {
        setResults(result.map((res: any) => ({
          address_name: res.address_name,
          road_address_name: res.road_address_name,
          x: res.x,
          y: res.y,
        })));
      } else {
        alert('주소를 찾을 수 없습니다.');
        setResults([]);
      }
    });
  };

  const selectAddress = (lat: string, lng: string) => {
    setSelectedCoords({ lat, lng });

    if (map) {
      const moveLatLon = new window.kakao.maps.LatLng(lat, lng);
      map.setCenter(moveLatLon);

      const marker = new window.kakao.maps.Marker({
        position: moveLatLon,
      });
      marker.setMap(map);
    }
  };

  useEffect(() => {
    if (branchPage && branchPage.footerInfo) {
      setIntroduction(branchPage.introduction);
      setHeaderBg(branchPage.headerBg);
      setAdminHeaderBg(branchPage.adminHeaderBg);
      setProgramGuideList(branchPage.programGuideList);
      setLogo(branchPage.logo);
      setBgImage(branchPage.bgImage);

      setZipCode(branchPage.footerInfo.zipCode);
      setAddress(branchPage.footerInfo.address);
      setChiefName(branchPage.footerInfo.chiefName);
      setCompanyRegistrationNum(branchPage.footerInfo.companyRegistrationNum);
      setEmail(branchPage.footerInfo.email);
    }
  }, [branchPage]);

  const updateHandler = async () => {
    // if (selectedLogoImage !== null) {
    //   const formData = new FormData();

    //   formData.append("image", selectedLogoImage);

    //   setUploading(true);

    //   try {
    //     const config = {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     };

    //     const { data } = await axios.post(
    //       `${API_SERVER_URL}/api/upload/branchlogo`,
    //       formData,
    //       config
    //     );

    //     setUploading(false);

    //     await deleteImage("branchlogo", logo);

    //     dispatch(updateBranchPage({
    //       branchId: branchPage.branchId,
    //       introduction,
    //       headerBg,
    //       programGuideList,
    //       logo: data,
    //       _id: branchPage._id,
    //     }));
    //   } catch (error) {
    //     console.error(error);
    //     setUploading(false);
    //   }
    // } else {
    //   dispatch(updateBranchPage({
    //     branchId: branchPage.branchId,
    //     introduction,
    //     headerBg,
    //     programGuideList,
    //     logo,
    //     _id: branchPage._id,
    //   }));
    // }

    setUploading(true);
    const logoImageUrl = await imageUrlGenerate(selectedLogoImage, "branchpage");

    if (logoImageUrl !== null) {
      await deleteImage("branchpage", logo);
    }

    const bgImageUrl = await imageUrlGenerate(selectedBgImage, "branchpage");

    if (bgImageUrl !== null) {
      await deleteImage("branchpage", bgImage);
    }

    dispatch(updateBranchPage({
      branchId: branchPage.branchId,
      introduction,
      headerBg,
      adminHeaderBg,
      programGuideList,
      logo: logoImageUrl || logo,
      bgImage: bgImageUrl || bgImage,
      footerInfo: {
        zipCode,
        address,
        chiefName,
        companyRegistrationNum,
        email,
      },
      _id: branchPage._id,
    }));

    alert("저장되었습니다.");
    window.location.reload();
  };

  const addProgramGuide = () => {
    if (programGuideList.length >= 4) {
      alert("프로그램 안내는 최대 4개까지만 추가할 수 있습니다.");
      return;
    }
    if (newProgramGuide.trim()) {
      setProgramGuideList([...programGuideList, newProgramGuide]);
      setNewProgramGuide("");
    }
  };

  const deleteProgramGuide = (index: number) => {
    const updatedList = programGuideList.filter((_, i) => i !== index);
    setProgramGuideList(updatedList);
  };

  return (
    <Box bg="white" color="black" p={10}>
      <Flex justifyContent="center" mb={4}>
        <Text fontSize="2xl" fontWeight="bold">홈페이지 설정</Text>
      </Flex>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>대문</Text>
        <Flex alignItems="center">
          <Text mr={4}>대문 글</Text>
          <Input
            style={{ maxWidth: '700px' }}
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
          />
        </Flex>
      </Box>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>색</Text>
        <Flex alignItems="center">
          <Text mr={4}>상단바(헤더) 배경 색</Text>
          <input
            type="color"
            value={headerBg}
            onChange={(e) => setHeaderBg(e.target.value)}
            style={{ width: '50px', height: '30px', border: 'none', padding: '0' }}
          />
        </Flex>
      </Box>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>색</Text>
        <Flex alignItems="center">
          <Text mr={4}>카테고리 대문 색</Text>
          <input
            type="color"
            value={adminHeaderBg}
            onChange={(e) => setAdminHeaderBg(e.target.value)}
            style={{ width: '50px', height: '30px', border: 'none', padding: '0' }}
          />
        </Flex>
      </Box>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>홈 화면 배경 사진</Text>
        <img
          src={selectedBgImage ? URL.createObjectURL(selectedBgImage) : bgImage}
          alt="bgImage"
          style={{ width: '400px' }}
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setSelectedBgImage(file);
            }
          }}
        />
      </Box>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>로고</Text>
        <img
          src={selectedLogoImage ? URL.createObjectURL(selectedLogoImage) : logo}
          alt="logo"
          style={{ width: '100px' }}
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setSelectedLogoImage(file);
            }
          }}
        />
      </Box>
      <Box mb={6}>
        <Flex mb={2} alignItems="center">
          <Text fontSize="lg" fontWeight="bold" mr={4}>프로그램 안내</Text>
          <Text fontSize="sm" color="red">*프로그램 안내를 삭제하면 포함된 모든 게시글이 삭제됩니다.</Text>
        </Flex>
        <VStack align="start">
          {programGuideList?.map((item, index) => (
            <HStack key={index} maxW={400}>
              <Text flex="1">{item}</Text>
              <IconButton
                aria-label="Delete"
                icon={<DeleteIcon />}
                size="sm"
                onClick={() => deleteProgramGuide(index)}
              />
            </HStack>
          ))}
          <HStack maxW={400}>
            <Input
              placeholder="새 프로그램 안내 추가"
              value={newProgramGuide}
              onChange={(e) => setNewProgramGuide(e.target.value)}
            />
            <IconButton
              aria-label="Add"
              icon={<AddIcon />}
              size="sm"
              onClick={addProgramGuide}
            />
          </HStack>
        </VStack>
      </Box>
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>하단(푸터) 정보</Text>
        <Flex alignItems="center" pb={3}>
          <Text mr={4} w={100}>우편번호</Text>
          <Input
            style={{ maxWidth: '400px' }}
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </Flex>
        <div>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="주소를 입력하세요"
          />
          <button onClick={searchAddress}>주소 검색</button>
          <ul>
            {results.map((result, index) => (
              <li key={index} onClick={() => selectAddress(result.y, result.x)}>
                {result.address_name} / {result.road_address_name}
              </li>
            ))}
          </ul>
          {selectedCoords && (
            <div>
              <h3>선택한 좌표:</h3>
              <p>위도: {selectedCoords.lat}</p>
              <p>경도: {selectedCoords.lng}</p>
            </div>
          )}
          <div id="map" style={{ width: '500px', height: '400px', marginTop: '20px' }}></div>
        </div>
        <Flex alignItems="center" pb={3}>
          <Text mr={4} w={100}>주소</Text>
          <Textarea
            style={{ maxWidth: '400px' }}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            resize="none" // 사용자가 크기를 조절하지 못하게 함
          />
        </Flex>
        <Flex alignItems="center" pb={3}>
          <Text mr={4} w={100}>대표자명</Text>
          <Input
            style={{ maxWidth: '400px' }}
            value={chiefName}
            onChange={(e) => setChiefName(e.target.value)}
          />
        </Flex>
        <Flex alignItems="center" pb={3}>
          <Text mr={4} w={100}>사업자등록번호</Text>
          <Input
            style={{ maxWidth: '400px' }}
            value={companyRegistrationNum}
            onChange={(e) => setCompanyRegistrationNum(e.target.value)}
          />
        </Flex>
        <Flex alignItems="center" pb={3}>
          <Text mr={4} w={100}>이메일</Text>
          <Input
            style={{ maxWidth: '400px' }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Flex>
      </Box>
      <Box>
        <Button colorScheme="blue" onClick={updateHandler}>저장</Button>
      </Box>
      {uploading && <Spinner />}
    </Box>
  );
}
