import { Box, Text } from "@chakra-ui/react";
import React from "react";

interface AdminHeaderBoxProps {
  text: string;
  adminHeaderBg?: string;
}

const AdminHeaderBox: React.FC<AdminHeaderBoxProps> = ({ text, adminHeaderBg }) => {
  return (
    <Box
      bg={adminHeaderBg || "gray.300"}
      color="white"
      textAlign={"center"}
      pt={{
        base: "50px",
        md: "100px",
      }}
      pb={{
        base: "50px",
        md: "100px",
      }}
    >
      <Text fontSize={{ base: "32px", md: "42px" }} fontWeight={"bold"}>
        {text}
      </Text>
    </Box>
  );
};

export default AdminHeaderBox;
