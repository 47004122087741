import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getYouthTeamList } from "../../redux/actions/YouthTeamActions";
import { deleteEtcPayment, deletePaymentLog, deletePaymentLogFileName, getEtcCategoryList, getEtcPaymentList, getPaymentLogList, xlsxUpdate } from "../../redux/actions/PaymentActions";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import accessCheck from "../../utils/accessCheck";
import { Link } from "react-router-dom";

const EtcPaymentListScreen = ({ history }: any) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const teacherInfo = JSON.parse(localStorage.getItem("teacherInfo") || "{}");
  const teacherId = userInfo._id;
  const dispatch: any = useDispatch();

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(Number(new Date().toISOString().slice(5, 7)));
  const [totalAmount, setTotalAmount] = useState(0);

  const [selectedEtcPayments, setSelectedEtcPayments] = useState<any[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<{ value: string; label: string }[]>([]);

  const etcPaymentList = useSelector((state: any) => state.etcPaymentList);
  const { loading, error, etcPayments } = etcPaymentList;

  const etcCategoryList = useSelector((state: any) => state.etcCategoryList);
  const { etcCategories } = etcCategoryList;

  useEffect(() => {
    // if (!accessCheck(["원장", "부원장", "조장"])) {
    //   alert("접근 권한이 없습니다.");
    //   window.location.href = "/youthdb/my";
    // }
  }, []);

  useEffect(() => {
    dispatch(getEtcPaymentList(teacherInfo.branch._id, year.toString(), month.toString()));
    dispatch(getEtcCategoryList(teacherInfo.branch._id, year.toString(), month.toString()));
  }, [dispatch, teacherId, year, month]);

  useEffect(() => {
    if (etcPayments) {
      setSelectedEtcPayments(etcPayments);
    }
  }, [etcPayments]);

  useEffect(() => {
    if (selectedEtcPayments) {
      let total = 0;
      selectedEtcPayments.forEach((etcPayment: any) => {
        total += etcPayment.amount;
      });
      setTotalAmount(total);
    }
  }, [selectedEtcPayments]);

  useEffect(() => {
    if (etcCategories) {
      const options = etcCategories.map((category: any) => ({
        value: category.category,
        label: category.category,
      }));
      
      options.unshift({ value: "전체", label: "전체" });
      options.push({ value: "기타", label: "기타" });
      setCategoryOptions(options);
    }
  }, [etcCategories]);

  useEffect(() => {
    if (month === 13) {
      setYear(year + 1);
      setMonth(1);
    }
    if (month === 0) {
      setYear(year - 1);
      setMonth(12);
    }
  }, [month]);

  const deleteEtcPaymentHandler = (id: any) => {
    dispatch(deleteEtcPayment(id));
  };

  const categoryChangeHandler = (e: any) => {
    let copy = [...etcPayments];

    if (e.target.value === "전체") {
      setSelectedEtcPayments(copy);
    } else if (e.target.value === "기타") {
      copy = copy.filter((p: any) => !etcCategories.some((c: any) => c.category === p.title));
      setSelectedEtcPayments(copy);
    } else {
      copy = copy.filter((p: any) => p.title === e.target.value);
      setSelectedEtcPayments(copy);
    }
  };

  return (
    <Box
      mt={10}
      mx={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack gap={4}>
        <HStack>
          <IconButton
            aria-label="prev"
            icon={<ArrowBackIcon />}
            mx={4}
            onClick={() => {
              setMonth(month - 1);
            }}
          />
          <Text fontSize="2xl" fontWeight="bold">
            {year}년 {month}월
          </Text>
          <IconButton
            aria-label="next"
            icon={<ArrowForwardIcon />}
            mx={4}
            onClick={() => {
              setMonth(month + 1);
            }}
          />
        </HStack>
        <HStack style={{ marginLeft: "auto" }} textAlign={"right"}>
          <Text>
            {month}월 카테고리 목록
          </Text>
          <Select
            w="10rem"
            onChange={categoryChangeHandler}
          >
            {categoryOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <Button
            colorScheme="blue"
            // onClick={() => history.push("/youthdb/etc-category/new")}
            onClick={() => window.location.href = "/youthdb/etc-category/manage"}
          >
            카테고리 관리
          </Button>
        </HStack>
        {loading ? (
          <Spinner />
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          <TableContainer
            minW={{ base: "90vw", md: "80vw", xl: "60vw" }}
            maxW="60vw"
            w="60vw"
            bg="white"
            fontSize={"sm"}
          >
            <Table
              variant="simple"
              size="sm"
            >
              <Thead bg="gray.100">
                <Tr>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    whiteSpace="nowrap"
                    p={1}
                    maxW="6px"
                  >
                    회원명
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    whiteSpace="nowrap"
                    p={1}
                  >
                    내용(카테고리)
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    whiteSpace="nowrap"
                    p={1}
                  >
                    금액
                  </Th>
                  <Th
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    whiteSpace="nowrap"
                    p={1}

                    maxW="80%"
                  >
                    삭제
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {selectedEtcPayments.map((etcPayment: any) => (
                  <Tr key={etcPayment._id}>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      whiteSpace="nowrap"
                      p={1}
                      maxW="6px"
                    >
                      {etcPayment.studentName}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      whiteSpace="nowrap"
                      p={1}
                    >
                      {etcPayment.title}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      whiteSpace="nowrap"
                      p={1}
                    >
                      {etcPayment.amount.toLocaleString()}
                    </Td>
                    <Td
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                      whiteSpace="nowrap"
                      p={1}
                      maxW="1.0rem"
                    >
                      <Button
                        colorScheme="red"
                        onClick={() => deleteEtcPaymentHandler(etcPayment._id)}
                        h={"1.0rem"}
                        fontSize={"0.7rem"}
                      >
                        삭제
                      </Button>
                    </Td>
                  </Tr>
                ))}
                <Tr>
                  <Td
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    whiteSpace="nowrap"
                    p={1}
                    bg="gray.100"
                  >
                    합계
                  </Td>
                  <Td
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    whiteSpace="nowrap"
                    p={1}
                    bg="gray.100"
                  >
                  </Td>
                  <Td
                    textAlign="center"
                    borderWidth="1px"
                    borderColor="gray.400"
                    whiteSpace="nowrap"
                    p={1}
                    bg="gray.100"
                  >
                    {totalAmount.toLocaleString()}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </VStack>
    </Box>
  );
}

export default EtcPaymentListScreen;