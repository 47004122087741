import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  deleteYouthTeam,
  getYouthTeamDetail,
} from "../../redux/actions/YouthTeamActions";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { updateYouthTeam } from "../../redux/actions/YouthTeamActions";
import formatPhoneNum from "../../utils/formatPhoneNum";

const TeamUpdateScreen = () => {
  const { id } = useParams<{ id: string }>();
  const teamId = id ?? "";
  const dispatch: any = useDispatch();

  const youthTeamDetail = useSelector((state: any) => state.youthTeamDetail);
  const { loading, error, youthTeam } = youthTeamDetail;

  const youthTeamUpdate = useSelector((state: any) => state.youthTeamUpdate);
  const { success } = youthTeamUpdate;

  const [name, setName] = useState("");
  const [birthYear, setBirthYear] = useState("");

  useEffect(() => {
    dispatch(getYouthTeamDetail(teamId));
  }, [dispatch, teamId]);

  useEffect(() => {
    setName(youthTeam && youthTeam.name);
    setBirthYear(youthTeam && youthTeam.birthYear);
  }, [youthTeam]);

  const optionsBirthYear = [
    { value: "연합", label: "연합" },
    { value: "초저부", label: "초저부" },
    { value: "초고부", label: "초고부" },
    { value: "중등부", label: "중등부" },
    { value: "고등부", label: "고등부" },
    { value: "기타", label: "기타" },
    { value: "성인", label: "성인" },
  ];

  const removeStudentHandler = (member: any) => {
    const answer = window.confirm(
      `${member.memberName} 회원을 탈퇴 처리하시겠습니까?`
    );
    if (answer) {
      const updatedMemberInfo = youthTeam.memberInfo.filter(
        (m: any) => m.memberId !== member.memberId
      );
      const updatedMembers = youthTeam.members.filter(
        (mId: string) => mId !== member.memberId
      );
      const updatedYouthTeam = {
        ...youthTeam,
        memberInfo: updatedMemberInfo,
        members: updatedMembers,
      };
      
      dispatch(updateYouthTeam(teamId, updatedYouthTeam));
      alert("탈퇴 처리되었습니다.");

      window.location.reload();
    } else {
      return;
    }
  };

  const deleteTeamHandler = () => {
    if (youthTeam.members.length > 0) {
      alert("회원이 0명인 팀만 삭제할 수 있습니다.");
      return;
    }

    const answer = window.confirm(`${youthTeam.name} 팀을 삭제하시겠습니까?`);
    if (!answer) {
      return;
    }

    const updatedYouthTeam = {
      ...youthTeam,
      teacherId: null,
      hiddenAt: new Date(),
    };

    dispatch(updateYouthTeam(teamId, updatedYouthTeam));
    alert("팀이 삭제되었습니다.");

    window.location.href = "/youthdb/youth-student";
  };

  const submitHandler = () => {
    const updatedYouthTeam = {
      name: name,
      teacherId: youthTeam.teacherId,
      branchId: youthTeam.branchId,
      members: youthTeam.members,
      birthYear: birthYear,
    };

    dispatch(updateYouthTeam(teamId, updatedYouthTeam));
    alert("업데이트 되었습니다.");

    window.location.reload();
  };

  return (
    <Box mt={10} mx={6}>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <Box mt={10} mx={6}>
          <VStack>
            <VStack mb={10}>
              <Text fontSize={28} textAlign={"center"} mb={10}>
                팀 정보
              </Text>
              <VStack textAlign="center" minW={"300px"} gap={10}>
                <FormControl>
                  <FormLabel>팀명</FormLabel>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <FormLabel>팀 종류</FormLabel>
                  <Select
                    value={birthYear}
                    onChange={(e) => setBirthYear(e.target.value)}
                  >
                    {optionsBirthYear.map((option, index) => (
                      <option
                        key={option.value}
                        value={option.value}
                        selected={option.value === birthYear}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <Button colorScheme="orange" w={120} onClick={submitHandler}>
                  업데이트
                </Button>
              </VStack>
            </VStack>
            <Box>
              <Text fontSize={28} textAlign={"center"} mb={10}>
                회원 관리
              </Text>
            </Box>
            <TableContainer maxH="2000px" overflowX="auto">
              <Table
                variant="simple"
                colorScheme="orange"
                border="ActiveBorder"
              >
                <Thead bg="gray.100">
                  <Tr>
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                    >
                      성명
                    </Th>
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                    >
                      보호자 연락처
                    </Th>
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                    >
                      휴강 여부
                    </Th>
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                    >
                      관리
                    </Th>
                    <Th
                      textAlign="center"
                      borderWidth="1px"
                      borderColor="gray.400"
                    >
                      출석부
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {youthTeam && youthTeam.memberInfo != null ? (
                    youthTeam.memberInfo.map((member: any) => (
                      <Tr key={member._id}>
                      <Td
                        textAlign="center"
                        borderWidth="1px"
                        borderColor="gray.400"
                        fontWeight={"bold"}
                        p={1}
                        w={40}
                      >
                        <Link
                          to={`/youthdb/youth-student-detail/${member.memberId}`}
                          style={{ textDecoration: "none" }}
                        >
                        {member.memberName}
                        </Link>
                      </Td>
                        <Td
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                        >
                          {member.parentPhoneNum &&
                            formatPhoneNum(member.parentPhoneNum)}
                        </Td>
                        <Td
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                        >
                          {member.isRest ? "O" : "-"}
                        </Td>
                        <Td
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                        >
                          <Button
                            colorScheme="blue"
                            onClick={() =>
                              (window.location.href = `/youthdb/youth-student/update/${member.memberId}`)
                            }
                            mr={2}
                          >
                            수정
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={() => removeStudentHandler(member)}
                          >
                            탈퇴
                          </Button>
                        </Td>
                        <Td
                          textAlign="center"
                          borderWidth="1px"
                          borderColor="gray.400"
                        >
                          <Button
                            colorScheme="blue"
                            onClick={() =>
                              (window.location.href = `/youthdb/attend/${teamId}`)
                            }
                          >
                            출석부
                          </Button>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td textAlign={"center"}>회원이 없습니다.</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Box pt={100}>
              <Button
                colorScheme="red"
                w={50}
                fontSize={"12px"}
                onClick={deleteTeamHandler}
              >
                팀 삭제
              </Button>
            </Box>
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default TeamUpdateScreen;
